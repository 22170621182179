import { IconButton, IconButtonProps } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from './DeleteButton.styles';

import { Icon } from '~ui-kit';

type DeleteButtonProps = IconButtonProps & WithStyles<typeof styles>;

export const DeleteButton = withStyles(styles)(({ classes, ...props }: DeleteButtonProps) => (
  <IconButton classes={{ root: clsx(classes.root) }} {...props}>
    <Icon icon="delete" />
  </IconButton>
));
