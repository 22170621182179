import { Accordion as MuiAccordion, AccordionProps as MuiAccordionProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { styles } from './Accordion.styles';

type AccordionProps = MuiAccordionProps & WithStyles<typeof styles>;

export const Accordion = withStyles(styles)((props: AccordionProps) => (
  <MuiAccordion
    {...props}
    classes={{ root: props.classes.accordion }}
    className={props.classes[props?.className ?? '']}
  >
    {props.children}
  </MuiAccordion>
));
