import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAppInstanceInfo, getFirstHealthyInstance } from '~features/app/appUtils';

import { NotificationKey } from '~models';
import { boundActions, selectors } from '~store';

export const useAppHealthCheck = () => {
  const appInstanceNumber = useSelector(selectors.app.getAppInstanceNumber);
  const instances = useSelector(selectors.app.getAppInstances, equals);
  const healthCheckError = useSelector(selectors.app.getHealthError);

  useEffect(() => {
    const instanceInfo = getAppInstanceInfo(instances, appInstanceNumber as number);

    if (!instanceInfo) {
      return;
    }

    if (instanceInfo?.status !== 'HEALTHY' || Boolean(healthCheckError)) {
      const redirect = getFirstHealthyInstance(instances, appInstanceNumber);

      if (!redirect) {
        boundActions.notifications.enqueue({
          message: 'Cannot redirect. There are no healthy instances.',
          options: {
            key: NotificationKey.CannotRedirect,
            variant: 'warning',
          },
        });
      } else {
        boundActions.app.appRedirectInit({
          timeout: 5000,
          message: `Redirecting to receiver №${redirect.instanceNumber} in 5 sec`,
          redirectTo: redirect.ipAddress,
        });
      }
    }
  }, [appInstanceNumber, instances, healthCheckError]);
};
