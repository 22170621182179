import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';

import { IPField, IPFieldProps } from '../Fields/IPField';

import { styles } from './styles';

export type FormIPFieldProps = Partial<IPFieldProps> &
  WithStyles<typeof styles> & {
    name: string;
    cleanable?: boolean;
  };

export const FormIPField = withStyles(styles)(
  ({ name, showing, classes, onClean, cleanable, ...props }: FormIPFieldProps) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Field name={name}>
        {({ field, meta: { error, touched } }) => (
          <IPField
            {...props}
            {...field}
            showing={showing}
            error={touched && Boolean(error)}
            helperText={(touched && error) || props.helperText}
            classes={{ root: clsx(classes.root) }}
            onClean={
              (onClean || cleanable) && !showing
                ? () => setFieldValue(name, typeof field.value === 'string' ? '' : 0)
                : undefined
            }
          />
        )}
      </Field>
    );
  }
);
