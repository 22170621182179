import { ArgumentScale, EventTracker, Stack, ValueScale } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis,
  ValueAxis,
  Chart as DevXChart,
  Legend,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Plugin } from '@devexpress/dx-react-core';
import { WithStyles, withStyles, Grid } from '@material-ui/core';
import { Fragment } from 'react';

import { FilterOption } from '~ui-kit/Filter';

import { GridLine } from './ChartGridLine';
import { styles } from './StackChart.styles';

export interface BarData {
  arg: string;
  [key: string]: number | string;
}

export interface BarChartProps<T> extends WithStyles<typeof styles> {
  title?: string;
  data: T[];
  argumentValues?: FilterOption[];
  maxValue?: number;
}

type ChartRootProps = WithStyles<typeof styles> & DevXChart.RootProps;
const ChartRootComponent = withStyles(styles)(({ classes, children, ...restProps }: ChartRootProps) => (
  <Grid {...restProps} className={classes.chart}>
    {children}
  </Grid>
));

type LegenRootProps = WithStyles<typeof styles> & Legend.RootProps;
const LegendRoot = withStyles(styles)(({ classes, ...restProps }: LegenRootProps) => (
  <Legend.Root className={classes.legend} {...restProps} />
));

type LegendItemProps = WithStyles<typeof styles> & Legend.ItemProps;
const LegendItem = withStyles(styles)(({ classes, ...restProps }: LegendItemProps) => (
  <Legend.Item {...restProps} className={classes.legendItem} />
));

const LegendLabel = props => <Legend.Label {...props} />;

type ArgumentAxisLabelProps = WithStyles<typeof styles> & ArgumentAxis.LabelProps;
const ArgumentAxisLabel = withStyles(styles)(({ classes, ...restProps }: ArgumentAxisLabelProps) => (
  <ArgumentAxis.Label {...restProps} className={classes.axisLabel} />
));

type ValueAxisLabelProps = WithStyles<typeof styles> & ValueAxis.LabelProps;
const ValueAxisLabel = withStyles(styles)(({ classes, ...restProps }: ValueAxisLabelProps) => (
  <ValueAxis.Label {...restProps} className={classes.axisLabel} />
));

type TooltipSheetProps = WithStyles<typeof styles> & Tooltip.SheetProps;
const TooltipSheet = withStyles(styles)(({ classes, children }: TooltipSheetProps) => (
  <Tooltip.Sheet className={classes.tooltip}>{children}</Tooltip.Sheet>
));

type TooltipContentProps = WithStyles<typeof styles> & Tooltip.ContentProps;
const TooltipContent = withStyles(styles)(({ color, classes, text }: { color?: string } & TooltipContentProps) => (
  <Fragment>
    <div className={classes.tooltipContent}>
      <span className={classes.tooltipIndicator} style={{ backgroundColor: color }} />
      <span>{`${text} units`}</span>
    </div>
  </Fragment>
));

type TooltipArrowProps = WithStyles<typeof styles> & Tooltip.ArrowProps;
const TooltipArrow = withStyles(styles)(({ classes, ...props }: TooltipArrowProps) => (
  <Tooltip.Arrow className={classes.tooltipArrow} {...props} />
));

const BarSeriesPoint = props => <BarSeries.Point {...props} maxBarWidth={15} />;

export const BarChart = withStyles(styles)(
  <T extends unknown>({ data, maxValue, argumentValues }: BarChartProps<T>) => {
    const modifyDomain = () => [0, maxValue];

    return (
      <DevXChart data={data} rootComponent={ChartRootComponent}>
        <Legend position="bottom" rootComponent={LegendRoot} itemComponent={LegendItem} labelComponent={LegendLabel} />
        <ArgumentAxis labelComponent={ArgumentAxisLabel} />
        <ArgumentScale />
        <ValueAxis gridComponent={GridLine} labelComponent={ValueAxisLabel} />
        <ValueScale modifyDomain={modifyDomain} />
        <EventTracker />
        <Tooltip
          sheetComponent={TooltipSheet}
          contentComponent={props => (
            <TooltipContent
              // @ts-ignore
              color={argumentValues.find(field => field.label === props.targetItem.series)?.color}
              text={data[props.targetItem.point]?.[props.targetItem.series]}
              targetItem={props.targetItem}
            />
          )}
          arrowComponent={props => <TooltipArrow {...props} />}
        />
        <Plugin>
          {argumentValues?.map(field => (
            <BarSeries
              key={field.label}
              barWidth={1}
              name={field.label}
              pointComponent={BarSeriesPoint}
              valueField={field.label}
              color={field.color}
              argumentField="timestamps"
            />
          ))}
          <Stack
            stacks={[
              {
                series: argumentValues?.map(field => field.label) as string[],
              },
            ]}
          />
        </Plugin>
      </DevXChart>
    );
  }
);
