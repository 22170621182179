import { createStyles, Theme } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = ({ spacing, palette }: Theme) => {
  const getStyles = matchStyles(palette.type);

  return createStyles({
    root: {
      padding: spacing(3),
      borderTop: `1px solid ${getStyles(palette.greyColor.dark, palette.greyColor.main)}`,

      '& > *': {
        '&:first-child': {
          marginLeft: spacing(0),
        },

        '&:not(:first-child)': {
          marginLeft: spacing(2),
        },
      },
    },
  });
};
