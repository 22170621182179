import { Button, ButtonGroup, IconButton, Typography, Grid, withStyles, Theme } from '@material-ui/core';
import { useMemo } from 'react';

import { Icon } from '~ui-kit';
import { getCountTitle } from '~utils';

export const GridWrapper = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    minHeight: 36,
  },
}))(Grid);

export type TabSectionItem<T> = {
  label: string;
  title?: string;
  tooltip: string;
  section: T;
  hideIcon?: boolean;
}

type TabSectionsProps = {
  section: string;
  items: TabSectionItem<string>[];
  onChange(section: string): void;
  onOpen?: (section: string) => void;
  countNumber?: number;
};

export const TabSections = ({ section, countNumber = 0, onChange, onOpen, items }: TabSectionsProps) => {
  const selected = useMemo(() => items.find(config => config.section === section), [section]);
  const countTitle = getCountTitle(countNumber, selected?.title || selected?.label);

  return (
    <GridWrapper>
      <Typography color="primary" variant="h3">
        {countTitle}

        {!(items.some(item => (item.section === section && item.hideIcon))) ? (
          <IconButton onClick={() => onOpen?.(section)}>
            <Icon icon="info" />
          </IconButton>
        ) : null}
      </Typography>

      <ButtonGroup>
        {items.map(config => (
          <Button
            size="small"
            color="primary"
            variant={config.section === section ? 'contained' : 'outlined'}
            key={config.section}
            onClick={() => onChange(config.section)}
          >
            {config.label}
          </Button>
        ))}
      </ButtonGroup>
    </GridWrapper>
  );
};

