import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      '& + .form-panel': {
        minHeight: theme.spacing(4),
        marginTop: theme.spacing(2),
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    title: {
      marginRight: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    subTitle: {
      width: '100%',
      color: theme.palette.greyColor.main,
      lineHeight: 1.5,
    },
    header: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',

      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
    },
    titleIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.blueColor.main,
    }
  });
