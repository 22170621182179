import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { assoc, omit } from 'ramda';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as faultActions } from '../faults/faultsSlice';
import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { api, appRoutes } from '~constants';
import {
  IPLink,
  PaginationResponse,
  IPLinksLocation,
  ResponseError,
  PaginationRequestPayload,
  PageSorting,
  PageFilters,
  UnitID,
  IPLinksListSection,
  Fault,
  IPLinkHistory,
  IPLinkHistoryPeriod,
  IPLinkNotifications,
  AssignedDealer,
} from '~models';
import { http } from '~services';
import {
  updateArray,
  getResponseError,
  getResponsePayload,
  updateArrayWithCallback,
  intToHex,
  findDataByCallback,
  saveFile,
} from '~utils';

export interface IPLinksState {
  data: IPLink[];
  ids: number[];
  loading: {
    list: boolean;
    ids: boolean;
    details: {
      dealers: boolean;
      general: boolean;
      update: boolean;
      reset: boolean;
      history: {
        all: boolean;
        export: boolean;
      };
      notifications: boolean;
    };
    dependencies: boolean;
    delete: boolean;
    restore: boolean;
    find: boolean;
  };
  details: {
    status: 'updated' | null;
  };
  error?: ResponseError | null;
  status: 'deleted' | 'found' | null;
}

export const initialState: IPLinksState = {
  data: [],
  ids: [],
  loading: {
    list: false,
    ids: false,
    details: {
      general: false,
      update: false,
      reset: false,
      history: {
        all: false,
        export: false,
      },

      notifications: false,
      dealers: false,
    },
    dependencies: false,
    delete: false,
    restore: false,
    find: false,
  },
  details: {
    status: null,
  },
  error: null,
  status: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'ipLinks',
  initialState,
  reducers: {
    // Fetch ip links
    fetchIpLinksInit(state, payload: PayloadAction<PaginationRequestPayload & PageFilters & PageSorting>) {
      state.loading.list = true;
      state.status = null;
    },
    fetchIpLinksSuccess: (state, { payload: { content, number } }: PayloadAction<PaginationResponse<IPLink>>) => {
      state.loading.list = false;

      state.data = content;
    },
    fetchIpLinksFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.list = false;
      state.error = payload;
    },

    // Fetch ip links ids
    fetchIpLinksIdsInit(
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: IPLink['businessUnitId'] }>
    ) {
      state.loading.ids = true;
    },
    fetchIpLinksIdsSuccess: (state, { payload: { content } }: PayloadAction<PaginationResponse<number>>) => {
      state.loading.ids = false;

      state.ids = content;
    },
    fetchIpLinksIdsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.ids = false;
      state.error = payload;
    },

    // Fetch ip link details
    fetchIpLinkByIdInit: (
      state,
      {
        payload: { id },
      }: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId']; notify?: boolean }>
    ) => {
      state.loading.details.general = true;
      state.status = null;
    },
    fetchIpLinkByIdSuccess: (state, { payload }: PayloadAction<IPLink>) => {
      state.loading.details.general = false;
      state.data = updateArray(state.data, payload);
    },
    fetchIpLinkByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.general = false;
      state.error = payload;
    },

    // Fetch ip links by business unit
    fetchIpLinksByBusinessUnitInit: (
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & { businessUnitId: IPLink['businessUnitId']; section?: IPLinksListSection }
      >
    ) => {
      state.loading.list = true;
      state.status = null;
    },

    fetchIpLinksByBusinessUnitSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<IPLink>>
    ) => {
      state.loading.list = false;
      state.data = content;
    },

    fetchIpLinksByBusinessUnitFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = action.payload;
    },

    // Find ip link by business unit
    findIPLinkByBusinessUnitInit: (
      state,
      { payload }: PayloadAction<{ buId: IPLink['businessUnitId']; ipLinkId: IPLink['id'] }>
    ) => {
      state.loading.find = true;
      state.status = null;
    },
    findIPLinkByBusinessUnitSuccess: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.status = 'found';
      }
      state.loading.find = false;
    },
    findIPLinkByBusinessUnitFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.find = false;
      state.error = action.payload;
    },

    // delete ip link
    deleteIpLinkInit(
      state,
      {
        payload,
      }: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId']; location: IPLinksLocation }>
    ) {
      state.loading.delete = true;
    },
    deleteIpLinkSuccess(state) {
      state.loading.delete = false;
      state.status = 'deleted';
    },
    deleteIpLinkFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.delete = false;
      state.error = payload;
      state.status = null;
    },

    // Reset ip links
    resetIpLinks(state) {
      Object.assign(state, initialState);
    },

    // Update ip link details

    updateIPLinkDetailsInfoInit(
      state,
      {
        payload,
      }: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId']; values: Partial<IPLink> }>
    ) {
      state.loading.details.update = true;
      state.details.status = null;
    },

    updateIPLinkDetailsInfoSuccess(state, { payload }: PayloadAction<IPLink>) {
      state.loading.details.update = false;
      state.details.status = 'updated';

      const omitedPayload = omit(
        [
          'lastConnectionTime',
          'businessUnitName',
          'firstConnectionTime',
          'supervisionIntervalSecs',
          'ppPacketCount',
          'ppPacketCount',
          'lastUpdate',
          'lastConnectionTime',
          'connectInterval',
          'connectCount',
          'isOffline',
          'model',
          'packetCount',
          'dependentDay',
          'dependentDecade',
          'revision',
          'faultCount',
        ],
        payload
      );

      const actualyPayload = { ...state.data, ...omitedPayload };

      state.data = updateArrayWithCallback(state.data, actualyPayload);
    },

    updateIPLinkDetailsInfoFailed(
      state,
      {
        payload: { error },
      }: PayloadAction<{
        error: ResponseError;
      }>
    ) {
      state.loading.details.update = false;
      state.error = error;
    },

    // Reset ip link
    requestIpLinkResetInit(
      state,
      { payload }: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId'] }>
    ) {
      state.loading.details.reset = true;
    },
    requestIpLinkResetSuccess(state) {
      state.loading.details.reset = false;
    },
    requestIpLinkResetFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.details.reset = false;
      state.error = error;
    },

    // Fetch ip link dependencies
    fetchIpLinkDependenciesInit: (
      state,
      {
        payload: { id },
      }: PayloadAction<
        PaginationRequestPayload & { id: IPLink['id']; businessUnitId: IPLink['businessUnitId']; period: string }
      >
    ) => {
      state.loading.dependencies = true;
    },
    fetchIpLinkDependenciesSuccess: (
      state,
      { payload: { id, content } }: PayloadAction<PaginationResponse<UnitID[]> & { id: IPLink['id'] }>
    ) => {
      state.loading.dependencies = false;

      const unit = findDataByCallback<IPLink>(state.data, ipLink => ipLink.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('dependent' as keyof IPLink, content, unit));
      }
    },
    fetchIpLinkDependenciesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.dependencies = false;
      state.error = payload;
    },

    restoreIPLinkFaultInit(
      state,
      {
        payload,
      }: PayloadAction<{
        buId: IPLink['businessUnitId'];
        cid: Fault['cid'];
        eventCode: Fault['eventCode'];
        id: IPLink['id'];
        zoneCode: Fault['zoneCode'];
      }>
    ) {
      state.loading.restore = true;
    },

    restoreIPLinkFaultSuccess(state) {
      state.loading.restore = false;
    },

    restoreIPLinkFaultFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.restore = false;
      state.error = payload;
    },

    fetchIPLinkHistoryInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: IPLink['businessUnitId'];
          period: IPLinkHistoryPeriod;
          id: IPLink['id'];
        }
      >
    ) => {
      state.loading.details.history.all = true;
    },
    fetchIPLinkHistorySuccess: (state, { payload: { content } }: PayloadAction<PaginationResponse<IPLinkHistory>>) => {
      state.loading.details.history.all = false;

      const unit = findDataByCallback<IPLink>(state.data, ipLink => ipLink.id === ipLink.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('eventHistory' as keyof IPLink, content, unit));
      }
    },
    fetchIPLinkHistoryFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.history.all = false;
      state.error = payload;
    },

    exportIPLinkEventHistoryInit: (
      state,
      { payload }: PayloadAction<{ buId: IPLink['businessUnitId']; id: IPLink['id']; period: IPLinkHistoryPeriod }>
    ) => {
      state.loading.details.history.export = true;
    },
    exportIPLinkEventHistorySuccess: state => {
      state.loading.details.history.export = false;
    },
    exportIPLinkEventHistoryFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.history.export = false;
      state.error = payload;
    },

    // Fetch IPLink notifications
    fetchIpLinkNotificationsInit: (
      state,
      action: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId'] }>
    ) => {
      state.loading.details.notifications = true;
    },
    fetchIpLinkNotificationsSuccess: (
      state,
      { payload: { notifications, id } }: PayloadAction<{ notifications: IPLinkNotifications; id: IPLink['id'] }>
    ) => {
      const ipLink = findDataByCallback<IPLink>(state.data, ipLink => ipLink.id === id);

      if (ipLink) {
        state.data = updateArrayWithCallback(state.data, assoc('notifications' as keyof IPLink, notifications, ipLink));
      }

      state.loading.details.notifications = false;
    },
    fetchIpLinkNotificationsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.notifications = false;
      state.error = payload;
    },

    //  Update IPLink notifications
    updateIPLinkNotificationsInit(
      state,
      {
        payload: { businessUnitId, id, values },
      }: PayloadAction<{
        id: IPLink['id'];
        businessUnitId: IPLink['businessUnitId'];
        values: Partial<IPLinkNotifications>;
      }>
    ) {
      state.loading.details.notifications = true;
    },

    // Fetch assigned dealers to ip-link
    fetchAssignedDealersInit: (
      state,
      action: PayloadAction<{ id: IPLink['id']; businessUnitId: IPLink['businessUnitId'] }>
    ) => {
      state.loading.details.dealers = true;
    },
    fetchAssignedDealersSuccess: (
      state,
      {
        payload: { dealers, id, businessUnitId },
      }: PayloadAction<{
        dealers: AssignedDealer[];
        id: IPLink['id'];
        businessUnitId: IPLink['businessUnitId'];
      }>
    ) => {
      const ipLink = findDataByCallback<IPLink>(state.data, ipLink => ipLink.id === id);
      if (ipLink) {
        state.data = updateArrayWithCallback(state.data, assoc('dealers' as keyof IPLink, dealers, ipLink));
      } else {
        state.data = [{ businessUnitId, id, dealers } as IPLink];
      }

      state.loading.details.dealers = false;
    },
    fetchAssignedDealersFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.dealers = false;
      state.error = payload;
    },

    // Reset IP Link status
    resetStatus(state) {
      state.status = null;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getIPLinksState = (state: AES.RootState) => state.ipLinks;

export const selectors = {
  getIPLinksState,
  getIpLinkById: (id: IPLink['id']) =>
    createDraftSafeSelector(getIPLinksState, state => state.data.find(sub => sub.id === id) as IPLink),

  getLoaders: createDraftSafeSelector(getIPLinksState, state => state.loading),
  getIpLinksData: createDraftSafeSelector(getIPLinksState, state => state.data),
  getIpLinksIds: createDraftSafeSelector(getIPLinksState, state => state.ids),
  getIpLinkStatus: createDraftSafeSelector(getIPLinksState, state => state.status),
  getIpLinkDetailsStatus: createDraftSafeSelector(getIPLinksState, state => state.details),
  isIPLinkFound: createDraftSafeSelector(getIPLinksState, state => state.status === 'found'),
  getIpLinkErrors: createDraftSafeSelector(getIPLinksState, state => state.error),
  getDealers: (id: IPLink['id'], businessUnitId: IPLink['businessUnitId']) =>
    createDraftSafeSelector(
      getIPLinksState,
      state =>
        state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)?.dealers as AssignedDealer[]
    ),

  getIPLinkNotifications: (id: IPLink['id'], businessUnitId: IPLink['businessUnitId']) =>
    createDraftSafeSelector(
      getIPLinksState,
      state =>
        state.data.find(ipLink => ipLink.id === id && ipLink.businessUnitId === businessUnitId)
          ?.notifications as IPLinkNotifications
    ),
};

const fetchIpLinksEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinksInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<IPLink>>(api.ipLinks.list(searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchIpLinksSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinksFailed(error));
        })
      );
    })
  );

const fetchIpLinksIdsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinksIdsInit.match),
    mergeMap(({ payload: { businessUnitId, initial, pagination } }) => {
      const searchParams = getPaginationQueryParams({ initial, pagination });

      return http.getJSON<PaginationResponse<number>>(api.ipLinks.ids(businessUnitId, searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchIpLinksIdsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinksIdsFailed(error));
        })
      );
    })
  );

const fetchBusinessUnitIpLinksEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinksByBusinessUnitInit.match),
    mergeMap(({ payload: { pagination, initial, businessUnitId, section = 'all' } }) => {
      const searchParams = getPaginationQueryParams({ pagination, initial });

      return http.getJSON<PaginationResponse<IPLink>>(api.ipLinks.byBU(businessUnitId, section, searchParams)).pipe(
        mergeMap(res =>
          of(
            actions.fetchIpLinksByBusinessUnitSuccess(res),
            pageActions.setPagePagination(extractPaginationValues(res))
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinksByBusinessUnitFailed(error));
        })
      );
    })
  );

const fetchIpLinkByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinkByIdInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify } }) =>
      http.getJSON<IPLink>(api.ipLinks.byId(businessUnitId, id)).pipe(
        mergeMap(ipLink => {
          const epicActions: AnyAction[] = [pageActions.setPageFound(), actions.fetchIpLinkByIdSuccess(ipLink)];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'IP Link general info updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinkByIdFailed(error), pageActions.setPageNotFound());
        })
      )
    )
  );

const findIPLinkByBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.findIPLinkByBusinessUnitInit.match),
    mergeMap(({ payload: { buId, ipLinkId } }) =>
      http.getJSON<PaginationResponse<number>>(api.ipLinks.ids(buId)).pipe(
        mergeMap(({ content }) => {
          const isIncluded = content.includes(ipLinkId);
          const epicActions: AnyAction[] = [actions.findIPLinkByBusinessUnitSuccess(isIncluded)];

          if (isIncluded) {
            epicActions.push(
              notificationsActions.enqueue({
                message: `IP Link ${intToHex(ipLinkId)} found`,
                options: { variant: 'success' },
              })
            );
          } else {
            epicActions.push(
              notificationsActions.enqueue({
                message: `IP Link ${intToHex(ipLinkId)} not found`,
                options: { variant: 'error' },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.findIPLinkByBusinessUnitFailed(error));
        })
      )
    )
  );

const deleteIpLinkEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteIpLinkInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.delete(api.ipLinks.byId(businessUnitId, id)).pipe(
        mergeMap(() => {
          const deleteIpLinkActions: AnyAction[] = [
            actions.deleteIpLinkSuccess(),
            push(appRoutes.ipLinks),
            notificationsActions.enqueue({
              message: `IP Link ${intToHex(id)} has been deleted`,
              options: { variant: 'success' },
            }),
          ];

          return of(...deleteIpLinkActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteIpLinkFailed(error));
        })
      )
    )
  );

const updateIPLinkDetailsInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateIPLinkDetailsInfoInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.patch(api.ipLinks.byId(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.updateIPLinkDetailsInfoSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'IP Link detail info updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateIPLinkDetailsInfoFailed({ error }));
        })
      )
    )
  );

const requestIPLinkResetEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestIpLinkResetInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.post(api.ipLinks.reset(businessUnitId, id)).pipe(
        mergeMap(() =>
          of(
            actions.requestIpLinkResetSuccess(),
            push(appRoutes.ipLinks),
            notificationsActions.enqueue({
              message: `IP Link ${intToHex(id as number)} has been reset`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.requestIpLinkResetFailed({ error }));
        })
      )
    )
  );

const fetchIpLinksDependenciesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinkDependenciesInit.match),
    mergeMap(({ payload: { businessUnitId, id, period, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<UnitID[]>>(api.ipLinks.dependencies(businessUnitId, id, period, searchParams))
        .pipe(
          mergeMap(res =>
            // eslint-disable-next-line max-len
            of(
              actions.fetchIpLinkDependenciesSuccess({ ...res, id }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchIpLinkDependenciesFailed(error));
          })
        );
    })
  );

const IPLinkFaultRestoreEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.restoreIPLinkFaultInit.match),
    mergeMap(({ payload: { buId, cid, eventCode, id, zoneCode } }) =>
      http.post(api.ipLinks.restore(buId, id), { buId, cid, eventCode, id, zoneCode }).pipe(
        mergeMap(() => of(
          faultActions.fetchFaultsInit({ apiUrl: api.faults.ipLink(buId, id) }),
          actions.restoreIPLinkFaultSuccess(),
          notificationsActions.enqueue({
            message: 'IP Link Fault Restored',
            options: { variant: 'success' },
          })
        )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.restoreIPLinkFaultFailed(error));
        })
      )
    )
  );

const fetchIPLinkHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchIPLinkHistoryInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<IPLinkHistory>>(
          api.ipLinks.eventHistory.all(payload.businessUnitId, payload.id, payload.period, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(actions.fetchIPLinkHistorySuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchIPLinkHistoryFailed(error));
          })
        );
    })
  );

const exportIPLinkEventHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportIPLinkEventHistoryInit.match),
    mergeMap(({ payload: { buId, id, period } }) =>
      http
        .call({
          method: 'GET',
          url: api.ipLinks.eventHistory.export(buId, id, period),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `${period}_Event_History.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportIPLinkEventHistorySuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportIPLinkEventHistoryFailed(getResponseError(err))))
        )
    )
  );

const fetchIPLinkNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchIpLinkNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<IPLinkNotifications>(api.ipLinks.notifications(businessUnitId, id)).pipe(
        mergeMap(notifications => of(actions.fetchIpLinkNotificationsSuccess({ notifications, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinkNotificationsFailed(error));
        })
      )
    )
  );

const updateIPLinkNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateIPLinkNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.put(api.ipLinks.notifications(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchIpLinkNotificationsSuccess({ notifications: getResponsePayload(res), id }),
            notificationsActions.enqueue({
              message: 'IP Link notification updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchIpLinkNotificationsFailed(error));
        })
      )
    )
  );

const fetchIPLinkDealersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAssignedDealersInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<AssignedDealer[]>(api.ipLinks.dealers(businessUnitId, id)).pipe(
        mergeMap(dealers => of(actions.fetchAssignedDealersSuccess({ dealers, id, businessUnitId }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchAssignedDealersFailed(error));
        })
      )
    )
  );

export const epics = combineEpics(
  fetchIpLinksEpic,
  fetchBusinessUnitIpLinksEpic,
  fetchIpLinkByIdEpic,
  findIPLinkByBusinessUnitEpic,
  deleteIpLinkEpic,
  updateIPLinkDetailsInfoEpic,
  requestIPLinkResetEpic,
  fetchIpLinksDependenciesEpic,
  IPLinkFaultRestoreEpic,
  fetchIPLinkHistoryEpic,
  exportIPLinkEventHistoryEpic,
  fetchIPLinkNotificationsEpic,
  updateIPLinkNotificationsEpic,
  fetchIpLinksIdsEpic,
  fetchIPLinkDealersEpic
);

export const allEpics = {
  fetchIpLinksEpic,
  fetchBusinessUnitIpLinksEpic,
  fetchIpLinkByIdEpic,
  findIPLinkByBusinessUnitEpic,
  deleteIpLinkEpic,
  updateIPLinkDetailsInfoEpic,
  requestIPLinkResetEpic,
  fetchIpLinksDependenciesEpic,
  IPLinkFaultRestoreEpic,
  fetchIPLinkHistoryEpic,
  exportIPLinkEventHistoryEpic,
  fetchIPLinkNotificationsEpic,
  updateIPLinkNotificationsEpic,
  fetchIpLinksIdsEpic,
  fetchIPLinkDealersEpic,
};

declare global {
  namespace AES {
    export interface RootState {
      ipLinks: IPLinksState;
    }
    export interface Actions {
      ipLinks: typeof actions;
    }
    export interface Selectors {
      ipLinks: typeof selectors;
    }
  }
}
