import { PaletteType } from '@material-ui/core';

export const matchStyles = <T extends string | number>(type: PaletteType) => (dark: T, light: T): string => {
  if (type === 'dark') {
    return `${dark}`;
  }

  return `${light}`;
};

export const maskImageStyles = (icon: string) => ({ maskImage: `url(${icon})` });
