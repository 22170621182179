import { Box, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { styles } from './Logo.styles';

import { selectors } from '~store';

export const Logo = withStyles(styles)(({ classes, ...props }: WithStyles<typeof styles>) => {
  const isExpanded = useSelector(selectors.layout.isExpanded);

  return (
    <Box
      {...props}
      component="i"
      className={[
        clsx(classes.logo, {
          [classes.collapsed]: !isExpanded,
        }),
      ].join(' ')}
    />
  );
});
