import { is } from 'ramda';

import { parseTimeValues } from '~utils';

const valueToString = (value?: number) => {
  if (!is(Number, value) || (value as number) <= 0) {
    return '00';
  }

  let string = (value as number).toString();

  while (string.length < 2) {
    string = `0${string}`;
  }

  return string;
};

export const prepareTimeFieldValue = (hours?: number, minutes?: number): string =>
  [valueToString(hours), valueToString(minutes)].join(':');

export const parseTimeFieldValue = (time: string, divider = ':'): { hours: number; minutes: number } => {
  const parseTime = time.split(divider);

  return { hours: parseInt(parseTime[0]), minutes: parseInt(parseTime[1]) };
};

export const convertTimeFieldValue = (value: string, divider = ':') => {
  const date = new Date();
  const [hours, minutes] = parseTimeValues(value, divider);

  date.setHours(hours, minutes);

  return date.toString();
};

export const prepareFromToTimeFieldValues = (from: string, to: string) => {
  const [fromDate, toDate] = [new Date(from), new Date(to)];

  return {
    from: prepareTimeFieldValue(fromDate.getHours(), fromDate.getMinutes()),
    to: prepareTimeFieldValue(toDate.getHours(), toDate.getMinutes()),
  };
};
