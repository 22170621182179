import { Button, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useMemo } from 'react';

import { DialogContent as UIKitDialogContent } from '~ui-kit/Dialog';

import { Dialog, DialogTitle, DialogActions } from '~ui-kit';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    '& p': {
      marginBottom: theme.spacing(2),
    },
    '& ul': {
      padding: 0,
      listStyle: 'inside',
    },
  },
}))(UIKitDialogContent);

type InfoSectionDialogProps = {
    open: boolean;
    section?: string;
    onConfirm: () => void;
    getContent: (section?: string) => { title: string; content };
  };

export const InfoSectionDialog = ({
  open = false,
  section = '',
  onConfirm,
  getContent,
  ...props
}: InfoSectionDialogProps) => {
  const { title, content } = useMemo(() => getContent(section), [section]);

  return (
    <Dialog open={open} fullWidth {...props}>
      <DialogTitle title={title} />
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
