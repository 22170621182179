import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';

import { http } from '~services/http';
import { getResponseError } from '~utils/response';

import { actions as sharedActions } from '../sharedSlice';

import { AvailableTrigger, ResponseError } from '~models';

export interface AvailableTriggersState {
    data: AvailableTrigger[] | null;
    loading: boolean;
    error?: ResponseError | null;
}

export const initialState: AvailableTriggersState = {
  data: null,
  loading: false,
  error: null
};

export const { name, reducer, actions } = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    // Fetch available triggers
    fetchAvailableTriggersInit(state, { payload }: PayloadAction<{businessUnitId: number}>) {
      state.loading = true;
    },
    fetchAvailableTriggersSuccess(
      state,
      { payload }: PayloadAction<AvailableTrigger[]>
    ) {
      state.loading = false;
      state.data = payload;
    },
    fetchAvailableTriggersFailed(state, action: PayloadAction<ResponseError>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getTriggersState = (state: AES.RootState) => state.triggers;

export const selectors = {
  getTriggersState,
  getAvailableTriggersData: createDraftSafeSelector(getTriggersState, state => state.data),
};

const fetchAvailableTriggers = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchAvailableTriggersInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<AvailableTrigger[]>(`/api/business-units/${payload.businessUnitId}/triggers`).pipe(
        mergeMap(values => of(actions.fetchAvailableTriggersSuccess(values))),
        catchError(err => of(actions.fetchAvailableTriggersFailed(getResponseError(err)))
        )
      )
    ));

export const epics = combineEpics(
  fetchAvailableTriggers,
);

export const allEpics = {
  fetchAvailableTriggers,
};

  declare global {
    namespace AES {
      export interface Actions {
        triggers: typeof actions;
      }
      export interface RootState {
        triggers: AvailableTriggersState;
      }
      export interface Selectors {
        triggers: typeof selectors;
      }
    }
  }

