import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      flexShrink: 0,
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    title: {
      fontWeight: 600,
    },
  });
