import {
  AlarmAutomationDelivery,
  AlarmAutomationFormat,
  AlarmAutomation,
  AlarmAutomationPrimarySocket,
} from './alarmAutomation';
import { SubscriberRFInfo, SubscriberTTLInfo } from './subscriber';
import { UnitID, UnitType } from './unit';
import { UserRole } from './user';

export type BusinessUnitId = {
  id: BusinessUnit['id'];
  name: BusinessUnit['name'];
};
export interface BusinessUnitHealth {
  /** DB data indicator */
  isDataPresent: boolean;
  businessUnitId: number;
  networkHealthScore: number;
  ackDelaysPoint: number;
  ackDelaysUnitIds: UnitID[];
  ackDelaysUnitCount: number;
  ipLinkAndHybridPoint: number;
  ipLinkAndHybridUnitIds: UnitID[];
  ipLinkAndHybridUnitCount: number;
  laterCheckInPoint: number;
  laterCheckInUnitIds: UnitID[];
  laterCheckInUnitCount: number;
  subscriberPoint: number;
  subscriberUnitIds: UnitID[];
  subscriberUnitCount: number;
}

export type BusinessUnitAlarmAutomation = { id: number; port: number; primaryIp: string };
export type BusinessUnitTTLSettings = {
  checkInTTL: number;
  alarmTTL: number;
  statusTTL: number;
  troubleTTL: number;
  restoralTTL: number;
  intelliTapTTL: number;
  specialsTTL: number;
  timeRangeFrom: string;
  timeRangeTo: string;
};
export interface BusinessUnit {
  id: number;
  name: string;
  status: 'UP' | 'DOWN';
  logo?: string;
  universal: boolean;
  ipLinks: number[];
  ipGroups: number[];
  ipLinksCount: number;
  ipGroupsCount: number;
  subscribersCount: number;
  receiverNumber: number;
  automationFormat: AlarmAutomationFormat;
  alarmAutomationId?: number;
  alarmAutomation?: AlarmAutomationPrimarySocket;
  oldAlarms: AlarmAutomationDelivery;
  isDefault?: boolean;
  health?: BusinessUnitHealth;
  healthPopup?: BusinessUnitHealthPopUp;
  networkHealthScore: number | null;
  networkPulse?: NetworkPulse;
  rfSettings?: SubscriberRFInfo[];
  ttlSettings?: BusinessUnitTTLSettings;
  ttlSettingsSubscribers?: SubscriberTTLInfo[] | null;
  activeUsersCount?: number;
  mesh: Mesh[];
  recipientTrigger: RecipientTrigger;
  notificationAssociation: NotificationAssociation;
  mobileCarriers: MobileCarrier[] | null;
  netCon: NetConSettings;
  totalSignals?: TotalSignals;
  subsOverTime?: SubsOverTime;
  geoCenter?: BusinessUnitGeoCenter;
  badPackets?: BusinessUnitBadPackets[];
  unitsNotifications: {
    list: BusinessUnitUnitsNotifications[] | null;
    info: BusinessUnitUnitNotificationInfo;
  };
}

export interface DefaultBusinessUnit extends Omit<BusinessUnit, 'alarmAutomation'> {
  alarmAutomation?: Partial<AlarmAutomation>;
}

export interface BusinessUnitStatuses {
  [key: string]: BusinessUnit['status'];
}

export interface BusinessUnitIPLinkLoad {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  country: string;
  businessUnitId: number;
  packets: number;
  percent: number;
  unitId: number;
  unitType: UnitType;
  dealersCount: number;
}

export interface NetworkPulse {
  rfCheckins: number[];
  ipCheckins: number[];
  ackDelays: number[];
  selfTestFailures: number[];
  general: number[];
  fire: number[];
  lineCard3: number[];
  lineCard4: number[];
  lineCard5: number[];
  lineCard6: number[];
  timestamps: number[];
}

export type NetworkPulsePeriod = 'TEN_DAYS' | 'MONTH' | 'YEAR';

export interface ImportCSVUnitsStats {
  faults: number;
  success: number;
  total: number;
}

export type ExportUnitsFileType = 'kml' | 'csv';

export type BusinessUnitHealthPopUp = {
  content: UnitID[];
  businessUnitId: BusinessUnit['id'];
};

export type Mesh = {
  ackDelays: number;
  address1: string;
  address2: string;
  country: string;
  dealersCount?: number;
  stateOrProvince: string;
  unitId: number;
  buId: number;
  city: string;
  events: number;
  zipOrPostalCode: string;
  mostRecentHopsCount: number;
  mostUsedHopsCount: number;
};

export type RecipientTrigger = {
  associations: [
    {
      trigger: {
        description: string;
        eventType: { triggerType: { name: string; title: string } };
        id: number;
        name: string;
      };
      recipient: {
        description: string;
        name: string;
        email: string;
        firstName: string;
        id: number;
        isEnabled: boolean;
        lastName: string;
        mobileCarrierId: number | null;
        phoneNumber: string | null;
      };
    }
  ];
  name: string;
  description: string;
  email: string;
  firstName: string;
  id: number;
  isEnabled: boolean;
  lastName: string;
  mobileCarrier: MobileCarrier;
  mobileCarrierId: number | null;
  phoneNumber: string | null;
  threshold: number;
  eventType: string;
};

export interface AvailableTrigger {
  name: string;
  nameTemplate: string;
  descriptionTemplate: string;
  notifyWhen: string;
  thresholdTemplate: string;
  faultName: string;
  eventCode: number;
  zoneCode: number;
  triggerType: {
    name: string;
    title: string
  }
}
export type LatestHelthScoreType = 'subscribers' | 'late-check-in-units' | 'ack-delay-units' | 'late-check-in-units';

export type NotificationSectionType = 'recipients' | 'triggers';

export type NotificationAssociation = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  description: string;
  email: string;
  phoneNumber: string;
  isAssociated: number;
  type: string;
  eventType: {
    triggerType: { name: string; title: string };
    recipientType: { name: string; title: string };
  };
};

export type BusinessUnitUnitsNotifications = {
  id: number;
  username: string;
  role: UserRole;
  ipLinks: number[];
  subscribers: number[];
  hybrid: number[];
};

export type BusinessUnitUnitNotificationInfo = {
  emails: string[],
  enabled: boolean,
  notifications: string[],
  restoral: boolean,
  unitId: number,
  unitType: string,
  userId: number,
  userName: string,
}

export type NetConSettings = {
  businessUnitName: string;
  enabled: number | boolean;
  method: NetConSettingsMethods;
  supvInterval: number;
  threshold: number;
  type: string;
  unitId: number;
  restoralCount: number;
  threshCount: number;
  warnIssued: number;
};

export type BusinessUnitFoundUnit = {
  id: number;
  unitType: UnitType;
};

export enum NetConSettingsMethods {
  'METHOD_1' = 1,
  'METHOD_2' = 2,
}

export type TotalSignalsDataType = {
  businessUnitId: number;
  count: number;
  unitId: number;
  unitType: UnitType;
}[];

export interface TotalSignals {
  data: TotalSignalsDataType[];
  timestamps: number[];
}

export type TotalSignalsPeriods = 'ONE_DAY' | 'TEN_DAYS' | 'MONTH';

export type SubsOverTimeDataType = {
  modelCount: number;
  modelName: string;
}[];

export interface SubsOverTime {
  data: SubsOverTimeDataType[];
  timestamps: number[];
}

export type SubsOverTimePeriods = 'THREE_YEARS' | 'MONTH' | 'YEAR';

export type BusinessUnitGeoCenter = {
  longitude: number;
  latitude: number;
}

export type BusinessUnitBadPackets = {
    buId: number;
    id: number;
    errorMsg: string;
    lastUpdated: string;
    rawPktHex: string;
    rawPktLen: number;
    unitId: number;
    unitType: UnitType;
};

export type MobileCarrier = {
  id: number;
  name: string;
};
