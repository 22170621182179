/* eslint-disable newline-per-chained-call */
import * as Yup from 'yup';

export const domainRegExp = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
const ipRegExp = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const hexIdValidator = (errorMessage = 'Should be 4 digits hex value') =>
  Yup.string().matches(/((?!^0{4}$)^[0-9a-fA-F]{4}$)|(^0{3}[[1-9a-fA-F]]$)/, errorMessage);

export const lettersAndNumbersValidator = (error: string) => Yup.string().matches(/^[0-9a-z]+$/gi, error);

export const ipValidator = (error = 'IP Address is not valid') =>
  Yup.string().matches(ipRegExp, error);
export const dnsValidator = () =>
  Yup.string().matches(ipRegExp, ({ value }) => `${value} is not valid; `);

export const phoneNumberValidator = (error = 'Phone number must have 7-15 digits') =>
  Yup.string().matches(/^(?:[0-9] ?){6,14}[0-9]$/, error);

export const phoneNumber10DigitsValidator = (error = 'Phone number must be 10 digits only') =>
  Yup.string().matches(/^(?:[0-9] ?){9,9}[0-9]$/, error);

export const businessUnitsValidators = {
  name: Yup.string()
    .max(32, 'Business unit name length must be less than 32 characters')
    .required('Business unit name is required'),
};

export const alarmAutomationValidators = {
  portNumber: Yup.number().moreThan(0, 'Please enter valid port number').required('Port number is required'),
  ipAddress: ipValidator('Please enter valid IP address').required('IP address is required'),
};

export const trailingSpacesValidator = (error = 'Leading and trailing spaces are not allowed') =>
  Yup.string().trim(error).strict();

export const geoCoordinatesValidators = {
  altitude: Yup.number().typeError('Incorrect format').nullable(true),
  latitude: Yup.string()
    .matches(
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,100})?))$/,
      'Incorrect latitude format'
    )
    .nullable(true),
  longitude: Yup.string()
    .matches(
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,100})?))$/,
      'Incorrect longitude format'
    )
    .nullable(true),
};

export const timingValidator = (error = 'Incorrect time format') => Yup.string().matches(/^\d{2}:\d{2}/, error);

export const manyEmailValidator = (email = 'example@example.com') => Yup.array().transform(function(value, originalValue) {
  if (value !== null) {
    return value;
  }
  return originalValue ? originalValue.split(/[\s,]+/) : [];
}).of(Yup.string().email(({ value }) => `${value} is not a valid email`));
