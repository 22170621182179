import { useEffect, useState } from 'react';

import { boundActions } from '~store';

export const usePageLoaded = (onMount = () => {}, onUnmount = () => {}) => {
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!isPageLoaded) {
      setPageLoaded(true);
      boundActions.page.setPageLoaded();

      return;
    }

    onMount();
  }, [isPageLoaded]);

  useEffect(
    () => () => {
      boundActions.page.resetPageState();
      onUnmount();
    },
    []
  );

  return isPageLoaded;
};
