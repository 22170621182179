import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, filter, catchError } from 'rxjs/operators';

import { http } from '~services/http';
import { getResponseError } from '~utils/response';

import { actions as appActions } from '../app/appSlice';

import { mapActionsWithReportingRoute } from './reportingRoutesUtils';

import { ReportingRouteSetting, ReportingRoute, ResponseError } from '~models';

export interface ReportingRoutesState {
  data: ReportingRouteSetting;
  loading: {
    list: boolean;
  };
  error?: ResponseError | null;
  actions: AnyAction[];
}

export const initialState: ReportingRoutesState = {
  data: {
    ipLinkId: null,
    ipDirect: null,
    ipViaHybrid: null,
    ipViaHybridMostFrequent: null,
    ipViaHybridNextLast: null,
    mostFrequentMesh: null,
    mostRecentMesh: null,
    nextMostRecentMesh: null,
  },
  loading: {
    list: false,
  },
  error: null,
  actions: [],
};

export const { name, actions, reducer } = createSlice({
  name: 'reportingRoutes',
  initialState,
  reducers: {
    // Fetch reporting routes
    fetchReportingRoutesInit: (
      state,
      { payload: { apiUrl, actions } }: PayloadAction<{ apiUrl: string; actions: AnyAction[] }>
    ) => {
      state.loading.list = true;
      state.actions = actions;
    },
    fetchReportingRoutesSuccess: (state, { payload }: PayloadAction<ReportingRouteSetting>) => {
      state.loading.list = false;
      state.data = payload;
    },
    fetchReportingRoutesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = payload;
    },

    selectReportingRoute: (
      state,
      { payload }: PayloadAction<{ manualPath: boolean; route: ReportingRoute['route'] }>
    ) => {},

    // Reset reporting routes
    resetReportingRoutes: state => {
      Object.assign(state, initialState);
    },
  },
});

const getReportingRoutesState = (state: AES.RootState) => state.reportingRoutes;

export const selectors = {
  getReportingRoutesState,

  getReportingRoutesLoading: createDraftSafeSelector(getReportingRoutesState, state => state.loading),
  getReportingRoutesData: createDraftSafeSelector(getReportingRoutesState, state => state.data),
  getPendingActions: createDraftSafeSelector(getReportingRoutesState, state => state.actions),
};

const fetchReportingRoutesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchReportingRoutesInit.match(action)),
    mergeMap(({ payload: { apiUrl } }) =>
      http.getJSON(apiUrl).pipe(
        mergeMap(res => of(actions.fetchReportingRoutesSuccess(res as ReportingRouteSetting))),
        catchError(err => of(actions.fetchReportingRoutesFailed(getResponseError(err))))
      )
    )
  );

const selectReportingRouteEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(action => actions.selectReportingRoute.match(action)),
    mergeMap(({ payload }) => {
      const { actions } = getReportingRoutesState(state$.value);

      return of(
        ...mapActionsWithReportingRoute(actions, payload),
        appActions.setAppDialog({ dialog: 'reportingRouteDialog', value: false })
      );
    })
  );

export const epics = combineEpics(fetchReportingRoutesEpic, selectReportingRouteEpic);

export const allEpics = { fetchReportingRoutesEpic, selectReportingRouteEpic };

declare global {
  namespace AES {
    export interface Actions {
      reportingRoutes: typeof actions;
    }

    export interface RootState {
      reportingRoutes: ReportingRoutesState;
    }

    export interface Selectors {
      reportingRoutes: typeof selectors;
    }
  }
}
