import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { PageRoute } from '~models';

const styles = theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(-2),
      display: 'flex',
      justifyContent: 'space-between',
      '&.withoutRoutes': {
        marginTop: 0,
      },
    },
  });

type PageNavigationProps = WithStyles<typeof styles> & {
  routes?: PageRoute[];
};

export const PageNavigation = withStyles(styles)(({ classes, routes, ...props }: PageNavigationProps) => (
  <div className={clsx(classes.root, { withoutRoutes: Boolean(!routes?.length) })} {...props} />
));
