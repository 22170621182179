import { Redirect, useLocation } from 'react-router-dom';

export const useRedirect = (path: string, redirectTo: string) => {
  const location = useLocation();

  if (location.pathname === path) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};
