import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    links: {
      display: 'flex',
      flexWrap: 'wrap',
      '&  .routing-link': {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(0.5),
      },
    },
    button: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.3),
      padding: 0,
    },
  });
