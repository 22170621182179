import { withStyles, WithStyles, Typography, IconButton } from '@material-ui/core';
import { MouseEvent } from 'react';

import { RefreshButton } from '~ui-kit/Buttons';

import { styles } from './SideFiltersHeading.styles';

import { Icon } from '~ui-kit';

interface SideFiltersHeadingProps extends WithStyles<typeof styles> {
  title?: string;
  onRefresh?: (e: MouseEvent) => void;
  onClose?: (e: MouseEvent) => void;
}

export const SideFiltersHeading = withStyles(styles)(
  ({ title = 'Filters', onClose, onRefresh, classes }: SideFiltersHeadingProps) => (
    <header className={classes.root}>
      <Typography variant="h3" color="primary">
        {title}
        {onRefresh ? <RefreshButton onClick={onRefresh as () => void} /> : null}
      </Typography>

      {onClose ? (
        <IconButton onClick={onClose} type="button">
          <Icon icon="close" />
        </IconButton>
      ) : null}
    </header>
  )
);
