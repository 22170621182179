import { Button, ButtonGroup, Paper, useTheme, withStyles, WithStyles } from '@material-ui/core';
import { equals } from 'ramda';
import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserTimeFormat } from '~features/users/usersUtils';

import {
  generateChartData,
  getFilters,
  getDataKeys,
  getDefaultFilterValues,
  isNetworkPulseData,
  getMaxValue,
  networkPulseSections,
} from './config';
import { styles } from './NetworkPulse.styles';
import { NetworkPulseChat } from './NetworkPulseChart';

import { NetworkPulse as NetworkPulseData, NetworkPulsePeriod } from '~models';
import { selectors } from '~store';
import { FormPanel, Filter, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';

type NetworkPulseProps = WithStyles<typeof styles> & {
  networkPulse?: NetworkPulseData;
  filterTitle?: string;
  hideControls?: boolean;
  hidePeriodsPanel?: boolean;
  onRefresh?: () => void;
  period: NetworkPulsePeriod;
  onPeriodChange?: (period: NetworkPulsePeriod) => void;
};

export const NetworkPulse = memo(
  withStyles(styles)(
    ({
      classes,
      filterTitle = '',
      networkPulse,
      hideControls = false,
      hidePeriodsPanel = false,
      onRefresh,
      period,
      onPeriodChange = () => {},
    }: NetworkPulseProps) => {
      const {
        palette: { type: theme },
      } = useTheme();

      const isData = isNetworkPulseData(networkPulse);
      const controlsPanel = isData && !hideControls;
      const periodsPanel = isData && !hidePeriodsPanel;
      const dataKeys = getDataKeys(networkPulse);
      const filters = getFilters(dataKeys, theme);

      const [selectedFilterValues, setSelectedFilterValues] = useState(getDefaultFilterValues(networkPulse));
      const {
        details: { networkPulse: isLoading },
      } = useSelector(selectors.businessUnits.getBusinessUnitsLoaders);

      const selectedFilters = useMemo(() => getFilters(selectedFilterValues, theme), [selectedFilterValues]);
      const chartData = useMemo(() => generateChartData(networkPulse, selectedFilterValues), [selectedFilterValues]);
      const maxValue = useMemo(() => getMaxValue(networkPulse, selectedFilterValues), [selectedFilterValues]);
      const account = useSelector(selectors.profile.getAccount, equals);
      const timeFormat = getUserTimeFormat(account);
      const lastUpdatedTime = useMemo(() => Math.floor(Date.now() / 1000), [isLoading]);

      useMemo(() => setSelectedFilterValues(getDefaultFilterValues(networkPulse)), [networkPulse]);

      return (
        <FormPanel
          title="Network Pulse"
          subTitle={<LastDataUpdatedFormPanelSubtitle
            timestamp={!isLoading ? lastUpdatedTime : 0}
            timeFormat={timeFormat}
          />}
          classes={{ root: classes.root, header: classes.header, title: classes.title, subTitle: classes.subTitle }}
          onRefresh={onRefresh}
          renderActions={() =>
            controlsPanel && (
              <>
                {periodsPanel ? (
                  <ButtonGroup>
                    {networkPulseSections.map(config => (
                      <Button
                        size="small"
                        color="primary"
                        variant={config.section === period ? 'contained' : 'outlined'}
                        key={config.tooltip}
                        onClick={() => onPeriodChange(config.section)}
                      >
                        {config.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                ) : (
                  ''
                )}
                <Filter
                  title={filterTitle}
                  icon="settings"
                  classes={{ actionButton: classes.actionButton }}
                  options={filters}
                  selected={selectedFilterValues}
                  initial={getDefaultFilterValues(networkPulse)}
                  onSelect={value => setSelectedFilterValues(value)}
                />
              </>
            )
          }
        >
          <Paper classes={{ root: classes.chart }}>
            <NetworkPulseChat
              isLoading={isLoading}
              isData={isData}
              chartData={chartData}
              selectedFilters={selectedFilters}
              maxValue={maxValue}
              timeFormat={timeFormat}
              chartTimeStamps={networkPulse?.timestamps}
            />
          </Paper>
        </FormPanel>
      );
    }
  )
);
