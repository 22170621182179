
import { FormikErrors } from 'formik';
import { keys, pathOr, join, is } from 'ramda';

import { FormTextFieldProps } from '~ui-kit/Forms';

import { ResponseError } from '~models';

export const getFormErrors = <T>(
  err: ResponseError | null | undefined,
  fieldsMap: { [key: string]: string } = {}
): FormikErrors<T> => {
  if (!err) {
    return {};
  }

  const errors = pathOr({}, ['errors'], err);

  return keys(errors).reduce<{ [key: string]: string }>((acc, key) => {
    const field = fieldsMap[key] ?? key;

    acc[field] = join(', ', errors[key]);

    return acc;
  }, {}) as FormikErrors<T>;
};

export const mapNumberFieldValueToBoolean = (value: number) => Boolean(value);
export const mapBooleanFieldValueToNumber = (value: boolean | null) => (value ? 1 : 0);

export const getShowingFormValue = value => {
  if (is(Number, value) || Boolean(value)) {
    return value;
  }

  return '-';
};

export const prepareFormDataFilesPayload = (files: File[]): FormData => {
  const formData = new FormData();

  files.forEach(file => formData.append(file.name, file, file.name));

  return formData;
};

export const geoCordinateHelper = (name: FormTextFieldProps['name']) => {
  switch (name) {
    case 'altitude':
      return { name: true };
    case 'latitude':
      return { name: true };
    case 'longitude':
      return { name: true };
    default:
      return { name: false };
  }
};
