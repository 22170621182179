/* eslint-disable newline-per-chained-call */
import * as yup from 'yup';

import { SubscriberSettings } from '~models';
import { parseTimeFieldValue, prepareTimeFieldValue } from '~ui-kit';
import { timingValidator } from '~utils';

export type SubscriberTimingSettings = {
  radioCheckin: string;
  ipCheckin: string;
  reportDelay: number;
  ackDelay: number;
  loopResponse: number;
};

export const getSubscriberTimingInitialValues = (values?: SubscriberSettings['timing']): SubscriberTimingSettings => ({
  ipCheckin: prepareTimeFieldValue(values?.ipCheckinHrs, values?.ipCheckinMins),
  radioCheckin: prepareTimeFieldValue(values?.radioCheckinHrs, values?.radioCheckinMins),
  reportDelay: values?.reportDelay || 0,
  ackDelay: values?.ackDelay || 0,
  loopResponse: values?.loopResponse || 0,
});

export const prepareSubscriberTimingRequestPayload = (
  values: SubscriberTimingSettings
): Partial<SubscriberSettings['timing']> => {
  const radioCheckin = parseTimeFieldValue(values.radioCheckin);
  const ipCheckin = parseTimeFieldValue(values.ipCheckin);
  const ipCheckinOption = ipCheckin ? { ipCheckinHrs: ipCheckin.hours, ipCheckinMins: ipCheckin.minutes } : {};

  return {
    radioCheckinHrs: radioCheckin.hours,
    radioCheckinMins: radioCheckin.minutes,
    ackDelay: values.ackDelay,
    loopResponse: Number(values.loopResponse),
    reportDelay: values.reportDelay,
    ...ipCheckinOption,
  };
};

export const subscriberTimingValidationSchema = yup.object().shape({
  radioCheckin: timingValidator().required('Radio Check-in is required'),
  ipCheckin: timingValidator()
    .test('is-not-a-null', value => value !== null)
    .required('Check-in is required'),
  ackDelay: yup.number().min(1).max(300).required('Acknowledgement Delay is required'),
  loopResponse: yup.number().min(0).max(2.5, 'Value should be less than or equal to 2.5').required('Contact Debounce Time is required').test({
    message: 'Should only have two numbers after the decimal',
    test(value) {
      return /^\d+(\.\d{0,2})?$/.test(value?.toString()!);
    }
  }),
  reportDelay: yup.number().min(1).max(330).required('Secondary Alarm Delay is required'),
});
