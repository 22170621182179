import { withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren, memo } from 'react';

import { styles } from './ContentWrapper.styles';

export type ContentWrapperProps = PropsWithChildren<WithStyles<typeof styles>> & {
  spacing?: 'left' | 'right' | 'both';
  width?: number | string;
};

export const ContentWrapper = memo(
  withStyles(styles)(({ children, spacing, classes, width }: ContentWrapperProps) => (
    <div className={clsx(classes?.root, spacing)} style={{ width }}>
      {children}
    </div>
  ))
);
