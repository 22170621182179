import { StyleRules, Theme, TooltipClassKey } from '@material-ui/core';

type TooltipStyles = Partial<StyleRules<TooltipClassKey>>;

export const MuiTooltip = ({ spacing, palette: { type, text, background } }: Theme): TooltipStyles => ({
  tooltip: {
    backgroundColor: background.paper,
    color: text.primary,
    fontSize: 14,
    padding: spacing(1, 2),
    '&, & $tooltipArrow': (type === 'dark' && {
      boxShadow: '6px 0px 10px 0px rgba(22,29,48,0.3)',
    }) || {
      boxShadow: '3px 0px 13px rgba(0, 0, 0, 0.06)',
    },
  },
  arrow: {
    color: background.paper,
  },
});
