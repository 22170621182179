import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    sizeProgress: {
      marginLeft: spacing(1),
    },
  });
