import { withStyles, WithStyles } from '@material-ui/core';
import { Fragment, PropsWithChildren } from 'react';

import { styles } from './Required.styles';

type RequiredProps = PropsWithChildren<WithStyles<typeof styles>>;

export const Required = withStyles(styles)(({ children, classes }: RequiredProps) => (
  <Fragment>
    {children} <span className={classes.asterix}>*</span>
  </Fragment>
));
