import DateFnsUtils from '@date-io/date-fns';
import { IconButton, WithStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isWithinInterval, format, isSameDay } from 'date-fns';

import { styles } from './DatePickerField.styles';

export type DatePickerFieldProps = KeyboardDatePickerProps &
  WithStyles<typeof styles> & {
    multiple?: boolean;
  };

export const DatePickerField = withStyles(styles)(({ classes, ...props }: DatePickerFieldProps) => {
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const start = selectedDate;
    const end = new Date();
    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span>{format(date, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  const pickerProps = props.multiple ? { renderDay: renderWrappedWeekDay } : {};

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker {...pickerProps} {...props} />
    </MuiPickersUtilsProvider>
  );
});
