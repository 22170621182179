import { Typography } from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';
import { withStyles, WithStyles } from '@material-ui/styles';
import { useCallback } from 'react';

import { styles } from './PagePagination.styles';

import { Pagination } from '~models';

type PagePaginationProps = WithStyles<typeof styles> & {
  pagination?: Pagination;
  loading?: boolean;
  onChange?: (pagination: Pagination) => void;
  isHideTitle?: boolean;
};

export const PagePagination = withStyles(styles)(
  ({ pagination, loading, onChange = () => {}, isHideTitle = false, classes }: PagePaginationProps) => {
    if (!pagination?.totalElements) {
      return null;
    }

    const onPageChange = useCallback(
      (e, page) => {
        if (pagination.number !== page - 1) {
          onChange({ ...pagination, number: page - 2 });
        }
      },
      [pagination.number]
    );

    const from = pagination.number * pagination.size;
    const to = Math.min(from + pagination.size, pagination.totalElements);

    return (
      <div className={!isHideTitle ? classes.root : classes.rootHide}>
        {!isHideTitle ? (
          <Typography variant="body1" className={classes.title}>
            Showing {from + 1} to {to} of {pagination.totalElements} items
          </Typography>
        ) : null}

        <MuiPagination
          shape="rounded"
          count={pagination.totalPages}
          page={pagination.number + 1}
          onChange={onPageChange}
          disabled={loading}
          showLastButton
          showFirstButton
        />
      </div>
    );
  }
);
