/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-invalid-this */

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
