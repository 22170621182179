import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: 0,
      width: 400,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    iconActive: {
      background: `${theme.palette.primary.main} !important`,
    },
    actionButton: {
      minWidth: 'auto',
      padding: '6px 12px',
    },
  });
