import TextMask from 'react-text-mask';

import { TextField, TextFieldProps } from './TextField';

export type IPFieldProps = Partial<TextFieldProps>;

const defaultMask = value => value.split('').map(() => /./);
const mask = value => {
  let result: (string | RegExp)[] = [];
  const chunks = value.split('.');

  for (let i = 0; i < 4; ++i) {
    const chunk = (chunks[i] || '').replace(/_/gi, '');

    if (chunk === '') {
      result.push(/\d/, /\d/, /\d/, '.');
      continue;
    } else if (+chunk === 0) {
      result.push(/\d/, '.');
      continue;
    } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].includes('_'))) {
      if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
        result.push(/\d/, /\d/, '.');
        continue;
      } else {
        result.push(/\d/, /\d/, /\d/, '.');
        continue;
      }
    } else {
      result.push(...new Array(chunk.length).fill(/\d/), '.');
      continue;
    }
  }

  result = result.slice(0, -1);
  return result;
};

export const IPField = ({ onChange, onBlur, ...props }: IPFieldProps) => (
  <TextMask
    mask={mask || defaultMask}
    pipe={value => {
      if (value === '.' || value.endsWith('..')) {
        return false;
      }

      const parts = value.split('.');

      if (parts.length > 4 || parts.some(part => part === '00' || parseInt(part) < 0 || parseInt(part) > 255)) {
        return false;
      }

      return value;
    }}
    guide
    onChange={onChange}
    onBlur={onBlur}
    render={(ref, inputProps) => <TextField inputRef={ref} {...props} {...inputProps} />}
  />
);
