import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

import { DARK, LIGHT } from '~styles/colors';

import { lightOverrides, darkOverrides } from './overrides';

const typography: TypographyOptions = {
  fontFamily: 'Poppins, sans-serif',
};

const sizes: ThemeOptions['sizes'] = {
  navBarCollapsedWidth: 68,
  navBarExpandedWidth: 224,
};

const lightPallete: PaletteOptions = {
  type: 'light',
  background: {
    default: LIGHT.background.main,
    paper: LIGHT.whiteColor.main,
  },
  text: {
    primary: LIGHT.blueColor.main,
  },
  primary: LIGHT.primary,
  warning: LIGHT.warning,
  success: LIGHT.success,
  error: LIGHT.error,
  greyColor: LIGHT.greyColor,
  blueColor: LIGHT.blueColor,
  whiteColor: LIGHT.whiteColor,
  blackColor: LIGHT.blackColor,
  transparentColor: LIGHT.transparentColor,
  logo: LIGHT.logo,
  sideNav: {
    icon: {
      main: LIGHT.greyColor.main,
      hover: LIGHT.primary.main,
    },
    label: {
      main: LIGHT.blueColor.main,
      hover: LIGHT.primary.main,
    },
  },
};
const lightTheme = createMuiTheme({
  typography,
  sizes,
  palette: lightPallete,
});

export const light = createMuiTheme(
  {
    overrides: lightOverrides(lightTheme),
    sizes,
  },

  lightTheme
);

const darkPalette: PaletteOptions = {
  type: 'dark',
  background: {
    default: DARK.background.main,
    paper: DARK.blueColor.main,
  },
  text: {
    primary: DARK.greyColor.light,
  },
  primary: DARK.primary,
  warning: DARK.warning,
  success: DARK.success,
  error: DARK.error,
  blueColor: DARK.blueColor,
  greyColor: DARK.greyColor,
  whiteColor: DARK.whiteColor,
  blackColor: LIGHT.blackColor,
  transparentColor: DARK.transparentColor,
  logo: DARK.logo,
  sideNav: {
    icon: {
      main: DARK.greyColor.main,
      hover: DARK.primary.main,
    },
    label: {
      main: DARK.greyColor.light,
      hover: DARK.primary.main,
    },
  },
};

const darkTheme = createMuiTheme({
  typography,
  sizes,
  palette: darkPalette,
});

export const dark = createMuiTheme(
  {
    sizes,
    overrides: darkOverrides(darkTheme),
  },
  darkTheme
);
