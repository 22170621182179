import { LineData, SimpleLineChart } from '~components/Chart/SimpleLineChart';
import { PageLoader } from '~components/Page/PageLoader';
import { DataPlaceholder } from '~ui-kit/DataPlaceholder/DataPlaceholder';
import { FilterOption } from '~ui-kit/Filter';

type NetworkPulseChatProps = {
  isLoading: boolean;
	isData: boolean;
	chartData: LineData[];
	selectedFilters: FilterOption[];
	maxValue: number;
	timeFormat: string;
	chartTimeStamps?: number[];
};

export const NetworkPulseChat = ({
  isLoading,
  isData,
  chartData,
  selectedFilters,
  maxValue,
  timeFormat,
  chartTimeStamps,
}: NetworkPulseChatProps) => {
  if (isLoading) {
    return <PageLoader />;
  }

  if (!isData) {
    return <DataPlaceholder />;
  }

  return <SimpleLineChart
    data={chartData}
    argumentValues={selectedFilters}
    argumentField="timestamps"
    maxValue={maxValue}
    timeFormat={timeFormat}
    timeStamps={chartTimeStamps}
  />;
};
