import { makeStyles, createStyles } from '@material-ui/core';

export const useSideNavStyles = makeStyles(
  ({ palette, transitions, breakpoints, spacing, sizes: { navBarCollapsedWidth, navBarExpandedWidth } }) =>
    createStyles({
      SideNav: {
        width: navBarExpandedWidth,
        position: 'static',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 1,
      },
      SideNavExpanded: {
        width: 'auto',
        transition: transitions.create('all', {
          easing: transitions.easing.easeIn,
          duration: '200ms',
        }),
      },
      SideNavCollapsed: {
        transition: transitions.create('all', {
          easing: transitions.easing.easeIn,
          duration: '200ms',
        }),
        overflowX: 'hidden',
        width: navBarCollapsedWidth,
        [breakpoints.up('sm')]: {
          width: navBarCollapsedWidth,
        },
      },
      paper: {
        position: 'static',
        ...((palette.type === 'dark' && {
          background: palette.blueColor.main,
          boxShadow: '6px 0px 10px 0px rgba(22,29,48,0.3)',
        }) || {
          background: palette.whiteColor.main,
          boxShadow: '3px 0px 13px rgba(0, 0, 0, 0.06)',
        }),
        border: 'none',
        padding: 0,
      },
      logoExpanded: {
        margin: spacing(2, 3),
      },
      logoCollapsed: {
        margin: spacing(2, 1),
      },
      selfMonitoring: {
        margin: spacing(2, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
      }
    })
);
