import { Box, BoxProps, withStyles, WithStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { Fragment, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import beepSound from '~assets/media/beep.mp3';
import { appRoutes } from '~constants/routes';

import { styles } from './LedPanel.styles';

import { selectors } from '~store';
import { NavLink, StatusText } from '~ui-kit';

export const LedPanel = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const ledStatuses = useSelector(selectors.app.getLedPanelStatuses, equals);
  const signalRef = useRef<HTMLAudioElement>(null);
  const isSoundEnabled = useSelector(selectors.app.isSoundEnabled);
  const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);
  const isAutomationDown = useSelector(selectors.app.isAutomationDown);
  const playSound = isAutomationDown && !isSelfMonitoring;
  const automationLedLabel = isSelfMonitoring ? 'Self Monitoring' : 'Automation';
  const automationLedStatus = isSelfMonitoring ? 'success' : ledStatuses.automation;
  const automationTooltip = isSelfMonitoring
    ? ''
    : 'AA connection status, RED when it is not possible to get Acknowledgements from a designated alarm monitoring system';
  const automationLink = isSelfMonitoring ? (
    automationLedLabel
  ) : (
    <NavLink to={appRoutes.businessUnits} classes={{ root: clsx(classes.link) }}>
      {automationLedLabel}
    </NavLink>
  );

  useEffect(() => {
    if (isSoundEnabled && playSound) {
      signalRef.current!.currentTime = 0;
      signalRef.current?.play();
    } else {
      signalRef.current?.pause();
    }
  }, [isSoundEnabled, playSound]);

  return (
    <Fragment>
      <audio src={beepSound} loop ref={signalRef} />
      <div className={classes.root}>
        {[
          {
            children: [
              {
                label: 'CPU',
                status: ledStatuses.cpu,
                link: 'CPU',
                tooltip:
                  'CPU load indication, RED when there are Server issues (e.g., buffering issues, catastrophic failure with the server)',
              },
              {
                label: 'Ethernet',
                status: ledStatuses.ethernet,
                link: 'Ethernet',
                tooltip:
                  'IP Links connection status, RED when there are missing check-in from IP-Link or IP Subscriber',
              },
            ],
            props: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            } as Partial<BoxProps>,
            className: 'left',
          },
          {
            children: [
              {
                label: automationLedLabel,
                status: automationLedStatus,
                link: automationLink,
                tooltip: automationTooltip,
              },
              {
                label: 'RF Interference',
                status: ledStatuses.rf,
                link: 'RF Interference',
                tooltip: 'RF Network interference status, RED when an RF interference condition exists',
              },
            ],
            props: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'hidden',
            } as Partial<BoxProps>,
            className: '',
          },
        ].map((box, index) => (
          <Box key={index} {...box.props} className={box.className}>
            {box.children.map(item => (
              <Tooltip key={item.label} title={item.tooltip}>
                <StatusText key={item.label} status={item.status} classes={{ root: clsx(classes.status) }}>
                  {item.link}
                </StatusText>
              </Tooltip>
            ))}
          </Box>
        ))}
      </div>
    </Fragment>
  );
});
