import { InputLabelClassKey, StyleRules, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { mergeDeepRight } from 'ramda';

import { classNames } from '../muiClassNames';

type InputLabelStyles = Partial<StyleRules<InputLabelClassKey>>;

const focusedCssProps: CSSProperties = {
  fontStyle: 'italic',
};

const commonStyles: InputLabelStyles = {
  root: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  shrink: focusedCssProps,
};

export const lightInputLabelStyles = (theme: Theme) =>
  mergeDeepRight<InputLabelStyles, InputLabelStyles>(commonStyles, {
    root: {
      color: theme.palette.greyColor.dark,
      [`&.${classNames.disabled}`]: {
        ...focusedCssProps,
        color: theme.palette.greyColor.dark,
      },
    },
  }) as InputLabelStyles;
export const darkInputLabelStyles = (theme: Theme) =>
  mergeDeepRight<InputLabelStyles, InputLabelStyles>(commonStyles, {
    root: {
      color: theme.palette.greyColor.main,
      [`&.${classNames.disabled}`]: {
        ...focusedCssProps,
        color: theme.palette.greyColor.main,
      },
    },
  }) as InputLabelStyles;
