import { withStyles } from '@material-ui/core';
import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps, useSnackbar } from 'notistack';

import { styles, useStyles } from './SnackbarProvider.styles';

import { Icon } from '~ui-kit';

export const SnackbarProvider = withStyles(styles)(({ classes, ...props }: SnackbarProviderProps) => {
  const styles = useStyles();

  return (
    <NotistackSnackbarProvider
      iconVariant={{
        error: <Icon className={styles.icon} icon="attention" />,
        info: <Icon className={styles.icon} icon="info" />,
        warning: <Icon className={styles.icon} icon="attention" />,
        success: <Icon className={styles.icon} icon="success" />,
      }}
      domRoot={document.getElementById('notifications') as HTMLDivElement}
      action={key => {
        const { closeSnackbar } = useSnackbar();

        return (
          <div onClick={() => closeSnackbar(key)}>
            <Icon classes={{ root: styles.close }} icon="close" />
          </div>
        );
      }}
      {...props}
      classes={classes}
    />
  );
});
