import { Button, styled } from '@material-ui/core';
import { Fragment, useMemo, useCallback } from 'react';

import { FormPanelProps } from '~ui-kit/Forms/FormPanel';

import { FormPanel } from '~ui-kit';

type EditableFormPanelProps = Partial<FormPanelProps> & {
  disabled?: boolean;
  isSubmitting?: boolean;
  isEditing?: boolean;
  isHidden?: boolean;
  onChange: (isEditing: boolean) => void;
  onReset: () => void;
  onSubmit: () => void;
};

const CancelButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const EditableFormPanel = ({
  disabled = false,
  isSubmitting = false,
  isEditing = false,
  isHidden = false,
  onChange,
  onSubmit,
  onReset,
  ...props
}: EditableFormPanelProps) => {
  const onChangeCallback = useCallback(() => {
    onChange(!isEditing);
  }, [isEditing]);

  const onResetCallback = useCallback(() => {
    onReset();
    onChange(false);
  }, []);

  const actions = useMemo(() => {
    if (isHidden) {
      return () => null;
    }

    if (isEditing) {
      return () => (
        <Fragment>
          <CancelButton variant="outlined" color="primary" size="small" onClick={onResetCallback} disabled={disabled}>
            Cancel
          </CancelButton>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="button"
            onClick={onSubmit}
            disabled={disabled || isSubmitting}
          >
            Save
          </Button>
        </Fragment>
      );
    }

    return () => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onChangeCallback}
        disabled={disabled || isSubmitting}
      >
        Edit
      </Button>
    );
  }, [isEditing, isSubmitting, disabled]);

  return <FormPanel renderActions={actions} loading={isSubmitting} {...props} />;
};
