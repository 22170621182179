import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { load } from 'redux-localstorage-simple';

export interface ThemeState {
  value: 'dark' | 'light';
}

export const { theme: initialState = { value: 'dark', toggling: false } } = load({
  states: ['theme'],
  disableWarnings: true,
}) as {
  theme: ThemeState;
};

export const { name, reducer, actions } = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: state => {
      state.value = (state.value === 'dark' && 'light') || 'dark';
    },
    switchToDark: state => {
      state.value = 'dark';
    },
    switchToLight: state => {
      state.value = 'light';
    },
  },
});

declare global {
  namespace AES {
    export interface Actions {
      theme: typeof actions;
    }
    export interface RootState {
      theme: ThemeState;
    }
  }
}

const getThemeState = (state: AES.RootState) => state.theme;
export const selectors = {
  getThemeState,

  selectTheme: createDraftSafeSelector(getThemeState, state => state.value),
};

declare global {
  namespace AES {
    export interface RootState {
      theme: ThemeState;
    }
    export interface Actions {
      theme: typeof actions;
    }

    export interface Selectors {
      theme: typeof selectors;
    }
  }
}
