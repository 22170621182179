import { createStyles, CSSProperties, withStyles, WithStyles } from '@material-ui/styles';
import { memo } from 'react';

const styles = createStyles({
  root: {
    transition: 'none',
  },
});

export interface ScrollableDataListProps extends WithStyles<typeof styles> {
  style: CSSProperties;
}

export const ScrollableDataListItem = memo(
  withStyles(styles)(({ style, classes, ...props }: ScrollableDataListProps) => (
    <div style={style} className={classes.root} {...props} />
  ))
);
