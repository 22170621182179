import { createStyles, Theme } from '@material-ui/core';

import logo from '~assets/images/logo.svg';
import logoMini from '~assets/images/logoMini.svg';
import { maskImageStyles } from '~utils/styles';

export const styles = (theme: Theme) =>
  createStyles({
    logo: {
      background: theme.palette.logo,
      display: 'block',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      maskSize: 'contain',
      width: 110,
      height: 36,
      ...maskImageStyles(logo),
    },
    collapsed: {
      width: 50,
      height: 36,
      ...maskImageStyles(logoMini),
    },
  });
