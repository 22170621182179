import { Button } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { Dialog, DialogContent, DialogActions, DialogProps } from '~ui-kit';

type DeleteDialogProps = Partial<DialogProps> &
  PropsWithChildren<{
    loading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  }>;

export const DeleteDialog = ({ open = false, onConfirm, onCancel, children, loading, ...props }: DeleteDialogProps) => (
  <Dialog open={open} {...props}>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm} disabled={loading}>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);
