import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as appActions } from '../app/appSlice';
import { actions as pageActions } from '../page/pageSlice';

import { api } from '~constants';
import { LicenseDetails, LicenseType, ResponseError, SelfMonitoringDetails } from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload, saveFile } from '~utils';

interface LicenseState {
  data: {
    license: LicenseDetails | null;
    selfMonitoring: SelfMonitoringDetails | null;
  };
  limitedDetails: {
    message: string;
    timeStamp: number;
  };
  loading: {
    license: {
      details: boolean;
      agreement: boolean;
    };
    selfMonitoring: {
      details: boolean;
    };
  };
  error?: ResponseError | null;
}

export const initialState: LicenseState = {
  data: {
    license: null,
    selfMonitoring: null,
  },
  limitedDetails: {
    message: '',
    timeStamp: 0,
  },
  loading: {
    license: {
      details: false,
      agreement: false,
    },
    selfMonitoring: {
      details: false,
    },
  },
  error: null,
};

export const { name, actions, reducer } = createSlice({
  name: 'license',
  initialState,
  reducers: {
    // Fetch license details
    fetchLicenseDetailsInit: state => {
      state.loading.license.details = true;
    },
    fetchLicenseDetailsSuccess: (state, { payload }) => {
      state.loading.license.details = false;
      state.data.license = payload;
    },
    fetchLicenseDetailsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.license.details = false;
      state.error = action.payload;
    },

    // Generate request key
    generateRequestKeyInit: (state, { payload }: PayloadAction<{ licenseType: LicenseType; productKey: string }>) => {
      state.loading.license.details = true;
    },

    // Activate license key
    activateLicenseKeyInit: (state, { payload }: PayloadAction<{ licenseKey: string }>) => {
      state.loading.license.details = true;
    },

    // Limited license details
    setLimitedLicenseDetails(
      state,
      { payload: { message, timeStamp } }: PayloadAction<{ message: string; timeStamp: number }>
    ) {
      state.limitedDetails = { message, timeStamp };
    },
    clearLimitedLicenseDetails(state) {
      state.limitedDetails = initialState.limitedDetails;
    },

    // Accept license agreement
    acceptLicenseAgreementInit(state, { payload }: PayloadAction<{ person: string }>) {
      state.loading.license.agreement = true;
    },
    acceptLicenseAgreementSuccess: state => {
      state.loading.license.agreement = false;
    },
    licenseAgreementFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.license.agreement = false;
      state.error = action.payload;
    },

    // Download license agreement
    downloadLicenseAgreementInit(state) {
      state.loading.license.agreement = true;
    },
    downloadLicenseAgreementSuccess: state => {
      state.loading.license.agreement = false;
    },

    fetchSelfMonitoringDetailsInit: state => {
      state.loading.selfMonitoring.details = true;
    },
    fetchSelfMonitoringDetailsSuccess: (state, { payload }) => {
      state.loading.selfMonitoring.details = false;
      state.data.selfMonitoring = payload;
    },
    fetchSelfMonitoringDetailsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.selfMonitoring.details = false;
      state.error = action.payload;
    },

    activateSelfMonitoringKeyInit: (state, { payload }: PayloadAction<{ licenseType: string; licenseKey: string }>) => {
      state.loading.selfMonitoring.details = true;
    },
    getSelfMonitoringKeyInit: (state, { payload }: PayloadAction<{ licenseCode: number }>) => {
      state.loading.selfMonitoring.details = true;
    },
  },
});

const getLicenseState = (state: AES.RootState) => state.license;
export const selectors = {
  getLicenseState,
  getLicenseData: createDraftSafeSelector(getLicenseState, state => state.data.license),
  getLimitedLicenseDetails: createDraftSafeSelector(getLicenseState, state => state.limitedDetails),
  getLicenseLoading: createDraftSafeSelector(getLicenseState, state => state.loading),
  getLicenseError: createDraftSafeSelector(getLicenseState, state => state.error),
  isPartialLicense: createDraftSafeSelector(
    getLicenseState,
    state => state.data.license?.currentLicenseType?.value === LicenseType.UP_TO_5000_UNITS
  ),
  isUnlimitedLicense: createDraftSafeSelector(
    getLicenseState,
    state => state.data.license?.currentLicenseType?.value === LicenseType.UNLIMITED
  ),
  getSelfMonitoringData: createDraftSafeSelector(getLicenseState, state => state.data.selfMonitoring),
};

const fetchLicenseDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLicenseDetailsInit.match),
    mergeMap(() =>
      http.getJSON<LicenseDetails>(api.license.details).pipe(
        mergeMap(res => of(actions.fetchLicenseDetailsSuccess(res))),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const generateRequestKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.generateRequestKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.license.details, payload).pipe(
        mergeMap(res => of(actions.fetchLicenseDetailsSuccess(getResponsePayload(res)))),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const activateLicenseKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateLicenseKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.license.activate, payload).pipe(
        mergeMap(res => {
          const epicActions = [
            actions.fetchLicenseDetailsSuccess(getResponsePayload(res)),
            window.location.reload(),
            pageActions.setPageNoLicense(false),
          ];

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const downloadLicenseAgreementEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.downloadLicenseAgreementInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'GET',
          url: api.license.agreement.download,
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            saveFile(res.response, 'INCC License Agreement');

            return of(actions.downloadLicenseAgreementSuccess());
          }),
          catchError(err => of(actions.licenseAgreementFailed(getResponseError(err))))
        )
    )
  );

const acceptLicenseAgreementEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.acceptLicenseAgreementInit.match),
    mergeMap(({ payload: { person } }) =>
      http.post(api.license.agreement.accept, { person, isAgree: true }).pipe(
        mergeMap(() => of(appActions.fetchSystemInfoInit(), actions.acceptLicenseAgreementSuccess())),
        catchError(err => of(actions.licenseAgreementFailed(getResponseError(err))))
      )
    )
  );

const fetchSelfMonitoringDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSelfMonitoringDetailsInit.match),
    mergeMap(() =>
      http.getJSON<SelfMonitoringDetails>(api.selfMonitoring.details).pipe(
        mergeMap(res => of(actions.fetchSelfMonitoringDetailsSuccess(res))),
        catchError(err => of(actions.fetchSelfMonitoringDetailsFailed(getResponseError(err))))
      )
    )
  );

const getSelfMonitoringKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.getSelfMonitoringKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.selfMonitoring.details, payload).pipe(
        mergeMap(res => of(actions.fetchSelfMonitoringDetailsSuccess(getResponsePayload(res)))),
        catchError(err => of(actions.fetchSelfMonitoringDetailsFailed(getResponseError(err))))
      )
    )
  );

const activateSelfMonitoringKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateSelfMonitoringKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.selfMonitoring.activate, payload).pipe(
        mergeMap(res => {
          const epicActions = [
            actions.fetchSelfMonitoringDetailsSuccess(getResponsePayload(res)),
            window.location.reload(),
          ];

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchSelfMonitoringDetailsFailed(getResponseError(err))))
      )
    )
  );

export const epics = combineEpics(
  fetchLicenseDetailsEpic,
  generateRequestKeyEpic,
  activateLicenseKeyEpic,
  downloadLicenseAgreementEpic,
  acceptLicenseAgreementEpic,
  fetchSelfMonitoringDetailsEpic,
  activateSelfMonitoringKeyEpic,
  getSelfMonitoringKeyEpic
);

export const allEpics = {
  fetchLicenseDetailsEpic,
  generateRequestKeyEpic,
  activateLicenseKeyEpic,
  downloadLicenseAgreementEpic,
  acceptLicenseAgreementEpic,
  fetchSelfMonitoringDetailsEpic,
  activateSelfMonitoringKeyEpic,
  getSelfMonitoringKeyEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      license: typeof actions;
    }
    export interface RootState {
      license: LicenseState;
    }

    export interface Selectors {
      license: typeof selectors;
    }
  }
}
