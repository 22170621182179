import { Button, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { Fragment, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getInitialValues,
  isManualPathSelected,
  mapToOptions,
  prepareReportingRouteRequestPayload,
  validationSchema,
} from './config';

import { PageLoader } from '~components';
import { FullHeightPage } from '~hocs';
import { ReportingRouteSetting } from '~models';
import { selectors, boundActions } from '~store';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormRadioGroup, FormTextField } from '~ui-kit';

const FormWrapper = FullHeightPage(Form);
const GridContainer = styled(Grid)({
  justifyContent: 'center',
});

export const ReportingRouteDialog = () => {
  const loaders = useSelector(selectors.reportingRoutes.getReportingRoutesLoading, equals);
  const reportingRoutes = useSelector(selectors.reportingRoutes.getReportingRoutesData, equals);
  const initialValues = useMemo(() => getInitialValues(reportingRoutes as ReportingRouteSetting), [reportingRoutes]);
  const options = useMemo(() => mapToOptions(initialValues), [initialValues]);

  const { reportingRouteDialog: isOpenDialog } = useSelector(selectors.app.getAppDialogs, equals);

  const onCloseDialog = useCallback(() => {
    boundActions.app.setAppDialog({ dialog: 'reportingRouteDialog', value: false });
  }, []);

  const onSubmit = useCallback(values => {
    boundActions.reportingRoutes.selectReportingRoute(prepareReportingRouteRequestPayload(values));
  }, []);

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpenDialog || false}>
      <DialogTitle title="Select Subscriber Path:" />

      <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, isValid }) => {
          const manualPath = isManualPathSelected(values.selected);

          return (
            <FormWrapper>
              <DialogContent>
                {loaders.list ? (
                  <PageLoader />
                ) : (
                  <Fragment>
                    <FormRadioGroup name="selected" options={options} />

                    {manualPath ? (
                      <Fragment>
                        <GridContainer container spacing={2}>
                          {initialValues.manualRoute.map((_, i) => (
                            <Grid key={i} item md={5}>
                              <FormTextField type="string" cleanable label={i + 1} name={`manualRoute[${i}]`} />
                            </Grid>
                          ))}
                        </GridContainer>
                      </Fragment>
                    ) : null}
                  </Fragment>
                )}
              </DialogContent>

              <DialogActions>
                <Button variant="outlined" color="primary" onClick={onCloseDialog}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                  Submit
                </Button>
              </DialogActions>
            </FormWrapper>
          );
        }}
      </Formik>
    </Dialog>
  );
};
