import { Dialog as Modal, DialogProps as ModalProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from './Dialog.styles';

export type DialogProps = ModalProps & WithStyles<typeof styles>;

export const Dialog = withStyles(styles)(({ classes, children, ...props }: DialogProps) => (
  <Modal classes={{ paper: clsx(classes.paperComponent) }} {...props}>
    {children}
  </Modal>
));
