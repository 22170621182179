import { WithStyles, withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { useSelector } from 'react-redux';

import { getUserTimeFormat } from '~features/users/usersUtils';

import { DependentUnits } from '../DependentUnits';

import { styles } from './GeoUnitModalFaultsListCard.styles';

import { BusinessUnit, Fault } from '~models';
import { selectors } from '~store';
import { Card, CardDetails, CardSummary, ContentField, IconText } from '~ui-kit';
import { formatDate, getLinkByUnitType } from '~utils';

export type GeoUnitModalFaultsListCardProps = WithStyles<typeof styles> & {
  fault: Fault;
  businessUnitId: BusinessUnit['id'];
};

export const GeoUnitModalFaultsListCard = withStyles(styles)(
  ({ fault, businessUnitId, classes }: GeoUnitModalFaultsListCardProps) => {
    const account = useSelector(selectors.profile.getAccount, equals);

    return (
      <Card expandable={true} classes={{ root: clsx(classes.card) }}>
        <CardSummary status="error" expandable={true} classes={{ cardSummaryRoot: clsx(classes.summary) }}>
          <IconText status="error" label={fault.description} icon="subscribers" />
        </CardSummary>

        <CardDetails classes={{ root: clsx(classes.details) }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentField title="Event code">{fault.cid}</ContentField>
            </Grid>

            <Grid item xs={12}>
              <ContentField title="Date & Time" width={240}>
                {formatDate(fault.timestamp, false, getUserTimeFormat(account), account?.userTimezone)}
              </ContentField>
            </Grid>

            <Grid item xs={12}>
              <DependentUnits
                units={fault.dependentDecade}
                title="Dependent Units in the last 10 days"
                getLinkTo={(unitType, id) => getLinkByUnitType(unitType, businessUnitId, id)}
              />
            </Grid>

            <Grid item xs={12}>
              <DependentUnits
                units={fault.dependentDay}
                title="Dependent Units in the last 24 hours"
                getLinkTo={(unitType, id) => getLinkByUnitType(unitType, businessUnitId, id)}
              />
            </Grid>
          </Grid>
        </CardDetails>
      </Card>
    );
  }
);

