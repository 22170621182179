import { Button, withStyles } from '@material-ui/core';
import { equals } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getInitialRequestParams } from '~features/page/pageUtils';
import { DialogContent as ModalContent } from '~ui-kit/Dialog';
import { intToHex } from '~utils/hex';

import { PaginatedRoutingLinkList } from '~components';
import { BusinessUnit } from '~models';
import { selectors, boundActions } from '~store';
import { Dialog, DialogTitle, DialogActions, Chip } from '~ui-kit';

const DialogContent = withStyles({ root: { paddingTop: 0 } })(ModalContent);

type AssignIPLinksDialogProps = {
  open: boolean;
  businessUnitId: BusinessUnit['id'];
  selected?: number[];
  onConfirm: (values) => void;
  onCancel?: () => void;
};

export const AssignIPLinksDialog = ({
  open,
  businessUnitId,
  selected = [],
  onConfirm,
  onCancel,
}: AssignIPLinksDialogProps) => {
  const ipLinksIds = useSelector(selectors.ipLinks.getIpLinksIds, equals);
  const { ids: isLoading } = useSelector(selectors.ipLinks.getLoaders);
  const pagination = useSelector(selectors.page.getPagePagination, equals);
  const [currentSelected, setCurrentSelected] = useState(selected);

  const onPaginationChange = useCallback(pagination => {
    boundActions.ipLinks.fetchIpLinksIdsInit({
      pagination,
      businessUnitId,
    });
  }, [businessUnitId]);

  const onOptionSelect = (e, value, isSelected: boolean) => {
    e.stopPropagation();

    const updated = isSelected ? currentSelected.filter(v => v !== value) : currentSelected.concat(value);
    setCurrentSelected(updated);
  };

  useEffect(() => {
    if (open) {
      boundActions.ipLinks.fetchIpLinksIdsInit({
        ...getInitialRequestParams(pagination),
        businessUnitId,
      });
      setCurrentSelected(selected);
    } else {
      boundActions.page.resetPageState();
      setCurrentSelected([]);
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
      <DialogTitle title="Select IP Links" />

      <DialogContent>
        <PaginatedRoutingLinkList
          data={ipLinksIds}
          onPaginationChange={onPaginationChange}
          loading={isLoading}
          renderItem={
            (res, i: number) => (
              <Chip
                key={i}
                label={ intToHex(res) }
                value={res}
                isSelected={currentSelected.find(value => value === res)}
                onOptionSelect={onOptionSelect}
                userForm={true}
              />
            )

          }
        />
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() =>
            onConfirm(currentSelected)
          }
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};
