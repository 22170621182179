import { Button } from '@material-ui/core';

import { DialogProps, Dialog, DialogContent, DialogActions } from '~ui-kit';

type IPLinkFaultRestoreProps = Partial<DialogProps> & {
  onConfirm?: () => void;
  onClose?: () => void;
};

export const IPLinkFaultRestore = ({ onClose, onConfirm, open }: IPLinkFaultRestoreProps) => (
  <Dialog open={open as boolean}>
    <DialogContent>Are you sure you want to mark this Fault as restored?</DialogContent>
    <DialogActions>
      <Button variant="outlined" size="medium" color="inherit" onClick={onClose}>
        No
      </Button>
      <Button variant="contained" size="medium" color="primary" onClick={onConfirm}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);
