import { Theme, withStyles, WithStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectors } from '~store';
import { DialogTitle, Icon } from '~ui-kit';

export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    icon: {
      transform: 'scale(1.5)',
      marginRight: spacing(1),
      background: `${palette.error.main} !important`,
    },
  });

type SelectMigratedBUTitleProps = WithStyles<typeof styles>;

export const SelectMigratedBUTitle = withStyles(styles)(({ classes }: SelectMigratedBUTitleProps) => {
  const isFailed = useSelector(selectors.app.isDatabaseMigrationFailed);

  return (
    <DialogTitle className={clsx({ error: isFailed })} title="Database migration">
      {isFailed && <Icon icon="attention" classes={{ root: classes.icon }} />}
    </DialogTitle>
  );
});
