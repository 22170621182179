import { Button, Grid, WithStyles, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { DialogContent } from '~ui-kit/Dialog';

import { styles } from './LicenseAgreement.styles';
import { LicenseAgreementText } from './LicenseAgreementText';

import { PageLoader } from '~components';
import { selectors, boundActions } from '~store';
import { DialogActions, Divider, FormTextField, Required, FormCheckbox } from '~ui-kit';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  isAccepted: yup.boolean().oneOf([true], 'Field must be checked'),
});

type LicenseAgreementProps = WithStyles<typeof styles>;

export const LicenseAgreement = withStyles(styles)(({ classes }: LicenseAgreementProps) => {
  const isAppStatusUpdated = useSelector(selectors.app.isAppStatusUpdated, equals);
  const { loading: appLoading } = useSelector(selectors.app.getAppInfo, equals);
  const { license: licenseLoading } = useSelector(selectors.license.getLicenseLoading, equals);
  const isLoading = licenseLoading.agreement || !isAppStatusUpdated || appLoading;

  const onSubmit = useCallback(({ name }) => {
    boundActions.license.acceptLicenseAgreementInit({ person: name });
  }, []);

  const onDownload = useCallback(() => {
    boundActions.license.downloadLicenseAgreementInit();
  }, []);

  if (isLoading) {
    return (
      <Grid classes={{ root: classes.loader }}>
        <PageLoader fullscreen />
      </Grid>
    );
  }

  return (
    <Formik
      initialValues={{
        name: '',
        isAccepted: false
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <DialogContent className={classes.dialog}>
            <LicenseAgreementText />

            <Divider spacing="top" classes={{ root: classes.divider }} />

            <Grid className={classes.control}>
              <Grid item md={5}>
                <FormTextField
                  name="name"
                  cleanable
                  label={<Required>Your Name</Required>}
                />
              </Grid>

              <Grid item md={5}>
                <FormCheckbox
                  name="isAccepted"
                  label="I accept the Agreement"
                  classes={{ root: classes.checkbox }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className={classes.buttons}>
            <Button type="button" variant="contained" color="primary" onClick={onDownload}>
              Download
            </Button>

            <Button type="submit" variant="contained" color="primary">
              Next
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
});
