import { AccordionSummary as MuiAccordionSummary, AccordionSummaryProps as MuiAccordionSummaryProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { styles } from './Accordion.styles';

import { Icon } from '~ui-kit';

type AccordionSummaryProps = MuiAccordionSummaryProps & WithStyles<typeof styles>;

export const AccordionSummary = withStyles(styles)((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<Icon icon="drop" />}
    classes={{ root: props.classes.accordionSummary }}
  >
    {props.children}
  </MuiAccordionSummary>
));
