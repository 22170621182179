import SockJS from 'sockjs-client';
import { Client, over, Subscription, Message } from 'stompjs';

import { api } from '~constants';
import { AppConnectionStatus } from '~models';

const CONNECTION_TIMEOUT = 60 * 1000;

export interface StompMessage<T> extends Message {
  data: T;
}

export type SubscriptionType = 'topic' | 'queue';

class StompService {
  private clientId: number | null = null;

  private reconnectTimeout = 5;

  private client: Client | null = null;

  private subscriptions: Map<string, { callback; type: SubscriptionType; subscription: Subscription }> = new Map();

  private timer: ReturnType<typeof setTimeout> | undefined = undefined;

  public connect(token: string, setConnectionStatus: (status: AppConnectionStatus) => void): void {
    this.clientId = this.clientId ?? Date.now();

    // Establish a new connection

    const socket = new SockJS(api.websocket(token), null, {
      timeout: CONNECTION_TIMEOUT,
    });

    const client = over(socket);
    client.debug = () => {};

    client.connect(
      { 'client-id': this.clientId },
      () => {
        this.client = client;

        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.resubscribe(client);

        setConnectionStatus('connected');

        console.info('Connected to websocket');
      },
      err => {
        console.error(err);

        this.reconnect(token, setConnectionStatus);
      }
    );
  }

  public disconnect(setConnectionStatus: (status: AppConnectionStatus) => void) {
    this.client?.disconnect(() => {
      console.error('Disconnected from websocket');

      this.client = null;

      setConnectionStatus('disconnected');
    });
  }

  private reconnect(token: string, setConnectionStatus: (status: AppConnectionStatus) => void) {
    const timeout = this.reconnectTimeout * 1000;

    if (this.client) {
      this.client?.disconnect(() => {
        console.error('Disconnected from websocket');

        setConnectionStatus('disconnected');

        this.client = null;

        console.info(`Reconnecting to websocket in ${this.reconnectTimeout} sec`);

        setConnectionStatus('reconnecting');

        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
          this.connect(token, setConnectionStatus);
        }, timeout);
      });

      return;
    }

    setConnectionStatus('reconnecting');

    console.info(`Reconnecting to websocket in ${this.reconnectTimeout} sec`);

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.connect(token, setConnectionStatus);
    }, timeout);
  }

  private formatMessage<T>(msg: Message): StompMessage<T> {
    return { ...msg, data: JSON.parse(msg.body) };
  }

  private constructSubscriptionName(topic: string, type: SubscriptionType = 'queue') {
    const prefix = type === 'queue' ? '/user/queue' : '/topic';

    return `${prefix}/${topic}`.replace('//', '/');
  }

  public subscribe<T>(
    topic: string,
    callback: (message: StompMessage<T>) => void,
    type: SubscriptionType = 'queue'
  ): void {
    if (this.subscriptions.has(topic)) {
      return;
    }

    const subscription = this.client?.subscribe(this.constructSubscriptionName(topic, type), msg =>
      callback(this.formatMessage(msg))
    ) as Subscription;

    this.subscriptions.set(topic, { callback, subscription, type });
  }

  public unsubscribe(topic: string) {
    if (!this.subscriptions.has(topic)) {
      return;
    }

    this.subscriptions.get(topic)?.subscription?.unsubscribe();
    this.subscriptions.delete(topic);
  }

  private resubscribe(client: Client): void {
    for (const [topic, { callback, subscription, type }] of this.subscriptions.entries()) {
      subscription.unsubscribe();
      const sub = client.subscribe(this.constructSubscriptionName(topic, type), msg =>
        callback(this.formatMessage(msg))
      );

      this.subscriptions.set(topic, { subscription: sub, callback, type });
    }
  }
}

export const stomp = new StompService();
