import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
      flexShrink: 0,

      '& button:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  });
