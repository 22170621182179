import { FormHelperTextClassKey, StyleRules } from '@material-ui/core';

export const MuiFormHelperText: Partial<StyleRules<FormHelperTextClassKey>> = {
  root: {
    fontSize: 11,
    fontStyle: 'italic',
    '&:empty': {
      margin: 0,
    },
  },
};
