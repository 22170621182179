import { Dictionary } from '@reduxjs/toolkit';
import { assoc, isNil, map, omit, pick, values } from 'ramda';

import {
  GeoCoordinates,
  UnitType,
  GeoIconType,
  Subscriber,
  IPLink,
  Hybrid,
  NonAESUnit,
  UnitRoute,
  GeoUnitRoute,
  GeoSubscriber,
  GeoIPLink,
  GeoHybrid,
  GeoUnitRouteHop,
  GeoUnitModel,
  GeoFaultsByUnitType,
  GeoFaultByUnitType,
  GeoFaultsByCode,
  GeoFaultByCode,
  GeoLinkLayers,
  GeoDependency,
  DependencyTypeColor,
} from '~models';

let turf;

import('~utils/turf').then(m => {
  turf = m.turf;
});

type Unit = Subscriber | IPLink | Hybrid | NonAESUnit;

const getGeoIconType = <T = Unit>(unit: T, unitType: UnitType): GeoIconType => {
  switch (unitType) {
    case 'SUBSCRIBER': {
      if (((unit as unknown) as Subscriber).type === 'Fire') {
        return ((unit as unknown) as Subscriber).reportingRoute === 'Internet Only' ? 'fire-ip' : 'fire-rf';
      }

      if (((unit as unknown) as Subscriber).type === 'Burg') {
        return ((unit as unknown) as Subscriber).reportingRoute === 'Internet Only' ? 'burglary-ip' : 'burglary-rf';
      }

      return 'unknown';
    }

    case 'HYBRID':
      return 'hybrid';

    case 'IP_LINK':
      return 'ip-link';

    case 'NON_AES':
      return 'non-aes';

    default:
      return 'unknown';
  }
};

const pickCoordinates = (unit: GeoUnitModel) => pick(['latitude', 'longitude'], unit);
export const getCoordinatesByUnitType = (
  unit: { unitType: UnitType; id: number },
  subscribers: Dictionary<GeoSubscriber>,
  ipLinks: Dictionary<GeoIPLink>,
  hybrid: Dictionary<GeoHybrid>
): GeoCoordinates | null => {
  switch (unit.unitType) {
    case 'SUBSCRIBER':
      return pickCoordinates((subscribers[unit.id] as GeoSubscriber) || {});

    case 'IP_LINK':
      return pickCoordinates((ipLinks[unit.id] as GeoIPLink) || {});

    case 'HYBRID':
      return pickCoordinates((hybrid[unit.id] as GeoHybrid) || {});

    default:
      return null;
  }
};

export const mapRoutesWithGeolocation = (
  routes: (GeoUnitRoute | UnitRoute)[],
  subscribers: Dictionary<GeoSubscriber>,
  ipLinks: Dictionary<GeoIPLink>,
  hybrid: Dictionary<GeoHybrid>
): GeoUnitRoute[] =>
  routes.map(route => {
    const firstHopPosition = getCoordinatesByUnitType(
      { unitType: 'SUBSCRIBER', id: route.id },
      subscribers,
      ipLinks,
      hybrid
    );
    const hops = route.hops
      .map(hop => ({
        ...hop,
        ...getCoordinatesByUnitType(hop, subscribers, ipLinks, hybrid),
      }))
      .filter(hop => !isNil(hop.latitude) && !isNil(hop.longitude));

    return {
      ...route,
      ...firstHopPosition,
      hops,
    } as GeoUnitRoute;
  }).filter(hop => !isNil(hop.latitude) && !isNil(hop.longitude));

export const mapGeoUnits = <T = Unit>(units: T[] = [], unitType: UnitType) =>
  map(unit => ({ ...unit, unitType, iconType: getGeoIconType<T>(unit, unitType) }), units);

export const mapUnitsToFeatureCollection = (units: GeoCoordinates[]) =>
  turf.helpers.featureCollection(
    units
      .filter(unit => !isNil(unit.latitude) && !isNil(unit.longitude))
      .map(unit =>
        turf.helpers.point([unit?.longitude as number, unit?.latitude as number], omit(['longitude', 'latitude'], unit))
      )
  );

export const getRouteCoordinates = (route: GeoUnitRoute) => {
  const firstHop = [route.longitude, route.latitude] as number[];
  const hops = (route.hops as GeoUnitRouteHop[]).map(hop => [hop.longitude, hop.latitude]) as number[][];

  return [firstHop, ...hops];
};
export const mapRoutesToFeatureCollection = (routes: GeoUnitRoute[]) => {
  const features = routes
    .map(route => ({ route, coordinates: getRouteCoordinates(route) }))
    .filter(({ coordinates }) => coordinates.length >= 2)
    .map(({ route, coordinates }) => turf.helpers.lineString(coordinates, route));

  return turf.helpers.featureCollection(features);
};

export const mapRoutesWithKeyField = (routes: UnitRoute[]): (UnitRoute & { key: number })[] =>
  routes.map((route, key) => assoc('key', key, route));

export const getGeoFaultsIds = (faults: GeoFaultsByUnitType | GeoFaultsByCode | null): number[] => {
  if (faults) {
    return values(faults)
      .flat()
      .map((fault: GeoFaultByUnitType | GeoFaultByCode) => (fault?.unitId));
  }

  return [];
};

export const getLinkLayersIds = (linkLayers: GeoLinkLayers | null): number[] => {
  if (linkLayers) {
    return Object
      .values(values(linkLayers)
        .flat())
      .flatMap(item => Object.values(item)
        .flat());
  }

  return [];
};

export const getDependencyIds = (
  geoDependency: GeoDependency | null
): {id: number, dependency: DependencyTypeColor }[] => {
  if (geoDependency) {
    return Object
      .values(geoDependency)
      .flatMap(dependency => Object.keys(dependency)
        .flatMap(color => dependency[color]
          .map(item =>
            ({ ...item, dependency: color })
          )));
  }

  return [];
};
