import { AnyAction, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { extractPaginationValues } from '~features/page/pageUtils';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { actions as sharedActions } from '../sharedSlice';

import { Fault, PaginationResponse, ResponseError } from '~models';
import { http } from '~services';
import { getResponseError } from '~utils';

export interface FaultsState {
  data: Fault[];
  isLoading: boolean;
  error?: ResponseError | null;
}

export const initialState: FaultsState = {
  data: [],
  isLoading: false,
};

export const { name, actions, reducer } = createSlice({
  name: 'faults',
  initialState,
  reducers: {
    // Fetch faults
    fetchFaultsInit(
      state,
      action: PayloadAction<{ apiUrl: string; message?: string; notify?: boolean }>) {
      state.isLoading = true;
    },
    fetchFaultsSuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<Fault>>) {
      state.isLoading = false;
      state.data = content;
    },
    fetchFaultsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.isLoading = false;
      state.error = payload;
    },

    // Reset faults
    resetFaults(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

export const fetchFaultsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchFaultsInit.match),
    mergeMap(({ payload: { apiUrl, notify, message } }) =>
      http.getJSON<PaginationResponse<Fault>>(apiUrl).pipe(
        mergeMap(values => {
          const epicActions: AnyAction[] = [
            actions.fetchFaultsSuccess(values),
            pageActions.setPagePagination(extractPaginationValues(values)),
          ];

          if (notify && message) {
            epicActions.push(
              notificationsActions.enqueue({
                message,
                options: {
                  variant: 'success',
                },
              })
            );
          }
          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchFaultsFailed(error));
        })
      )
    )
  );

export const epics = combineEpics(fetchFaultsEpic);
export const allEpics = {
  fetchFaultsEpic,
};

const getFaultsState = (state: AES.RootState) => state.faults;
export const selectors = {
  getFaultsState,

  getFaults: createDraftSafeSelector(getFaultsState, state => state.data),
  isFaultsLoading: createDraftSafeSelector(getFaultsState, state => state.isLoading),
};

declare global {
  namespace AES {
    export interface Actions {
      faults: typeof actions;
    }
    export interface RootState {
      faults: FaultsState;
    }
    export interface Selectors {
      faults: typeof selectors;
    }
  }
}
