import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getLiveTrafficPacketMeta } from './utils';

import { Clipboard, LiveTrafficRouting } from '~components';
import { appDetailRoutes } from '~constants';
import { LiveTrafficPacket as ILiveTrafficPacket } from '~models';
import { selectors } from '~store';
import { Card, CardDetails, CardSummary, ContentField, IconText, ContentWrapper, Divider, NavLink } from '~ui-kit';
import { formatDate, getLinkByUnitType, getUnitLabelByUnitType, intToHex } from '~utils';

const styles = createStyles({
  title: {
    minWidth: 170,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  buttons: {
    display: 'flex',
    minWidth: 210,
    justifyContent: 'space-between',
  },
  message: {
    flexGrow: 1,
  },
});

export interface LiveTrafficPacketProps extends WithStyles<typeof styles> {
  packet: ILiveTrafficPacket;
  timeFormat?: string;
  info?: boolean;
}

export const LiveTrafficPacket = withStyles(styles)(({ packet, timeFormat, info, classes }: LiveTrafficPacketProps) => {
  if (!packet) {
    return null;
  }

  const {
    alarmType,
    alarmTypeDescription,
    businessUnitId,
    businessUnitName,
    cid,
    createdAt,
    id,
    ipDeviceId,
    ipDeviceUnitType,
    lineCard,
    routes,
    message,
    title,
    unitId,
    unitType,
    isRestoral,
  } = packet;
  const { status, icon } = getLiveTrafficPacketMeta(alarmType ?? 'UNKNOWN');
  const routeCount = routes.length + (unitId === ipDeviceId ? 0 : 2);
  const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

  return (
    <Card key={id} expandable>
      <CardSummary status={(isRestoral && 'success') || status} expandable>
        <IconText label={title} icon={icon} status={status} classes={{ root: clsx(classes.title) }} />

        <Divider flexItem orientation="vertical" />

        <ContentWrapper spacing="both">
          <ContentField title={getUnitLabelByUnitType(unitType)} width={100}>
            <NavLink to={getLinkByUnitType(unitType, businessUnitId, unitId)}>{intToHex(unitId)}</NavLink>
          </ContentField>

          <ContentField title="Business Unit" width={130}>
            <NavLink to={appDetailRoutes.businessUnitGeneral(businessUnitId)}>{businessUnitName}</NavLink>
          </ContentField>

          <ContentField title="Line Card" width={70}>
            {lineCard || '-'}
          </ContentField>

          {ipDeviceId && (
            <ContentField title={`Delivery ${getUnitLabelByUnitType(ipDeviceUnitType)}`} width={130}>
              <NavLink to={getLinkByUnitType(ipDeviceUnitType, businessUnitId, ipDeviceId)}>
                {intToHex(ipDeviceId)}
              </NavLink>
            </ContentField>
          )}

          <ContentField title="Date & Time">{formatDate(createdAt, false, timeFormat, timeZone)}</ContentField>

          {info && (
            <Fragment>
              <ContentField title="CID" width={120}>
                {cid || '-'}
              </ContentField>
              <ContentField title="Alarm Type" width={120}>
                {alarmType || '-'}
              </ContentField>
            </Fragment>
          )}
        </ContentWrapper>

        <ContentWrapper spacing="both" classes={{ root: clsx(classes.actions) }}>
          <Divider flexItem orientation="horizontal" spacing="right" />
        </ContentWrapper>
      </CardSummary>

      <CardDetails>
        {(cid && (
          <Fragment>
            {!info && (
              <div>
                <ContentField title="CID" width={120}>
                  {cid}
                </ContentField>
                <ContentField title="Alarm Type" width={120}>
                  {alarmType}
                </ContentField>
              </div>
            )}
            <ContentField title="Route" width={400}>
              {routeCount > 0 ? (
                <LiveTrafficRouting
                  route={routes}
                  deliveryId={ipDeviceId}
                  deliveryType={ipDeviceUnitType}
                  unitId={unitId}
                  count={routeCount}
                  businessUnitId={businessUnitId}
                />
              ) : (
                '-'
              )}
            </ContentField>
            <ContentField title="Alarm Description">{alarmTypeDescription}</ContentField>
          </Fragment>
        )) || (
          <ContentField classes={{ root: classes.message }} title="Message">
            <Clipboard text={message}>{message as string}</Clipboard>
          </ContentField>
        )}
      </CardDetails>
    </Card>
  );
});
