import { WithStyles, Button, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState, MouseEvent, useEffect, useCallback } from 'react';

import { styles } from './Filter.styles';
import { FilterOption, SelectedFilterOption } from './types';

import { IconType } from '~icons';
import { IconText, Menu, Checkbox } from '~ui-kit';

type FilterProps = WithStyles<typeof styles> & {
  options?: FilterOption[];
  selected?: SelectedFilterOption[];
  initial?: SelectedFilterOption[];
  title?: string;
  icon?: IconType;
  onSelect(selected: SelectedFilterOption[]): void;
};

export const Filter = withStyles(styles)(
  ({
    options = [],
    selected = [],
    initial = [],
    title = 'Filter',
    icon = 'filter',
    classes,
    onSelect,
  }: FilterProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedInner, setSelected] = useState<SelectedFilterOption[]>(initial);

    useEffect(() => {
      setSelected(selected);
    }, [selected]);

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const onOptionToggle = useCallback(
      ({ value }: FilterOption, isSelected: boolean): void => {
        const values: SelectedFilterOption[] = isSelected
          ? selectedInner.filter(v => v !== value)
          : [...selectedInner, value];

        setSelected(values);
      },
      [selectedInner]
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
      setSelected(selected);
    }, [selected]);

    const onSubmit = useCallback(() => {
      setAnchorEl(null);
      onSelect(selectedInner);
    }, [selectedInner]);

    const onReset = useCallback(() => {
      handleClose();
      onSelect(initial);
    }, []);

    return (
      <div>
        <Button variant="text" onClick={handleClick} className={classes.actionButton}>
          <IconText label={title} icon={icon} />
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
        >
          {options.map(item => {
            const isSelected = selectedInner.includes(item.value);

            return (
              <MenuItem key={item.value} onClick={() => onOptionToggle(item, isSelected)}>
                <Checkbox classes={{ root: classes.checkbox }} checked={isSelected} color="primary" />
                {item.label}
              </MenuItem>
            );
          })}

          <div className={classes.buttons}>
            <Button variant="outlined" color="primary" size="small" onClick={onReset}>
              Reset
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={onSubmit}>
              Ok
            </Button>
          </div>
        </Menu>
      </div>
    );
  }
);
