import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(1.5),
      '&:empty': {
        margin: 0,
      },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      padding: '3px 8px',
      borderRadius: '12px',
      margin: theme.spacing(0, 0.5, 0.5, 0),
      '&.clear': {
        backgroundColor: theme.palette.transparentColor.main,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '& .icon': {
          marginLeft: 0,
          marginRight: theme.spacing(0.5),
        },
        '&:hover': {
          color: getStyles(theme.palette.primary.light, theme.palette.primary.dark),
          '& .icon': {
            background: `${getStyles(theme.palette.primary.light, theme.palette.primary.dark)} !important`,
          },
        },
      },
    },
    iconClear: {
      marginLeft: theme.spacing(0.5),
      cursor: 'pointer',
    },
    iconSort: {
      marginLeft: theme.spacing(0.5),
      backgroundColor: `${theme.palette.blueColor.light} !important`,
    },
  });
};
