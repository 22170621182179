import { equals } from 'ramda';
import { FC, useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getUserTimeFormat } from '~features/users/usersUtils';
import { useSubscription } from '~hooks/useSubscription';

import { LiveTrafficPacket } from './LiveTrafficPacket';

import { PageLoader } from '~components';
import { LiveTrafficPacket as ILiveTrafficPacket } from '~models';
import { boundActions, selectors } from '~store';

interface LiveTrafficContentProps {
  channel: string;
  liveTrafficData?: ILiveTrafficPacket[];
  limit?: boolean;
  isLive?: boolean;
}

export const LiveTrafficContent: FC<LiveTrafficContentProps> = ({ channel, liveTrafficData, limit, isLive }) => {
  const events = useSelector(selectors.liveTraffic.getLiveTraffic, equals);
  const recentItems = (events ?? [])
    .concat(liveTrafficData ?? [])
    .sort((a: ILiveTrafficPacket, b: ILiveTrafficPacket) => (a.createdAt > b.createdAt && -1) || 1);
  const isLoading = useSelector(selectors.liveTraffic.getLiveTrafficLoader, equals);
  const account = useSelector(selectors.profile.getAccount, equals);
  const timeFormat = getUserTimeFormat(account);
  const [liveTraffic, setLiveTraffic] = useState<ILiveTrafficPacket[]>([]);

  useSubscription<ILiveTrafficPacket[]>(channel, msg => {
    boundActions.liveTraffic.addLiveTraffic(msg.data);
  });

  useEffect(() => {
    localStorage.setItem('liveTraffic', JSON.stringify([]));

    return () => {
      boundActions.liveTraffic.reset();
      localStorage.removeItem('liveTraffic');
    };
  }, []);

  useEffect(() => {
    if (liveTrafficData?.length) {
      localStorage.setItem('liveTraffic', JSON.stringify(liveTrafficData));
      setLiveTraffic(liveTrafficData);
    }
  }, [liveTrafficData]);

  useEffect(() => {
    if (isLive) {
      const savedEvents = JSON.parse(localStorage.getItem('liveTraffic') as string);
      setLiveTraffic(limit ? savedEvents?.slice(0, 500) : savedEvents);
    }
  }, [isLive]);

  useEffect(() => {
    if (isLive) {
      setLiveTraffic(liveTrafficData?.length ? recentItems : events);
    }
    localStorage.setItem('liveTraffic', JSON.stringify(liveTrafficData?.length ? recentItems : events));
  }, [events, isLive]);

  if (isLoading) {
    return <PageLoader fullscreen />;
  }

  return (
    <Fragment>
      {liveTraffic.map(event => (
        <LiveTrafficPacket packet={event} key={event?.uniqueKey} timeFormat={timeFormat} info={limit} />
      ))}
    </Fragment>
  );
};
