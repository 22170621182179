import { Box, Tab as MuiTab, TabProps, Theme, makeStyles, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { has } from 'ramda';
import { FC } from 'react';

import { styles } from './Tab.styles';

import { IconType } from '~icons';
import { Icon } from '~ui-kit';

export type CustomTabProps = TabProps & {
  counter?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  })
);

const CustomTab = withStyles(styles)(MuiTab);

export const Tab: FC<CustomTabProps> = props => {
  const { counter, label, icon } = props;
  const classes = useStyles();

  const title = has('counter', props) ? (
    <Box component="span">
      {label}
      <Box component="i" className="counter">
        {counter}
      </Box>
    </Box>
  ) : has('icon', props) ? (
    <Box component="span" style={{ display: 'flex' }}>
      {props.selected ? <Icon icon={icon as IconType} className={classes.root} /> : <Icon icon={icon as IconType} />}
      <Box style={{ marginLeft: '8px' }}>{label}</Box>
    </Box>
  ) : (
    label
  );

  return <CustomTab {...props} icon={''} label={title} disableRipple />;
};
