import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { FormTextFieldProps } from '~ui-kit';

export const GridItem = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(0, 1)} !important`,
  },
}))(Grid);

export const radioPacketLifeFields = [
  { name: 'checkin', label: 'TTL Check-in', type: 'number', helperText: '10-1440 min' },
  { name: 'status', label: 'TTL Status', type: 'number', helperText: '10-1440 min' },
  { name: 'alarm', label: 'TTL Alarm', type: 'number', helperText: '10-1440 min' },
  { name: 'trouble', label: 'TTL Trouble', type: 'number', helperText: '10-1440 min' },
  { name: 'restoral', label: 'TTL Restoral', type: 'number', helperText: '10-1440 min' },
  { name: 'intelliTap', label: 'TTL Intellipro', type: 'number', helperText: '10-1440 min' },
  { name: 'specials', label: 'TTL Specials', type: 'number', helperText: '10-1440 min' },
] as FormTextFieldProps[];
