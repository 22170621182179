import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { Icon } from '../Icon/Icon';

import { styles } from './Select.styles';

export type LabelLike = number | string | ReactNode;
export type ValueLike = number | string;

export type SelectOption = {
  label?: LabelLike;
  value: ValueLike;
  optional?: string;
};

export type SelectProps = WithStyles<typeof styles> &
  MuiSelectProps & {
    options?: (SelectOption | ValueLike)[];
    helperText?: string;
    error?: boolean;
    showing?: boolean;
  };

export const Select = withStyles(styles)(
  ({ error, className, classes, options = [], label, helperText, showing, ...props }: SelectProps) => (
    <FormControl className={clsx(classes.root, className, { showing })}>
      <InputLabel error={error}>{label}</InputLabel>
      <MuiSelect
        IconComponent={p => <Icon {...p} icon="drop" />}
        MenuProps={{ classes: { paper: classes.paper } }}
        error={error}
        disabled={props.disabled ?? showing}
        {...props}
      >
        {options.map((option, i) => {
          let label: LabelLike;
          let value: ValueLike;
          if (['string', 'number'].includes(typeof option)) {
            label = value = option as ValueLike;
          } else {
            value = (option as SelectOption).value;
            label = (option as SelectOption).label ?? (option as SelectOption).value;
          }
          return (
            <MenuItem key={i} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
    </FormControl>
  )
);
