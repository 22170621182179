import { OptionsObject, SnackbarMessage } from 'notistack';

export interface Notification {
  key?: number | string | NotificationKey;
  message?: SnackbarMessage;
  dismissed?: boolean;
  options?: OptionsObject;
}

export enum NotificationKey {
  HealthCheckFailed = 'health-check-failed',
  AlarmSound = 'sound',
  CannotRedirect = 'cannot-redirect',
}
