import { Drawer, Button, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Formik, FormikValues } from 'formik';
import { MouseEvent, useCallback, PropsWithChildren } from 'react';

import { PageLoader } from '~components/Page';

import { styles } from './SideFilters.styles';
import { SideFiltersButtons } from './SideFiltersButtons';
import { SideFiltersContent } from './SideFiltersContent';
import { SideFiltersHeading } from './SideFiltersHeading';

type SideFiltersProps = WithStyles<typeof styles> &
  PropsWithChildren<{
    opened: boolean;
    loading: boolean;
    title?: string;
    initialValues: unknown;
    validationSchema?: unknown;
    values: unknown;
    getAppliedFiltersCount: (initial: unknown, values: unknown) => number;
    onSubmit(values: unknown): void;
    onReset(): void;
    onClose(): void;
    onRefresh?(): void;
  }>;

export const SideFilters = withStyles(styles)(
  ({
    opened,
    loading,
    title = 'Filters',
    initialValues,
    validationSchema,
    values,
    getAppliedFiltersCount,
    onSubmit,
    onReset,
    onClose,
    onRefresh,
    classes,
    children,
  }: SideFiltersProps) => {
    const onCloseHandler = useCallback((e: MouseEvent) => {
      e.stopPropagation();

      onClose();
    }, []);

    const onRefreshHandler = useCallback((e: MouseEvent) => {
      e.stopPropagation();

      if (onRefresh) {
        onRefresh();
      }
    }, []);

    const onResetFilters = useCallback(e => {
      e.stopPropagation();

      onClose();
      onReset();
    }, []);

    const onFiltersApply = useCallback(
      values => e => {
        e.stopPropagation();

        onClose();

        onSubmit(values);
      },
      []
    );

    const onFormSubmit = useCallback((values: unknown) => onSubmit(values), []);

    return (
      <Drawer open={opened} anchor="right" onClose={onCloseHandler} classes={{ paper: clsx(classes.paper) }}>
        <Formik
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
          initialValues={values as FormikValues}
          enableReinitialize
        >
          {({ values: formValues, isValid }) => {
            const appliedFiltersCount = getAppliedFiltersCount(values, formValues) || 0;

            return (
              <div className={classes.root}>
                <SideFiltersHeading onClose={onCloseHandler} onRefresh={onRefreshHandler} title={title} />

                {loading ? <PageLoader /> : <SideFiltersContent>{children}</SideFiltersContent>}

                <SideFiltersButtons>
                  <Button color="primary" variant="outlined" type="button" onClick={onResetFilters}>
                    Reset Filters
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={onFiltersApply(formValues)}
                    disabled={!appliedFiltersCount || !isValid}
                  >
                    Apply Filters ({isValid ? appliedFiltersCount : 0})
                  </Button>
                </SideFiltersButtons>
              </div>
            );
          }}
        </Formik>
      </Drawer>
    );
  }
);
