/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { equals, is, not } from 'ramda';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { http } from '~services/http';
import { getResponseError } from '~utils/response';

import { actions as sharedActions } from '../sharedSlice';

import { isNoLicense, removeLocalStorageItem } from './pageUtils';

import { Pagination, ResponseError } from '~models';
import { SortOption, SelectedSortOption, FilterOption, SelectedFilterOption, SideFiltersCategory } from '~ui-kit';

interface PageState {
  search: string | null;
  sorting: {
    options: SortOption[];
    selected?: SelectedSortOption | null;
  };
  filters: {
    opened: boolean;
    loading: boolean;
    values: any;
    initialValues: any;
    options: any;
    categories: {
      [key: string]: SideFiltersCategory;
    };
  };
  columns: {
    options: FilterOption[];
    selected: SelectedFilterOption[];
  };
  pagination: Pagination;
  loaded: boolean;
  notFound: boolean | null;
  noLicense: boolean | null;
}

export const initialState: PageState = {
  search: null,
  sorting: {
    options: [],
    selected: null,
  },
  filters: {
    opened: false,
    loading: false,
    values: {},
    initialValues: {},
    options: {},
    categories: {},
  },
  columns: {
    options: [],
    selected: [],
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    size: 20,
    number: 0,
  },
  loaded: false,
  notFound: null,
  noLicense: null,
};

export type SideFiltersMapper = (payload: any) => { values: any; categories: { [key: string]: SideFiltersCategory } };

export const { name, actions, reducer } = createSlice({
  name: 'page',
  initialState,
  reducers: {
    // Sorting actions
    setPageSortingOptions(
      state,
      { payload: { options, selected } }: PayloadAction<{ options: SortOption[]; selected?: SelectedSortOption | null }>
    ) {
      state.sorting.options = options;

      if (selected) {
        state.sorting.selected = selected;
      }
    },
    selectPageSorting(state, { payload }: PayloadAction<SelectedSortOption | null>) {
      state.sorting.selected = payload;
    },
    clearPageSorting(state, { payload = '' }: PayloadAction<string>) {
      state.sorting.selected = null;

      if (payload) {
        removeLocalStorageItem(payload);
      }
    },

    // Side page filters actions
    toggleSideFilters(state) {
      state.filters.opened = !state.filters.opened;
    },
    resetSideFiltersToInitialValues(state) {
      state.filters.values = state.filters.initialValues;
    },
    setSideFiltersValues(state, { payload }: PayloadAction<any>) {
      state.filters.values = payload;
    },
    resetSideFilters(state) {
      state.filters = initialState.filters;
    },
    clearCategoryFilters(state, { payload }: PayloadAction<string>) {
      if (state.filters.categories[payload]?.names?.length) {
        state.filters.categories[payload]?.names?.forEach(name => {
          state.filters.values[name] = state.filters.initialValues[name];
        });
      }
      state.filters.values[payload] = state.filters.initialValues[payload];
    },

    // Fetch side filter

    fetchSideFiltersOptionsInit(
      state,
      // TODO: typings
      { payload }: PayloadAction<{ url: string; mapFiltersOptions: SideFiltersMapper }>
    ) {
      state.filters.loading = true;
      state.filters.options = {};
    },
    fetchSideFiltersOptionsSuccess(
      state,
      { payload: { categories, values } }: PayloadAction<ReturnType<SideFiltersMapper>>
    ) {
      state.filters.loading = false;
      state.filters.categories = categories;
      state.filters.initialValues = values;
      state.filters.values = values;
    },
    fetchSideFiltersOptionsFailed(state, action: PayloadAction<ResponseError>) {
      state.filters.loading = false;
    },

    // Reset actions
    resetPageState(state) {
      state.search = initialState.search;
      state.filters = initialState.filters;
      state.sorting = initialState.sorting;
      state.columns = initialState.columns;
      state.pagination = initialState.pagination;
      state.loaded = initialState.loaded;
      state.notFound = initialState.notFound;
    },

    // Columns filters
    setPageColumnsFiltersOptions(
      state,
      {
        payload: { options, selected = [] },
      }: PayloadAction<{ options: FilterOption[]; selected?: SelectedFilterOption[] }>
    ) {
      state.columns.options = options;

      if (selected.length) {
        state.columns.selected = selected;
      } else {
        state.columns.selected = options.map(({ value }) => value);
      }
    },

    selectPageColumnsFilters(state, { payload }: PayloadAction<SelectedFilterOption[]>) {
      state.columns.selected = payload;
    },

    // Pagination
    setPagePagination(state, { payload }: PayloadAction<Pagination>) {
      state.pagination = payload;
    },

    // Set page loaded
    setPageLoaded(state) {
      state.loaded = true;
    },

    // Set page not found
    setPageFound(state) {
      state.notFound = false;
    },
    setPageNotFound(state) {
      state.notFound = true;
    },

    // Set page No License
    setPageNoLicense(state, { payload }: PayloadAction<boolean>) {
      state.noLicense = payload;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getPageState = (state: AES.RootState) => state.page;

export const selectors = {
  getPageState,

  getPageSearchValue: createDraftSafeSelector(getPageState, state => state.search),
  getPageSortingOptions: createDraftSafeSelector(getPageState, state => state.sorting.options),
  getSelectedPageSorting: createDraftSafeSelector(getPageState, state => state.sorting.selected),

  isPageSideFiltersOpened: createDraftSafeSelector(getPageState, state => state.filters?.opened),
  getSideFiltersValues: createDraftSafeSelector(getPageState, state => state.filters?.values),
  getSideFiltersInitialValues: createDraftSafeSelector(getPageState, state => state.filters.initialValues),
  isSideFiltersLoading: createDraftSafeSelector(getPageState, state => state.filters.loading),
  getSideFiltersCategories: createDraftSafeSelector(getPageState, state => state.filters.categories),
  hasAppliedFilters: createDraftSafeSelector(getPageState, state =>
    not(equals(state.filters.initialValues, state.filters.values))
  ),

  getPageColumnsFiltersOptions: createDraftSafeSelector(getPageState, state => state.columns.options),
  getSelectedPageColumnsFilters: createDraftSafeSelector(getPageState, state => state.columns.selected),

  getPagePagination: createDraftSafeSelector(getPageState, state => state.pagination),

  isPageLoaded: createDraftSafeSelector(getPageState, state => state.loaded),
  isPageNotFound: createDraftSafeSelector(getPageState, state => is(Boolean, state.notFound) && state.notFound),
  isPageNoLicense: createDraftSafeSelector(getPageState, state => Boolean(state.noLicense)),
};

const fetchSideFiltersOptionsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSideFiltersOptionsInit.match),
    mergeMap(({ payload: { url, mapFiltersOptions } }) =>
      http.getJSON(url).pipe(
        mergeMap(payload => of(actions.fetchSideFiltersOptionsSuccess(mapFiltersOptions(payload)))),
        catchError(err => {
          const epicActions: AnyAction[] = [actions.fetchSideFiltersOptionsFailed(getResponseError(err))];

          if (isNoLicense(err.status)) {
            epicActions.push(actions.setPageNoLicense(true));
          }

          return of(...epicActions);
        })
      )
    )
  );

export const epics = combineEpics(fetchSideFiltersOptionsEpic);
export const allEpics = {
  fetchSideFiltersOptionsEpic,
};

declare global {
  namespace AES {
    export interface RootState {
      page: PageState;
    }
    export interface Actions {
      page: typeof actions;
    }
    export interface Selectors {
      page: typeof selectors;
    }
  }
}
