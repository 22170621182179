import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { memo, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getConnectivityAlarmsCount } from '~features/alarms/utils';

import { links } from './CurrentAlerts.config';
import { styles } from './CurrentAlerts.styles';

import { appDetailRoutes } from '~constants';
import { ConnectivityAlarm } from '~models';
import { selectors } from '~store';
import { NavLink, ContentField } from '~ui-kit';

type CurrentAlertsProps = WithStyles<typeof styles>;

const renderContent = (
  type: string,
  label: string,
  count: AES.RootState['alarms']['count'],
  alarms?: ConnectivityAlarm[]
) => {
  const value = count[type];
  const text = value === 0 ? value : `${value} ${label}`;
  const className = value === 0 ? 'success' : 'error';

  switch (type) {
    case 'alerts':
    case 'unacknowledged': {
      return <span className={className}>{text}</span>;
    }

    case 'connectivity': {
      if (!alarms?.length) {
        return <span className="error">N/A</span>;
      }

      const { UP, DOWN } = getConnectivityAlarmsCount(alarms);

      return (
        <Fragment>
          <span className={UP > 0 ? 'success' : 'error'}>{UP} Up</span>
          <span className="default"> / </span>
          <span className="error">{DOWN} Down</span>
        </Fragment>
      );
    }
  }
};

export const CurrentAlerts = memo(
  withStyles(styles)(({ classes }: CurrentAlertsProps) => {
    const alarmsCount = useSelector(selectors.alarms.getAlarmsCount, equals);
    const connectivityAlarms = useSelector(selectors.alarms.getConnectivityAlarms, equals) as ConnectivityAlarm[];
    const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);

    return (
      <div className={classes.root}>
        {links(isSelfMonitoring).filter(link => !link.hide)
          .map(({ title, type, label }) => (
            <ContentField title={title} key={type} classes={{ root: clsx(classes.contentField) }}>
              <NavLink to={appDetailRoutes.dashboardTab(type)} classes={{ root: clsx(classes.link) }}>
                {renderContent(type, label, alarmsCount, connectivityAlarms)}
              </NavLink>
            </ContentField>
          ))}
      </div>
    );
  })
);
