import { FormHelperText, InputLabel, Switch, SwitchProps, FormControlLabel } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { styles } from './SwitchField.styles';

export type SwitchFieldProps = Partial<SwitchProps> &
  WithStyles<typeof styles> & {
    label?: string | ReactNode;
    helperText?: string;
    showing?: boolean;
    error?: boolean;
    activeText?: ReactNode;
    text?: ReactNode;
  };

export const SwitchField = withStyles(styles)(
  ({
    showing,
    label,
    activeText = 'Yes',
    text = 'No',
    classes,
    helperText,
    error,
    disabled,
    value,
    ...props
  }: SwitchFieldProps) => (
    <div className={clsx(classes.root, { showing })}>
      {label ? (
        <InputLabel shrink error={error}>
          {label}
        </InputLabel>
      ) : null}

      <FormControlLabel
        classes={{ root: clsx(classes.switch) }}
        control={
          <Switch
            {...props}
            color="primary"
            checked={value as boolean}
            disabled={disabled ?? showing}
            disableRipple
          ></Switch>
        }
        label={value ? activeText : text}
      />

      {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
    </div>
  )
);
