import { RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core';

import { SelectOption } from '../Select/Select';

import { Radio } from './Radio';

export type RadioGroupProps = MuiRadioGroupProps & {
  options: SelectOption[];
  showing?: boolean;
};

export const RadioGroup = ({ options, showing, classes, ...props }: RadioGroupProps) => {
  if (showing) {
    const option = options.find(opt => opt.value === props.value);

    return <Radio label={option?.label} showing />;
  }

  // TODO: incorrectly handles values: typeof value === 'number'

  return (
    <MuiRadioGroup {...props}>
      {options.map((option, i) => (
        <Radio key={i} label={option.label} value={option.value} />
      ))}
    </MuiRadioGroup>
  );
};
