import { Button, Typography, withStyles, WithStyles, Tooltip, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { useCallback, memo, PropsWithChildren, Fragment } from 'react';
import { useSelector } from 'react-redux';

import ackIcon from '~assets/images/icons/ack.svg';
import { getAlarmCode } from '~features/alarms/utils';

import { styles } from './AlarmListCard.styles';
import { getAlarmMeta, getAlarmUnitLabel, getAlarmUnitLink, isFullAlarm } from './utils';

import { ProtectedContent } from '~components';
import { appDetailRoutes } from '~constants';
import { Alarm, AlarmManualAck, PermissionName } from '~models';
import { selectors } from '~store';
import {
  Card,
  CardDetails,
  CardSummary,
  ContentField,
  IconText,
  ContentWrapper,
  Divider,
  NavLink,
  CardColumn,
} from '~ui-kit';
import { formatDate, intToHex } from '~utils';

export interface AlarmListCardCallbacks {
  onSilence?(alarm: Alarm): void;
  onAcknowledge?(alarm: Alarm): void;
  onAlarmsCount?(alarmCount: number, alarm: Alarm): void;
  onAckAllDialog?(): void;
  onExpand(alarm: Alarm, expanded: boolean): void;
  getDate?(alarm: Alarm): number;
  count?: number;
  showDetailsStatus?: boolean;
  showTooltip?: boolean;
}

export type AlarmListCardProps = PropsWithChildren<WithStyles<typeof styles>> &
  AlarmListCardCallbacks & {
    alarm: Alarm;
    columns?: string[];
    expanded?: boolean;
    timeFormat: string;
  };

export const AlarmListCard = memo(
  withStyles(styles)(
    ({
      alarm,
      columns = [],
      onExpand,
      onSilence = () => {},
      onAcknowledge = () => {},
      onAlarmsCount = () => {},
      onAckAllDialog = () => {},
      getDate = (alarm: Alarm) => alarm.createdAt,
      count = 0,
      showTooltip = false,
      showDetailsStatus = false,
      classes,
      expanded,
      timeFormat,
    }: AlarmListCardProps) => {
      if (!alarm) {
        return null;
      }

      const { status, title, icon } = getAlarmMeta(alarm.alarmType);
      const isExpanded = expanded ?? alarm.meta?.isExpanded;
      const unitLabel = getAlarmUnitLabel(alarm);
      const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);
      const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);
      const alarmTitle = (
        <IconText
          label={alarm.alarmTypeDescription}
          icon={icon}
          status={status}
          classes={{ root: clsx(classes.titleRoot), title: clsx(classes.title) }}
        />
      );
      const isAcknowledgeAllBtn = isFullAlarm(alarm);

      const onAckCallback = useCallback(() => {
        onAcknowledge(alarm);
      }, [alarm]);

      const onSilenceCallback = useCallback(() => {
        onSilence(alarm);
      }, [alarm]);

      const onExpandCallback = useCallback(
        (e: unknown, panelExpanded: boolean) => {
          if (expanded) {
            return;
          }

          onExpand(alarm, panelExpanded);
        },
        [alarm, isExpanded]
      );

      const onAckAllConfirmationCallback = useCallback(() => {
        onAlarmsCount(count, alarm);
        onAckAllDialog();
      }, [count, alarm]);

      if (alarm.isTest && alarm.manualAck === AlarmManualAck.ACKNOWLEDGED) {
        return null;
      }

      return (
        <Card
          key={alarm.id}
          expandable
          expanded={isExpanded}
          onChange={onExpandCallback}
          className={status}
          classes={{ root: clsx(classes.root) }}
          TransitionProps={{ timeout: 0, unmountOnExit: true, in: isExpanded }}
        >
          <CardSummary
            status={status}
            expandable
            hideIcon={expanded}
            classes={{ cardSummaryRoot: clsx(classes.cardSummary) }}
          >
            {showTooltip ? (
              <Tooltip title={alarm.alarmTypeDescription} placement="right">
                {alarmTitle}
              </Tooltip>
            ) : (
              alarmTitle
            )}

            <Divider flexItem orientation="vertical" spacing="left" />

            <ContentWrapper spacing="both" classes={{ root: classes.fieldsWrapper }}>
              {count ? (
                <Fragment>
                  <CardColumn value="count" columns={columns}>
                    <ContentField title="Alarms count" width={110} classes={{ root: classes.field }}>
                      {count}
                    </ContentField>
                  </CardColumn>

                  <Divider flexItem orientation="vertical" spacing="right" />
                </Fragment>
              ) : null}

              <ContentField title="Address" classes={{ root: classes.address }}>
                <Tooltip title={`${alarm?.address1}\n${alarm.address2}` ?? ''}>
                  <Grid>
                    {alarm?.address1 || 'N/A'}
                  </Grid>
                </Tooltip>
              </ContentField>

              {isSelfMonitoring && <Fragment>
                <Divider flexItem orientation="vertical" spacing="right" />

                <ContentField title="Description" classes={{ root: classes.address }}>
                  <Tooltip title={alarm?.facpDescription ?? ''}>
                    <Grid>
                      {alarm?.facpDescription || 'N/A'}
                    </Grid>
                  </Tooltip>
                </ContentField>
              </Fragment>}
            </ContentWrapper>

            <CardColumn columns={columns} value="actions">
              <ContentWrapper spacing="both" classes={{ root: clsx(classes.actions, { expanded }) }}>
                <div className={classes.buttons}>
                  {isAcknowledgeAllBtn && <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE_ALL]}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={alarm.meta?.isLoading || count === 1 }
                      onClick={onAckAllConfirmationCallback}
                    >
                      Ack All
                    </Button>
                  </ProtectedContent>}

                  <Divider flexItem orientation="vertical" />

                  <ProtectedContent permissions={[PermissionName.DASHBOARD_SILENCE]}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={alarm.silent || alarm.meta?.isLoading}
                      onClick={onSilenceCallback}
                    >
                      Silence
                    </Button>
                  </ProtectedContent>

                  <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE]}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={alarm.meta?.isLoading}
                      onClick={onAckCallback}
                    >
                      Ack
                    </Button>
                  </ProtectedContent>
                </div>
              </ContentWrapper>
            </CardColumn>

            <CardColumn columns={columns} value="acknowledged">
              <Divider flexItem orientation="vertical" spacing="right" />

              <ContentWrapper classes={{ root: classes.acknowledged }}>
                <img src={ackIcon} className="icon" />
                <Typography variant="body1">Acknowledged</Typography>
              </ContentWrapper>
            </CardColumn>
          </CardSummary>

          <CardDetails
            classes={{ root: clsx(classes.cardDetails) }}
            status={showDetailsStatus ? status : undefined}
          >
            <ContentField title="Date & Time" width={180}>
              {formatDate(getDate(alarm), false, timeFormat, timeZone)}
            </ContentField>
            <ContentField title={unitLabel} width={80} classes={{ root: classes.field }}>
              <NavLink to={getAlarmUnitLink(alarm)}>{intToHex(alarm.unitId)}</NavLink>
            </ContentField>
            <ContentField title="Code" width={180}>
              {getAlarmCode(alarm).trim()}
            </ContentField>
            <ContentField title="Tracking Number" width={350}>
              {alarm.id}
            </ContentField>
            <ContentField title="Business Unit" width={150}>
              <NavLink to={appDetailRoutes.businessUnitDetails(alarm.businessUnitId)}>
                {alarm.businessUnitName}
              </NavLink>
            </ContentField>
            <ContentField title="Alarm type" width={150}>
              {title}
            </ContentField>
            <ContentField title="Note">{alarm.note || 'N/A'}</ContentField>
          </CardDetails>
        </Card>
      );
    }
  )
);
