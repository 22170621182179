import { is } from 'ramda';

export const intToHex = (id: number, size = 4) => {
  if (!is(Number, id)) {
    return id;
  }

  let value = id.toString(16).toUpperCase();

  while (value?.length < size) {
    value = `0${value}`;
  }

  return value;
};

export const hexToInt = (hex: string) => parseInt(hex, 16);
