import {
  Button,
  Stepper,
  Step,
  StepLabel,
  WithStyles,
  withStyles,
  StepConnector as MiStepConnector,
} from '@material-ui/core';
import { equals } from 'ramda';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ConnectSpeaker } from './ConnectSpeaker';
import { DefaultBusinessUnit } from './DefaultBusinessUnit';
import { styles } from './FirstLoginStepper.styles';
import { LicenseAgreement } from './LicenseAgreement/LicenseAgreement';
import { PasswordReset } from './PasswordReset';

import { selectors, boundActions } from '~store';
import { Dialog, DialogTitle, Divider } from '~ui-kit';

export const steps = ['Agreement', 'Change password', 'Add Business Unit', 'Connect Speaker'];

const getStepContent = (step: number) => {
  switch (step) {
    case 0: {
      return <LicenseAgreement />;
    }

    case 1: {
      return (
        <PasswordReset>
          <Button type="submit" variant="contained" color="primary">
            Next
          </Button>
        </PasswordReset>
      );
    }

    case 2: {
      return <DefaultBusinessUnit />;
    }

    case 3: {
      return <ConnectSpeaker />;
    }
  }
};

type StepConnectorProps = WithStyles<typeof styles>;
const StepConnector = withStyles(styles)(({ classes }: StepConnectorProps) => (
  <MiStepConnector classes={{ line: classes.line }} />
));

type FirstLoginStepperProps = WithStyles<typeof styles>;

export const FirstLoginStepper = withStyles(styles)(({ classes }: FirstLoginStepperProps) => {
  const [activeStep, setActiveStep] = useState(-1);
  const isAuthorized = useSelector(selectors.auth.isAuthorized, equals);
  const isLicenseAgreement = useSelector(selectors.app.getAppInfo, equals).isAgreed;
  const shouldUpdatePassword = useSelector(selectors.auth.shouldUpdatePassword, equals);
  const isOnlyOrphanBusinessUnit = useSelector(selectors.businessUnits.isOnlyOrphanBusinessUnit, equals);

  const appDialog = useSelector(selectors.app.getAppDialogs, equals);
  const areBusinessUnits = useSelector(selectors.businessUnits.areBusinessUnitsIds, equals);
  const isOpenDialog =
    appDialog.firstLoginStepper &&
    !appDialog.uploadMigratedDB &&
    !appDialog.selectMigratedBU &&
    areBusinessUnits;

  useEffect(() => {
    if (isAuthorized) {
      boundActions.businessUnits.fetchBusinessUnitsIdsInit({ includeOrphan: true });
    }
  }, [isAuthorized]);

  useEffect(() => {
    switch (true) {
      case !isLicenseAgreement: {
        setActiveStep(0);
        break;
      }

      case shouldUpdatePassword: {
        setActiveStep(1);
        break;
      }

      case isOnlyOrphanBusinessUnit: {
        setActiveStep(2);
        break;
      }

      default:
        setActiveStep(3);
    }
  }, [isOpenDialog, shouldUpdatePassword, isOnlyOrphanBusinessUnit, isLicenseAgreement]);

  return (
    <Dialog open={isOpenDialog} fullWidth maxWidth="sm">
      <DialogTitle classes={{ title: classes.title }} title="To start working with INCC:" />

      <Stepper
        classes={{ horizontal: classes.horizontal }}
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector />}
      >
        {steps.map(step => (
          <Step key={step} classes={{ completed: classes.completedStep }}>
            <StepLabel classes={{ iconContainer: classes.icon, alternativeLabel: classes.label }}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Divider classes={{ root: classes.divider }} />

      <div>{getStepContent(activeStep)}</div>
    </Dialog>
  );
});
