import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '36px !important',
      minWidth: 'auto !important',
      padding: `${theme.spacing(0, 1)} !important`,
      marginLeft: theme.spacing(1),
    },
    icon: {
      background: `${theme.palette.primary.main} !important`,
      '&:hover': {
        background: `${theme.palette.greyColor.light} !important`,
      },
    },
  });
