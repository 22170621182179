import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      padding: 0,
      lineHeight: '60px',
      whiteSpace: 'nowrap',
      color: getStyles(theme.palette.greyColor.light, theme.palette.greyColor.dark),
      '&:hover .icon': {
        background: `${theme.palette.primary.main} !important`,
      },
      '& .icon': {
        background: getStyles(theme.palette.greyColor.light, theme.palette.greyColor.dark),
      },
      '&.Mui-disabled .icon': {
        background: getStyles(theme.palette.greyColor.dark, theme.palette.greyColor.main),
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  });
};
