/* eslint-disable no-confusing-arrow */
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { Fragment, useMemo, useState } from 'react';

import { SubscriberSettingsSection } from '~features/subscribers/subscribersSlice';

import {
  getSubscriberRadioPacketLifeInitialValues,
  prepareSubscriberRadioPacketLifeRequestPayload,
  subscriberRadioPacketLifeValidationSchema,
} from './config';
import { GridItem, radioPacketLifeFields } from './fields';

import { FullHeightPage } from '~hocs';
import { Subscriber, ResponseError, SubscriberSettings } from '~models';
import { FormContent, FormPanelWrapper, FormTextField, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';

const FormWrapper = FullHeightPage(Form);
const DefaultButton = withStyles({ root: { marginRight: 4 } })(Button);

type SubscriberRadioPacketSettingsProps = {
  subscriber: Subscriber;
  loaders: {
    sections: { [key in SubscriberSettingsSection]: boolean };
    request: { [key in SubscriberSettingsSection]: boolean };
    defaultSetting: { 'radio-packet-life': boolean };
  };
  errors: ResponseError | null;
  timeFormat: string;
  onSubmit(value: {
    id: Subscriber['id'];
    businessUnitId: Subscriber['businessUnitId'];
    section: SubscriberSettingsSection;
    values: Partial<SubscriberSettings['timing']>;
  }): void;
  onRefresh(section: SubscriberSettingsSection): void;
  onSetDefault(secton: SubscriberSettingsSection, isSetDefault: boolean): void;
};

export const SubscriberRadioPacketSettings = ({
  subscriber,
  loaders,
  errors,
  timeFormat,
  onSubmit,
  onRefresh,
  onSetDefault,
}: SubscriberRadioPacketSettingsProps) => {
  const { id, businessUnitId, settings } = subscriber;
  const lastUpdated = useMemo(() => settings?.['radio-packet-life']?.lastUpdated, [loaders.sections['radio-packet-life']]);
  const [isSetDefault, setIsSetDefault] = useState<boolean>(true);

  return (
    <Formik
      initialValues={getSubscriberRadioPacketLifeInitialValues(settings?.['radio-packet-life'])}
      validationSchema={subscriberRadioPacketLifeValidationSchema}
      enableReinitialize
      onSubmit={payload => {
        onSubmit({
          id,
          businessUnitId,
          section: 'radio-packet-life',
          values: prepareSubscriberRadioPacketLifeRequestPayload(payload),
        });
        setIsSetDefault(isSetDefault ? isSetDefault : !isSetDefault);
      }}
    >
      {({ handleSubmit }) => (
        <FormWrapper>
          <FormPanelWrapper
            title="Radio Packet TTL"
            loading={loaders.request['radio-packet-life']}
            onRefresh={() => onRefresh('radio-packet-life')}
            renderActions={() => (
              <Fragment>
                <DefaultButton
                  onClick={() => {
                    onSetDefault('radio-packet-life', isSetDefault);
                    setIsSetDefault(!isSetDefault);
                  }}
                  size="small"
                  disabled={loaders.sections['radio-packet-life'] || loaders.defaultSetting['radio-packet-life']}
                >
                  { isSetDefault ? 'Set Default' : 'Cancel'}
                </DefaultButton>

                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={loaders.sections['radio-packet-life'] || loaders.defaultSetting['radio-packet-life']}
                >
                  Save
                </Button>
              </Fragment>
            )}
            subTitle={<LastDataUpdatedFormPanelSubtitle
              title="Last Updated: "
              timestamp={lastUpdated}
              timeFormat={timeFormat}
            />}
          >
            <FormContent error={errors} loading={loaders.sections['radio-packet-life'] || loaders.defaultSetting['radio-packet-life']}>
              <Grid container spacing={2}>
                {radioPacketLifeFields.map((field, i) => (
                  <GridItem item xs={12} md={4} key={i}>
                    <FormTextField {...field} cleanable />
                  </GridItem>
                ))}
              </Grid>
            </FormContent>
          </FormPanelWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
};
