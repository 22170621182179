import { ArgumentScale, EventTracker, ValueScale } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis,
  ValueAxis,
  Chart as DevXChart,
  SplineSeries,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Plugin } from '@devexpress/dx-react-core';
import { WithStyles, withStyles, Grid } from '@material-ui/core';
import { scaleTime } from 'd3-scale';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { DATE_FORMAT } from '~constants/date';
import { FilterOption } from '~ui-kit/Filter';
import { formatDate } from '~utils/date';

import { GridLine } from './ChartGridLine';
import { styles } from './LineChart.styles';

import { selectors } from '~store';

export type LineData = {
  arg: string;
  [key: string]: number | string;
};

export interface LineChartProps extends WithStyles<typeof styles> {
  data: LineData[];
  title?: string;
  argumentField?: string;
  argumentValues?: FilterOption[];
  argumentAxisValue?: number[];
  maxValue?: number;
  timeFormat?: string;
}

type ChartRootProps = WithStyles<typeof styles> & DevXChart.RootProps;
const ChartRootComponent = withStyles(styles)(({ classes, children, ...restProps }: ChartRootProps) => (
  <Grid {...restProps} className={classes.chart}>
    {children}
  </Grid>
));

type LegendRootProps = WithStyles<typeof styles> & Legend.RootProps;
const LegendRoot = withStyles(styles)(({ classes, ...restProps }: LegendRootProps) => (
  <Legend.Root className={classes.legend} {...restProps} />
));

type LegendItemProps = WithStyles<typeof styles> & Legend.ItemProps;
const LegendItem = withStyles(styles)(({ classes, ...restProps }: LegendItemProps) => (
  <Legend.Item {...restProps} className={classes.legendItem} />
));

type LegendLabelProps = WithStyles<typeof styles> & Legend.LabelProps;
const LegendLabel = withStyles(styles)(({ classes, ...restProps }: LegendLabelProps) => (
  <Legend.Label {...restProps} className={classes.legendLabel} />
));

type ArgumentAxisLabelProps = WithStyles<typeof styles> & ArgumentAxis.LabelProps;
const ArgumentAxisLabel = withStyles(styles)(({ classes, ...restProps }: ArgumentAxisLabelProps) => (
  <ArgumentAxis.Label {...restProps} className={classes.axisLabel} />
));

type ValueAxisLabelProps = WithStyles<typeof styles> & ValueAxis.LabelProps;
const ValueAxisLabel = withStyles(styles)(({ classes, ...restProps }: ValueAxisLabelProps) => (
  <ValueAxis.Label {...restProps} className={classes.axisLabel} />
));

type TooltipSheetProps = WithStyles<typeof styles> & Tooltip.SheetProps;
const TooltipSheet = withStyles(styles)(({ classes, children }: TooltipSheetProps) => (
  <Tooltip.Sheet className={classes.tooltip}>{children}</Tooltip.Sheet>
));

type TooltipContentProps = WithStyles<typeof styles> & Tooltip.ContentProps;
const TooltipContent = withStyles(styles)(
  ({ color, date, classes, text }: { color?: string; date: string | null } & TooltipContentProps) => (
    <Fragment>
      <div className={classes.tooltipContent}>
        <span className={classes.tooltipIndicator} style={{ backgroundColor: color }} />
        <span>{`${text} signals received`}</span>
      </div>
      <span className={classes.tooltipDate}>{date}</span>
    </Fragment>
  )
);

type TooltipArrowProps = WithStyles<typeof styles> & Tooltip.ArrowProps;
const TooltipArrow = withStyles(styles)(({ classes, ...props }: TooltipArrowProps) => (
  <Tooltip.Arrow className={classes.tooltipArrow} {...props} />
));

export const LineChart = withStyles(styles)(
  ({
    data = [],
    argumentValues = [],
    argumentField = '',
    maxValue = 0,
    timeFormat = DATE_FORMAT,
  }: LineChartProps) => {
    const modifyDomain = () => [0, maxValue];

    if (!data?.length) {
      return null;
    }

    const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

    return (
      <DevXChart data={data} rootComponent={ChartRootComponent}>
        <Legend position="bottom" rootComponent={LegendRoot} itemComponent={LegendItem} labelComponent={LegendLabel} />
        <ArgumentAxis labelComponent={ArgumentAxisLabel} showTicks />
        <ArgumentScale factory={scaleTime} />
        <ValueAxis gridComponent={GridLine} labelComponent={ValueAxisLabel} />
        <ValueScale modifyDomain={modifyDomain} />
        <EventTracker />
        <Tooltip
          sheetComponent={TooltipSheet}
          contentComponent={props => (
            <TooltipContent
              // @ts-ignore
              color={argumentValues[props.targetItem.order].color}
              date={formatDate(data[props.targetItem.point].timestamps as number, false, timeFormat, timeZone)}
              {...props}
            />
          )}
          arrowComponent={props => <TooltipArrow {...props} />}
        />
        <Plugin name="SpLineSeries">
          {argumentValues.map((field, i) => {
            const label = (
              <div style={{ display: 'flex' }}>
                <p>{field.label}</p>
                <img src={field.icon} style={{ margin: '-4px 0px 0px 4px' }} />
              </div>
            );
            return (
              <SplineSeries
                key={i}
                name={(label as unknown) as string}
                valueField={field.value}
                argumentField={argumentField}
                color={field.color}
              />
            );
          })}
        </Plugin>
      </DevXChart>
    );
  }
);
