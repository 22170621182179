import { Theme, StyleRules } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

import { matchStyles } from '~utils';

const ellipsisStyles: CSSProperties = {
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const styles = ({ palette: { type, greyColor }, spacing }: Theme) => {
  const getStyles = matchStyles(type);

  return {
    root: {
      marginRight: spacing(3),
      ...ellipsisStyles,
      '&:last-child': {
        marginRight: 0,
      },
    },
    title: {
      fontSize: 10.5,
      lineHeight: '15px',
      marginBottom: 4,
      color: getStyles(greyColor.main, greyColor.dark),
      ...ellipsisStyles,
    },
  } as StyleRules<'root' | 'title'>;
};
