import { memo, PropsWithChildren, ReactElement } from 'react';

import { FilterOption, SelectedFilterOption } from '../Filter/types';

type ColumnProps = PropsWithChildren<{
  value?: FilterOption['value'];
  columns: SelectedFilterOption[];
}>;

export const CardColumn = memo(({ columns = [], value = '', children }: ColumnProps) => {
  if (!columns.includes(value)) {
    return null;
  }

  return children as ReactElement;
});
