import { withStyles, WithStyles } from '@material-ui/styles';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback } from 'react';

import { AutocompleteField, AutocompleteFieldProps } from '../Fields/AutocompleteField';

import { styles } from './styles';

import { SelectOption } from '~ui-kit';

export type FormAutocompleteFieldProps = Partial<AutocompleteFieldProps> &
  WithStyles<typeof styles> & {
    name: string;
    isUserForm?: boolean;
  };

export const FormAutocompleteField = withStyles(styles)(
  ({ name, classes, options = [], isUserForm = false, ...props }: FormAutocompleteFieldProps) => {
    const { setFieldValue } = useFormikContext();

    const onClickField = useCallback((name, option) => {
      if (isUserForm) {
        setFieldValue(name, option ? { id: option.value, units: [] } : '');
      } else {
        setFieldValue(name, option ? option.value : '');
      }
    }, []);

    return (
      <Field name={name}>
        {({ field: { value, ...fieldProps }, meta: { touched, error } }: FieldProps) => {
          const getSelectedOption = () => {
            let selectedOption;

            if (isUserForm) {
              selectedOption = options.find(option => option.value === value.id) || null;
            } else {
              selectedOption = options.find(option => (option as SelectOption).value === value) || null;
            }

            return selectedOption;
          };

          return (
            <AutocompleteField
              {...props}
              {...fieldProps}
              options={options}
              error={touched && Boolean(error)}
              helperText={(touched && error) || props.helperText}
              classes={classes}
              // @ts-ignore
              onChange={(_event, option: SelectOption) => {
                onClickField(name, option);
              }}
              value={getSelectedOption()}
            />
          );
        }}
      </Field>
    );
  }
);
