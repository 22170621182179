import { Grid, Button, WithStyles, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { styles } from './MultipleRangeInput.styles';

import { FormTextField, Required } from '~ui-kit';
import { hexIdValidator, hexToInt } from '~utils';

const validationSchema = Yup.object().shape({
  valueFrom: hexIdValidator('Incorrect format')
    .required('Field is required'),
  valueTo: hexIdValidator('Incorrect format')
    .required('Field is required')
    .test(
      'valueTo should equal or more than valueFrom',
      'Incorrect format',
      (currentValue, context) =>
        hexToInt(context.parent.valueFrom) <= hexToInt(currentValue as string))
});

type MultipleRangeInputProps = WithStyles<typeof styles> & {
  onSubmit?: (values) => void;
  fromValue?: string;
  toValue?: string;
}

export const MultipleRangeInput = withStyles(styles)(
  ({ fromValue = '', toValue = '', onSubmit = () => {}, classes }: MultipleRangeInputProps
  ) => (
    <Formik
      initialValues={{
        valueFrom: fromValue,
        valueTo: toValue
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form className={classes.content}>
          <Grid className={classes.text}>Range</Grid>

          <FormTextField
            name="valueFrom"
            className={classes.input}
            label={<Required>From</Required>}
            cleanable
          />

          <FormTextField
            name="valueTo"
            className={classes.input}
            label={<Required>To</Required>}
            cleanable
          />

          <Button
            className={classes.button}
            type="submit"
            size="small"
            variant="contained"
            color="primary"
          >
            Find
          </Button>
        </Form>
      )}
    </Formik>
  ));
