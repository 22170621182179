import { Fragment } from 'react';

import { PageContent, PagePanel } from '~components';
import { appRoutes } from '~constants';
import { DataPlaceholder } from '~ui-kit';

interface NoResultsProps {
  backTo?: string;
  title?: string;
  panelTitle?: string;
}

export const NoResults = ({
  backTo = appRoutes.dashboard,
  title = 'No results',
  panelTitle = 'Go Back',
}: NoResultsProps) => (
  <Fragment>
    <PagePanel backTo={backTo} title={panelTitle} />

    <PageContent>
      <DataPlaceholder title={title} image="no-results" />
    </PageContent>
  </Fragment>
);
