import { Typography, Grid, styled } from '@material-ui/core';

const Content = styled(Grid)({
  height: '230px',
  overflowY: 'scroll',
});

export const LicenseAgreementText = () => (
  <Content>
    <Typography>AES Corporation Software License Agreement</Typography>
    <br />
    <Typography>
      This AES Corporation Software License Agreement, including the Ordering Document which by this reference
      is incorporated herein (the “Agreement” or “License”), is a binding agreement between AES Corporation, a
      Massachusetts corporation (“AES” or “Licensor”) and the entity identified on the Ordering Document as the licensee
      of the Software (“Licensee” or “you”). This Agreement govern Licensee’s access to and use of the Software
      (as defined below) and shall be effective as of the earlier of (i) the date on which you install the Software
      and (ii) the date on which you accept this Agreement in accordance with the following paragraph.</Typography>
    <br />
    <Typography>
      AES PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT
      LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY (1) CLICKING THE “ACCEPT” BUTTON, (2) CHECKING THE “ACCEPT” BOX ON THE
      ORDERING DOCUMENT, OR (3) USING THE SOFTWARE, LICENSEE ACCEPTS THIS AGREEMENT AND AGREE THAT LICENSEE IS LEGALLY
      BOUND BY ITS TERMS. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL
      ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE
      TERMS AND CONDITIONS, IN WHICH CASE THE TERMS “LICENSEE” AND “YOU” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES.
      IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH THE TERMS AND
      CONDITIONS SET FORTH HEREIN, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT DOWNLOAD, INSTALL, OR USE
      THE SOFTWARE OR DOCUMENTATION, AND AES WILL NOT AND DOES NOT LICENSE THE SOFTWARE OR DOCUMENTATION TO LICENSEE.
    </Typography>
    <br />
    <Typography>
      NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE’S ACCEPTANCE OF THIS AGREEMENT, NO
      LICENSE IS GRANTED (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER THIS AGREEMENT, AND THIS AGREEMENT
      EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A
      LEGITIMATE, AUTHORIZED COPY OF AES’S SOFTWARE.
    </Typography>
    <br />
    <Typography>1. LICENSE GRANT</Typography>
    <Typography>
      1.1 Subject to and conditioned on Licensee’s payment of Fees, AES grants to Licensee, and Licensee accepts, a
      perpetual, non-exclusive, non-sublicensable and non-transferable license to (1) use the Software only as
      authorized in this Agreement; (2) use and make reasonable number of copies of the Documentation (as defined
      below) solely for its internal operations; and (3) make one (1) copy of the Software solely for back-up,
      disaster recovery, and testing purposes, provided that all titles, trademarks, copyright, proprietary and
      restricted rights shall be reproduced in such copy and that such copy shall be subject to the terms of this
      Agreement. Licensee may not assign, transfer, rent, or sublicense the Software (any violation of the foregoing
      will result in automatic termination of the license without any right of refund). The Software consists of
      proprietary products of AES or its third party suppliers, third party licenses, and the proprietary rights that
      protect such property may include, but are not limited to, U.S. and international copyrights, trademarks, patents,
      and trade secret laws of general applicability. All right, title and interest in and to the Software shall remain
      with AES or its third party suppliers, as applicable. This Agreement does not convey to Licensee title to the
      Software or Documentation, but only a limited right of use, revocable in accordance with its terms.
    </Typography>
    <Typography>
      The license granted herein (subject to the terms of this License) is a perpetual license, but access to support
      and maintenance as described in Section 5 of this Agreement is always subject to Licensee paying for a current
      Annual Support and Maintenance Plan. For use of Software in which more than one network environment is available,
      you may solely use the Software on a network that you own or on the network of customers for whom you provide
      monitoring services.
    </Typography>
    <Typography>
      For purposes of this Agreement, (i) “Software” means the AES software for which Licensee is purchasing a license,
      as set forth in the Ordering Document, in machine executable object code format, and, if Licensee maintains an
      Annual Support and Maintenance Plan, any upgrades, updates, or releases provided to Licensee pursuant to such
      Annual Support and Maintenance Plan, and (ii) ”Documentation” means Licensor’s user manuals, handbooks, and
      installation guides relating to the Software provided by Licensor to Licensee either electronically or in hard
      copy form.
    </Typography>
    <br />
    <Typography>1.2 Installation and Use Restrictions</Typography>
    <Typography>
    Licensee assumes sole responsibility for the installation, use, and valid operation of the Software. Licensee shall
    not use the Software or Documentation for any purpose beyond the scope of the license granted in this Agreement.
    Without limiting the foregoing and except as otherwise expressly set forth in this Agreement, Licensee shall not at
    any time, directly or indirectly: (i) copy (except as provided by Section 1), modify, or create derivative works of
    the Software or the Documentation, in whole or in part; (ii) rent, lease, lend, sell, sublicense, assign,
    distribute, publish, transfer, or otherwise make available the Software or the Documentation; (iii) reverse
    engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to or discover the
    source code of, or the techniques or trade secrets incorporated in, the Software, in whole or in part; (iv) remove
    any proprietary notices from the Software or the Documentation; or (v) use the Software in any manner or for any
    purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of
    any person, or that violates any applicable law.
    </Typography>
    <br />
    <Typography>1.3 Reservation of Rights</Typography>
    <Typography>Licensor reserves all rights not expressly granted to Licensee in this Agreement. Except for the limited
    rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication,
    waiver, estoppel, or otherwise, to Licensee or any third party any intellectual property rights or other right,
    title, or interest in or to the Software or Documentation.</Typography>
    <br />
    <Typography>1.4 Delivery</Typography>
    <Typography>Licensor shall deliver the Software electronically, on tangible media, or by other means, in Licensor’s
    sole discretion.</Typography>
    <br />
    <Typography>2. LICENSEE RESPONSIBILITIES</Typography>
    <Typography>Licensee is responsible and liable for all uses of the Software and Documentation, directly or
    indirectly, whether such access or use is permitted by or in violation of this Agreement and whether such use
    results by or from access provided by Licensee.</Typography>
    <br />
    <Typography>3. CONSENT TO USE OF DATA</Typography>
    <Typography>Licensee agrees that AES may collect and use technical and related information, including but not
    limited to technical information about Licensee’s appliance, network, and peripherals, that is gathered
    periodically to facilitate the provision of software updates, product support and other services to Licensee
    (if any), and to verify compliance with the terms of this Agreement.</Typography>
    <br />
    <Typography>4. LICENSE FEES</Typography>
    <Typography>4.1 Fees</Typography>
    <Typography>The license fees set out in the Ordering Document (the “Fees”) paid by Licensee are in consideration
    of the Software, the Documentation and license.</Typography>
    <br />
    <Typography>4.2 Taxes</Typography>
    <Typography>Licensee shall be responsible for all applicable sales or use taxes or any value added or similar
    taxes payable with respect to the licensing of the Software, or arising out of or in connection with this
    Agreement, other than taxes levied or imposed based upon AES’s income. In the event that AES pays any such
    taxes on behalf of Licensee, AES shall invoice Licensee for such taxes and Licensee agrees to pay such
    taxes.</Typography>
    <br />
    <Typography>5. SOFTWARE SUPPORT AND MAINTENANCE AGREEMENT</Typography>
    <Typography>AES shall only provide upgrades, maintenance, additional support and enhancements in connection
    with the Software, if Licensee pays the required fee for the Annual Support and Maintenance Plan. If a
    Licensee declines to pay for the Annual Support and Maintenance Plan for one or more renewal periods and
    subsequently seeks to do so, Licensee must pay the fees for all missed renewal periods before AES will allow
    Licensee to enroll in the Annual Support and Maintenance Plan.</Typography>
    <br />
    <Typography>6. LIMITED WARRANTY</Typography>
    <Typography>(a) During the one (1)-year period commencing on the Effective Date (the “Warranty Period”), AES
    warrants for Licensee’s benefit alone, that the Software will perform materially as described in the
    Documentation.</Typography>
    <br />
    <Typography>(b) The warranty set forth in Section 6(a) does not apply and becomes null and void if Licensee
    breaches any provision of this Agreement, or if Licensee or any other person provided access to the Software
    by Licensee, whether or not in violation of this Agreement: (i) installs or uses the Software on or in
    connection with any hardware or software not specified in the Documentation or expressly authorized by
    Licensor in writing; (ii) modifies or damages the Software; or (iii) misuses the Software, including any use
    of the Software other than as specified in the Documentation or expressly authorized by Licensor in writing.
    AES assumes no responsibility for the operation or performance of any Licensee-written or Third-Party Materials.
    “Third-Party Materials” means materials and information, in any form or medium, that are not proprietary to
    Licensor, including any third-party: (a) documents, data, content or specifications; (b) components or other
    software, hardware or other products, facilities, equipment or devices; and (c) accessories, components, parts
    or features of any of the foregoing.</Typography>
    <br />
    <Typography>(c) If, during the Warranty Period, any Software fails to comply with the warranty set forth in
    Section 6(a) above, and such failure is not excluded from warranty pursuant to Section 6(b), Licensor shall,
    subject to Licensee’s promptly notifying Licensor in writing of such failure, at its sole option, either:
    (i) repair or replace the Software, provided that Licensee provides Licensor with all information Licensor
    requests to resolve the reported failure, including sufficient information to enable the Licensor to recreate
    such failure; or (ii) refund the Fees, subject to Licensee’s ceasing all use of and, if requested by Licensor,
    returning to Licensor all copies of the Software. If Licensor repairs or replaces the Software, the warranty will
    continue to run from the Effective Date and not from Licensee’s receipt of the repair or replacement. The remedies
    set forth in this Section 6(c) are Licensee’s sole remedies and Licensor’s sole liability under the limited warranty
    set forth in Section 6(a). For any breach of the foregoing warranty during the Warranty Period, Licensee’s exclusive
    remedy and AES’s entire liability will be as described in the previous sentence.</Typography>
    <br />
    <Typography>(d) THE FOREGOING LIMITED WARRANTIES ARE THE ONLY WARRANTIES PROVIDED BY AES AND AES DISCLAIMS ANY AND
    ALL OTHER WARRANTIES, WHETHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COURSE OF DEALING, USAGE OF TRADE, TITLE, OR INFRINGEMENT. AES
    DOES NOT WARRANT THAT THE SOFTWARE WILL MEET CUSTOMER’S REQUIREMENTS OR THAT THE OPERATION OF THE SOFTWARE WILL BE
    UNINTERRUPTED OR ERROR- FREE, OR THAT ERRORS IN THE SOFTWARE WILL BE CORRECTED. AES’ LIMITED WARRANTY IS IN LIEU OF
    ALL LIABILITIES OR OBLIGATIONS OF LICENSOR FOR DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE INSTALLATION OR
    PERFORMANCE OF THE SOFTWARE. THE PARTIES AGREE THAT THE SOFTWARE’S FAILURE TO PERFORM IN ACCORDANCE WITH THE
    SPECIFICATIONS SHALL NOT BE CONSIDERED A FAILURE OF THE ESSENTIAL PURPOSE OF THE REMEDIES CONTAINED HEREIN. EXCEPT
    FOR THE ABOVE LIMITED WARRANTY, THE ENTIRE RISK OF THE SOFTWARE’S QUALITY AND PERFORMANCE IS WITH CUSTOMER. ALL
    THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY OF THEM, WHETHER
    EXPRESS OR IMPLIED, IS STRICTLY BETWEEN LICENSEE AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF SUCH THIRD-PARTY
    MATERIALS.</Typography>
    <br />
    <Typography>7. PROPRIETARY RIGHTS; FEEDBACK</Typography>
    <Typography>7.1 Licensee acknowledges that title to all patents, copyrights, trade secrets, and other proprietary
    rights in or related to the Software and Documentation are and will remain the exclusive property of AES.
    Licensee will not acquire any right in the Software or Documentation except the limited rights specified in
    Section 1.</Typography>
    <br />
    <Typography>7.2 In addition, if Licensee or any of its employees or contractors sends or transmits any
    communications or materials to Licensor by mail, email, telephone, or otherwise, suggesting or recommending
    changes to the Software or Documentation, including without limitation, new features or functionality relating
    thereto, or any comments, questions, suggestions, or the like (“Feedback”), Licensor is free to use such Feedback
    irrespective of any other obligation or limitation between the Parties governing such Feedback. Licensee hereby
    assigns to Licensor on Licensee’s behalf, and on behalf of its employees, contractors and/or agents, all right,
    title, and interest in, and Licensor is free to use, without any attribution or compensation to any party, any
    ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any
    purpose whatsoever, although Licensor is not required to use any Feedback.</Typography>
    <br />
    <Typography>8. CONFIDENTIALITY</Typography>
    <Typography>Each party agrees that it shall not disclose to any third party any information concerning the
    customers, trade secrets, methods, processes or procedures or any other confidential, financial or business
    information of the other party which it learns during the course of its performance of this Agreement, without
    the prior written consent of such other party. This obligation shall survive the cancellation or other termination
    of this Agreement.</Typography>
    <Typography>The Software contains trade secrets and proprietary know-how that belong to AES and it is being made
    available to Licensee in strict confidence.</Typography>
    <Typography>ANY USE OR DISCLOSURE OF THE SOFTWARE OR OF THE SOFTWARE’S ALGORITHMS, PROTOCOLS OR INTERFACES, OTHER
    THAN IN STRICT ACCORDANCE WITH THIS LICENSE AGREEMENT, MAY BE ACTIONABLE AS A VIOLATION OF AES TRADE SECRET
    RIGHTS, AND SHALL OTHERWISE CONSTITUTE A MATERIAL BREACH OF THIS AGREEMENT.</Typography>
    <br />
    <Typography>9. TERMINATION</Typography>
    <Typography>9.1 Termination</Typography>
    <Typography>In addition to any other express termination right set forth in this Agreement:</Typography>
    <Typography>(a) Licensor may terminate this Agreement, effective on written notice to Licensee, if Licensee:
    (i) fails to pay any amount when due hereunder, and such failure continues more than thirty (30) days after
    Licensor’s delivery of written notice thereof; or (ii) breaches any of its obligations under Section 1.2 or
    Section 8; or</Typography>
    <br />
    <Typography>(b) Either party may terminate this Agreement, effective on written notice to the other party,
    if the other party materially breaches this Agreement, and such breach: (i) is incapable of cure; or (ii)
    being capable of cure, remains uncured thirty (30) days after the non- breaching party provides the breaching
    party with written notice of such breach.</Typography>
    <br />
    <Typography>9.2 Effect of Expiration and Termination</Typography>
    <Typography>Upon expiration or earlier termination of this Agreement, the license granted hereunder will also
    terminate, and, without limiting Licensee’s obligations under Section 8, Licensee shall cease using and delete,
    destroy, or return all copies of the Software and Documentation and certify in writing to the Licensor that the
    Software and Documentation has been deleted or destroyed. No expiration or termination will affect Licensee’s
    obligation to pay all fees that may have become due before such expiration or termination, or entitle Licensee
    to any refund.</Typography>
    <br />
    <Typography>9.3 Survival</Typography>
    <Typography>This Section 9.3 and Sections 4, 6.2(d), 7, 8, and 10-16 survive any termination or expiration of
    this Agreement. No other provisions of this Agreement survive the expiration or earlier termination of this
    Agreement.</Typography>
    <br />
    <Typography> 10. LIMITATION OF LIABILITY</Typography>
    <Typography>10.1 AES’ cumulative liability to Licensee or any party for any loss or damages resulting from any
    claims, demands, or actions arising out of or relating to this Agreement or the Software shall not exceed the
    Fees paid to AES to license the Software.</Typography>
    <br />
    <Typography>10.2 AES SHALL HAVE NO LIABILITY WITH RESPECT TO ITS OBLIGATIONS, UNDER THIS AGREEMENT OR OTHERWISE,
    FOR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES EVEN IF IT HAS BEEN ADVISED OF THE
    POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, THE LIABILITY OF AES TO CUSTOMER FOR ANY REASON AND UPON ANY CAUSE
    OF ACTION SHALL BE LIMITED TO THE AMOUNT PAID TO AES BY CUSTOMER UNDER THIS AGREEMENT. THIS LIMITATION APPLIES
    TO ALL CAUSES OF ACTION IN THE AGGREGATE, INCLUDING WITHOUT LIMITATION TO BREACH OF CONTRACT, BREACH OF WARRANTY,
    INFRINGEMENT, NEGLIGENCE, STRICT LIABILITY, DAMAGES OR COSTS RELATING TO THE LOSS OF PROFITS, BUSINESS, GOODWILL,
    DATA, OR COMPUTER PROGRAMS, MISREPRESENTATIONS, AND OTHER TORTS. BOTH PARTIES UNDERSTAND AND AGREE THAT THE REMEDIES
    AND LIMITATIONS HEREIN ALLOCATE THE RISKS OF SOFTWARE AND SERVICE NONCONFORMITY BETWEEN THE PARTIES AS AUTHORIZED BY
    APPLICABLE LAWS. THE FEES HEREIN REFLECT, AND ARE SET IN RELIANCE UPON, THIS ALLOCATION OF RISK AND THE EXCLUSION OF
    CONSEQUENTIAL DAMAGES SET FORTH IN THIS AGREEMENT.</Typography>
    <br />
    <Typography>10.3 Neither party shall be under any liability for any loss or for any failure to perform any
    obligation hereunder due to causes beyond its control including without limitation industrial disputes of whatever
    nature, power loss, telecommunications failure, acts of God, or any other cause beyond its reasonable
    control.</Typography>
    <br />
    <Typography>11. INDEMNIFICATION</Typography>
    <Typography>11.1 Licensor Indemnification</Typography>
    <Typography>(a) Licensor shall indemnify, defend, and hold harmless Licensee from and against any and all losses,
    damages, liabilities, and costs (including reasonable attorneys’ fees) (“Losses”) incurred by Licensee resulting
    from any third-party claim, suit, action, or proceeding (“Third-Party Claim”) alleging that the Software or
    Documentation, or any use of the Software or Documentation in accordance with this Agreement, infringes or
    misappropriates such third party’s US intellectual property rights, copyrights, or trade secrets, provided that
    Licensee promptly notifies Licensor in writing of the claim, cooperates with Licensor, and allows Licensor sole
    authority to control the defense and settlement of such claim</Typography>
    <br />
    <Typography>(b) If such a claim is made or appears possible, Licensee agrees to permit Licensor, at Licensor’s
    sole discretion, to (i) modify or replace the Software or Documentation, or component or part thereof, to make
    it non-infringing, or (ii) obtain the right for Licensee to continue use. If Licensor determines that none of
    these alternatives is reasonably available, Licensor may terminate this Agreement, in its entirety or with
    respect to the affected component or part, effective immediately on written notice to Licensee.</Typography>
    <br />
    <Typography>(c) Section 11.1(a) will not apply to the extent that the alleged infringement arises from or
    relates to: (i) use of the Software in combination with data, software, hardware, equipment, or technology not
    provided by Licensor or authorized by Licensor in writing; (ii) modifications to the Software not made by Licensor;
    (iii) use of any version other than the most current version of the Software or Documentation delivered to
    Licensee; or (iv) Third-Party Materials.</Typography>
    <br />
    <Typography>11.2 Licensee Indemnification</Typography>
    <Typography>Licensee shall indemnify, hold harmless, and, at Licensor’s option, defend Licensor from and against any
    Losses resulting from any Third-Party Claim based on Licensee’s: (i) use of the Software or Documentation in a
    manner not authorized by this Agreement; (ii) use of the Software in combination with data, software, hardware,
    equipment or technology not provided by Licensor or authorized by Licensor in writing; or (iii) modifications to the
    Software not made by Licensor, provided that Licensee may not settle any Third-Party Claim against Licensor without
    Licensor’s prior written consent to such settlement, and further provided that Licensor will have the right, at its
    option, to defend itself against any such Third-Party Claim or to participate in the defense thereof by counsel of
    its own choice. If Licensor elects to have Licensee defend a Third-Party Claim but Licensee fails or refuses to
    assume control of the defense of such Third-Party Claim, Licensor shall have the right, but no obligation, to defend
    against such Third-Party Claim, including settling such Third-Party Claim after giving notice to Licensee, in each
    case in such manner and on such terms as Licensor may deem appropriate.</Typography>
    <br />
    <Typography>11.3 Sole Remedy</Typography>
    <Typography>THIS SECTION 11 SETS FORTH LICENSEE’S SOLE REMEDIES AND LICENSOR’S SOLE LIABILITY AND OBLIGATION FOR ANY
    ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SOFTWARE OR DOCUMENTATION INFRINGES, MISAPPROPRIATES, OR OTHERWISE
    VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. IN NO EVENT WILL LICENSOR’S LIABILITY UNDER THIS
    SECTION 11 EXCEED THE FEES PAID.</Typography>
    <br />
    <Typography>12. EXPORT COMPLIANCE</Typography>
    <Typography>Exports of AES Corporation software may fall under the jurisdiction of the U.S. Department of Commerce,
    Bureau of Industry and Security¹s Export Administration Regulations (EAR), 15 CFR 730-774. By installing this
    software, you hereby agree that any re-sale, re-export or transfer of this software will be in full compliance with
    the EAR and that you will not ship to sanctioned/embargoed countries or individuals/entities on a U.S. Government
    Agency Denied Persons List. Further, you agree that you will not use any AES software in the design, development,
    production, use, maintenance or repair of any article with defense or proliferation related end uses including but
    not limited to nuclear, chemical, biological or missile technology applications.</Typography>
    <br />
    <Typography>13. GOVERNING LAW</Typography>
    <Typography>This Agreement shall be deemed to have been executed in the Commonwealth of Massachusetts and will be
    governed by and construed in accordance with its laws. The parties hereby consent to the jurisdiction of the state
    or federal courts of the Commonwealth of Massachusetts for the purpose of any action or proceeding brought by either
    of them in connection with or arising out of this Agreement.</Typography>
    <br />
    <Typography>14. ENTIRE AGREEMENT</Typography>
    <Typography>This Agreement comprises the entire agreement between the parties regarding the subject matter hereof
    and supersedes and merges all prior proposals, understandings and all other agreements, oral and written between the
    parties relating to the Agreement and may not be amended or altered except by a writing signed by both parties.
    Should any term of this Agreement be declared void or unenforceable by any court of competent jurisdiction such
    declaration shall have no effect on the remaining terms hereof.</Typography>
    <br />
    <Typography>15. MODIFICATION</Typography>
    <Typography>This Agreement may not be modified or amended except in a written document signed by a duly authorized
    representative of each party; no other act, usage, or custom will be deemed to amend or modify this Agreement.
    Each party hereby waives any right it may have to claim that this Agreement was subsequently modified other than
    in accordance with this Section 15.</Typography>
    <br />
    <Typography>16. EQUITABLE RELIEF</Typography>
    <Typography>Licensee acknowledges and agrees that a breach or threatened breach by Licensee of this License,
    including, but not limited to, a breach of its obligations under Sections 1.2, 7 or 8, would cause Licensor
    irreparable harm for which monetary damages would not be an adequate remedy and agrees that, in the event of such
    breach or threatened breach, Licensor will be entitled to equitable relief, including a restraining order, an
    injunction, specific performance, and any other relief that may be available from any court, without any requirement
    to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy.
    Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity,
    or otherwise.</Typography>
    <br />
    <Typography>EXHIBIT A FEES</Typography>
    <Typography>Licensee is required to make a one-time payment based on the number of accounts it wishes to include in
    the License as set forth in the Ordering Document.</Typography>
    <br />
    <Typography>Accounts: Accounts are calculated on a cumulative basis.</Typography>
    <br />
    <Typography>Payment Terms: All payments hereunder shall be made in US dollars. Payment of the upgrade fee for
    additional Accounts, if applicable, shall be made on or before the first day of the month following the month in
    which Licensee exceeds the applicable Account limit. If Licensee fails to make such payment or any other payment
    under this License when due, in addition to all other remedies that may be available: (i) Licensor may charge
    interest on the past due amount at the rate of 1.5% per month calculated daily and compounded monthly or, if
    lower, the highest rate permitted under applicable law; and (ii) Licensee shall reimburse Licensor for all
    reasonable costs incurred by Licensor in collecting any late payments or interest, including attorneys’ fees,
    court costs, and collection agency fees.</Typography>
  </Content>
);
