import { useTheme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

import { SelectedSortOption } from '~ui-kit/Sort';
import { getSortIconType } from '~ui-kit/Sort/utils';

import { Icon } from '../Icon/Icon';

import { styles } from './AppliedSideFilters.styles';

type AppliedSideFiltersProps = WithStyles<typeof styles> & {
  categories: { [key: string]: string };
  sorting?: SelectedSortOption | null;
  onFilterClear: (key: string) => void;
  onSortingClear: () => void;
  onReset: () => void;
};

export const AppliedSideFilters = withStyles(styles)(
  ({ categories, sorting, onFilterClear, onSortingClear, onReset, classes }: AppliedSideFiltersProps) => {
    const theme = useTheme();
    const filters = useMemo(() => Object.keys(categories), [categories]);
    const hasAppliedOptions = filters.length || sorting;
    const sortLabel = sorting?.value ? `Sort ${sorting.label}` : null;

    const onFilterReset = useCallback((key: string) => () => onFilterClear(key), [onFilterClear]);

    return (
      <ul className={classes.root}>
        {sorting && (
          <li key="sorting" className={classes.item}>
            {sortLabel}

            {/* @ts-ignore */}
            <Icon icon={getSortIconType(sorting)} classes={{ root: clsx(classes.iconSort) }} />

            <Icon
              icon="close"
              color={theme.palette.background.default}
              hoverColor={theme.palette.whiteColor.main}
              size={18}
              classes={{ root: clsx(classes.iconClear) }}
              onClick={onSortingClear}
            />
          </li>
        )}

        {filters.map(key => (
          <li className={classes.item} key={key}>
            {categories[key]}

            <Icon
              icon="close"
              color={theme.palette.background.default}
              hoverColor={theme.palette.whiteColor.main}
              size={18}
              classes={{ root: clsx(classes.iconClear) }}
              onClick={onFilterReset(key)}
            />
          </li>
        ))}

        {hasAppliedOptions ? (
          <li className={clsx('clear', classes.item)} onClick={onReset}>
            <Icon
              icon="close"
              color={theme.palette.primary.main}
              size={18}
              classes={{ root: clsx('clear', classes.iconClear) }}
            />
            Clear all
          </li>
        ) : null}
      </ul>
    );
  }
);
