import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';

import { Select, SelectProps } from '../Select/Select';

import { styles } from './FormSelect.styles';

export type FormSelectProps = Partial<SelectProps> &
  WithStyles<typeof styles> & {
    name: string;
  };

export const FormSelect = withStyles(styles)(({ name, classes, ...props }: FormSelectProps) => (
  <Field name={name}>
    {({ field, meta: { error, touched } }: FieldProps) => (
      <Select
        {...props}
        type="text"
        error={touched && Boolean(error)}
        helperText={(touched && error) || props.helperText}
        classes={{ root: clsx(classes.root) }}
        {...field}
      />
    )}
  </Field>
));
