import { PaperClassKey, StyleRules, Theme } from '@material-ui/core';
import { mergeDeepRight } from 'ramda';

import { colors } from '~styles/colors';

type PaperStyles = Partial<StyleRules<PaperClassKey>>

const commonStyles = ({ spacing }: Theme): PaperStyles => ({
  root: {
    boxShadow: `${colors.BOX_SHADOW} !important`,
    padding: spacing(2)
  },
});

export const lightPaperStyles = (theme: Theme) => mergeDeepRight<PaperStyles, PaperStyles>(commonStyles(theme), {
  root: {
    color: theme.palette.blueColor.main,
    backgroundColor: theme.palette.whiteColor.main
  }
}) as PaperStyles;
export const darkPaperStyles = (theme: Theme) => mergeDeepRight<PaperStyles, PaperStyles>(commonStyles(theme), {
  root: {
    color: theme.palette.greyColor.light,
    backgroundColor: theme.palette.blueColor.main
  }
}) as PaperStyles;
