import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Icon } from '../Icon/Icon';

import { styles } from './Checkbox.styles';

export type CheckboxProps = WithStyles<typeof styles> & Partial<MuiCheckboxProps>;

export const Checkbox = withStyles(styles)(({ classes, ...props }: CheckboxProps) => (
  <MuiCheckbox
    icon={<Icon size={16} icon="unchecked" classes={{ root: clsx(classes.icon) }} disabled={props.disabled} />}
    checkedIcon={
      <Icon size={16} icon="checked" classes={{ root: clsx(classes.checkedIcon) }} disabled={props.disabled} />
    }
    color="primary"
    classes={{ root: clsx(classes.root) }}
    disableRipple
    disableFocusRipple
    {...props}
  />
));
