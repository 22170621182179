import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { trailingSpacesValidator } from '~utils/validators';

import { SearchInput } from './SearchInput';

const validationSchema = yup.object().shape({
  search: trailingSpacesValidator(),
});

type UnitSearchProps = {
  onFind: (values) => void;
  onClean: () => void;
  onKeyPress?: (values) => void;
}

export const UnitSearchForm = ({ onFind, onClean, onKeyPress }: UnitSearchProps) => (
  <Formik
    enableReinitialize
    initialValues={{ search: '' }}
    validationSchema={validationSchema}
    onSubmit={onFind || onKeyPress}
  >
    {({ values }) => (
      <Form>
        <SearchInput
          inputName="search"
          searchBy="id"
          onFind={onFind}
          onClean={onClean}
          onKeyPress={onKeyPress}
          isValues={Boolean(values.search.length)}
        />
      </Form>
    )}
  </Formik>
);
