import { Box, WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectors } from '~store';

export const styles = (theme: Theme) =>
  createStyles({
    logo: {
      background: theme.palette.background.paper,
      margin: '16px auto !important',
      display: 'block',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      maskSize: 'contain',
      objectFit: 'contain',
      width: 110,
      height: 36,
    },
    collapsed: {
      width: 50,
      height: 36,
      objectFit: 'contain',
    },
  });

interface CustomLogoProps extends WithStyles<typeof styles> {
  logo: string;
}

export const CustomLogo = withStyles(styles)(({ classes, logo, ...props }: CustomLogoProps) => {
  const isExpanded = useSelector(selectors.layout.isExpanded);
  const isPageNoLicense = useSelector(selectors.page.isPageNoLicense);

  if (isPageNoLicense) {
    return null;
  }

  return (
    <Box {...props} component="i">
      <img
        src={logo}
        className={[
          clsx(classes.logo, {
            [classes.collapsed]: !isExpanded,
          }),
        ].join(' ')}
      />
    </Box>
  );
});
