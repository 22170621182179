import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    buttonWrapper: {
      flexShrink: 0,
    },
    button: {
      marginRight: theme.spacing(2),
    },
    files: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      margin: 0,
      marginRight: theme.spacing(1),
      padding: 0,
      '&:empty': {
        margin: 0,
      },
    },
    file: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: 14,
    },
    icon: {
      background: `${theme.palette.primary.main} !important`,
      cursor: 'pointer',
    },
    helperText: {
      margin: theme.spacing(0, 0, 1, 0),
      whiteSpace: 'pre-line',
    },
  });
