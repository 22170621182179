import { DialogActions as ModalActions, DialogActionsProps as ModalActionsProps, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from './DialogActions.styles';

type DialogActionsProps = ModalActionsProps & WithStyles<typeof styles>;

export const DialogActions = withStyles(styles)(({ classes, children, ...props }: DialogActionsProps) => (
  <ModalActions classes={{ root: clsx(classes.root) }} {...props}>
    {children}
  </ModalActions>
));
