export const focusedClassName = 'Mui-focused';
export const disabledClassName = 'Mui-disabled';
export const errorClassName = 'Mui-error';
export const expandedClassName = 'Mui-expanded';

export const classNames = {
  focused: focusedClassName,
  disabled: disabledClassName,
  error: errorClassName,
  expanded: expandedClassName,
};
