import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 0,
      flexGrow: 1,
      alignItems: 'center',
      '&.left': {
        paddingLeft: spacing(3),
      },
      '&.right': {
        paddingRight: spacing(3),
      },
      '&.both': {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
    },
  });
