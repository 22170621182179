import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import { MuiButton } from './Button/Button';
import { MuiFormHelperText } from './FormHelperText/FormHelperText';
import { iconButtonStyles } from './IconButton/IconButton';
import { lightInputStyles, darkInputStyles } from './Input/Input';
import { lightInputLabelStyles, darkInputLabelStyles } from './InputLabel/InputLabel';
import { listStyles } from './List/List';
import { menuItemStyles } from './MenuItem/MenuItem';
import { MuiCssBaseline } from './MuiCssBaseline';
import { lightPaperStyles, darkPaperStyles } from './Paper/Paper';
import { switchStyles } from './Switch/Switch';
import { MuiTooltip } from './Tooltip/Tooltip';
import { MuiTypography } from './Typography/Typography';

export const lightOverrides = (theme: Theme): Overrides => ({
  MuiCssBaseline,
  MuiTypography,
  MuiTooltip: MuiTooltip(theme),
  MuiButton: MuiButton(theme),
  MuiInputLabel: lightInputLabelStyles(theme),
  MuiInput: lightInputStyles(theme),
  MuiPaper: lightPaperStyles(theme),
  MuiFormHelperText,
  MuiIconButton: iconButtonStyles(theme),
  MuiMenuItem: menuItemStyles(theme),
  MuiList: listStyles(theme),
  MuiSwitch: switchStyles(theme),
});

export const darkOverrides = (theme: Theme): Overrides => ({
  MuiCssBaseline,
  MuiTypography,
  MuiTooltip: MuiTooltip(theme),
  MuiButton: MuiButton(theme),
  MuiInputLabel: darkInputLabelStyles(theme),
  MuiInput: darkInputStyles(theme),
  MuiPaper: darkPaperStyles(theme),
  MuiFormHelperText,
  MuiIconButton: iconButtonStyles(theme),
  MuiMenuItem: menuItemStyles(theme),
  MuiList: listStyles(theme),
  MuiSwitch: switchStyles(theme),
});
