import { Fragment } from 'react';

import { appDetailRoutes } from '~constants/routes';

import { NavLink } from '~ui-kit';

interface BusinessUnitLinksProps {
  links?: { id: number; name: string, units?: number[] }[];
}

export const BusinessUnitLinks = ({ links = [] }: BusinessUnitLinksProps) => {
  if (!links?.length) {
    return <Fragment>N/A</Fragment>;
  }

  const businessUnitLinks = links.map((data, i) => {
    const name = i === links.length - 1 ? data.name : `${data.name}, `;

    return (
      <NavLink key={data.id} to={appDetailRoutes.businessUnitDetails(data.id)}>
        {name}
      </NavLink>
    );
  });

  return <Fragment>{businessUnitLinks}</Fragment>;
};
