import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      '&.showing .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
      '&.showing .MuiInputBase-root.Mui-disabled': {
        color: theme.palette.text.primary,
      },
      '&.showing .MuiInput-underline:before': {
        display: 'none',
      },
    },
    input: {
      width: '100%',
    },
    paper: {
      padding: theme.spacing(1, 0),
    },
    clearIndicator: {
      padding: theme.spacing(0),
    },
    option: {
      fontSize: 14,
      lineHeight: 1.5,
      minHeight: 'auto',
    },
  });
