import { Box } from '@material-ui/core';
import Slider, { SliderProps } from '@material-ui/core/Slider';
import { WithStyles, withStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { styles } from './SliderField.styles';

import { debounce } from '~utils';

export type SliderFieldProps = WithStyles<typeof styles> &
  SliderProps & {
    label?: string | ReactNode;
    range: number[];
    onChange?: (v: number[]) => void;
    id: string;
    step?: number;
  };

export const SliderField = withStyles(styles)(
  ({
    label = null,
    range,
    value,
    disabled,
    step = 1,
    onChange = () => {},
    classes: { wrapper, ...classes },
    ...props
  }: SliderFieldProps) => {
    const isEmpty = useMemo(() => range[0] === 0 && range[1] === 0, [...range]);
    const hasOneStep = useMemo(() => Math.abs(range[1] - range[0]) <= step, [...range, step]);
    const marks = useMemo(() => {
      if (range[0] === range[1]) {
        return [];
      }

      return range.map(value => ({ value, label: value }));
    }, [...range]);
    const [inner, setValue] = useState((value as number[]) || range || []);

    const handleChange = useCallback((e, v) => {
      if (Array.isArray(v)) {
        return setValue(v);
      }

      setValue([v]);
    }, []);

    const emitChange = useCallback(
      debounce(v => {
        onChange(v);
      }, 100),
      []
    );

    useEffect(() => {
      emitChange(inner);
    }, [inner]);

    useEffect(() => {
      if ((value as number[])?.length && !equals(inner, value)) {
        setValue(value as number[]);
      }
    }, [...((value as number[]) || [])]);

    return (
      <Box marginBottom={1} marginTop={0.5} className={wrapper}>
        {label}
        <Slider
          aria-labelledby="range-slider"
          defaultValue={isEmpty ? range[0] : range}
          value={isEmpty ? inner[0] : inner}
          disabled={disabled || isEmpty || hasOneStep}
          marks={marks}
          min={range[0]}
          max={range[1]}
          valueLabelDisplay="on"
          onChange={handleChange}
          step={step}
          classes={classes}
          {...props}
        />
      </Box>
    );
  }
);
