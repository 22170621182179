import { createStyles, Theme } from '@material-ui/core';

import logo from '~assets/images/SelfMonitoringLogo.svg';
import { maskImageStyles } from '~utils/styles';

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      textAlign: 'center',
      fontWeight: 'bold',
    },

    logo: {
      margin: '16px auto !important',
      display: 'block',
      background: '#3D8BD1',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      maskSize: 'contain',
      width: 110,
      height: 46,
      ...maskImageStyles(logo),

      '&.collapsed': {
        width: '50px !important',
        height: '36px !important',
        ...maskImageStyles(logo),
      },
    },
  });
