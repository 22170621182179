import { isValid } from 'date-fns';

import { SettingsTab } from './settingsSlice';

import { api } from '~constants';

export const getSettingsRequestConfig = (
  tab: SettingsTab = 'network'
): { url: string; title: string; handler: 'put' | 'patch' } => {
  switch (tab) {
    case 'system':
      return { url: api.settings.system, title: 'System', handler: 'put' };

    case 'length-unit':
      return { url: api.settings.lengthUnit, title: 'Elevation', handler: 'patch' };

    case 'server':
      return { url: api.settings.server, title: 'Server', handler: 'patch' };

    case 'tech-options':
      return { url: api.settings.techOptions, title: 'Tech Option', handler: 'put' };

    case 'sub-tools':
      return { url: api.settings.subTools, title: 'Subtools', handler: 'put' };

    case 'coordinate':
      return { url: api.settings.coordinate, title: 'Coordinate', handler: 'patch' };

    case 'check-in':
      return { url: api.settings.checkIn, title: 'Check-in Grace', handler: 'put' };

    case 'call-sign':
      return { url: api.settings.callSign, title: 'FCC', handler: 'patch' };

    case 'instances':
      return { url: api.settings.instances, title: 'Instances', handler: 'patch' };

    default:
      return { url: api.settings.network, title: 'Network', handler: 'put' };
  }
};

export const validateDateAndTimeFields = (date, time) =>
  date !== time && isValid(new Date(date)) && isValid(new Date(time));
