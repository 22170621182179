import {
  assoc,
  map,
  flatten,
  assocPath,
  compose,
  head,
  sort,
  descend,
  prop,
  groupBy,
  length,
  split,
  last,
  propOr,
} from 'ramda';

import { intToHex } from '~utils/hex';

import { AlarmsState, AlarmTab } from './alarmsSlice';

import { Alarm, AlarmsGroup, AlarmType, ConnectivityAlarm } from '~models';

export const getAlarms = (data: Alarm[]) => {
  const unacknowledged = [] as Alarm[];
  const test = [] as Alarm[];

  data.map(alarm => ((alarm?.isTest) ? test.push(alarm) : unacknowledged.push(alarm)));

  return { unacknowledged, test };
};

export const sortAlarms = (alarms: Alarm[], sortBy: 'createdAt' | 'acknowledgedAt' = 'createdAt'): Alarm[] =>
  sort(descend(prop(sortBy)), alarms);

export const generateAlarmGroupId = (alarm: Alarm): string =>
  [alarm?.unitId, alarm?.businessUnitId, alarm?.code?.slice(1), alarm?.zone].join('/');

export const generateMetaAlarmId = (alarm: Alarm) => [alarm?.id, alarm?.businessUnitId].join('/');

export const generateAlarmsGroups = (data: { [key in AlarmType]: { [key: string]: Alarm[] } }): AlarmsGroup[] => {
  const values = ['FIRE', 'MEDICAL', 'PANIC', 'BURGLARY', 'SUPERVISORY', 'OTHER', 'UNKNOWN']
    .map(key =>
      Object.entries(data[key] || {})
        .filter(([id, children]) => Boolean((children as Alarm[]).length))
        .map(([id, children]) => ({ id, children } as AlarmsGroup))
        .sort((a, b) => b.children[0]?.createdAt - a.children[0]?.createdAt)
    )
    .flat();

  return values;
};

export const getGroupedAlarms = (groups: AlarmsGroup[], meta: AES.RootState['alarms']['meta'] = {}): Alarm[] =>
  compose(
    flatten,
    map<AlarmsGroup, Alarm[]>(({ children }) => {
      const alarm = head(children) as Alarm;
      const rest = children.slice(1);
      const firstAlarmMeta = meta[generateMetaAlarmId(alarm)] || {};
      const first = assoc(
        'meta',
        { ...firstAlarmMeta, first: true, count: children.length, isExpanded: Boolean(firstAlarmMeta.isExpanded) },
        alarm
      );

      if (firstAlarmMeta.isExpanded) {
        const values = rest.map((item, i) => {
          const itemMeta = { ...(meta[generateMetaAlarmId(item)] || {}) };
          itemMeta.isExpanded = true;

          if (i === rest.length - 1) {
            itemMeta.last = true;

            return assoc('meta', itemMeta, item);
          }

          return assoc('meta', itemMeta, item);
        }) as Alarm[];

        values.unshift(first as Alarm);

        return values;
      }

      return [assocPath(['meta', 'parent'], true, first)];
    })
  )(groups);

export const filterRestoredAlarms = (alarms: Alarm[], restored: Alarm[]): Alarm[] => {
  const restoredIds = restored.map(generateAlarmGroupId);

  return alarms.filter(alarm => !restoredIds.includes(generateAlarmGroupId(alarm)));
};

export const filterRestoredFaults = (
  alarms: Alarm[],
  buId: Alarm['businessUnitId'],
  cid: Alarm['cid'],
  code: Alarm['code'],
  id: Alarm['id'],
  zone: Alarm['zone']
) => {
  const restoredFaults = alarms.splice(
    alarms.findIndex(
      alarm =>
        alarm.businessUnitId === buId &&
        alarm.cid === cid &&
        intToHex(alarm.unitId) === id
    ),
    1
  );
  return alarms.filter(alarm => !restoredFaults.includes(alarm));
};

export const getAlarmsCount = (alarms: AlarmsState['data']['unacknowledged']) => {
  let counter = 0;

  Object.keys(alarms).forEach(alarmType => {
    Object.keys(alarms[alarmType]).forEach(group => {
      counter = counter + length(alarms[alarmType][group] || []);
    });
  });

  return counter;
};

export const getUpdatesCount = (count?: number, updates?: number) => {
  const value = (count || 0) - (updates || 0);

  return value > 0 ? value : 0;
};

export const getConnectivityAlarmsCount = (
  alarms: ConnectivityAlarm[]
): { [key in ConnectivityAlarm['status']]: number } => {
  const { UP = [], DOWN = [] } = groupBy(prop('status'), alarms);

  return { UP: length(UP), DOWN: length(DOWN) };
};

export const getAlarmCode = (alarm?: Alarm | null): string => {
  if (!alarm) {
    return 'N/A';
  }
  const cid = alarm?.cid ?? '';
  const code = alarm?.code ?? '';

  return alarm.aaMessage || cid || code;
};

export const mapAlarmsWithMeta = (key: AlarmTab) => (state: AlarmsState) =>
  (state.data[key] as (Alarm | ConnectivityAlarm)[]).map((alarm, i) => {
    if ((alarm as Alarm).id) {
      return assoc('meta', state.meta[generateMetaAlarmId(alarm as Alarm)] || {}, alarm);
    }

    return assoc('meta', state.meta[i] || {}, alarm);
  });

export const getAlarmZone = (alarm?: Alarm) =>
  compose<Alarm | undefined, string, string[], string>(last, split(' '), propOr('', 'cid'))(alarm);

export const updateAlarm = (alarm: Alarm): Alarm => {
  const zone = getAlarmZone(alarm);

  return assoc('zone', zone, alarm);
};
