export enum NetworkPulseLabels {
  'rfCheckins' = 'RF Check-ins',
  'ipCheckins' = 'IP Check-ins',
  'ackDelays' = 'Ack Delays',
  'selfTestFailures' = 'Self-test failure',
  'general' = 'General alarm',
  'fire' = 'Fire trouble',
  'lineCard3' = 'AP events (LC3)',
  'lineCard4' = 'AP events (LC4)',
  'lineCard5' = 'AP events (LC5)',
  'lineCard6' = 'AP events (LC6)',
}

export enum NetworkPulseDarkColors {
  'rfCheckins' = '#3BAEDA',
  'ipCheckins' = '#8CC051',
  'ackDelays' = '#EB5757',
  'selfTestFailures' = '#F49D0E',
  'general' = '#4DF0A2',
  'fire' = '#8A4BA9',
  'lineCard3' = '#F3FD55',
  'lineCard4' = '#0281C1',
  'lineCard5' = '#019587',
  'lineCard6' = '#5F7C8A',
}

export enum NetworkPulseLightColors {
  'rfCheckins' = '#449CEA',
  'ipCheckins' = '#7DD753',
  'ackDelays' = '#EF3D39',
  'selfTestFailures' = '#F6BE0F',
  'general' = '#4DF0A2',
  'fire' = '#8A4BA9',
  'lineCard3' = '#F3FD55',
  'lineCard4' = '#0281C1',
  'lineCard5' = '#019587',
  'lineCard6' = '#5F7C8A',
}
