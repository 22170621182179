import { withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';

import { SelectOption } from '../Select/Select';

import { styles } from './Chips.styles';

export type ChipProps = WithStyles<typeof styles> & {
  label?: SelectOption['label'];
  value?: SelectOption['value'];
  isSelected?: SelectOption['value'];
  onOptionSelect: (e, value, isSelected) => void;
  userForm?: boolean,
};

export const Chip = withStyles(styles)(
  ({ label = '', value = '', isSelected = '', userForm = false, classes, onOptionSelect }: ChipProps) => (
    <li
      className={clsx(userForm ? classes.assignItem : classes.item, { selected: isSelected })}
      onClick={e => onOptionSelect(e, value, Boolean(isSelected))}
    >
      {label}
    </li>
  ));
