import { createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { ReactNode, PropsWithChildren } from 'react';

import { Icon, NavLink } from '~ui-kit';

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...flexStyles,
      padding: theme.spacing(3, 0),
    },
    heading: {
      ...flexStyles,
      marginRight: 'auto',
      lineHeight: `${theme.spacing(5)}px`,
    },
    title: {
      lineHeight: `${theme.spacing(5)}px`,
      marginRight: theme.spacing(2),
    },
    subTitle: {
      lineHeight: `${theme.spacing(3)}px`,
    },
    back: {
      marginRight: 10,
      background: `${theme.palette.primary.main} !important`,
      '&:hover': {
        background: `${theme.palette.primary.light} !important`,
      },
    },
    content: {
      ...flexStyles,
    },
  });

export type PagePanelProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title?: ReactNode;
  subTitle?: ReactNode;
  backTo?: string;
};

export const PagePanel = withStyles(styles)(({ title, subTitle, backTo, classes, children }: PagePanelProps) => (
  <div className={classes.root}>
    <div className={classes.heading}>
      {backTo ? (
        <NavLink to={backTo}>
          <Icon icon="back" classes={{ root: classes.back }} />
        </NavLink>
      ) : null}

      {title ? (
        <Typography variant="h2" color="primary" classes={{ root: classes.title }}>
          {title}
        </Typography>
      ) : null}

      {subTitle && (
        <Typography variant="h4" color="textSecondary" classes={{ root: classes.subTitle }}>
          {subTitle}
        </Typography>
      )}
    </div>
    <div className={classes.content}>{children}</div>
  </div>
));
