import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(5),
    },
    flexContainer: {
      padding: theme.spacing(1, 0, 2),
    },
    indicator: {
      bottom: theme.spacing(2),
      display: 'none',
    },
    scrollButtons: {
      '&.Mui-disabled': {
        display: 'none',
      },
    },
  });
