import { Button, createStyles, Theme, useTheme, WithStyles, withStyles } from '@material-ui/core';
import { equals, keys } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { setLocalStorageItem } from '~features/page/pageUtils';

import { boundActions, selectors } from '~store';
import { Filter, Sort, IconText } from '~ui-kit';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > *:last-child': {
        marginRight: theme.spacing(1),
      },
    },
    actionButton: {
      minWidth: 'auto',
      padding: '6px 12px',
    },
  });

type PageControlsProps = WithStyles<typeof styles> & { place?: string };

export const PageControls = withStyles(styles)(({ classes, place = '' }: PageControlsProps) => {
  const theme = useTheme();
  const search = useSelector(selectors.page.getPageSearchValue);

  const sorting = useSelector(selectors.page.getPageSortingOptions, equals);
  const selectedSorting = useSelector(selectors.page.getSelectedPageSorting, equals);

  const columns = useSelector(selectors.page.getPageColumnsFiltersOptions, equals);
  const selectedColumns = useSelector(selectors.page.getSelectedPageColumnsFilters, equals);

  const sideFilters = useSelector(selectors.page.getSideFiltersValues, equals);
  const hasAppliedSideFilters = useSelector(selectors.page.hasAppliedFilters);
  const sideFiltersButtonProps = useMemo(() => {
    if (hasAppliedSideFilters) {
      return {
        color: theme.palette.primary.main,
        iconColor: theme.palette.primary.main,
      };
    }

    return {};
  }, [hasAppliedSideFilters]);

  const onFiltersToggle = useCallback(() => {
    boundActions.page.toggleSideFilters();
  }, []);

  useEffect(() => {
    boundActions.page.resetPageState();
  }, []);

  return (
    <div className={classes.root}>
      {search ? (
        <Button variant="text" className={classes.actionButton}>
          <IconText icon="search" label="Search" />
        </Button>
      ) : null}

      {sorting.length ? (
        <Sort
          options={sorting}
          selected={selectedSorting}
          onSelect={item => {
            setLocalStorageItem(place, JSON.stringify(item));
            boundActions.page.selectPageSorting(item);
          }}
        />
      ) : null}

      {keys(sideFilters).length ? (
        <Button variant="text" onClick={onFiltersToggle}>
          <IconText icon="filter" label="Filters" {...sideFiltersButtonProps} />
        </Button>
      ) : null}

      {columns.length ? (
        <Filter
          options={columns}
          selected={selectedColumns}
          icon="columns"
          title="Columns"
          onSelect={values => boundActions.page.selectPageColumnsFilters(values)}
        />
      ) : null}
    </div>
  );
});
