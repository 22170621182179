import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      height: spacing(18),
      overflowY: 'auto',
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      marginBottom: spacing(1.5),
    }
  });
