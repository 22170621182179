import { AnyAction, createDraftSafeSelector, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';

import { api, appDetailRoutes } from '~constants';
import { NonAESUnit, PaginationRequestPayload, PaginationResponse, ResponseError } from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload } from '~utils';

export const adapter = createEntityAdapter<NonAESUnit>({
  selectId: unit => unit.id,
});

export interface NonAESUnitsState {
  data: ReturnType<typeof adapter.getInitialState>;
  loading: {
    list: boolean;
    create: boolean;
    details: boolean;
    edit: boolean;
    delete: boolean;
  };
  error?: ResponseError | null;
}

export const initialState: NonAESUnitsState = {
  data: adapter.getInitialState(),
  loading: {
    list: false,
    create: false,
    details: false,
    edit: false,
    delete: false,
  },
};

export const { name, actions, reducer } = createSlice({
  name: 'nonAESUnits',
  initialState,
  reducers: {
    // Fetch units list by business unit
    fetchNonAESUnitsListInit: (
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: NonAESUnit['businessUnitId'] }>
    ) => {
      state.error = null;
      state.loading.list = true;
    },
    fetchNonAESUnitsListSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<NonAESUnit>>
    ) => {
      state.loading.list = false;

      state.data = adapter.setAll(state.data, content);
    },
    fetchNonAESUnitsListFailed: (state, action: PayloadAction<string>) => {
      state.loading.list = false;
    },

    // fetch Non-AES unit  by id
    fetchNonAESUnitByIdInit: (state, { payload }: PayloadAction<{ businessUnitId: number; id: NonAESUnit['id'] }>) => {
      state.error = null;
      state.loading.details = true;
    },
    fetchNonAESUnitByIdSuccess: (state, { payload }: PayloadAction<NonAESUnit>) => {
      adapter.upsertOne(state.data, payload);
      state.loading.details = false;
    },
    fetchNonAESUnitByIdFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details = false;
      state.error = action.payload;
    },

    // create Non-AES unit
    createNonAESUnitInit: (
      state,
      { payload }: PayloadAction<{ businessUnitId: number; nonAESUnit: Partial<NonAESUnit> }>
    ) => {
      state.error = null;
      state.loading.create = true;
    },
    createNonAESUnitSuccess: state => {
      state.loading.create = false;
    },
    createNonAESUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.create = false;
      state.error = payload;
    },

    // update Non-AES unit
    updateNonAESUnitInit: (
      state,
      { payload }: PayloadAction<Partial<{ businessUnitId: number; nonAESUnit: NonAESUnit }>>
    ) => {
      state.error = null;
      state.loading.edit = true;
    },
    updateNonAESUnitSuccess: (state, { payload }: PayloadAction<NonAESUnit>) => {
      adapter.updateOne(state.data, {
        id: payload.id,
        changes: payload,
      });
      state.loading.edit = false;
    },
    updateNonAESUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.edit = false;
      state.error = payload;
    },

    // delete Non-AES unit
    deleteNonAESUnitInit: (
      state,
      { payload }: PayloadAction<{ businessUnitId: NonAESUnit['businessUnitId']; id: NonAESUnit['id'] }>
    ) => {
      state.error = null;
      state.loading.delete = true;
    },
    deleteNonAESUnitSuccess: (state, { payload }: PayloadAction<NonAESUnit['id']>) => {
      adapter.removeOne(state.data, payload);
      state.loading.delete = false;
    },
    deleteNonAESUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.delete = false;
      state.error = payload;
    },
  },
});

const getNonAESUnitsState = (state: AES.RootState) => state.nonAESUnits;

const nonAESUnitsSelectors = adapter.getSelectors();

export const selectors = {
  getNonAESUnitsState,

  getNonAESUnitsList: createDraftSafeSelector(getNonAESUnitsState, state => nonAESUnitsSelectors.selectAll(state.data)),
  getNonAESUnitById: (id: NonAESUnit['id']) =>
    createDraftSafeSelector(getNonAESUnitsState, state => nonAESUnitsSelectors.selectById(state.data, id)),
  getNonAESUnitsLoading: createDraftSafeSelector(getNonAESUnitsState, state => state.loading),
};

const fetchNonAESUnitsByBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchNonAESUnitsListInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<NonAESUnit>>(api.nonAESUnit.list(payload.businessUnitId, searchParams))
        .pipe(
          mergeMap(res =>
            of(actions.fetchNonAESUnitsListSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchNonAESUnitsListFailed(error.message));
          })
        );
    })
  );

const fetchNonAESUnitByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchNonAESUnitByIdInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<NonAESUnit>(api.nonAESUnit.byId(businessUnitId, id)).pipe(
        mergeMap(values => of(actions.fetchNonAESUnitByIdSuccess(values), pageActions.setPageFound())),
        catchError(err => of(actions.fetchNonAESUnitByIdFailed(getResponseError(err)), pageActions.setPageNotFound()))
      )
    )
  );

const createNonAESUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createNonAESUnitInit.match),
    mergeMap(({ payload: { businessUnitId, nonAESUnit } }) =>
      http.post(api.nonAESUnit.all(businessUnitId), nonAESUnit).pipe(
        mergeMap(() => of(actions.createNonAESUnitSuccess(), push(appDetailRoutes.businessUnitNonAES(businessUnitId)))),
        catchError(err => of(actions.createNonAESUnitFailed(getResponseError(err))))
      )
    )
  );

const updateNonAESUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateNonAESUnitInit.match),
    mergeMap(({ payload: { businessUnitId, nonAESUnit } }) =>
      http.put(api.nonAESUnit.byId(businessUnitId as number, nonAESUnit?.id as number), nonAESUnit).pipe(
        mergeMap(values =>
          of(
            actions.updateNonAESUnitSuccess(getResponsePayload(values)),
            push(appDetailRoutes.businessUnitNonAESDetails(businessUnitId as number, nonAESUnit?.id as number)),
            notificationsActions.enqueue({
              message: 'Non-AES Unit updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateNonAESUnitFailed(getResponseError(err))))
      )
    )
  );

const deleteNonAESUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteNonAESUnitInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.delete(api.nonAESUnit.byId(businessUnitId as number, id)).pipe(
        mergeMap(() =>
          of(actions.deleteNonAESUnitSuccess(id), push(appDetailRoutes.businessUnitNonAES(businessUnitId as number)))
        ),
        catchError(err => of(actions.deleteNonAESUnitFailed(getResponseError(err))))
      )
    )
  );

export const epics = combineEpics(
  fetchNonAESUnitsByBusinessUnitEpic,
  createNonAESUnitEpic,
  fetchNonAESUnitByIdEpic,
  updateNonAESUnitEpic,
  deleteNonAESUnitEpic
);
export const allEpics = {
  fetchNonAESUnitsByBusinessUnitEpic,
  createNonAESUnitEpic,
  fetchNonAESUnitByIdEpic,
  updateNonAESUnitEpic,
  deleteNonAESUnitEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      nonAESUnits: typeof actions;
    }
    export interface RootState {
      nonAESUnits: NonAESUnitsState;
    }

    export interface Selectors {
      nonAESUnits: typeof selectors;
    }
  }
}
