import { SubscriberSettings } from '~models';

export type ModesSettings = {
  intelliTap: boolean | null;
  lineCut: boolean | null;
  repeater: boolean | null;
};

export const getSubscriberModesValues = (values?: SubscriberSettings['modes']): ModesSettings => ({
  intelliTap: values ? Boolean(values?.intelliTap) : null,
  lineCut: values ? Boolean(values?.lineCut) : null,
  repeater: values ? Boolean(values?.repeater) : null,
});
