import { isNil } from 'ramda';

import { DATE_AM_PM_FORMAT, DATE_FORMAT } from '~constants/date';

import { Permission, Profile, Role, User, UserRole } from '~models';
import { CardStatus } from '~ui-kit';

export const getUserRoleLabel = (role?: UserRole | string): string => {
  if (!role) {
    return '';
  }

  switch (role) {
    case 'ROLE_TECH_SUPPORT':
      return 'Tech support';

    case 'ROLE_ADMIN':
      return 'Admin';

    case 'ROLE_TIER_ONE':
      return 'Tier 1';

    case 'ROLE_TIER_TWO':
      return 'Tier 2';

    case 'ROLE_TIER_THREE':
      return 'Tier 3';

    case 'KIOSK':
      return 'Kiosk mode';

    case 'ROLE_OPERATOR':
      return 'Operator';

    default:
      return role;
  }
};
export const getUserName = (user?: Profile): string => {
  if (!user) {
    return '';
  }

  const name = [user.firstName, user.lastName].join(' ');

  return name.trim() ? name : user.username;
};

export const getUserMeta = (
  user: User
): { status: { value: CardStatus; title: string }; role: string; fullName: string } => {
  const role = getUserRoleLabel(user.role);
  const fullName = getUserName(user);

  if (user.isOnline) {
    return { status: { value: 'success', title: 'Online' }, role, fullName };
  }

  return { status: { value: 'error', title: 'Offline' }, role, fullName };
};

export const getMaximumUserRolesBusinessUnitsCount = (roles: Role[], userRole: UserRole): number | null => {
  const role = roles.find(role => role.name === userRole);

  if (!role) {
    return null;
  }

  return role.buCount;
};

export const canUserAddMoreBusinessUnits = (
  roles: Role[], userRole: UserRole, businessUnitsCount: number, isDealer: boolean
) => {
  const roleCount = getMaximumUserRolesBusinessUnitsCount(roles, userRole);

  if (isDealer) {
    return;
  }

  if (isNil(roleCount)) {
    return true;
  }

  return roleCount > businessUnitsCount;
};

export const userHasPermissions = (user?: User | null, permissions?: Permission['name'][]) => {
  if (!user) {
    return false;
  }

  if (!permissions?.length) {
    return true;
  }

  return permissions.every(perm => user.permissions.includes(perm));
};

export const getUserTimeFormat = (user?: Profile): string => {
  if (!user) {
    return DATE_FORMAT;
  }

  return user.fullHoursTimeFormat ? DATE_FORMAT : DATE_AM_PM_FORMAT;
};

export const isBusinessUnitsRequired = (role: UserRole | string) =>
  !(['ROLE_ADMIN', 'ROLE_TECH_SUPPORT', 'ROLE_OPERATOR'] as UserRole[]).includes(role as UserRole);
