import { createStyles, Theme } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getColor = matchStyles(theme.palette.type)(
    theme.palette.greyColor.light,
    theme.palette.greyColor.dark
  );

  return createStyles({
    root: {
      display: 'flex',
      '& .left': {
        paddingRight: theme.spacing(2),
      },
      color: getColor,
    },
    status: {
      cursor: 'auto',
    },
    link: {
      color: getColor,
      textDecoration: 'none',
      '&:hover': {
        color: `${getColor} !important`,
      },
    }
  });
};

