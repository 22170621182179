import { createStyles, WithStyles, withStyles } from '@material-ui/styles';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
});

type FullHeightPageProps = WithStyles<typeof styles>;

export const FullHeightPage = Component => withStyles(styles)(
  ({ classes, ...props }: FullHeightPageProps) => <Component {...props} className={classes.root} />
);
