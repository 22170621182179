import { CircularProgress } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { memo } from 'react';

import { ScrollableDataListItem, ScrollableDataListProps } from './ScrollableDataListItem';

const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type ScrollableDataListLoaderProps = WithStyles<typeof styles> & ScrollableDataListProps;

export const ScrollableDataListLoader = memo(
  withStyles(styles)(({ classes, ...props }: ScrollableDataListLoaderProps) => (
    <ScrollableDataListItem {...props} classes={{ root: clsx(classes.root) }}>
      <CircularProgress size={30} />
    </ScrollableDataListItem>
  ))
);
