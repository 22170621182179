import { WithStyles, withStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { styles } from './SideFiltersContent.styles';

type SideFiltersContentProps = PropsWithChildren<WithStyles<typeof styles>>;

export const SideFiltersContent = withStyles(styles)(({ classes, children }: SideFiltersContentProps) => (
  <div className={classes.root}>{children}</div>
));
