import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { mergeDeepRight } from 'ramda';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { actions as sharedActions } from '../sharedSlice';

import { api } from '~constants';
import { RequestStatus } from '~models';
import { http } from '~services';
import { boundActions } from '~store';
import { getResponseError, hexToInt, saveFile } from '~utils';

export interface ReportExportState {
  report: Required<AES.DataRepository<string>>;
  reportPreFetch: Required<AES.DataRepository<number>>;
}

const initialReportState: ReportExportState['report'] = {
  data: '',
  errorMessage: '',
  status: RequestStatus.Idle,
  progress: {
    loaded: 0,
    total: 0,
    completed: true,
  },
};

const initialReportPreFetchState: ReportExportState['reportPreFetch'] = {
  data: 0,
  errorMessage: '',
  status: RequestStatus.Idle,
  progress: {
    loaded: 0,
    total: 0,
    completed: true,
  },
};

export const initialState: ReportExportState = {
  report: initialReportState,
  reportPreFetch: initialReportPreFetchState,
};

export const { actions, reducer, name } = createSlice({
  name: 'reportExport',
  initialState,
  reducers: {
    reportFetch: (state, action: PayloadAction<AES.ReportExport.SearchDTO>) => {
      state.report.status = RequestStatus.Pending;
    },
    reportFetchProgress: (state: ReportExportState, { payload: { loaded, total } }) => {
      state.report.progress.loaded = loaded;
      state.report.progress.total = total;
      state.report.progress.completed = false;
    },
    reportFetchSuccess: state => {
      state.report.errorMessage = '';
      state.report.status = RequestStatus.Success;
      state.report.progress.completed = true;
    },
    reportFetchFailure: (state, action) => {
      state.report.errorMessage = action.payload;
      state.report.status = RequestStatus.Failure;
      state.report.progress.completed = true;
    },
    reportFetchIdle: state => {
      state.report.errorMessage = '';
      state.report.status = RequestStatus.Idle;
    },
    reportFetchReset: state => {
      state.report = mergeDeepRight({}, initialReportState);
    },
    reportPreFetch: (state, action: PayloadAction<AES.ReportExport.SearchForm>) => {
      state.reportPreFetch.status = RequestStatus.Pending;
      state.reportPreFetch.data = 0;
    },
    reportPreFetchSuccess: (state, { payload }) => {
      state.reportPreFetch.data = payload;
      state.reportPreFetch.errorMessage = '';
      state.reportPreFetch.status = RequestStatus.Success;
    },
    reportPreFetchFailure: (state, action) => {
      state.reportPreFetch.data = 0;
      state.reportPreFetch.errorMessage = action.payload;
      state.reportPreFetch.status = RequestStatus.Failure;
    },
    reportPreFetchIdle: state => {
      state.reportPreFetch.errorMessage = '';
      state.reportPreFetch.status = RequestStatus.Idle;
    },
    reportPreFetchReset: state => {
      state.reportPreFetch = mergeDeepRight({}, initialReportPreFetchState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getReportExportState = (state: AES.RootState) => state.reportExport;

export const selectors = {
  getReportExportState,

  reportFetchProgress: createDraftSafeSelector(getReportExportState, state => state.report.progress),
  reportPreFetchCount: createDraftSafeSelector(getReportExportState, state => state.reportPreFetch.data),
  reportPreFetchIsPending: createDraftSafeSelector(
    getReportExportState,
    state => state.reportPreFetch.status === 'pending'
  ),
};

const reportFetchEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.reportFetch.match(action)),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'POST',
          url: api.report.fetch,
          responseType: 'blob' as 'json',
          body: { ...payload, unitId: payload.unitId ? hexToInt(payload.unitId) : null },
          createXHR: () => {
            const xhr = new XMLHttpRequest();
            xhr.onprogress = ({ loaded, total }) => {
              boundActions.reportExport.reportFetchProgress({
                total,
                loaded,
              });
            };
            return xhr;
          },
        })
        .pipe(
          mergeMap(res => {
            const fileName = `alarm-history_${format(
              new Date(),
              'yyyyMMdd-HH:mm:ss'
            )}.${payload.reportType.toLowerCase()}`;

            saveFile(res.response, fileName);

            return of(actions.reportFetchSuccess());
          }),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.reportFetchFailure(error));
          })
        )
    )
  );

const reportPreFetchEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.reportPreFetch.match(action)),
    map(action => {
      const {
        payload: { businessUnitId, reportType, fromDate, toDate, subscriberId, unrestoredAlarmsOnly },
      } = action as PayloadAction<AES.ReportExport.SearchForm>;

      return {
        fromDate,
        toDate,
        unitId: subscriberId ? hexToInt(subscriberId) : null,
        isUnrestored: unrestoredAlarmsOnly,
        reportType,
        businessUnitId,
      };
    }),
    mergeMap(payload => http.post(api.report.preFetch, payload)),
    map(res => actions.reportPreFetchSuccess(res.response)),
    catchError((err, caught) => merge(of(actions.reportFetchFailure(getResponseError(err).message)), caught)),
    mergeMap(action => of(action, actions.reportPreFetchIdle()))
  );

export const epics = combineEpics(reportFetchEpic, reportPreFetchEpic);

export const allEpics = { reportFetchEpic, reportPreFetchEpic };
declare global {
  namespace AES {
    namespace ReportExport {
      export type Alarm = [string, string, string, string, string];
      export type reportType = 'CSV' | 'PDF' | 'XLS';
      export interface SearchDTO {
        fromDate?: string;
        toDate?: string | null;
        id?: number;
        isUnrestored?: boolean;
        reportType: reportType;
        businessUnitId: number;
        unitId?: string;
      }
      export interface SearchForm {
        fromDate?: string;
        toDate?: string | null;
        subscriberId?: string;
        unrestoredAlarmsOnly?: boolean;
        reportType: reportType;
        businessUnitId: number;
      }
    }
    export interface Actions {
      reportExport: typeof actions;
    }
    export interface RootState {
      reportExport: ReportExportState;
    }
    export interface Selectors {
      reportExport: typeof selectors;
    }
  }
}
