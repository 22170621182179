import {
  DialogTitle as ModalTitle,
  DialogTitleProps as ModalTitleProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useCallback, Fragment } from 'react';

import { Icon } from '../Icon/Icon';

import { styles } from './DialogTitle.styles';

type DialogTitleProps = ModalTitleProps &
  WithStyles<typeof styles> & {
    title?: string;
    onClose?: () => void;
  };

export const DialogTitle = withStyles(styles)(({ classes, title, children, onClose, ...props }: DialogTitleProps) => {
  const onClick = useCallback(
    e => {
      e.stopPropagation();

      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <ModalTitle {...props} classes={{ root: clsx(classes.root) }} disableTypography>
      <Fragment>
        {children}

        <Typography classes={{ root: clsx(classes.title) }} variant="h3">
          {title}
        </Typography>
      </Fragment>

      {onClose && (
        <IconButton onClick={onClick} classes={{ root: clsx(classes.iconButton) }}>
          <Icon icon="close" size={24} />
        </IconButton>
      )}
    </ModalTitle>
  );
});
