import { createStyles, Theme } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: `1px solid ${matchStyles(theme.palette.type)(
        theme.palette.greyColor.dark,
        theme.palette.greyColor.main
      )}`,
      padding: theme.spacing(3),
      '& button:last-child': {
        marginLeft: theme.spacing(2),
      },
    },
  });
