import { createEntityAdapter } from '@reduxjs/toolkit';

import { GeoHybrid, GeoIPLink, GeoNonAESUnit, GeoSubscriber, UnitRoute } from '~models';

const subscribersEntityAdapter = createEntityAdapter<GeoSubscriber>({
  selectId: sub => sub.id,
});
const ipLinksEntityAdapter = createEntityAdapter<GeoIPLink>({
  selectId: ipLink => ipLink.id,
});
const hybridsEntityAdapter = createEntityAdapter<GeoHybrid>({
  selectId: hybrid => hybrid.id,
});
const nonAESUnitsEntityAdapter = createEntityAdapter<GeoNonAESUnit>({
  selectId: unit => unit.id,
});
const routesEntityAdapter = createEntityAdapter<UnitRoute & { key: number }>({
  selectId: route => route.key,
});

export const entities = {
  units: {
    SUBSCRIBER: subscribersEntityAdapter,
    HYBRID: hybridsEntityAdapter,
    IP_LINK: ipLinksEntityAdapter,
    NON_AES: nonAESUnitsEntityAdapter,
  },
  routes: routesEntityAdapter,
};
