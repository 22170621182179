import { Theme, createStyles } from '@material-ui/core';

import { statusColors } from '~styles/colors';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: getStyles(theme.palette.greyColor.light, theme.palette.greyColor.main),
      ...statusColors(theme),
    },
    icon: {
      marginRight: theme.spacing(1),
      ...statusColors(theme, 'background'),
    },
    title: {
      fontWeight: 600,
    },
  });
};
