import { Avatar, Container, AppBar, withStyles, WithStyles, MenuItem, Button, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import avatarPlaceholder from '~assets/images/placeholders/avatar.svg';
import { getLocalStorageItem } from '~features/page/pageUtils';
import { getUserName, getUserRoleLabel, getUserTimeFormat } from '~features/users/usersUtils';

import { InformPromptDialog, promptContents } from '..';

import { styles } from './AppHeader.styles';
import { UnitsSearchForm } from './UnitsSearchForm';

import { appRoutes } from '~constants';
import { useSubscription } from '~hooks';
import { AppInfo } from '~models';
import { boundActions, selectors } from '~store';
import { ContentField, Icon, NavLink, Divider, Menu } from '~ui-kit';
import { serverDateFormat } from '~utils';

interface AppHeaderProps extends WithStyles<typeof styles> {
  defaultUserMenuOpen?: boolean; // for test purposes
}

export const AppHeader = withStyles(styles)(
  forwardRef(({ classes, defaultUserMenuOpen }: AppHeaderProps, ref) => {
    const history = useHistory();
    const userMenuAnchorRef = useRef<HTMLDivElement | null>(null);
    const account = useSelector(selectors.profile.getAccount, equals);
    const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
    const [openUserMenu, setOpenUserMenu] = useState(Boolean(defaultUserMenuOpen));
    const info = useSelector(selectors.app.getAppInfo, equals);
    const instanceInfo = useSelector(selectors.app.getInstanceInfo, equals);
    const isSoundEnabled = useSelector(selectors.app.isSoundEnabled);
    const [openPrompt, setOpenPrompt] = useState<boolean>(false);
    const soundStatus = getLocalStorageItem('soundStatus');

    useSubscription<{ time: AppInfo['time'] }>(
      '/system/info',
      msg => {
        boundActions.app.setAppTime(msg.data?.time);
      },
      'topic'
    );

    const onMenuToggle = useCallback(() => setOpenUserMenu(!openUserMenu), [openUserMenu]);

    const onProfileNavigate = useCallback(() => {
      history.push(appRoutes.profile);
      setOpenUserMenu(false);
    }, []);

    const onLogOut = useCallback(() => {
      boundActions.auth.logoutInit();
      setOpenUserMenu(false);
    }, []);

    useEffect(() => {
      if (soundStatus) {
        boundActions.app.toggleSoundState(soundStatus === 'ON' ? true : false);
      }
    }, []);

    return (
      <AppBar position="static" className={classes.root} ref={ref}>
        <InformPromptDialog open={openPrompt} onConfirm={() => setOpenPrompt(false)}>
          {promptContents.soundSettings}
        </InformPromptDialog>
        <Container maxWidth="xl" className={classes.container}>
          <div className={classes.inner}>
            <UnitsSearchForm />
            <Button
              className={clsx(classes.sound, { enabled: isSoundEnabled })}
              variant="contained"
              onClick={() => setOpenPrompt(true)}
              size="small"
            >
              {isSoundEnabled ? 'Sound On' : 'Sound Off'}
            </Button>

            <Divider spacing="both" flexItem orientation="vertical" />

            {info ? (
              <div className={classes.stats}>
                {info.time ? (
                  <ContentField title="Server Time" width={240}>
                    <div className={classes.serverTime}>
                      {serverDateFormat(info.time, false, getUserTimeFormat(account), account?.userTimezone)}
                    </div>
                  </ContentField>
                ) : null}

                {instanceInfo.ipAddress ? (
                  <ContentField title="Server IP">{instanceInfo.ipAddress}</ContentField>
                ) : null}

                {info.version ? <ContentField title="Version">{info?.version}</ContentField> : null}

                {instanceInfo.instanceType ? (
                  <ContentField title="INCC Instance">{instanceInfo.instanceType}</ContentField>
                ) : null}

                <Divider flexItem orientation="vertical" spacing="both" />
              </div>
            ) : null}

            <div className={classes.user} ref={userMenuAnchorRef}>
              <Avatar className={classes.avatar} src={account?.profilePhoto ?? avatarPlaceholder} />
              <ContentField classes={{ root: classes.userName }} title={getUserRoleLabel(currentUser?.role)}>
                <NavLink to="/profile">{getUserName(account)}</NavLink>
              </ContentField>
              <IconButton onClick={onMenuToggle}>
                <Icon icon="drop" />
              </IconButton>

              <Menu
                anchorEl={() => userMenuAnchorRef?.current as Element}
                open={openUserMenu}
                onClose={onMenuToggle}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                getContentAnchorEl={null}
              >
                <MenuItem onClick={onProfileNavigate}>
                  <NavLink to="/profile">View Profile</NavLink>
                </MenuItem>
                <MenuItem onClick={onLogOut}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Container>
      </AppBar>
    );
  })
);
