import { Typography, withStyles, Box, Button } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { Fragment, useCallback, useEffect, useRef, useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import beepSound from '~assets/media/beep.mp3';
import { getAlarmCode } from '~features/alarms/utils';
import { getUserTimeFormat } from '~features/users/usersUtils';

import { styles } from './RecentAlarm.styles';

import { getAlarmMeta, getAlarmUnitLabel, getAlarmUnitLink, ProtectedContent } from '~components';
import { Alarm, PermissionName } from '~models';
import { boundActions, selectors } from '~store';
import { Placeholder, NavLink } from '~ui-kit';
import { formatDate, getElapsedTime, intToHex } from '~utils';

type AppPanelLCDProps = WithStyles<typeof styles>;

export const RecentAlarm = memo(
  withStyles(styles)(({ classes }: AppPanelLCDProps) => {
    const beepRef = useRef<HTMLAudioElement>(null);
    const [timeAgo, setTimeAgo] = useState<string>('');
    const timerRef = useRef<ReturnType<typeof setInterval>>();
    const alarm = useSelector(selectors.alarms.getFirstReceivedAlarm, equals);
    const meta = useMemo(() => getAlarmMeta(alarm?.alarmType as Alarm['alarmType']), [alarm]);
    const isSoundEnabled = useSelector(selectors.app.isSoundEnabled);
    const account = useSelector(selectors.profile.getAccount, equals);
    const timeFormat = getUserTimeFormat(account);

    const onAcknowledge = useCallback(() => {
      boundActions.alarms.ackAlarmInit(alarm as Alarm);
    }, [alarm]);

    const onSilence = useCallback(() => {
      boundActions.alarms.muteAlarmInit(alarm as Alarm);
    }, [alarm]);

    useEffect(() => {
      if (isSoundEnabled && alarm && !alarm.silent) {
        beepRef.current!.currentTime = 0;
        beepRef.current?.play();
      } else {
        beepRef.current?.pause();
      }
    }, [alarm, isSoundEnabled]);

    useEffect(() => {
      if (timerRef.current) {
        clearInterval(timerRef.current);

        setTimeAgo('');
      }

      if (!alarm?.createdAt) {
        return;
      }

      const timer = setInterval(() => {
        const { moreThanDayAgo, value } = getElapsedTime(new Date(alarm?.createdAt as number), new Date());
        const elapsedTime = moreThanDayAgo ? `Elapsed ${value} ago` : value;

        setTimeAgo(elapsedTime);
      }, 1000);

      timerRef.current = timer;

      return () => clearInterval(timer);
    }, [alarm?.id, alarm?.businessUnitId]);

    return (
      <div className={classes.root}>
        <audio src={beepSound} loop ref={beepRef} />

        {alarm ? (
          <Fragment>
            <Box className="info" display="flex" flexDirection="column" justifyContent="space-between">
              <Typography variant="body1">
                {meta.title} | {getAlarmCode(alarm)} | {getAlarmUnitLabel(alarm)}
                <NavLink to={getAlarmUnitLink(alarm)}> {intToHex(alarm.unitId)}</NavLink>
              </Typography>

              <Typography variant="body1">
                {formatDate(alarm.createdAt, false, timeFormat, account?.userTimezone)} {timeAgo ? ` | ${timeAgo}` : null}
              </Typography>
            </Box>

            <Box className="buttons" display="flex" flexDirection="column" justifyContent="center">
              <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE]}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={onAcknowledge}
                  disabled={alarm.meta?.isLoading}
                >
                  Acknowledge
                </Button>
              </ProtectedContent>

              <ProtectedContent permissions={[PermissionName.DASHBOARD_SILENCE]}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={onSilence}
                  disabled={alarm.silent || alarm.meta?.isLoading}
                >
                  Silence
                </Button>
              </ProtectedContent>
            </Box>
          </Fragment>
        ) : (
          <Placeholder classes={{ placeholder: clsx(classes.placeholder) }}>
            <div className="buttons">
              <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE]}>
                <Button variant="contained" size="small" color="primary" disabled>
                  Acknowledge
                </Button>
              </ProtectedContent>

              <ProtectedContent permissions={[PermissionName.DASHBOARD_SILENCE]}>
                <Button variant="outlined" size="small" color="primary" disabled>
                  Silence
                </Button>
              </ProtectedContent>
            </div>
          </Placeholder>
        )}
      </div>
    );
  }),
  equals
);
