import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field } from 'formik';

import { SwitchField, SwitchFieldProps } from '../Fields/SwitchField';

import { styles } from './FormSwitchField.styles';

export type FormSwitchFieldProps = WithStyles<typeof styles> &
  SwitchFieldProps & {
    name: string;
  };

export const FormSwitchField = withStyles(styles)(({ name, classes, ...props }: FormSwitchFieldProps) => (
  <Field name={name}>
    {({ field, meta: { error, touched } }) => (
      <SwitchField
        {...props}
        {...field}
        value={Boolean(field.value)}
        error={Boolean(error)}
        helperText={(touched && error) || props.helperText}
        classes={{ root: clsx(classes.root) }}
      />
    )}
  </Field>
));
