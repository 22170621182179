/* eslint-disable no-confusing-arrow */
import { Button, Grid, styled } from '@material-ui/core';
import { useCallback } from 'react';

import { Subscriber } from '~models';
import { FormContent, FormPanelWrapper, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';

const FormRFButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const rfButtons = [
  { text: 'Turn on', path: 'tx-on', rfStat: 'ON' },
  { text: 'Turn off', path: 'tx-off', rfStat: 'OFF' },
];

type SubscriberRFSettingsProps = {
  subscriber: Subscriber;
  rfStatus?: 'ON' | 'OFF' | 'UNDEFINED';
  setRfStatus: (rfStatus) => void;
  loader: boolean;
  timeFormat: string;
  onSubmit(value: { id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; path: string }): void;
};

export const SubscriberRFSettings = ({
  subscriber,
  rfStatus = subscriber.settings?.['rf-settings']?.subscriberRFStatus,
  setRfStatus,
  loader,
  timeFormat,
  onSubmit }: SubscriberRFSettingsProps) => {
  const { id, businessUnitId } = subscriber;

  const onRFButtonClick = useCallback((rf, path) => {
    setRfStatus(rf);

    onSubmit({
      id,
      businessUnitId,
      path,
    });
  }, []);

  const rfSettingsTitle = () => {
    if (rfStatus === 'ON') {
      return rfStatus;
    }
    if (rfStatus === 'OFF') {
      return rfStatus;
    }

    return 'No Status';
  };

  return (
    <FormPanelWrapper
      title={`RF TX Settings: ${rfSettingsTitle()}`}
      subTitle={<LastDataUpdatedFormPanelSubtitle
        title="Last Updated: "
        timestamp={subscriber.settings?.['rf-settings']?.lastUpdated}
        timeFormat={timeFormat}
      />}
    >
      <FormContent>
        <Grid container spacing={2}>
          {rfButtons.map((button, i) => (
            <Grid item xs={6} md={4} key={i}>
              <FormRFButton
                onClick={() => onRFButtonClick(button.rfStat, button.path)}
                variant="contained"
                color="primary"
                size="small"
                disabled={loader || rfStatus === button.rfStat}
                key={i}
              >
                {button.text}
              </FormRFButton>
            </Grid>
          ))}
        </Grid>
      </FormContent>
    </FormPanelWrapper>
  );
};
