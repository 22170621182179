// TODO: show submenu for extanded state for active paths by default

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef, HTMLAttributes, Ref, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { IconType } from '~icons';
import { selectors } from '~store';
import { Icon } from '~ui-kit';

export const useNavItemStyles = makeStyles(({ palette, spacing, sizes }: Theme) =>
  createStyles({
    navItem: {
      display: 'block',
      width: sizes.navBarExpandedWidth,
      padding: 0,
      minHeight: 44,
      whiteSpace: 'nowrap',
      '&:hover': {
        background: 'none',
      },
      '&$child': {
        width: 'auto',
      },
    },

    child: {},

    wrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: `${spacing(1)}px ${spacing(0)}px !important`,
      color: palette.sideNav.label.main,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      '&:hover, &.active': {
        color: palette.sideNav.label.hover,
        outline: 'none',
        '& $icon': {
          backgroundColor: palette.sideNav.icon.hover,
        },
      },
    },
    icon: {
      backgroundColor: palette.sideNav.icon.main,
      padding: '12px 48px',
    },
    label: {
      whiteSpace: 'normal',
      opacity: 1,
    },
    link: {
      padding: spacing(1),
    },

    submenu: {},
    submenuHeader: {
      display: 'none',
    },
    submenuContent: {
      margin: 0,
      padding: 0,
      '& > $navItem': {
        minHeight: 'auto',
        '& > $wrapper': {
          padding: 0,
        },

        lineHeight: 21,
        marginBottom: spacing(1),
        '&:last-child': {
          margin: spacing(0),
        },
      },
    },
    collapsed: {
      width: sizes.navBarCollapsedWidth,
      '& $submenu $label': {
        opacity: 1,
        minHeight: 44,
      },
      '& $label': {
        height: 0,
        opacity: 0,
      },
      '& $submenuHeader': {
        display: 'block',
      },
    },
    accordeon: {
      padding: 0,
      margin: 0,
    },
    accordeonSummary: {
      paddingLeft: 0,
      margin: 0,
      minHeight: '44px !important',
    },
    accordeonSummaryContent: {
      margin: '0 !important',
    },
    accordeonDetails: {
      padding: spacing(0, 0, 0, `${sizes.navBarCollapsedWidth}px`),
    },
  })
);

export interface NavItemProps {
  label: string;
  to?: string;
  icon?: IconType;
  openTooltip?: boolean; // For storybook purposes
  depth?: number;
  nestedItems?: NavItemProps[];
}

export type NavItemLinkProps = NavLinkProps<NavItemProps> & NavItemProps;
export type NavItemSpanProps = HTMLAttributes<HTMLSpanElement> & NavItemProps;

export const NavItem = (props: NavItemSpanProps | NavItemLinkProps) => {
  const isExpanded = useSelector(selectors.layout.isExpanded);
  const classes = useNavItemStyles();

  const { icon, label, className, to, nestedItems, depth = 0, ...rest } = props as NavItemLinkProps;

  const Wrapper = useCallback(
    forwardRef(
      to
        ? (p, ref) => <NavLink {...p} to={to} ref={ref as Ref<HTMLAnchorElement>} />
        : (p, ref) => <span {...p} ref={ref as Ref<HTMLSpanElement>} />
    ),
    []
  );

  const submenu = nestedItems && (
    <div className={classes.submenu}>
      <div className={classes.submenuHeader}>{label}</div>
      <ul className={classes.submenuContent}>
        {nestedItems.map((item, i) => (
          <NavItem className={`depth${depth}`} key={i} depth={depth + 1} {...item} />
        ))}
      </ul>
    </div>
  );

  const itemContent = (
    // @ts-ignore
    <Wrapper {...rest} className={classes.wrapper}>
      {icon && <Icon className={clsx('Icon', classes.icon)} icon={icon} />}
      <Typography className={clsx(classes.label)} variant="inherit">
        {label}
      </Typography>
    </Wrapper>
  );

  return (
    <MenuItem
      className={clsx(classes.navItem, className, {
        [classes.collapsed]: !isExpanded && !depth,
        [classes.child]: depth,
      })}
    >
      {isExpanded ? (
        (submenu && (
          <Accordion className={classes.accordeon}>
            <AccordionSummary
              classes={{
                content: classes.accordeonSummaryContent,
                root: classes.accordeonSummary,
              }}
              className={classes.accordeonSummary}
              expandIcon={<Icon icon="drop" />}
            >
              {itemContent}
            </AccordionSummary>
            <AccordionDetails className={classes.accordeonDetails}>{submenu}</AccordionDetails>
          </Accordion>
        )) ||
        itemContent
      ) : (
        <Tooltip
          title={(!depth && (submenu || label)) || ''}
          placement="right-start"
          arrow
          open={props.openTooltip}
          interactive={!!submenu}
        >
          {itemContent}
        </Tooltip>
      )}
    </MenuItem>
  );
};
