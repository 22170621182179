import TextMask from 'react-text-mask';

import { TextField, TextFieldProps } from '../TextField';

export type TimeFieldProps = Partial<TextFieldProps>;

export const TimeField = ({ onChange, onBlur, ...props }: TimeFieldProps) => (
  <TextMask
    mask={value => {
      const chars = value.split('');
      const parsed = chars.map(v => parseInt(v));
      const hours: RegExp[] = [/[0-2]/];
      const minutes: RegExp[] = [];

      if (parsed[0] === 2) {
        hours.push(/[0-4]/);
      } else {
        hours.push(/[0-9]/);
      }

      if (parsed[0] === 0 && parsed[1] === 0 && parsed[3] === 0) {
        minutes.push(/[0-5]/, /[0-9]/);
      } else if (parsed[0] === 2 && parsed[1] === 4) {
        minutes.push(/[0]/, /[0]/);
      } else {
        minutes.push(/[0-5]/, /[0-9]/);
      }

      return [...hours, ':', ...minutes];
    }}
    onChange={onChange}
    onBlur={onBlur}
    render={(ref, inputProps) => <TextField inputRef={ref} {...props} {...inputProps} />}
  />
);
