import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    inner: {},
  });
