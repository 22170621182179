import * as querystring from 'querystring';

import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import { assoc, find, omit, propEq, uniq } from 'ramda';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, mergeMap, catchError, delay } from 'rxjs/operators';

import { NotificationsListSections } from '~pages/BusinessUnitDetail/Recipients&Trigers/NotificationSections.config';
import { http } from '~services/http';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams, isNoLicense } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { getImportCSVUnitsNotificationConfig, updateBusinessUnitsWithStatuses } from './utils';

import { api, appDetailRoutes, appRoutes, IMPORT_UNITS_XLS_TEMPLATE_NAME } from '~constants';
import {
  PaginationRequestPayload,
  PaginationResponse,
  BusinessUnit,
  BusinessUnitStatuses,
  ResponseError,
  BusinessUnitHealth,
  PageFilters,
  PageSorting,
  DefaultBusinessUnit,
  NetworkPulse,
  BusinessUnitId,
  ExportUnitsFileType,
  SubscriberRFInfo,
  BusinessUnitTTLSettings,
  SubscriberTTLInfo,
  BusinessUnitHealthPopUp,
  LatestHelthScoreType,
  BusinessUnitIPLinkLoad,
  MeshListSections,
  Mesh,
  RecipientTrigger,
  NotificationSectionType,
  Subscriber,
  Hybrid,
  BusinessUnitFoundUnit,
  NetConSettings,
  TotalSignalsPeriods,
  TotalSignals,
  SubsOverTimePeriods,
  SubsOverTime,
  BusinessUnitGeoCenter,
  BusinessUnitBadPackets,
  BusinessUnitUnitsNotifications,
  BusinessUnitUnitNotificationInfo,
  MobileCarrier,
  NetworkPulsePeriod,
} from '~models';
import {
  convertUnixEpochToTimestamp,
  findDataByCallback,
  getResponseError,
  getResponsePayload,
  intToHex,
  saveFile,
  updateArray,
  updateArrayWithCallback,
} from '~utils';

export interface BusinessUnitsState {
  data: BusinessUnit[];
  ids: BusinessUnitId[];
  signals: BusinessUnitIPLinkLoad[];
  loading: {
    ids: boolean;
    details: {
      general: boolean;
      health: {
        data: boolean;
        report: boolean;
      };
      healthPopup: boolean;
      networkPulse: boolean;
      networkHealthAnalysis: boolean;
      totalSignals: boolean;
      subsOverTime: boolean;
      geoCenter: boolean;
      logo: boolean;
      badPackets: boolean;
      unitsNotifications: {
        list: boolean;
        export: boolean;
        import: boolean;
        info: boolean;
      };
    };
    list: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    import: {
      template: boolean;
      units: boolean;
    };
    export: { [key in ExportUnitsFileType]: boolean };
    clearData: boolean;
    rfSettings: {
      list: boolean;
      update: boolean;
    };
    ttlSettings: {
      fetch: boolean;
      update: boolean;
      subscribers: boolean;
    };
    activeUsers: {
      count: boolean;
    };
    mesh: boolean;
    notifications: {
      byBU: boolean;
      list: boolean;
      import: {
        triggers: boolean;
        recipients: boolean;
      };
      mobileCarriers: boolean;
    };
    findUnits: boolean;
    netCon: {
      list: boolean;
      update: boolean;
    };
  };
  error?: ResponseError | null;
  status?: 'created' | 'edited' | 'units-imported' | 'cleared' | 'unit-found' | null;
  latestHelthScore: LatestHelthScoreType;
  foundUnits: BusinessUnitFoundUnit[] | null;
  buLogo: string | null;
}

export const initialState: BusinessUnitsState = {
  data: [],
  ids: [],
  signals: [],
  loading: {
    ids: false,
    details: {
      general: false,
      health: {
        data: false,
        report: false,
      },
      healthPopup: false,
      networkPulse: false,
      networkHealthAnalysis: false,
      totalSignals: false,
      subsOverTime: false,
      geoCenter: false,
      logo: false,
      badPackets: false,
      unitsNotifications: {
        list: false,
        export: false,
        import: false,
        info: false,
      },
    },
    list: false,
    create: false,
    edit: false,
    delete: false,
    import: {
      template: false,
      units: false,
    },
    export: {
      kml: false,
      csv: false,
    },
    clearData: false,
    rfSettings: {
      list: false,
      update: false,
    },
    ttlSettings: {
      fetch: false,
      update: false,
      subscribers: false,
    },
    activeUsers: {
      count: false,
    },
    mesh: false,
    notifications: {
      byBU: false,
      list: false,
      import: {
        triggers: false,
        recipients: false,
      },
      mobileCarriers: false,
    },
    findUnits: false,
    netCon: {
      list: false,
      update: false,
    },
  },
  error: null,
  status: null,
  latestHelthScore: 'subscribers',
  foundUnits: null,
  buLogo: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'businessUnits',
  initialState,
  reducers: {
    // Fetch list of business units
    fetchBusinessUnitsInit: (state, payload: PayloadAction<PaginationRequestPayload & PageFilters & PageSorting>) => {
      state.error = null;
      state.loading.list = true;
      state.data = [];
    },
    fetchBusinessUnitsSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<BusinessUnit>>
    ) => {
      state.loading.list = false;
      state.data = content;
    },
    fetchBusinessUnitsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = action.payload;
    },

    // Fetch business unit by id
    fetchBusinessUnitByIdInit: (state, { payload }: PayloadAction<number>) => {
      state.error = null;
      state.loading.details.general = true;
    },
    fetchBusinessUnitByIdSuccess: (state, { payload }: PayloadAction<BusinessUnit>) => {
      state.loading.details.general = false;
      state.data = updateArray(state.data, payload);
    },
    fetchBusinessUnitByIdFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.general = false;
      state.error = action.payload;
    },

    // Create business unit
    createBusinessUnitInit(state, action: PayloadAction<Partial<BusinessUnit>>) {
      state.error = null;
      state.loading.create = true;
    },
    createBusinessUnitSuccess(state) {
      state.loading.create = false;
      state.status = 'created';
    },
    createBusinessUnitFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.create = false;
      state.error = payload;
    },

    // Create default business unit
    createDefaultBusinessUnitInit(state, { payload }: PayloadAction<Partial<DefaultBusinessUnit>>) {
      state.error = null;
      state.loading.create = true;
    },

    // Update business unit
    updateBusinessUnitInit(state, action: PayloadAction<Partial<BusinessUnit>>) {
      state.error = null;
      state.loading.edit = true;
    },
    updateBusinessUnitSuccess(state) {
      state.loading.edit = false;
      state.status = 'edited';
    },
    updateBusinessUnitFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.edit = false;
      state.error = payload;
    },

    // Reset unit status
    resetUnitStatus(state) {
      state.status = null;
    },

    // Business unit status
    updateBusinessUnitsWithStatuses(state, { payload }: PayloadAction<BusinessUnitStatuses>) {
      state.data = updateBusinessUnitsWithStatuses(state.data, payload);
    },

    // Fetch business units ids
    fetchBusinessUnitsIdsInit(state, action: PayloadAction<{ includeOrphan: boolean }>) {
      state.ids = [];
      state.loading.ids = true;
      state.error = null;
    },
    fetchBusinessUnitsIdsSuccess(state, action: PayloadAction<BusinessUnitsState['ids']>) {
      state.loading.ids = false;
      state.ids = action.payload.sort((a, b) => a.id - b.id);
    },
    fetchBusinessUnitsIdsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.ids = false;
      state.error = action.payload;
    },

    // Fetch business unit health score
    fetchBusinessUnitHealthScoreInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loading.details.health.data = true;
    },
    fetchBusinessUnitHealthScoreSuccess(state, { payload }: PayloadAction<BusinessUnitHealth>) {
      state.loading.details.health.data = false;

      const unit = findDataByCallback<BusinessUnit>(
        state.data,
        businessUnit => businessUnit.id === payload.businessUnitId
      );

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('health' as keyof BusinessUnit, payload, unit));
      }
    },
    fetchBusinessUnitHealthScoreFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.health.data = false;
    },

    // Export business unit health score report
    exportBusinessUnitHealthScoreReportInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loading.details.health.report = true;
      state.error = initialState.error;
    },
    exportBusinessUnitHealthScoreReportSuccess(state) {
      state.loading.details.health.report = false;
    },
    exportBusinessUnitHealthScoreReportFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.health.report = false;
      state.error = action.payload;
    },

    // Fetch network pulse by BU
    fetchNetworkPulseByBusinessUnitInit: (
      state,
      { payload }: PayloadAction<{ id: BusinessUnit['id']; period?: NetworkPulsePeriod }>
    ) => {
      state.error = null;
      state.loading.details.networkPulse = true;
    },
    fetchNetworkPulseByBusinessUnitSuccess: (
      state,
      { payload: { networkPulse, id } }: PayloadAction<{ networkPulse: NetworkPulse; id: BusinessUnit['id'] }>
    ) => {
      state.loading.details.networkPulse = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === id);

      if (unit) {
        const timestamps = networkPulse.timestamps.map(item => convertUnixEpochToTimestamp(item));

        state.data = updateArrayWithCallback(
          state.data,
          assoc('networkPulse' as keyof BusinessUnit, { ...networkPulse, timestamps }, unit)
        );
      }
    },
    fetchNetworkPulseByBusinessUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.networkPulse = false;
      state.error = payload;
    },

    // Delete business unit
    deleteBusinessUnitInit: (state, action: PayloadAction<BusinessUnit['id']>) => {
      state.error = null;
      state.loading.delete = true;
    },
    deleteBusinessUnitSuccess: state => {
      state.loading.delete = false;
    },
    deleteBusinessUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.delete = false;
      state.error = payload;
    },

    // Export template
    exportXLSTemplateInit: (state, action: PayloadAction<{ templateType: string, templateName: string }>) => {
      state.loading.import.template = true;
    },
    exportXLSTemplateSuccess: state => {
      state.loading.import.template = false;
    },
    exportXLSTemplateFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.import.template = false;
    },

    // Import CSV file
    importCSVFilesInit: (state, action: PayloadAction<{ files: File[]; id: BusinessUnit['id'] }>) => {
      state.loading.import.units = true;
      state.status = null;
    },
    importCSVFilesSuccess: state => {
      state.loading.import.units = false;
      state.status = 'units-imported';
    },
    importCSVFilesFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.import.units = false;
    },

    // Clean data
    clearDataInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loading.clearData = true;
    },
    clearDataSuccess(state) {
      state.loading.clearData = false;
      state.status = 'cleared';
    },
    clearDataFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.clearData = false;
    },

    // Export units
    exportUnitsInit(
      state,
      { payload: { type, query } }: PayloadAction<{ id: BusinessUnit['id']; type: ExportUnitsFileType; query?: string }>
    ) {
      state.loading.export[type] = true;
    },
    exportUnitsSuccess(state, { payload: { type } }: PayloadAction<{ type: ExportUnitsFileType }>) {
      state.loading.export[type] = false;
    },
    exportUnitsFailed(
      state,
      { payload: { type } }: PayloadAction<{ type: ExportUnitsFileType; error: ResponseError }>
    ) {
      state.loading.export[type] = false;
    },

    // Fetch RF Settings
    fetchRFSettingsInit(state, action: PayloadAction<PaginationRequestPayload & { id: BusinessUnit['id'] }>) {
      state.loading.rfSettings.list = true;
    },
    fetchRFSettingsSuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<SubscriberRFInfo>>) {
      state.loading.rfSettings.list = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === unit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('rfSettings' as keyof BusinessUnit, content, unit));
      }
    },
    fetchRFSettingsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.rfSettings.list = false;
    },

    // Update RF Settings
    updateRFSettingsInit(
      state,
      action: PayloadAction<PaginationRequestPayload & { id: BusinessUnit['id']; content: SubscriberRFInfo[] }>
    ) {
      state.loading.rfSettings.update = true;
    },
    updateRFSettingsSuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<SubscriberRFInfo>>) {
      state.loading.rfSettings.update = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === unit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('rfSettings' as keyof BusinessUnit, content, unit));
      }
    },
    updateRFSettingsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.rfSettings.update = false;
    },

    // Fetch TTL settings
    fetchTTLSettingsInit(state, action: PayloadAction<{ id: BusinessUnit['id']; notify?: boolean }>) {
      state.loading.ttlSettings.fetch = true;
    },
    fetchTTLSettingsSuccess(
      state,
      { payload: { id, settings } }: PayloadAction<{ id: BusinessUnit['id']; settings: BusinessUnitTTLSettings }>
    ) {
      state.loading.ttlSettings.fetch = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('ttlSettings' as keyof BusinessUnit, settings, unit));
      }
    },
    fetchTTLSettingsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.ttlSettings.fetch = false;
    },

    // Fetch TTL settings subscribers
    fetchTTLSettingsSubscribersInit(
      state,
      payload: PayloadAction<
        PaginationRequestPayload & {
          id: number;
          from?: number;
          to?: number;
        }
      >
    ) {
      state.loading.ttlSettings.subscribers = true;
    },

    fetchTTLSettingsSubscribersSuccess(
      state,
      { payload: { content } }: PayloadAction<PaginationResponse<SubscriberTTLInfo>>
    ) {
      state.loading.ttlSettings.subscribers = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === unit.id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('ttlSettingsSubscribers' as keyof BusinessUnit, content, unit)
        );
      }
    },

    fetchTTLSettingsSubscribersFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.ttlSettings.subscribers = false;
    },

    // Update TTL subscribers
    updateTTLSettingsSubscribersInit(
      state,
      action: PayloadAction<PaginationRequestPayload & { id: BusinessUnit['id']; values: SubscriberTTLInfo }>
    ) {
      state.error = null;
      state.loading.ttlSettings.update = true;
    },

    updateTTLSettingsSubscribersSuccess(
      state,
      { payload: { id, values } }: PayloadAction<{ id: BusinessUnit['id']; values: SubscriberTTLInfo }>
    ) {
      state.loading.ttlSettings.update = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('ttlSettingsSubscribers' as keyof BusinessUnit, values, unit)
        );
      }
    },

    updateTTLSettingsSubscribersFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.ttlSettings.update = false;
      state.error = action.payload;
    },

    // Update TTL Settings
    updateTTLSettingsInit(state, action: PayloadAction<{ id: BusinessUnit['id']; values: BusinessUnitTTLSettings }>) {
      state.error = null;
      state.loading.ttlSettings.update = true;
    },
    updateTTLSettingsSuccess(
      state,
      { payload: { id, settings } }: PayloadAction<{ id: BusinessUnit['id']; settings: BusinessUnitTTLSettings }>
    ) {
      state.loading.ttlSettings.update = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('ttlSettings' as keyof BusinessUnit, settings, unit));
      }
    },
    updateTTLSettingsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.ttlSettings.update = false;
      state.error = action.payload;
    },

    // Fetch active users count
    fetchActiveUsersCountInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loading.activeUsers.count = true;
    },
    fetchActiveUsersCountSuccess(
      state,
      {
        payload: { id, activeUsersCount },
      }: PayloadAction<{ id: BusinessUnit['id']; activeUsersCount: BusinessUnit['activeUsersCount'] }>
    ) {
      state.loading.activeUsers.count = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('activeUsersCount' as keyof BusinessUnit, activeUsersCount, unit)
        );
      }
    },
    fetchActiveUsersCountFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.activeUsers.count = false;
    },

    // Fetch business unit health score pop-up data
    fetchBusinessUnitPopUpHealthScoreInit(
      state,
      payload: PayloadAction<
        PaginationRequestPayload & { calls?: LatestHelthScoreType; businessUnitId: BusinessUnit['id'] }
      >
    ) {
      state.loading.details.healthPopup = true;
      if (payload.payload.calls) {
        state.latestHelthScore = payload.payload.calls;
      }
    },
    fetchBusinessUnitPopUpHealthScoreSuccess(
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<BusinessUnitHealthPopUp>>
    ) {
      state.loading.details.healthPopup = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('healthPopup' as keyof BusinessUnit, content, unit));
      }
    },
    fetchBusinessUnitPopUpHealthScoreFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.healthPopup = false;
    },

    // Fetch list of IP Links Load
    fetchIPLinksLoadInit: (
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: BusinessUnit['id'] }>
    ) => {
      state.error = null;
      state.loading.list = true;
      state.signals = [];
    },
    fetchIPLinksLoadSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<BusinessUnitIPLinkLoad>>
    ) => {
      state.loading.list = false;
      state.signals = content;
    },
    fetchIPLinksLoadFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = action.payload;
    },

    // Fetch mesh by BU
    fetchMeshByBusinessUnitInit: (
      state,
      { payload }: PayloadAction<{ id: BusinessUnit['id']; section: MeshListSections }>
    ) => {
      state.error = null;
      state.loading.mesh = true;
    },
    fetchMeshByBusinessUnitSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<Partial<Mesh>>>
    ) => {
      state.loading.mesh = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('mesh' as keyof BusinessUnit, content, unit));
      }
    },
    fetchMeshByBusinessUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.mesh = false;
      state.error = payload;
    },

    // Fetch notifications by BU
    fetchNotificationByBusinessUnitInit: (
      state,
      { payload }: PayloadAction<PaginationRequestPayload & { id: BusinessUnit['id']; section: NotificationsListSections }>
    ) => {
      state.error = null;
      state.loading.notifications.byBU = true;
    },
    fetchNotificationByBusinessUnitSuccess: (
      state,
      { payload: { content } }: PayloadAction<PaginationResponse<RecipientTrigger>>
    ) => {
      state.loading.notifications.byBU = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('recipientTrigger' as keyof BusinessUnit, content, unit)
        );
      }
    },
    fetchNotificationByBusinessUnitFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.notifications.byBU = false;
      state.error = payload;
    },

    // Fetch notification by ID
    fetchNotificationByIdInit: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: RecipientTrigger['id'];
        section: NotificationSectionType;
      }>
    ) => {
      state.error = null;
      state.loading.notifications.byBU = true;
    },
    fetchNotificationByIdSuccess: (state, { payload }: PayloadAction<Partial<RecipientTrigger>>) => {
      state.loading.notifications.byBU = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('recipientTrigger' as keyof BusinessUnit, payload, unit)
        );
      }
    },
    fetchNotificationByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.notifications.byBU = false;
      state.error = payload;
    },

    // Update recipient by ID
    updateNotificationByIdInit(
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: RecipientTrigger['id'];
        section: NotificationSectionType;
        values: Partial<BusinessUnit['recipientTrigger']>;
      }>
    ) {
      state.loading.notifications.byBU = true;
    },
    updateNotificationByIdSuccess(state) {
      state.loading.notifications.byBU = false;
    },
    updateNotificationByIdFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.notifications.byBU = false;
      state.error = payload;
    },

    // Create notification
    createNotificationInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        values: Partial<BusinessUnit['recipientTrigger']>;
        section: NotificationSectionType;
      }>
    ) {
      state.loading.create = true;
    },
    createNotificationSuccess(state) {
      state.loading.create = false;
    },
    createNotificationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.create = false;
      state.error = payload;
    },

    // Delete notification
    deleteNotificationInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: RecipientTrigger['id'];
        section: NotificationSectionType;
      }>
    ) {
      state.error = null;
      state.loading.delete = true;
    },
    deleteNotificationSuccess(state) {
      state.loading.delete = false;
    },
    deleteNotificationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.delete = false;
      state.error = payload;
    },

    // Fetch notification associated list
    fetchNotificationAssociatedInit(
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: RecipientTrigger['id'];
        section: NotificationsListSections;
      }>
    ) {
      state.loading.notifications.list = true;
    },
    fetchNotificationAssociatedSuccess(state, { payload }: PayloadAction<Partial<RecipientTrigger>>) {
      state.loading.notifications.list = false;
      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('notificationAssociation' as keyof BusinessUnit, payload, unit)
        );
      }
    },
    fetchNotificationAssociatedFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.notifications.list = false;
      state.error = payload;
    },

    // Update notification associated list
    updateNotificationAssociatedInit(
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: RecipientTrigger['id'];
        section: NotificationsListSections;
        values: { id: number; isAssociated: number; name: string; type: string }[];
      }>
    ) {
      state.loading.notifications.list = true;
    },
    updateNotificationAssociatedSuccess(state) {
      state.loading.notifications.list = false;
    },
    updateNotificationAssociatedFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.notifications.list = false;
      state.error = payload;
    },

    // fetch units by range
    fetchUnitsByRangeInit(
      state,
      payload: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: Subscriber['businessUnitId'];
          path: string;
          from?: number;
          to?: number;
        }
      >
    ) {
      state.loading.findUnits = true;
    },
    fetchUnitsByRangeSuccess(state, { payload }: PayloadAction<BusinessUnitFoundUnit[]>) {
      state.foundUnits = payload;
      state.loading.findUnits = false;
    },
    fetchUnitsByRangeFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.findUnits = false;
    },

    // find unit by id
    findUnitByIdInit(
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; unitId: Subscriber['id'] | Hybrid['id'] }>
    ) {
      state.loading.findUnits = true;
    },
    findUnitByIdSuccess(state, { payload }: PayloadAction<BusinessUnitFoundUnit>) {
      state.status = 'unit-found';
      state.loading.findUnits = false;
    },
    findUnitByIdFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.findUnits = false;
    },

    // Fetch list of Subscriber NetCon
    fetchNetConSettingsInit: (state, payload: PayloadAction<PaginationRequestPayload & { businessUnitId: number }>) => {
      state.error = null;
      state.loading.netCon.list = true;
    },
    fetchNetConSettingsSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<NetConSettings>>
    ) => {
      state.loading.netCon.list = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === businessUnit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('netCon' as keyof BusinessUnit, content, unit));
      }
    },
    fetchNetConSettingsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.netCon.list = false;
      state.error = payload;
    },

    // Update NetCon Settings
    updateNetConSettingsInit(
      state,
      action: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: number; values: Partial<NetConSettings> }>
    ) {
      state.loading.netCon.update = true;
    },
    updateNetConSettingsSuccess(state) {
      state.loading.netCon.update = false;
    },
    updateNetConSettingsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.netCon.update = false;
      state.error = payload;
    },

    // Export Non-AES units
    exportNonAESUnitsInit(
      state,
      { payload: { type } }: PayloadAction<{ id: BusinessUnit['id']; type: ExportUnitsFileType }>
    ) {
      state.loading.export[type] = true;
    },
    exportNonAESUnitsSuccess(state, { payload: { type } }: PayloadAction<{ type: ExportUnitsFileType }>) {
      state.loading.export[type] = false;
    },
    exportNonAESUnitsFailed(
      state,
      { payload: { type, error } }: PayloadAction<{ type: ExportUnitsFileType; error: ResponseError }>
    ) {
      state.loading.export[type] = false;
    },

    // Import CSV file Non-AES units
    importCSVNonAESFilesInit: (state, action: PayloadAction<{ files: File[]; id: BusinessUnit['id'] }>) => {
      state.loading.import.units = true;
      state.status = null;
    },
    importCSVNonAESFilesSuccess: state => {
      state.loading.import.units = false;
      state.status = 'units-imported';
    },
    importCSVNonAESFilesFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.import.units = false;
    },

    // Export business unit network health analysis
    exportBusinessUnitNetworkHealthAnalysisReportInit(
      state,
      action: PayloadAction<{ buId: BusinessUnit['id']; buName: BusinessUnit['name'] }>
    ) {
      state.loading.details.networkHealthAnalysis = true;
      state.error = initialState.error;
    },
    exportBusinessUnitNetworkHealthAnalysisReportSuccess(state) {
      state.loading.details.networkHealthAnalysis = false;
    },
    exportBusinessUnitNetworkHealthAnalysisReportFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.networkHealthAnalysis = false;
      state.error = action.payload;
    },

    // Fetch total signals received by BU
    fetchTotalSignalsInit: (
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; period: TotalSignalsPeriods }>
    ) => {
      state.error = null;
      state.loading.details.totalSignals = true;
    },
    fetchTotalSignalsSuccess: (
      state,
      { payload: { totalSignals, buId } }: PayloadAction<{ totalSignals: TotalSignals; buId: BusinessUnit['id'] }>
    ) => {
      state.loading.details.totalSignals = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === buId);

      if (unit) {
        const timestamps = totalSignals.timestamps.map(item => convertUnixEpochToTimestamp(item));

        state.data = updateArrayWithCallback(
          state.data,
          assoc('totalSignals' as keyof BusinessUnit, { ...totalSignals, timestamps }, unit)
        );
      }
    },
    fetchTotalSignalsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.totalSignals = false;
      state.error = payload;
    },

    // Subscriber Over Time
    fetchSubscriberOverTimeInit: (
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; period: SubsOverTimePeriods }>
    ) => {
      state.error = null;
      state.loading.details.subsOverTime = true;
    },
    fetchSubscriberOverTimeSuccess: (
      state,
      { payload: { subsOverTime, buId } }: PayloadAction<{ subsOverTime: SubsOverTime; buId: BusinessUnit['id'] }>
    ) => {
      state.loading.details.subsOverTime = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === buId);

      if (unit) {
        const timestamps = subsOverTime.timestamps.map(item => convertUnixEpochToTimestamp(item));

        state.data = updateArrayWithCallback(
          state.data,
          assoc('subsOverTime' as keyof BusinessUnit, { ...subsOverTime, timestamps }, unit)
        );
      }
    },
    fetchSubscriberOverTimeFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.subsOverTime = false;
      state.error = payload;
    },

    // fetch geo center per bu
    fetchGeoCenterPerBUInit: (state, { payload }: PayloadAction<BusinessUnit['id']>) => {
      state.loading.details.geoCenter = true;
    },
    fetchGeoCenterPerBUSuccess: (
      state,
      { payload: { values, buId } }: PayloadAction<{ values: BusinessUnitGeoCenter; buId: BusinessUnit['id'] }>
    ) => {
      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === buId);
      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('geoCenter' as keyof BusinessUnit, values, unit));
      }
      state.loading.details.geoCenter = false;
    },
    fetchGeoCenterPerBUFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.geoCenter = false;
      state.error = payload;
    },

    // set geo center per bu
    setGeoCenterPerBUInit: (
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; geoCenter: BusinessUnitGeoCenter }>
    ) => {
      state.loading.details.geoCenter = true;
    },
    setGeoCenterPerBUSuccess: state => {
      state.loading.details.geoCenter = false;
    },
    setGeoCenterPerBUFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.geoCenter = false;
      state.error = payload;
    },

    // fetch business Unit Logo
    fetchBusinessUnitLogoInit: (state, { payload }: PayloadAction<{ buId: BusinessUnit['id'] }>) => {
      state.loading.details.logo = true;
    },
    fetchBusinessUnitLogoSuccess: (state, { payload: { logo } }: PayloadAction<{ logo: string }>) => {
      state.loading.details.logo = false;
      state.buLogo = logo;
    },
    fetchBusinessUnitLogoFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.logo = false;
      state.error = payload;
    },

    // Fetch bad packets
    fetchBadPacketsInit: (
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: BusinessUnit['id'] }>
    ) => {
      state.error = null;
      state.loading.details.badPackets = true;
    },

    fetchBadPacketsSuccess: (
      state,
      { payload: { content } }: PayloadAction<PaginationResponse<BusinessUnitBadPackets>>
    ) => {
      state.loading.details.badPackets = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === unit.id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('badPackets' as keyof BusinessUnit, content, unit));
      }
    },

    fetchBadPacketsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.badPackets = false;
      state.error = action.payload;
    },

    fetchUnitsNotificationsInit: (state, payload: PayloadAction<{ businessUnitId: BusinessUnit['id'] }>) => {
      state.error = null;
      state.loading.details.unitsNotifications.list = true;
    },

    fetchUnitsNotificationsSuccess: (
      state,
      { payload: { content, id } }: PayloadAction<{ content: BusinessUnitUnitsNotifications; id: BusinessUnit['id'] }>
    ) => {
      state.loading.details.unitsNotifications.list = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('unitsNotifications' as keyof BusinessUnit, { list: content, info: unit.unitsNotifications?.info }, unit)
        );
      }
    },

    fetchUnitsNotificationsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.unitsNotifications.list = false;
      state.error = action.payload;
    },

    // unit notification info
    fetchUnitNotificationInfoInit: (
      state,
      payload: PayloadAction<{ businessUnitId: BusinessUnit['id'], unitId: number, userId: number, path: string }>
    ) => {
      state.error = null;
      state.loading.details.unitsNotifications.info = true;
    },

    fetchUnitNotificationInfoSuccess: (
      state,
      { payload: { content, id } }: PayloadAction<{ content: BusinessUnitUnitNotificationInfo; id: BusinessUnit['id'] }>
    ) => {
      state.loading.details.unitsNotifications.info = false;

      const unit = findDataByCallback<BusinessUnit>(state.data, unit => unit.id === id);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('unitsNotifications' as keyof BusinessUnit, { list: unit.unitsNotifications?.list, info: content }, unit));
      }
    },

    fetchUnitNotificationInfoFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.unitsNotifications.info = false;
      state.error = action.payload;
    },

    importTriggersNotificationsCSVInit: (state, action: PayloadAction<{ files: File[]; id: BusinessUnit['id'] }>) => {
      state.loading.notifications.import.triggers = true;
      state.status = null;
    },
    importTriggersNotificationsCSVSuccess: state => {
      state.loading.notifications.import.triggers = false;
      state.status = 'units-imported';
    },
    importTriggersNotificationsCSVFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.notifications.import.triggers = false;
      state.error = action.payload;
    },

    importRecipientsNotificationsCSVInit: (state, action: PayloadAction<{ files: File[]; id: BusinessUnit['id'] }>) => {
      state.loading.notifications.import.recipients = true;
      state.status = null;
    },
    importRecipientsNotificationsCSVSuccess: state => {
      state.loading.notifications.import.recipients = false;
      state.status = 'units-imported';
    },
    importRecipientsNotificationsCSVFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.notifications.import.recipients = false;
      state.error = action.payload;
    },

    exportUnitsNotificationSettingsInit(state, { payload }: PayloadAction<{ id: BusinessUnit['id'] }>) {
      state.loading.details.unitsNotifications.export = true;
    },
    exportUnitsNotificationSettingsSuccess(state) {
      state.loading.details.unitsNotifications.export = false;
    },
    exportUnitsNotificationSettingsFailed(state, { payload }: PayloadAction<{ error: ResponseError }>) {
      state.loading.details.unitsNotifications.export = false;
      state.error = payload.error;
    },

    importUnitsNotificationSettingsInit(state, action: PayloadAction<{ files: File[]; id: BusinessUnit['id'] }>) {
      state.loading.details.unitsNotifications.import = true;
    },
    importUnitsNotificationSettingsSuccess(state) {
      state.loading.details.unitsNotifications.import = false;
    },
    importUnitsNotificationSettingsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.unitsNotifications.import = false;
      state.error = action.payload;
    },

    // fetch mobile carriers
    fetchMobileCarriersInit(state, { payload: { id } }: PayloadAction<{ id: BusinessUnit['id'] }>) {
      state.loading.notifications.mobileCarriers = true;
      state.error = null;
    },

    fetchMobileCarriersSuccess(state, { payload: { content, buId } }: PayloadAction<{ content: MobileCarrier[], buId: BusinessUnit['id'] }>) {
      state.loading.notifications.mobileCarriers = false;
      state.error = null;

      const unit = findDataByCallback<BusinessUnit>(state.data, businessUnit => businessUnit.id === buId);

      if (unit) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('mobileCarriers' as keyof BusinessUnit, content, unit)
        );
      } else {
        state.data = [{ id: buId, mobileCarriers: content } as BusinessUnit];
      }
    },

    fetchMobileCarriersFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.notifications.mobileCarriers = false;
      state.error = payload;
    },

    // Reset business unit error
    resetBusinessUnitError(state) {
      state.error = null;
    },

    // Reset business units
    resetBusinessUnits(state) {
      state.data = [];
    },

    // Reset business units status
    resetStatus(state) {
      state.status = null;
    },
  },

  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getBusinessUnitsState = (state: AES.RootState) => state.businessUnits;

export const selectors = {
  getBusinessUnitsState,

  getBusinessUnitsList: createDraftSafeSelector(getBusinessUnitsState, state => state.data),
  getBusinessUnitsLoaders: createDraftSafeSelector(getBusinessUnitsState, state => state.loading),
  getBusinessUnit: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => find(propEq('id', id), state.data)),

  isOrphan: (unit?: BusinessUnit) => unit?.id === 1,
  areBusinessUnitsIds: createDraftSafeSelector(getBusinessUnitsState, state => Boolean(state.ids.length)),
  isOnlyOrphanBusinessUnit: createDraftSafeSelector(
    getBusinessUnitsState,
    state => state.ids.length && state.ids.every(({ id }) => id < 2)
  ),

  getDefaultBusinessUnit: (id?: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => state.ids.find(v => v.id === id)),
  isBusinessUnitCreated: createDraftSafeSelector(getBusinessUnitsState, state => state.status === 'created'),
  isBusinessUnitEdited: createDraftSafeSelector(getBusinessUnitsState, state => state.status === 'edited'),
  areUnitsImported: createDraftSafeSelector(getBusinessUnitsState, state => state.status === 'units-imported'),
  isBusinessUnitCleared: createDraftSafeSelector(getBusinessUnitsState, state => state.status === 'cleared'),
  isBusinessUnitFoundUnit: createDraftSafeSelector(getBusinessUnitsState, state => state.status === 'unit-found'),

  getBusinessUnitError: createDraftSafeSelector(getBusinessUnitsState, state => state.error),

  getBusinessUnitIds: createDraftSafeSelector(getBusinessUnitsState, state => state.ids),
  getBusinessUnitId: (id?: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => state.ids.find(v => v.id === id)),
  getSignalsLoaded: createDraftSafeSelector(getBusinessUnitsState, state => state.signals),
  getBusinessUnitFoundUnits: createDraftSafeSelector(getBusinessUnitsState, state => state.foundUnits),
  getBusinessUnitTTLSettingsSubscribers: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        return bu.ttlSettingsSubscribers;
      }
    }),
  getBusinessUnitBadPackets: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        return bu.badPackets;
      }
    }),

  getBusinessUnitUnitsNotifications: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        return bu.unitsNotifications;
      }
    }),

  getTotalSignalsUnitsIds: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        const ipLinks: string[] = [];
        const ipLinkIds = uniq(
          ipLinks.concat(
            // eslint-disable-next-line array-callback-return
            bu?.totalSignals?.data.flat().map(unit => {
              if (unit.unitType === 'IP_LINK') {
                return intToHex(unit.unitId);
              }
            }) as string[]
          )
        ).filter(value => value !== undefined);
        const hybrids: string[] = [];
        const hybridIds = uniq(
          hybrids.concat(
            // eslint-disable-next-line array-callback-return
            bu?.totalSignals?.data.flat().map(unit => {
              if (unit.unitType === 'HYBRID') {
                return intToHex(unit.unitId);
              }
            }) as string[]
          )
        ).filter(value => value !== undefined);

        return { ipLinkIds, hybridIds };
      }
    }),
  getUnitsModels: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        const list: string[] = [];
        const dataKeys = uniq(list.concat(bu?.subsOverTime?.data.flat().map(model => model.modelName) as string[]));

        return dataKeys;
      }
    }),
  getBusinessUnitLogo: createDraftSafeSelector(getBusinessUnitsState, state => state.buLogo),
  getMobileCarriers: (id: BusinessUnit['id']) =>
    createDraftSafeSelector(getBusinessUnitsState, state => {
      const bu = state.data.find(v => v.id === id);

      if (bu?.id) {
        return bu.mobileCarriers;
      }
    }),
};

export const fetchBusinessUnitsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchBusinessUnitsInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<BusinessUnit>>(api.businessUnits.all(searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchBusinessUnitsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchBusinessUnitsFailed(error));
        })
      );
    })
  );

const createBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createBusinessUnitInit.match),
    mergeMap(({ payload }) =>
      http.post(api.businessUnits.create, payload).pipe(
        mergeMap(() => {
          const createBUActions: AnyAction[] = [
            actions.createBusinessUnitSuccess(),
            notificationsActions.enqueue({
              message: 'Business unit created',
              options: { variant: 'success' },
            }),
          ];

          return of(...createBUActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.createBusinessUnitFailed(error));
        })
      )
    )
  );

const createDefaultBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createDefaultBusinessUnitInit.match),
    mergeMap(({ payload }) =>
      http.post(api.businessUnits.createDefault, payload).pipe(
        mergeMap(() => {
          const createBUActions: AnyAction[] = [
            actions.fetchBusinessUnitsIdsInit({ includeOrphan: true }),
            actions.createBusinessUnitSuccess(),
            notificationsActions.enqueue({
              message: 'Business unit created',
              options: { variant: 'success' },
            }),
          ];

          return of(...createBUActions);
        }),
        catchError(err => of(actions.createBusinessUnitFailed(getResponseError(err))))
      )
    )
  );

const updateBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateBusinessUnitInit.match),
    mergeMap(({ payload }) =>
      http.put(api.businessUnits.update(payload.id as number), omit(['id'], payload)).pipe(
        mergeMap(res =>
          of(
            actions.updateBusinessUnitSuccess(),
            actions.fetchBusinessUnitByIdSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'Business unit updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateBusinessUnitFailed(error));
        })
      )
    )
  );

export const fetchBusinessUnitByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchBusinessUnitByIdInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<BusinessUnit>(api.businessUnits.byId(payload)).pipe(
        mergeMap(unit => of(actions.fetchBusinessUnitByIdSuccess(unit), pageActions.setPageFound())),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchBusinessUnitByIdFailed(error), pageActions.setPageNotFound());
        })
      )
    )
  );

export const fetchBusinessUnitsIds = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchBusinessUnitsIdsInit.match),
    mergeMap(({ payload: { includeOrphan } }) => {
      const params = querystring.stringify({ includeOrphan });

      return http.getJSON<BusinessUnitsState['ids']>(api.businessUnits.ids(params)).pipe(
        mergeMap(values => of(actions.fetchBusinessUnitsIdsSuccess(values))),
        catchError(err => {
          const epicActions: AnyAction[] = [actions.fetchBusinessUnitsIdsFailed(getResponseError(err))];

          if (isNoLicense(err.status)) {
            epicActions.push(pageActions.setPageNoLicense(true));
          }

          return of(...epicActions);
        })
      );
    })
  );

export const fetchBusinessUnitHealthScore = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchBusinessUnitHealthScoreInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<BusinessUnitHealth>(api.businessUnits.health.byBU(payload)).pipe(
        mergeMap(health => of(actions.fetchBusinessUnitHealthScoreSuccess(health))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchBusinessUnitHealthScoreFailed(error));
        })
      )
    )
  );

export const fetchBusinessUnitHealthScorePopUpData = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.fetchBusinessUnitPopUpHealthScoreInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<BusinessUnitHealthPopUp>>(
          api.businessUnits.health.ipLinksHybridsByBU(
            state$.value.businessUnits.latestHelthScore,
            payload.businessUnitId,
            searchParams
          )
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchBusinessUnitPopUpHealthScoreSuccess(res),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchBusinessUnitPopUpHealthScoreFailed(error));
          })
        );
    })
  );

export const exportBusinessUnitHealthScoreReportEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.exportBusinessUnitHealthScoreReportInit.match),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.health.exportToCSV(payload),
          responseType: 'blob' as 'json',
          body: payload,
        })
        .pipe(
          mergeMap(res => {
            const fileName = `health-score_${format(new Date(), 'yyyyMMdd-HH:mm:ss')}.csv`;

            saveFile(res.response, fileName);

            return of(actions.exportBusinessUnitHealthScoreReportSuccess());
          }),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.exportBusinessUnitHealthScoreReportFailed(error));
          })
        )
    )
  );

const fetchNetworkPulseByBusinessUnitEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchNetworkPulseByBusinessUnitInit.match),
    mergeMap(({ payload: { id, period } }) =>
      http.getJSON<NetworkPulse>(api.businessUnits.networkPulse(id, period as NetworkPulsePeriod)).pipe(
        mergeMap(res => of(actions.fetchNetworkPulseByBusinessUnitSuccess({ networkPulse: res, id }))),
        catchError(err => of(actions.fetchNetworkPulseByBusinessUnitFailed(getResponseError(err))))
      )
    )
  );

const deleteBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteBusinessUnitInit.match),
    mergeMap(({ payload }) =>
      http.delete(api.businessUnits.byId(payload)).pipe(
        mergeMap(() =>
          of(
            actions.deleteBusinessUnitSuccess(),
            notificationsActions.enqueue({
              message: 'Business Unit deleted',
              options: { variant: 'success' },
            }),
            push(appRoutes.businessUnits)
          )
        ),
        catchError(err => of(actions.deleteBusinessUnitFailed(getResponseError(err))))
      )
    )
  );

const exportXLSTemplateEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportXLSTemplateInit.match),
    mergeMap(({ payload: { templateType, templateName } }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.importTemplate(templateType, templateName),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            if (templateType.includes('units')) {
              saveFile(res.response, `${templateType}_${IMPORT_UNITS_XLS_TEMPLATE_NAME}`);
            } else {
              const fileName = `${templateName}_template.xlsx`;
              saveFile(res.response, fileName);
            }

            return of(
              actions.exportXLSTemplateSuccess(),
              notificationsActions.enqueue({
                message: 'XLS file has been exported',
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportXLSTemplateFailed(getResponseError(err))))
        )
    )
  );

const importCSVFilesEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.importCSVFilesInit.match),
    mergeMap(({ payload: { id, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.businessUnits.import(id),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res => {
          const { variant, message } = getImportCSVUnitsNotificationConfig(res.response);

          return of(actions.importCSVFilesSuccess(), notificationsActions.enqueue({ message, options: { variant } }));
        }),
        catchError(err =>
          of(
            actions.importCSVFilesFailed(getResponseError(err)),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const clearDataEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.clearDataInit.match),
    mergeMap(({ payload }) =>
      http.post(api.businessUnits.clearData(payload)).pipe(
        mergeMap(() =>
          of(
            actions.clearDataSuccess(),
            notificationsActions.enqueue({
              message: 'Data of the Orphan business unit has been cleared',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.clearDataFailed(getResponseError(err))))
      )
    )
  );

const exportUnitsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.exportUnitsInit.match),
    mergeMap(({ payload: { id, type, query } }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.export(id, type, query),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const businessUnit = selectors.getBusinessUnitId(id)(state$.value);
            const fileName = `${businessUnit?.name}.${type}`;

            saveFile(res.response, fileName);

            return of(
              actions.exportUnitsSuccess({ type }),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: {
                  variant: 'success',
                },
              })
            );
          }),
          catchError(err => of(actions.exportUnitsFailed({ type, error: getResponseError(err) })))
        )
    )
  );

const fetchRFSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchRFSettingsInit.match),
    mergeMap(({ payload: { id, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<SubscriberRFInfo>>(api.businessUnits.rfSettings(id, searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchRFSettingsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => of(actions.fetchRFSettingsFailed(getResponseError(err))))
      );
    })
  );

const updateRFSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateRFSettingsInit.match),
    mergeMap(({ payload: { id, content, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.post(api.businessUnits.rfSettings(id, searchParams), content).pipe(
        mergeMap(res =>
          of(
            actions.updateRFSettingsSuccess((res as unknown) as PaginationResponse<SubscriberRFInfo>),
            pageActions.setPagePagination(
              extractPaginationValues((res as unknown) as PaginationResponse<SubscriberRFInfo>)
            ),
            actions.fetchRFSettingsInit({ ...payload, id }),
            notificationsActions.enqueue({
              message: 'RF Settings have been updated',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateRFSettingsFailed(getResponseError(err))))
      );
    })
  );

const fetchTTLSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchTTLSettingsInit.match),
    mergeMap(({ payload: { id, notify } }) =>
      http.getJSON<BusinessUnitTTLSettings>(api.businessUnits.ttlSettings.all(id)).pipe(
        mergeMap(settings => {
          const epicActions: AnyAction[] = [actions.fetchTTLSettingsSuccess({ id, settings })];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'TTL settings updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }
          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchTTLSettingsFailed(getResponseError(err))))
      )
    )
  );

const fetchTTLSettingsSubscribersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchTTLSettingsSubscribersInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<SubscriberTTLInfo>>(
          api.businessUnits.ttlSettings.subscribers(payload.id, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchTTLSettingsSubscribersSuccess(res),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchTTLSettingsSubscribersFailed(error));
          })
        );
    })
  );

const updateTTLSettingsSubscribersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateTTLSettingsSubscribersInit.match),
    mergeMap(({ payload: { id, values, pagination } }) =>
      http.post(api.businessUnits.ttlSettings.subscribers(id), values).pipe(
        mergeMap(res =>
          of(
            actions.updateTTLSettingsSubscribersSuccess({
              id,
              values: getResponsePayload<SubscriberTTLInfo>(res),
            }),
            actions.fetchTTLSettingsSubscribersInit({ id, pagination }),
            notificationsActions.enqueue({
              message: 'TTL Subscribers have been updated',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateTTLSettingsSubscribersFailed(getResponseError(err))))
      )
    )
  );

const updateTTLSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateTTLSettingsInit.match),
    mergeMap(({ payload: { id, values } }) =>
      http.post(api.businessUnits.ttlSettings.all(id), values).pipe(
        mergeMap(res =>
          of(
            actions.updateTTLSettingsSuccess({ id, settings: getResponsePayload<BusinessUnitTTLSettings>(res) }),
            notificationsActions.enqueue({
              message: 'TTL settings have been updated',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateTTLSettingsFailed(getResponseError(err))))
      )
    )
  );

const fetchActiveUsersCount = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchActiveUsersCountInit.match),
    mergeMap(({ payload }) =>
      http
        .getJSON<{ activeUsersCount: BusinessUnit['activeUsersCount'] }>(api.businessUnits.activeUsersCount(payload))
        .pipe(
          mergeMap(({ activeUsersCount }) =>
            of(actions.fetchActiveUsersCountSuccess({ id: payload, activeUsersCount }))
          ),
          catchError(err => of(actions.fetchActiveUsersCountFailed(getResponseError(err))))
        )
    )
  );

const fetchIPLinksLoadEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchIPLinksLoadInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<BusinessUnitIPLinkLoad>>(
          api.businessUnits.signalsLoad(payload.businessUnitId, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(actions.fetchIPLinksLoadSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchIPLinksLoadFailed(error));
          })
        );
    })
  );

const fetchMeshEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchMeshByBusinessUnitInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<Mesh>>(api.businessUnits.mesh(payload.id, payload.section, searchParams))
        .pipe(
          mergeMap(res =>
            of(actions.fetchMeshByBusinessUnitSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchMeshByBusinessUnitFailed(error));
          })
        );
    })
  );

const fetchNotificationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchNotificationByIdInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<RecipientTrigger>(
          api.businessUnits.notificarionDetails(payload.businessUnitId, payload.id, payload.section)
        )
        .pipe(
          mergeMap(res => of(actions.fetchNotificationByIdSuccess(res))),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchNotificationByIdFailed(error));
          })
        )
    )
  );

const fetchRecipientEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchNotificationByBusinessUnitInit.match(action)),
    mergeMap(({ payload: { pagination, initial, id, section } }) => {
      const searchParams = getPaginationQueryParams({ pagination, initial });

      return http
        .getJSON<PaginationResponse<RecipientTrigger>>(
          api.businessUnits.notification(id, section, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchNotificationByBusinessUnitSuccess(res),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchNotificationByBusinessUnitFailed(error));
          })
        );
    })
  );

const updateNotificationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateNotificationByIdInit.match),
    mergeMap(({ payload }) =>
      http
        .put(api.businessUnits.notificarionDetails(payload.businessUnitId, payload.id, payload.section), payload.values)
        .pipe(
          mergeMap(res =>
            of(
              actions.updateNotificationByIdSuccess(),
              actions.fetchNotificationByIdSuccess(getResponsePayload(res)),
              notificationsActions.enqueue({
                message: 'Notification updated',
                options: { variant: 'success' },
              })
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.updateNotificationByIdFailed(error));
          })
        )
    )
  );

const createNotificationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createNotificationInit.match),
    mergeMap(({ payload: { businessUnitId, values, section } }) =>
      http.post(api.businessUnits.notificationCreate(businessUnitId, section), values).pipe(
        mergeMap(() => {
          const createBUActions: AnyAction[] = [
            actions.createNotificationSuccess(),
            notificationsActions.enqueue({
              message: 'Notification created',
              options: { variant: 'success' },
            }),

            push(
              section === 'recipients'
                ? appDetailRoutes.businessUnitNotificationsRecipients(businessUnitId)
                : appDetailRoutes.businessUnitNotificationsTriggers(businessUnitId)
            ),
          ];

          return of(...createBUActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.createNotificationFailed(error));
        })
      )
    )
  );

const deleteNotificationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteNotificationInit.match),
    mergeMap(({ payload }) =>
      http.delete(api.businessUnits.notificarionDetails(payload.businessUnitId, payload.id, payload.section)).pipe(
        mergeMap(() =>
          of(
            actions.deleteNotificationSuccess(),
            notificationsActions.enqueue({
              message: 'Notification deleted',
              options: { variant: 'success' },
            }),
            push(
              payload.section === 'recipients'
                ? appDetailRoutes.businessUnitNotificationsRecipients(payload.businessUnitId)
                : appDetailRoutes.businessUnitNotificationsTriggers(payload.businessUnitId)
            )
          )
        ),
        catchError(err => of(actions.deleteNotificationFailed(getResponseError(err))))
      )
    )
  );

const fetchNotificationAssociatedEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchNotificationAssociatedInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<RecipientTrigger>(
          api.businessUnits.notificationAssociated(payload.businessUnitId, payload.id, payload.section)
        )
        .pipe(
          mergeMap(res => of(actions.fetchNotificationAssociatedSuccess(res))),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchNotificationAssociatedFailed(error));
          })
        )
    )
  );

const updateNotificationAssociated = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateNotificationAssociatedInit.match),
    mergeMap(({ payload: { businessUnitId, id, section, values } }) =>
      http.post(api.businessUnits.notificationAssociated(businessUnitId, id, section), { associations: values }).pipe(
        mergeMap(res =>
          of(
            actions.updateNotificationAssociatedSuccess(),
            notificationsActions.enqueue({
              message: 'Association List updated',
              options: {
                variant: 'success',
              },
            }),
            section === 'recipients'
              ? push(appDetailRoutes.businessUnitRecipientDetails(businessUnitId, id))
              : push(appDetailRoutes.businessUnitTriggerDetails(businessUnitId, id))
          )
        ),
        catchError(err => of(actions.updateNotificationAssociatedFailed(getResponseError(err))))
      )
    )
  );

const findUnitByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.findUnitByIdInit.match(action)),
    mergeMap(({ payload: { buId, unitId } }) =>
      http.getJSON<BusinessUnitFoundUnit>(api.businessUnits.findUnitById(buId, unitId)).pipe(
        mergeMap(res =>
          of(
            actions.findUnitByIdSuccess(res),

            notificationsActions.enqueue({
              message: `Unit ${intToHex(unitId)} found`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.findUnitByIdFailed(error));
        })
      )
    )
  );

const fetchUnitsByRangeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchUnitsByRangeInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<BusinessUnitFoundUnit>>(
          api.businessUnits.fetchUnitsByRange(payload.businessUnitId, payload.path, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchUnitsByRangeSuccess(res.content),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(
              actions.fetchUnitsByRangeFailed(error),
              notificationsActions.enqueue({
                message: error.message,
                options: {
                  variant: 'error',
                },
              })
            );
          })
        );
    })
  );

const fetchNetConSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchNetConSettingsInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<NetConSettings>>(
          api.businessUnits.netCon.list(payload.businessUnitId, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(actions.fetchNetConSettingsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchNetConSettingsFailed(error));
          })
        );
    })
  );

const updateNetConSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateNetConSettingsInit.match),
    mergeMap(({ payload: { businessUnitId, id, values } }) =>
      http.post(api.businessUnits.netCon.update(businessUnitId, id), values).pipe(
        mergeMap(() =>
          of(
            actions.updateNetConSettingsSuccess(),
            notificationsActions.enqueue({
              message: `NetCon ${intToHex(id)} Data Modified`,
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateNetConSettingsFailed(getResponseError(err))))
      )
    )
  );

const exportNonAESUnitsEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.exportNonAESUnitsInit.match),
    mergeMap(({ payload: { id, type } }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.nonAES.export(id),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const businessUnit = selectors.getBusinessUnitId(id)(state$.value);
            const fileName = `${businessUnit?.name}.csv`;

            saveFile(res.response, fileName);

            return of(
              actions.exportUnitsSuccess({ type: 'csv' }),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: {
                  variant: 'success',
                },
              })
            );
          }),
          catchError(err => of(actions.exportNonAESUnitsFailed({ type, error: getResponseError(err) })))
        )
    )
  );

const importCSVNonAESFilesEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.importCSVNonAESFilesInit.match),
    mergeMap(({ payload: { id, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.businessUnits.nonAES.import(id),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res => {
          const { variant, message } = getImportCSVUnitsNotificationConfig(res.response);

          return of(
            actions.importCSVNonAESFilesSuccess(),
            notificationsActions.enqueue({ message, options: { variant } })
          );
        }),
        catchError(err => of(actions.importCSVNonAESFilesFailed(getResponseError(err))))
      );
    })
  );

export const exportBusinessUnitNetworkHealthAnalysisReportEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.exportBusinessUnitNetworkHealthAnalysisReportInit.match),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.networkHealthAnalysis(payload.buId),
          responseType: 'blob' as 'json',
          body: payload,
        })
        .pipe(
          mergeMap(res => {
            const fileName = `${payload.buName}_Monthly_NHA_Report.txt`;

            saveFile(res.response, fileName);

            return of(actions.exportBusinessUnitNetworkHealthAnalysisReportSuccess());
          }),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.exportBusinessUnitNetworkHealthAnalysisReportFailed(error));
          })
        )
    )
  );

const fetchTotalSignalsEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchTotalSignalsInit.match),
    mergeMap(({ payload: { buId, period } }) =>
      http.getJSON<TotalSignals>(api.businessUnits.totalSignals(buId, period)).pipe(
        mergeMap(res => of(actions.fetchTotalSignalsSuccess({ totalSignals: res, buId }))),
        catchError(err => of(actions.fetchTotalSignalsFailed(getResponseError(err))))
      )
    )
  );

const fetchSubscriberOverTimeEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchSubscriberOverTimeInit.match),
    mergeMap(({ payload: { buId, period } }) =>
      http.getJSON<SubsOverTime>(api.businessUnits.subsOverTime(buId, period)).pipe(
        mergeMap(res => of(actions.fetchSubscriberOverTimeSuccess({ subsOverTime: res, buId }))),
        catchError(err => of(actions.fetchSubscriberOverTimeFailed(getResponseError(err))))
      )
    )
  );

const fetchGeoCenterPerBUEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchGeoCenterPerBUInit.match(action)),
    mergeMap(({ payload }) =>
      http.getJSON<BusinessUnitGeoCenter>(api.geography.geoCenter(payload)).pipe(
        mergeMap(res => of(actions.fetchGeoCenterPerBUSuccess({ buId: payload, values: res }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchGeoCenterPerBUFailed(error));
        })
      )
    )
  );

const setGeoCenterPerBUEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.setGeoCenterPerBUInit.match(action)),
    mergeMap(({ payload }) =>
      http.put(api.businessUnits.geoCenter(payload?.buId), payload?.geoCenter).pipe(
        mergeMap(() => of(actions.setGeoCenterPerBUSuccess())),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.setGeoCenterPerBUFailed(error));
        })
      )
    )
  );

const fetchBusinessUnitLogoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchBusinessUnitLogoInit.match(action)),
    mergeMap(({ payload: { buId } }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.logo(buId),
          responseType: 'text/plain',
        })
        .pipe(
          mergeMap(res => of(actions.fetchBusinessUnitLogoSuccess({ logo: res.response }))),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchBusinessUnitLogoFailed(error));
          })
        )
    )
  );

const fetchBadPacketsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchBadPacketsInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<BusinessUnitBadPackets>>(
          api.businessUnits.badPackets(payload.businessUnitId, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(actions.fetchBadPacketsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => of(actions.fetchBadPacketsFailed(getResponseError(err))))
        );
    })
  );

const fetchUnitsNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchUnitsNotificationsInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<BusinessUnitUnitsNotifications>(api.businessUnits.unitsNotifications.list(payload.businessUnitId))
        .pipe(
          mergeMap(res => of(actions.fetchUnitsNotificationsSuccess({ content: res, id: payload.businessUnitId }))),
          catchError(err => of(actions.fetchUnitsNotificationsFailed(getResponseError(err))))
        )
    )
  );

const fetchUnitNotificationInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchUnitNotificationInfoInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<BusinessUnitUnitNotificationInfo>(
          api.businessUnits.unitNotificationInfo(
            payload.businessUnitId,
            payload.unitId,
            payload.userId,
            payload.path
          )
        )
        .pipe(
          mergeMap(res => of(actions.fetchUnitNotificationInfoSuccess({ content: res, id: payload.businessUnitId }))),
          catchError(err => of(actions.fetchUnitNotificationInfoFailed(getResponseError(err))))
        )
    )
  );

const importTriggersNotificationsCSVEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.importTriggersNotificationsCSVInit.match),
    mergeMap(({ payload: { id, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('csvFile', file, file.name));

      return ajax({
        method: 'POST',
        url: api.businessUnits.importNotifications.triggers(id),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importTriggersNotificationsCSVSuccess(),
            notificationsActions.enqueue({
              message: 'Successfully imported Triggers',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.importTriggersNotificationsCSVFailed(getResponseError(err)),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const importRecipientsNotificationsCSVEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.importRecipientsNotificationsCSVInit.match),
    mergeMap(({ payload: { id, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('csvFile', file, file.name));

      return ajax({
        method: 'POST',
        url: api.businessUnits.importNotifications.recipients(id),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importRecipientsNotificationsCSVSuccess(),
            notificationsActions.enqueue({
              message: 'Successfully imported Recipients',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.importRecipientsNotificationsCSVFailed(getResponseError(err)),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const exportUnitsNotificationSettingsEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.exportUnitsNotificationSettingsInit.match),
    mergeMap(({ payload: { id } }) =>
      http
        .call({
          method: 'GET',
          url: api.businessUnits.unitsNotifications.export(id),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const businessUnit = selectors.getBusinessUnitId(id)(state$.value);
            const fileName = `${businessUnit?.name}_Units_NotificationSettings.csv`;

            saveFile(res.response, fileName);

            return of(
              actions.exportUnitsNotificationSettingsSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: {
                  variant: 'success',
                },
              })
            );
          }),
          catchError(err => of(actions.exportUnitsNotificationSettingsFailed({ error: getResponseError(err) })))
        )
    )
  );

const importUnitsNotificationSettingsEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.importUnitsNotificationSettingsInit.match),
    mergeMap(({ payload: { id, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.businessUnits.unitsNotifications.import(id),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importUnitsNotificationSettingsSuccess(),
            notificationsActions.enqueue({
              message: 'Successfully imported Units Notifications',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.importUnitsNotificationSettingsFailed(getResponseError(err)),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const fetchMobileCarriersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchMobileCarriersInit.match),
    mergeMap(({ payload: { id } }) =>
      http
        .getJSON<MobileCarrier[]>(
          api.businessUnits.mobileCarriers(),
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchMobileCarriersSuccess({ content: res, buId: id }),
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(
              actions.fetchMobileCarriersFailed(error),
            );
          })
        )
    )
  );

export const epics = combineEpics(
  fetchBusinessUnitsEpic,
  fetchBusinessUnitByIdEpic,
  createBusinessUnitEpic,
  createDefaultBusinessUnitEpic,
  updateBusinessUnitEpic,
  fetchBusinessUnitsIds,
  fetchBusinessUnitHealthScore,
  exportBusinessUnitHealthScoreReportEpic,
  fetchNetworkPulseByBusinessUnitEpic,
  deleteBusinessUnitEpic,
  exportXLSTemplateEpic,
  importCSVFilesEpic,
  clearDataEpic,
  exportUnitsEpic,
  fetchRFSettingsEpic,
  updateRFSettingsEpic,
  fetchTTLSettingsEpic,
  updateTTLSettingsEpic,
  fetchTTLSettingsSubscribersEpic,
  updateTTLSettingsSubscribersEpic,
  fetchActiveUsersCount,
  fetchBusinessUnitHealthScorePopUpData,
  fetchIPLinksLoadEpic,
  fetchMeshEpic,
  fetchNotificationEpic,
  fetchRecipientEpic,
  updateNotificationEpic,
  createNotificationEpic,
  deleteNotificationEpic,
  fetchNotificationAssociatedEpic,
  updateNotificationAssociated,
  findUnitByIdEpic,
  fetchUnitsByRangeEpic,
  fetchNetConSettingsEpic,
  updateNetConSettingsEpic,
  exportNonAESUnitsEpic,
  importCSVNonAESFilesEpic,
  exportBusinessUnitNetworkHealthAnalysisReportEpic,
  fetchTotalSignalsEpic,
  fetchSubscriberOverTimeEpic,
  fetchGeoCenterPerBUEpic,
  setGeoCenterPerBUEpic,
  fetchBusinessUnitLogoEpic,
  fetchBadPacketsEpic,
  importTriggersNotificationsCSVEpic,
  importRecipientsNotificationsCSVEpic,
  fetchUnitsNotificationsEpic,
  exportUnitsNotificationSettingsEpic,
  fetchUnitNotificationInfoEpic,
  updateTTLSettingsSubscribersEpic,
  fetchMobileCarriersEpic,
  importUnitsNotificationSettingsEpic,
);

export const allEpics = {
  fetchBusinessUnitsEpic,
  fetchBusinessUnitByIdEpic,
  createBusinessUnitEpic,
  createDefaultBusinessUnitEpic,
  updateBusinessUnitEpic,
  fetchBusinessUnitsIds,
  fetchBusinessUnitHealthScore,
  exportBusinessUnitHealthScoreReportEpic,
  fetchNetworkPulseByBusinessUnitEpic,
  deleteBusinessUnitEpic,
  exportXLSTemplateEpic,
  importCSVFilesEpic,
  clearDataEpic,
  exportUnitsEpic,
  fetchRFSettingsEpic,
  updateRFSettingsEpic,
  fetchTTLSettingsEpic,
  updateTTLSettingsEpic,
  fetchActiveUsersCount,
  fetchBusinessUnitHealthScorePopUpData,
  fetchIPLinksLoadEpic,
  fetchMeshEpic,
  fetchNotificationEpic,
  fetchRecipientEpic,
  updateNotificationEpic,
  createNotificationEpic,
  deleteNotificationEpic,
  fetchNotificationAssociatedEpic,
  updateNotificationAssociated,
  findUnitByIdEpic,
  fetchUnitsByRangeEpic,
  fetchNetConSettingsEpic,
  updateNetConSettingsEpic,
  exportNonAESUnitsEpic,
  importCSVNonAESFilesEpic,
  exportBusinessUnitNetworkHealthAnalysisReportEpic,
  fetchTotalSignalsEpic,
  fetchSubscriberOverTimeEpic,
  fetchGeoCenterPerBUEpic,
  setGeoCenterPerBUEpic,
  fetchBusinessUnitLogoEpic,
  fetchBadPacketsEpic,
  importTriggersNotificationsCSVEpic,
  importRecipientsNotificationsCSVEpic,
  fetchUnitsNotificationsEpic,
  exportUnitsNotificationSettingsEpic,
  fetchUnitNotificationInfoEpic,
  updateTTLSettingsSubscribersEpic,
  fetchMobileCarriersEpic,
  importUnitsNotificationSettingsEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      businessUnits: typeof actions;
    }
    export interface RootState {
      businessUnits: BusinessUnitsState;
    }
    export interface Selectors {
      businessUnits: typeof selectors;
    }
  }
}
