import { WithStyles, withStyles } from '@material-ui/core';

import { UnitID } from '~models/unit';

import { RoutingLink } from '../Routing';

import { styles } from './DependentUnits.styles';

import { ContentField } from '~ui-kit';

interface DependentUnitsProps extends WithStyles<typeof styles> {
  units: { id: UnitID['unitId']; unitType: UnitID['unitType'] }[];
  title: string;
  width?: number;
  getLinkTo?: (unitType: UnitID['unitType'], id: number) => string;
}

export const DependentUnits = withStyles(styles)(
  ({ units, title, getLinkTo = id => id.toString(), width, classes }: DependentUnitsProps) => {
    if (!units?.length) {
      return <ContentField title={title}>N/A</ContentField>;
    }

    return (
      <ContentField title={title} width={width}>
        <div className={classes.root}>
          {units.map((unit, i) => (
            <RoutingLink key={i} id={unit.id} linkTo={getLinkTo(unit.unitType, unit.id as number)} />
          ))}
        </div>
      </ContentField>
    );
  }
);
