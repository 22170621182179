import { keys, omit } from 'ramda';

import { NetworkPulseDarkColors, NetworkPulseLightColors, NetworkPulseLabels } from './types';

import { NetworkPulse, NetworkPulsePeriod } from '~models';
import { FilterOption } from '~ui-kit';
import { getNotEmptyKeys } from '~utils';

export const getDataKeys = (data?: Partial<NetworkPulse>): string[] => {
  if (!data) {
    return [];
  }

  return keys(omit(['timestamps'], data));
};

export const isNetworkPulseData = (data?: Partial<NetworkPulse>): boolean => {
  if (!data) {
    return false;
  }

  const dataKeys = Object.keys(data);

  return Boolean(getNotEmptyKeys(data, dataKeys).length);
};

export const getDefaultFilterValues = (data?: Partial<NetworkPulse>): string[] => {
  const defaultFilters = ['rfCheckins', 'ipCheckins', 'ackDelays'];

  if (!data) {
    return [];
  }

  return getNotEmptyKeys(data, defaultFilters);
};

// eslint-disable-next-line no-confusing-arrow
const getThemeColor = (key: string, theme: string) =>
  theme === 'dark' ? NetworkPulseDarkColors[key] : NetworkPulseLightColors[key];

export const getFilters = (data: string[], theme: string): FilterOption[] => {
  if (!data.length) {
    return [];
  }

  return data.map(key => ({
    label: NetworkPulseLabels[key],
    value: key,
    color: getThemeColor(key, theme),
  }));
};

export const generateChartData = (data?: NetworkPulse, keys: string[] = []) => {
  if (!data) {
    return [];
  }

  const dataKey = [...keys, 'timestamps'];

  return data.timestamps.map((_, index) =>
    dataKey.reduce((acc, key) => {
      acc[key] = data[key][index];

      return acc;
    }, {})
  );
};

export const getMaxValue = (data?: Partial<NetworkPulse>, keys: string[] = []): number => {
  if (!data) {
    return 0;
  }

  return Math.max(...keys.map(key => Math.max(...data[key])));
};

export const networkPulseSections: {
  label: string;
  tooltip: string;
  section: NetworkPulsePeriod;
}[] = [
  {
    label: '10d',
    tooltip: '10 days',
    section: 'TEN_DAYS',
  },
  {
    label: '1mon',
    tooltip: '1 month',
    section: 'MONTH',
  },
  {
    label: '1y',
    tooltip: '1 year',
    section: 'YEAR',
  },
];
