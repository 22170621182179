import { TabsProps } from '@material-ui/core';
import { compose, last, split } from 'ramda';
import { useHistory, useLocation } from 'react-router';

import { PageNavigation, PageControls } from '~components';
import { PageRoute, PageQuery } from '~models';
import { Tab, Tabs } from '~ui-kit';
import { withQueryParams } from '~utils';

type PageDetailsNavigationProps = {
  routes: PageRoute[];
  query?: PageQuery;
  activeTab?: string;
  tabsProps?: TabsProps;
  place?: string;
  withPageControls?: boolean;
};
export const PageDetailsNavigation = ({
  routes,
  query = {},
  activeTab,
  tabsProps = {},
  place,
  withPageControls = true,
}: PageDetailsNavigationProps) => {
  const location = useLocation();
  const history = useHistory();
  const hasRoutes = Boolean(routes.length);
  const selected = compose(last, split('/'))(location.pathname) as string;
  const value = activeTab || selected;

  return (
    <PageNavigation routes={routes}>
      {hasRoutes ? (
        <Tabs
          {...tabsProps}
          value={value}
          // @ts-ignore
          onChange={(e, v) => history.push(withQueryParams(location.pathname.replace(selected, v), query))}
        >
          {routes.map(({ label, path, icon }) => (
            icon ? (
              <Tab label={label} value={path} key={path} icon={icon}></Tab>
            ) : (
              <Tab label={label} value={path} key={path}></Tab>
            )
          ))}
        </Tabs>
      ) : null}

      {withPageControls ? <PageControls place={place} /> : null}
    </PageNavigation>
  );
};
