import { MenuItemClassKey, StyleRules, Theme } from '@material-ui/core';

type MenuItemStyles = Partial<StyleRules<MenuItemClassKey>>;

export const menuItemStyles = (theme: Theme): MenuItemStyles => ({
  root: {
    padding: theme.spacing(0, 3),
    fontSize: 14,
    lineHeight: 1.5,
    minHeight: 'auto',
  },
});
