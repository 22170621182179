import { styled, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Fragment } from 'react';

import { getPageSizeOption } from '~features/page/pageUtils';
import { FormSelect } from '~ui-kit/Forms';
import { SelectOption } from '~ui-kit/index';

type UnitsListPageSizeProps = {
    size: number,
    totalNumber?: number,
    onSizeSelect: (values: EventTarget) => void,
};

const PageSizeTitle = styled(Typography)(({ theme }) => ({ marginRight: theme.spacing(1) }));
const PageForm = styled(Form)(({ theme }) => ({ marginRight: theme.spacing(2) }));
const PageFormSelect = styled(FormSelect)(({ theme }) => ({ marginBottom: theme.spacing(2) }));

export const UnitsListPageSize = ({ size, totalNumber = 500, onSizeSelect }: UnitsListPageSizeProps) => {
  const options = getPageSizeOption(totalNumber);

  return (
    <Fragment>
      <PageSizeTitle variant="h4">
        Page size
      </PageSizeTitle>
      <Formik
        enableReinitialize
        initialValues={{ pageSize: size }}
        onSubmit={() => void {}}
      >
        <PageForm>
          <PageFormSelect
            name="pageSize"
            options={options as SelectOption[]}
            onClick={ e => onSizeSelect(e.target)}
          />
        </PageForm>
      </Formik>
    </Fragment>
  );
};
