import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pickAll } from 'ramda';
import { load } from 'redux-localstorage-simple';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as appActions } from '../app/appSlice';
import { actions as profileActions } from '../profile/profileSlice';
import { actions as sharedActions } from '../sharedSlice';

import { api } from '~constants';
import { RequestStatus, ResponseError, User } from '~models';
import { http } from '~services';
import { getResponseError } from '~utils';

type LoginPayload = { accessToken: string; refreshToken: string | null };
export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthorized: boolean | null;
  currentUser: User | null;
  statuses: {
    login: RequestStatus;
    logout: RequestStatus;
  };
  error: ResponseError | null;
}

export const defaultState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: null,
  currentUser: null,
  statuses: {
    login: RequestStatus.Idle,
    logout: RequestStatus.Idle,
  },
  error: null,
};

// TODO: correctly load state from localstorage
const loaded = load({ states: ['auth'], disableWarnings: true }) as { auth: AuthState };
const prevState = pickAll(['accessToken', 'refreshToken', 'error'], loaded.auth || {}) as AuthState;

export const initialState = {
  ...defaultState,
  ...prevState,
  statuses: { ...defaultState.statuses },
} as AuthState;

export const { actions, reducer, name } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Login
    loginInit(state, action: PayloadAction<{ username: string; password: string }>) {
      state.error = null;
      state.accessToken = null;
      state.statuses.login = RequestStatus.Pending;
      state.isAuthorized = null;
    },
    loginSuccess(state, { payload }: PayloadAction<LoginPayload>) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.statuses.login = RequestStatus.Success;
      state.isAuthorized = true;
    },
    loginFail(state, { payload }: PayloadAction<ResponseError>) {
      state.error = payload;
      state.statuses.login = RequestStatus.Failure;
    },

    // Logout
    logoutInit(state) {
      state.statuses.logout = RequestStatus.Pending;
    },
    logoutSuccess(state) {
      state.statuses.logout = RequestStatus.Success;
      localStorage.clear();
    },
    logoutFail(state) {
      state.statuses.logout = RequestStatus.Failure;
    },

    // Set auth token
    setAuthToken(state, { payload: { accessToken, refreshToken } }: PayloadAction<LoginPayload>) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },

    // Fetch current user
    fetchCurrentUserInit(state, action: PayloadAction<{ loginOnSuccess: boolean }>) {},
    fetchCurrentUserSuccess(state, { payload }: PayloadAction<User>) {
      state.currentUser = payload;
    },
    fetchCurrentUserFailed(state, action: PayloadAction<ResponseError>) {},

    // Update required password
    updateRequiredPassword(state, { payload }: PayloadAction<boolean>) {
      if (state.currentUser) {
        state.currentUser.updateRequired = payload;
      }
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => {
      Object.assign(state, defaultState);
      state.isAuthorized = false;
    },
  },
});

const getAuthState = (state: AES.RootState) => state.auth;
export const selectors = {
  getAuthState,

  isLoginProcessing: createDraftSafeSelector(getAuthState, state => state.statuses.login === RequestStatus.Pending),
  isLogoutProcessing: createDraftSafeSelector(getAuthState, state => state.statuses.logout === RequestStatus.Pending),

  isAuthorized: createDraftSafeSelector(getAuthState, state => state.isAuthorized),

  getAuthToken: createDraftSafeSelector(getAuthState, state => state.accessToken),
  getAuthError: createDraftSafeSelector(getAuthState, state => state.error),

  getCurrentUser: createDraftSafeSelector(getAuthState, state => state.currentUser),

  shouldUpdatePassword: createDraftSafeSelector(getAuthState, state => state.currentUser?.updateRequired),
};

const userLoginEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.loginInit.match),
    mergeMap(({ payload }) =>
      http.post(api.auth.login, payload).pipe(
        mergeMap(({ response: { accessToken } }) =>
          of(
            actions.loginSuccess({ accessToken, refreshToken: null }),
            appActions.setAppDialog({ dialog: 'firstLoginStepper', value: true })
          )
        ),
        catchError(err => of(actions.loginFail(getResponseError(err))))
      )
    )
  );

const userLogoutEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.logoutInit.match),
    mergeMap(() =>
      http.post(api.auth.logout).pipe(
        mergeMap(() => of(actions.logoutSuccess())),
        catchError(() => of(actions.logoutFail(), sharedActions.reset()))
      )
    )
  );

const userLogoutSuccessEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.logoutSuccess.match),
    mergeMap(() => of(sharedActions.reset()))
  );

const fetchCurrentUserEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.fetchCurrentUserInit.match),
    mergeMap(({ payload: { loginOnSuccess } }) =>
      http.getJSON<User>(api.users.current).pipe(
        mergeMap(user => {
          const epicActions: AnyAction[] = [
            actions.fetchCurrentUserSuccess(user),
            profileActions.get(),
            appActions.fetchSystemInfoInit(),
          ];

          if (loginOnSuccess) {
            epicActions.push(
              actions.loginSuccess({
                accessToken: state$.value.auth.accessToken as string,
                refreshToken: state$.value.auth.refreshToken,
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchCurrentUserFailed(getResponseError(err))))
      )
    )
  );

export const epics = combineEpics(userLoginEpic, userLogoutEpic, userLogoutSuccessEpic, fetchCurrentUserEpic);
export const allEpics = { userLoginEpic, userLogoutEpic, userLogoutSuccessEpic, fetchCurrentUserEpic };

declare global {
  namespace AES {
    export interface Actions {
      auth: typeof actions;
    }
    export interface RootState {
      auth: AuthState;
    }
    export interface Selectors {
      auth: typeof selectors;
    }
  }
}
