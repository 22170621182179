import { StyleRules } from '@material-ui/core/styles/withStyles';
import { TypographyClassKey } from '@material-ui/core/Typography';

export const MuiTypography: Partial<StyleRules<TypographyClassKey>> = {
  root: {
    lineHeight: 1.5,
  },
  h2: {
    fontSize: 24,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 500,
  },
  h4: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 11,
    fontStyle: 'italic',
  },
  subtitle2: {
    fontSize: 14,
    fontStyle: 'italic',
  },
  body1: {
    fontSize: 14,
  },
  button: {
    textTransform: 'none',
  },
};
