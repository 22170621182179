import { Formik, Form } from 'formik';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { hexIdValidator } from '~utils/validators';

import { UnitsSearch } from './UnitsSearch';

import { appDetailRoutes, appRoutes } from '~constants';
import { boundActions, selectors } from '~store';
import { hexToInt } from '~utils';

const validationSchema = Yup.object().shape({
  search: hexIdValidator(),
});

export const UnitsSearchForm = () => {
  const history = useHistory();
  const isPageNoLicense = useSelector(selectors.page.isPageNoLicense);

  const onFind = useMemo(
    () => ({ search }) => {
      const unitId = hexToInt(search);
      boundActions.app.fetchUnitsSearchInit({ unitId });
      history.push(appDetailRoutes.unitDetails(unitId));
    },
    []
  );

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      history.push(appDetailRoutes.unitDetails(e.target.value));
    }
  };

  const onClean = useCallback(() => {
    history.push(appRoutes.root);
  }, []);

  if (isPageNoLicense) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ search: '' }}
      validationSchema={validationSchema}
      onSubmit={onFind || onKeyPress}
    >
      {({ values }) => (
        <Form>
          <UnitsSearch
            inputName="search"
            onFind={onFind}
            onClean={onClean}
            onKeyPress={e => onKeyPress(e)}
            isValues={Boolean(values.search.length)}
          />
        </Form>
      )}
    </Formik>
  );
};
