import { Theme, WithStyles, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const colorsSequence = ['#EB5757', '#EB5757', '#EB732F', '#F49D0E', '#8CC051'];
const defaultColorEasing = (val: number) => colorsSequence[Math.ceil(val / 25)];

const styles = ({ spacing, palette: { text, primary } }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'block',
      height: spacing(6),
      position: 'relative',
    },
    header: {
      color: primary.main,
      marginBottom: spacing(2),
    },
    bar: {
      height: spacing(0.5),
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'block',
      position: 'absolute',
      borderRadius: '2px',
    },
    barBg: {
      background: '#B8CAE3',
      width: '100%',
    },
    text: {
      position: 'absolute',
      bottom: spacing(1.5),
      fontStyle: 'italic',
      fontSize: 11,
      '&$textMin, &$textMax': {
        bottom: spacing(0.5),
      },
    },
    textMin: {
      left: 0,
    },
    textMax: {
      right: 0,
    },
    textActual: {
      top: spacing(0.5),
    },
  });

export interface ScoreBarProps extends WithStyles<typeof styles> {
  value: number;
  label?: string | ReactNode;
  min?: number;
  max?: number;
  isNegative?: boolean;
  colorEasing?: (val: number) => string;
}

export const ScoreBar = withStyles(styles)(
  ({
    colorEasing = defaultColorEasing,
    isNegative = false,
    min = 0,
    max = 100,
    value,
    label,
    classes,
  }: ScoreBarProps) => {
    const val = value > max ? max : value < min ? min : value;
    const relVal = (val / max) * 100;

    return (
      <div className={classes.root}>
        <span className={clsx(classes.text, classes.textMin)}>{min}</span>
        <span className={clsx(classes.text, classes.textMax)}>{max}</span>
        <span
          className={clsx(classes.text, classes.textActual)}
          style={{
            left: `${relVal}%`,
            transform: `translateX(${relVal < 5 ? 0 : relVal > 95 ? '-100%' : '-50%'})`,
          }}
        >
          {val}
        </span>
        <i className={clsx(classes.bar, classes.barBg)} />
        <i
          className={clsx(classes.bar)}
          style={{
            background: colorEasing(isNegative ? 100 - relVal : relVal),
            width: `${relVal}%`,
          }}
        />
      </div>
    );
  }
);
