import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 0, 0, 0),
      marginBottom: theme.spacing(-1),
    },
    rootHide: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      color: theme.palette.greyColor.dark,
    },
  });
