import { AnyAction, createDraftSafeSelector, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';

import { http } from '~services/http';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';
import { actions as subscribersActions } from '../subscribers/subscribersSlice';

import { api, appDetailRoutes, appRoutes } from '~constants';
import { PaginationRequestPayload, PaginationResponse, ControlRelay, ResponseError, Relay, Subscriber } from '~models';
import { getResponseError, getResponsePayload, updateArray } from '~utils';

export const adapter = createEntityAdapter<ControlRelay>({
  selectId: controlRelay => controlRelay?.id,
});

export type CopySettingsPayload = {
  unitId: ControlRelay['unitId'];
  unitIds: number[];
};
export interface ControlRelayState {
  data: ReturnType<typeof adapter.getInitialState>;
  subscriberIds: number[];
  loading: {
    list: boolean;
    details: {
      general: boolean;
      update: boolean;
    };
    switchRelay: boolean;
    create: boolean;
    delete: boolean;
    unitIds: boolean;
    copySettings: boolean;
  };
  status: 'updated' | null;
  error?: ResponseError | null;
}

export const initialState: ControlRelayState = {
  data: adapter.getInitialState(),
  subscriberIds: [],
  loading: {
    list: false,
    details: {
      general: false,
      update: false,
    },
    switchRelay: false,
    create: false,
    delete: false,
    unitIds: false,
    copySettings: false,
  },
  status: null,
  error: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'controlRelays',
  initialState,
  reducers: {
    fetchControlRelaysListInit: (state, payload: PayloadAction<PaginationRequestPayload & { model: string }>) => {
      state.error = null;
      state.loading.list = true;
    },
    fetchControlRelaysListSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<ControlRelay>>
    ) => {
      state.loading.list = false;
      adapter.setAll(state.data, content);
    },
    fetchControlRelaysListFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = action.payload;
    },
    fetchControlRelayByIdInit: (state, action: PayloadAction<{ id: ControlRelay['id'] }>) => {
      state.loading.details.general = true;
    },
    fetchControlRelayByIdSuccess: (state, { payload }: PayloadAction<ControlRelay>) => {
      state.loading.details.general = false;
      adapter.upsertOne(state.data, payload);
    },
    fetchControlRelayByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.general = false;
      state.error = payload;
    },
    updateControlRelayDetailsInit(
      state,
      {
        payload,
      }: PayloadAction<{
        controlRelayId: ControlRelay['id'];
        values: Partial<ControlRelay>;
        path?: string;
      }>
    ) {
      state.loading.details.update = true;
      state.status = null;
      state.error = null;
    },
    updateControlRelayDetailsSuccess(state, { payload }: PayloadAction<Partial<ControlRelay>>) {
      state.loading.details.update = false;
      state.status = 'updated';

      adapter.upsertOne(state.data, payload as ControlRelay);
    },

    updateControlRelayDetailsFailed(
      state,
      {
        payload: { error },
      }: PayloadAction<{
        error: ResponseError;
      }>
    ) {
      state.loading.details.update = false;
      state.error = error;
    },

    //delete control relay
    deleteControlRelayInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          model: string;
          controlRelayId: ControlRelay['id'];
          path?: string;
          businessUnitId?: Subscriber['businessUnitId'];
          unitId?: Subscriber['id'];
        }
      >
    ) {
      state.loading.delete = true;
    },
    deleteControlRelaySuccess(state, { payload }: PayloadAction<{ controlRelayId: ControlRelay['id'] }>) {
      state.loading.delete = false;
    },
    deleteControlRelayFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.delete = false;
      state.error = error;
    },
    fetchSubscriberIdsWithRelaysInit: (
      state,
      action: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: ControlRelay['businessUnitId'];
          model: ControlRelay['model'];
          from?: number;
          to?: number;
        }
      >
    ) => {
      state.subscriberIds = [];
      state.loading.unitIds = true;
    },
    fetchSubscriberIdsWithRelaysSuccess: (state, { payload }: PayloadAction<number[]>) => {
      state.loading.unitIds = false;
      state.subscriberIds = updateArray(state.subscriberIds, payload);
    },
    fetchSubscriberIdsWithRelaysFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.unitIds = false;
      state.error = payload;
    },
    //create control relay
    createControlRelayInit(state, { payload }: PayloadAction<Partial<ControlRelay> & { path?: string }>) {
      state.loading.create = true;
    },
    createControlRelaySuccess(state) {
      state.loading.create = false;
    },
    createControlRelayFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.create = false;
      state.error = payload;
    },
    updateControlRelayStateInit(
      state,
      {
        payload,
      }: PayloadAction<{
        controlRelayId: ControlRelay['id'];
        relayId: Relay['id'];
      }>
    ) {
      state.loading.switchRelay = true;
      state.error = null;
    },
    updateControlRelayStateSuccess(
      state,
      { payload }: PayloadAction<{ controlRelayId: ControlRelay['id']; relayId: Relay['id'] }>
    ) {
      state.loading.switchRelay = false;
    },

    updateControlRelayStateFailed(
      state,
      {
        payload: { error },
      }: PayloadAction<{
        error: ResponseError;
      }>
    ) {
      state.loading.switchRelay = false;
      state.error = error;
    },

    copyControlRelaySettingsInit(
      state,
      { payload }: PayloadAction<{ buId: ControlRelay['businessUnitId']; values: CopySettingsPayload }>
    ) {
      state.loading.copySettings = true;
    },
    copyControlRelaySettingsSuccess(state) {
      state.loading.copySettings = false;
    },
    copyControlRelaySettingsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.copySettings = false;
      state.error = payload;
    },

    resetStatus: state => {
      state.status = null;
    },

    resetControlRelayData(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getControlRelaysState = (state: AES.RootState) => state.controlRelays;

const controlRelaySelectors = adapter.getSelectors();

const getControlRelayById = (id: ControlRelay['id']) =>
  createDraftSafeSelector(
    getControlRelaysState,
    state => controlRelaySelectors.selectById(state.data, id) as ControlRelay
  );

export const selectors = {
  getControlRelaysState,
  getControlRelaysList: createDraftSafeSelector(getControlRelaysState, state =>
    controlRelaySelectors.selectAll(state.data)
  ),
  getControlRelayById,
  getControlRelaysLoaders: createDraftSafeSelector(getControlRelaysState, state => state.loading),
  getControlRelaysError: createDraftSafeSelector(getControlRelaysState, state => state.error),
  getControlRelaysStatus: createDraftSafeSelector(getControlRelaysState, state => state.status),
  getSubscriberIdsWithRelays: createDraftSafeSelector(getControlRelaysState, state =>
    state.subscriberIds.flat().map(ids => ids)
  ),
};

export const fetchControlRelaysEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchControlRelaysListInit.match(action)),
    mergeMap(({ payload: { model, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<ControlRelay>>(api.controlRelays.all(model, searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchControlRelaysListSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchControlRelaysListFailed(error));
        })
      );
    })
  );

const fetchControlRelayByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchControlRelayByIdInit.match),
    mergeMap(({ payload: { id } }) =>
      http.getJSON<ControlRelay>(api.controlRelays.byId(id)).pipe(
        mergeMap(relay => of(actions.fetchControlRelayByIdSuccess(relay), pageActions.setPageFound())),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchControlRelayByIdFailed(error), pageActions.setPageNotFound());
        })
      )
    )
  );

const updateControlRelayDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateControlRelayDetailsInit.match),
    mergeMap(({ payload: { controlRelayId, values, path } }) =>
      http.put(api.controlRelays.byId(controlRelayId), values).pipe(
        mergeMap(res =>
          of(
            actions.updateControlRelayDetailsSuccess(getResponsePayload(res)),
            path === 'subscriber'
              ? push(appDetailRoutes.subscribersControlRelay(values.businessUnitId as number, values.unitId as number))
              : push(appDetailRoutes.controlRelayDetails(controlRelayId)),
            notificationsActions.enqueue({
              message: 'Control Relay details updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateControlRelayDetailsFailed({ error }));
        })
      )
    )
  );

const deleteControlRelayEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteControlRelayInit.match),
    mergeMap(({ payload: { pagination, controlRelayId, model, path, businessUnitId, unitId } }) =>
      http.delete(api.controlRelays.byId(controlRelayId)).pipe(
        mergeMap(() => {
          const epicActions: AnyAction[] = [
            actions.deleteControlRelaySuccess({ controlRelayId }),
            notificationsActions.enqueue({
              message: `Control Relay ID ${controlRelayId} has been deleted`,
              options: { variant: 'success' },
            }),
          ];
          if (path === 'subscriber' && unitId) {
            epicActions.push(
              subscribersActions.resetSubscriberControlRelay({
                businessUnitId: businessUnitId as number,
                id: unitId as number,
              }),
            );
          } else {
            epicActions.push(actions.fetchControlRelaysListInit({ pagination, model }));
          }
          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteControlRelayFailed({ error }));
        })
      )
    )
  );

const fetchSubscriberIdsWithRelaysEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberIdsWithRelaysInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<number>>(
          api.controlRelays.unitIds(payload.businessUnitId, payload.model, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchSubscriberIdsWithRelaysSuccess(res.content),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchSubscriberIdsWithRelaysFailed(error));
          })
        );
    })
  );

const createControlRelayEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createControlRelayInit.match),
    mergeMap(({ payload }) =>
      http.post(api.controlRelays.create, payload).pipe(
        mergeMap(() =>
          of(
            actions.createControlRelaySuccess(),
            notificationsActions.enqueue({
              message: 'Control Relay created',
              options: { variant: 'success' },
            }),
            payload.path === 'subscriber'
              ? push(
                appDetailRoutes.subscribersControlRelay(payload?.businessUnitId as number, payload.unitId as number)
              )
              : push(appRoutes.controlRelays)
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.createControlRelayFailed(error));
        })
      )
    )
  );

const updateControlRelayStateEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateControlRelayStateInit.match),
    mergeMap(({ payload: { controlRelayId, relayId } }) =>
      http.post(api.controlRelays.updateRelayState(controlRelayId, relayId as number)).pipe(
        mergeMap(() =>
          of(
            actions.updateControlRelayStateSuccess({ controlRelayId, relayId }),
            notificationsActions.enqueue({
              message: 'Control Relay State Change Requested',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateControlRelayStateFailed({ error }));
        })
      )
    )
  );

const copyControlRelaySettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.copyControlRelaySettingsInit.match),
    mergeMap(({ payload: { buId, values } }) =>
      http.post(api.controlRelays.copySettings(buId), { ...values }).pipe(
        mergeMap(() =>
          of(
            actions.copyControlRelaySettingsSuccess(),
            notificationsActions.enqueue({
              message: `Control Relay settings copied to ${values.unitIds.length} units `,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.copyControlRelaySettingsFailed(error));
        })
      )
    )
  );

export const epics = combineEpics(
  fetchControlRelaysEpic,
  fetchControlRelayByIdEpic,
  updateControlRelayDetailsEpic,
  deleteControlRelayEpic,
  fetchSubscriberIdsWithRelaysEpic,
  createControlRelayEpic,
  updateControlRelayStateEpic,
  copyControlRelaySettingsEpic
);

export const allEpics = {
  fetchControlRelaysEpic,
  fetchControlRelayByIdEpic,
  updateControlRelayDetailsEpic,
  deleteControlRelayEpic,
  fetchSubscriberIdsWithRelaysEpic,
  createControlRelayEpic,
  updateControlRelayStateEpic,
  copyControlRelaySettingsEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      controlRelays: typeof actions;
    }
    export interface RootState {
      controlRelays: ControlRelayState;
    }
    export interface Selectors {
      controlRelays: typeof selectors;
    }
  }
}
