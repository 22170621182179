import { Theme, createStyles } from '@material-ui/core';

import { colors } from '~styles/colors';

import { matchStyles } from '~utils';

export const styles = ({ palette: { type, primary, greyColor, blueColor } }: Theme) => {
  const getStyles = matchStyles(type);

  return createStyles({
    root: {
      textTransform: 'none',
      opacity: 1,
      color: getStyles(greyColor.main, greyColor.dark),
      minHeight: 40,
      minWidth: 0,
      backgroundColor: getStyles(blueColor.dark, greyColor.light),
      fontWeight: 500,
      boxShadow: colors.BOX_SHADOW,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: '6px 24px 4px',
      borderBottom: '2px solid transparent',
      marginRight: 2,
      '& .counter': {
        paddingLeft: 16,
        fontWeight: 400,
      },
    },
    selected: {
      color: primary.main,
      backgroundColor: getStyles(blueColor.main, greyColor.light),
      borderBottom: `2px solid ${primary.main}`,
    },
  });
};
