import { AnyAction, Store } from 'redux';
import { of, Observable, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';

import { api } from '~constants';
import { getResponseError, shouldShowNotification } from '~utils';

export class HTTP {
  private accessToken: string | null = null;

  private store: Store<AES.RootState> | null = null;

  private actions: AES.Actions | null = null;

  private selectors: AES.Selectors | null = null;

  private get headers(): { [key: string]: string } {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };
    const token = this.store?.getState()?.auth?.accessToken || this.accessToken;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  }

  public setAuthToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  public setStore({ store, actions, selectors }: { store: Store; actions: AES.Actions; selectors: AES.Selectors }) {
    this.store = store;
    this.actions = actions;
    this.selectors = selectors;
  }

  public call(urlOrRequest: string | AjaxRequest): Observable<AjaxResponse> {
    let options;

    if (typeof urlOrRequest === 'string') {
      options = urlOrRequest;
    } else {
      const { headers, ...rest } = urlOrRequest;

      options = {
        headers: { ...this.headers, ...(headers || {}) },
        ...rest,
      };
    }

    return of(null).pipe(
      delay(300),
      mergeMap(() => ajax(options)),
      catchError((err: AjaxError) => {
        const {
          request: { url, method },
        } = err;
        const error = getResponseError(err);

        if (
          [401, 504].includes(error.status) &&
          ![api.auth.login].includes(url as string) &&
          !this.selectors?.auth.isLogoutProcessing(this.store?.getState() as AES.RootState)
        ) {
          this.store?.dispatch(this.actions?.auth.logoutSuccess() as AnyAction);
        }

        if (shouldShowNotification(url, method) && this.store && this.actions) {
          this.store.dispatch(
            this.actions.notifications.enqueue({
              message: error.message,
              key: error.message,
              options: { variant: 'error' },
            })
          );
        }

        return throwError(err);
      })
    );
  }

  post(url: string, body?: unknown, headers?: Record<string, unknown>): Observable<AjaxResponse> {
    return this.call({
      method: 'POST',
      url,
      body,
      headers,
    });
  }

  put(url: string, body?: unknown, headers?: Record<string, unknown>): Observable<AjaxResponse> {
    return this.call({
      method: 'PUT',
      url,
      body,
      headers,
    });
  }

  patch(url: string, body?: unknown, headers?: Record<string, unknown>): Observable<AjaxResponse> {
    return this.call({
      method: 'PATCH',
      url,
      body,
      headers,
    });
  }

  delete(url: string, headers?: Record<string, unknown>): Observable<AjaxResponse> {
    return this.call({
      method: 'DELETE',
      url,
      headers,
    });
  }

  get(url: string, headers?: Record<string, unknown>): Observable<AjaxResponse> {
    return this.call({
      method: 'GET',
      url,
      headers,
    });
  }

  getJSON<T>(url: string, headers?: Record<string, unknown>, options: AjaxRequest = {}): Observable<T> {
    return this.call({
      method: 'GET',
      url,
      responseType: 'json',
      headers,
      ...options,
    }).pipe(map(({ response }) => response));
  }
}

export const http = new HTTP();
