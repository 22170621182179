import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StompMessage, stomp, SubscriptionType } from '~services';
import { selectors } from '~store';

export const useSubscription = <T extends unknown>(
  topic: string,
  callback: (msg: StompMessage<T>) => void,
  type: SubscriptionType = 'queue',
  deps: unknown[] = []
) => {
  const isConnected = useSelector(selectors.app.isWebsocketConnected);

  useEffect(() => {
    if (isConnected) {
      stomp.unsubscribe(topic);
      stomp.subscribe(topic, callback, type);
    }

    return () => {
      stomp.unsubscribe(topic);
    };
  }, [isConnected, ...deps]);
};
