import { Title, Chart as DevXChart, Legend, PieSeries } from '@devexpress/dx-react-chart-material-ui';
import { Theme, WithStyles, withStyles, Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import { sum } from 'ramda';
import { Fragment } from 'react';

const styles = ({ palette: { background, primary, text }, spacing }: Theme) => ({
  chart: {
    display: 'inline-block',
    padding: spacing(3),
    marginBottom: spacing(2),
    background: background.paper,
    '& #center-center': {
      height: 250,
      minWidth: 500,
    },
  },
  titleText: {
    textAlign: 'left',
    color: primary.main,
    fontWeight: 500,
  } as CSSProperties,
  axisLabel: {
    fontStyle: 'italic',
  },
  pieText: {
    fill: text.primary,
  },
  pieLine: {
    stroke: text.primary,
    opacity: 0.7,
  },
  rtl: {
    direction: 'rtl',
  } as CSSProperties,
});

export interface PieData {
  device: string;
  signals: number;
}

export interface PieChartProps extends WithStyles<typeof styles> {
  title: string;
  data: PieData[];
}

type ChartRootProps = WithStyles<typeof styles> & DevXChart.RootProps;
const ChartRootComponent = withStyles(styles)(({ classes, children, ...restProps }: ChartRootProps) => (
  <Grid {...restProps} className={classes.chart}>
    {children}
  </Grid>
));

type TextProps = WithStyles<typeof styles> & Title.TextProps;
const TextComponent = withStyles(styles)(({ classes, ...restProps }: TextProps) => (
  <Title.Text {...restProps} className={classes.titleText} />
));

type PieSeriesPointProps = WithStyles<typeof styles> & PieSeries.PointProps;
const PieSeriesPoint = withStyles(styles)(({ classes, ...restProps }: PieSeriesPointProps) => {
  const lineSize = 115;
  const { startAngle, endAngle, val, value, arg, maxRadius: r, innerRadius } = restProps;
  const angle = startAngle + (endAngle - startAngle) / 2 - Math.PI / 2;
  const rMid = r - (r * (1 - innerRadius)) / 2;
  const x = Math.cos(angle) * rMid;
  const y = Math.sin(angle) * rMid;
  const rtl = x > 0;
  const dx = (rtl && lineSize) || -115;
  return (
    <Fragment>
      <PieSeries.Point {...restProps} />
      <g transform={`translate(${arg} ${val})`}>
        <line x1={x} y1={y} x2={x + dx} y2={y} className={classes.pieLine} />
        <text
          x={x + dx}
          y={y - 5}
          className={clsx(classes.pieText, {
            [classes.rtl]: rtl,
          })}
        >
          {Math.round(value * 100)}%
        </text>
      </g>
    </Fragment>
  );
});

export const PieChart = withStyles(styles)(({ title, data: inputData }: PieChartProps) => {
  const data = (total =>
    inputData.map(({ device, signals }) => ({
      device,
      signals: signals / total,
    })))(sum(inputData.map(item => item.signals)));
  return (
    <DevXChart data={data} rootComponent={ChartRootComponent}>
      <Title text={title} textComponent={TextComponent} />
      <Legend position="right" />
      <PieSeries innerRadius={0.85} pointComponent={PieSeriesPoint} valueField={'signals'} argumentField="device" />
    </DevXChart>
  );
});
