import { StyleRules, Theme, ButtonClassKey } from '@material-ui/core';

import { matchStyles } from '~utils';

type ButtonStyles = Partial<StyleRules<ButtonClassKey>>;

export const MuiButton = ({ palette }: Theme): ButtonStyles => {
  const getStyles = matchStyles(palette.type);
  return {
    root: {
      padding: '8px 24px',
      minWidth: 100,
      boxShadow: 'none',
      color: palette.primary.main,
      '&:hover': {
        color: palette.primary.light,
        backgroundColor: palette.transparentColor.main,
      },
      '&[disabled]': {
        color: getStyles(palette.greyColor.dark, palette.greyColor.main),
      },
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      color: getStyles(palette.background.default, palette.whiteColor.main),
      '&:hover': {
        color: palette.background.default,
        backgroundColor: palette.primary.light,
      },

      '&[disabled]': {
        color: palette.background.default,
        backgroundColor: palette.greyColor.dark,
      },
    },
    outlinedPrimary: {
      padding: '7px 24px',
      borderColor: palette.primary.main,
      '&:hover': {
        borderWidth: 1,
        borderColor: palette.primary.light,
        color: palette.primary.light,
        backgroundColor: palette.transparentColor.main,
      },
      '&[disabled]': {
        borderWidth: 1,
        borderColor: getStyles(palette.greyColor.dark, palette.greyColor.main),
      },
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: palette.transparentColor.main,
      },
    },
    sizeSmall: {
      minWidth: 50,
      fontSize: 12,
    },
    outlinedSizeSmall: {
      padding: '1px 16px',
    },
    containedSizeSmall: {
      padding: '2px 16px',
    },
    label: {
      textTransform: 'none',
      fontWeight: 600,
    },
  };
};
