import { createStyles, Typography, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

import { PagePanel } from '~components';
import { appRoutes } from '~constants';
import { FullHeightPage } from '~hocs';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    display: 'flex',
    margin: 'auto',
  },
  content: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
  },
});

export const NotFound = FullHeightPage(
  withStyles(styles)(({ classes }: WithStyles<typeof styles>) => (
    <div className={classes.root}>
      <PagePanel title="Go back" backTo={appRoutes.root} />
      <div className={classes.content}>
        <Typography variant="h2" color="primary" className={classes.title}>
          Not found
        </Typography>
      </div>
    </div>
  ))
);
