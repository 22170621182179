import { Button, WithStyles, withStyles } from '@material-ui/core';

import { styles } from './SearchInput.styles';

import { FormTextField, Icon } from '~ui-kit';

export type SearchBy = 'username' | 'id';

type SearchInputProps = WithStyles<typeof styles> & {
  inputName: string;
  isValues: boolean;
  disabled?: boolean;
  searchBy: SearchBy;
  onFind?: (values) => void;
  onClean?: () => void;
  cleanable?: boolean;
  onKeyPress?: (values) => void;
}

export const SearchInput = withStyles(styles)((
  { classes, isValues, inputName, searchBy, onClean, cleanable, disabled = false, onKeyPress }: SearchInputProps
) => (
  <div className={classes.root}>
    <Icon icon="search" />

    <FormTextField
      className={classes.input}
      name={inputName}
      label={`Search by ${searchBy === 'id' ? 'ID' : 'Username'}`}
      cleanable={cleanable}
      onClean={onClean}
      disabled={disabled}
      onKeyPress={onKeyPress}
    />

    <Button className={classes.button} type="submit" variant="contained" color="primary" size="small" disabled={!isValues}>
      Find
    </Button>
  </div>
));
