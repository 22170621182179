import { Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { AppHeader } from '../AppHeader/AppHeader';
import { AppPanel } from '../AppPanel/AppPanel';
import { SideNav } from '../SideNav/SideNav';

import { useWindowSize } from '~hooks';

const useLayoutStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  main: {
    flexGrow: 1,
    minWidth: 0,
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3, 2, 3),
  },
}));

export const Layout = ({ children }) => {
  const layoutClasses = useLayoutStyles();
  const [contentHeight, setContentHeight] = useState('auto');
  const [headerRef, appPanelRef] = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const size = useWindowSize();

  useEffect(() => {
    const headerHeight = headerRef.current?.clientHeight;
    const appPanelHeight = appPanelRef.current?.clientHeight;

    setContentHeight(`calc(100% - ${headerHeight}px - ${appPanelHeight}px)`);
  }, [size?.width, size?.height]);

  return (
    <div className={layoutClasses.root}>
      <SideNav />
      <main className={clsx(layoutClasses.main)}>
        <AppHeader ref={headerRef} />
        <AppPanel ref={appPanelRef} />

        <Container className={layoutClasses.content} style={{ height: contentHeight }} maxWidth="xl">
          {children}
        </Container>
      </main>
    </div>
  );
};
