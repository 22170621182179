import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { combineEpics, ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, mergeMap, catchError } from 'rxjs/operators';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { sortAlarmAutomationIpAddresses, updateAlarmAutomationWithStatuses } from './utils';

import { api } from '~constants';
import {
  AlarmAutomation,
  ResponseError,
  PaginationResponse,
  PaginationRequestPayload,
  AlarmAutomationPrimarySocket,
  AlarmAutomationStatuses,
} from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload } from '~utils';

export const adapter = createEntityAdapter<AlarmAutomation>({
  selectId: aa => aa.id,
});

export interface AlarmAutomationState {
  data: ReturnType<typeof adapter.getInitialState>;
  loading: {
    list: boolean;
    details: boolean;
    create: boolean;
    edit: boolean;
  };
  error?: ResponseError | null;
  primarySockets: AlarmAutomationPrimarySocket[];
  status: 'created' | 'edited' | null;
}

export const initialState: AlarmAutomationState = {
  data: adapter.getInitialState(),
  primarySockets: [],
  loading: {
    list: false,
    details: false,
    create: false,
    edit: false,
  },
  error: null,
  status: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'alarmAutomation',
  initialState,
  reducers: {
    // fetch Alarm Automation
    fetchAlarmAutomationInit: (state, payload: PayloadAction<PaginationRequestPayload>) => {
      state.loading.list = true;
    },
    fetchAlarmAutomationSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<AlarmAutomation>>
    ) => {
      state.loading.list = false;
      const sortedAA = sortAlarmAutomationIpAddresses(content);

      if (number === 0) {
        adapter.setAll(state.data, sortedAA);
      } else {
        adapter.addMany(state.data, sortedAA);
      }
    },
    fetchAlarmAutomationFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.error = payload;
    },

    // fetch Alarm Automation by id
    fetchAlarmAutomationByIdInit: (state, { payload }: PayloadAction<AlarmAutomation['id']>) => {
      state.error = null;
      state.loading.details = true;
    },
    fetchAlarmAutomationByIdSuccess: (state, { payload }: PayloadAction<AlarmAutomation>) => {
      adapter.upsertOne(state.data, payload);
      state.loading.details = false;
    },
    fetchAlarmAutomationByIdFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details = false;
      state.error = action.payload;
    },

    // fetch Alarm Automation primary sockets
    fetchAlarmAutomationPrimarySocketsInit: state => {
      state.error = null;
      state.loading.details = true;
    },
    fetchAlarmAutomationPrimarySocketsSuccess: (state, { payload }: PayloadAction<AlarmAutomationPrimarySocket[]>) => {
      state.primarySockets = payload;
      state.loading.details = false;
    },
    fetchAlarmAutomationPrimarySocketsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details = false;
      state.error = action.payload;
    },

    // create Alarm Automation
    createAlarmAutomationInit: (state, { payload }: PayloadAction<Partial<AlarmAutomation>>) => {
      state.error = null;
      state.loading.create = true;
    },
    createAlarmAutomationSuccess: state => {
      state.status = 'created';
      state.loading.create = false;
    },
    createAlarmAutomationFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.create = false;
      state.error = payload;
    },

    // update Alarm Automation
    updateAlarmAutomationInit: (state, { payload }: PayloadAction<Partial<AlarmAutomation>>) => {
      state.error = null;
      state.loading.edit = true;
    },
    updateAlarmAutomationSuccess: (state, { payload }: PayloadAction<AlarmAutomation>) => {
      adapter.updateOne(state.data, {
        id: payload.id,
        changes: payload,
      });
      state.status = 'edited';
      state.loading.edit = false;
    },
    updateAlarmAutomationFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.edit = false;
      state.error = payload;
    },

    // update Alarm Automation status
    updateAlarmAutomationWithStatuses(state, { payload }: PayloadAction<AlarmAutomationStatuses>) {
      adapter.setAll(
        state.data,
        updateAlarmAutomationWithStatuses(adapter.getSelectors().selectAll(state.data), payload)
      );
    },

    // delete Alarm Automation
    deleteAlarmAutomationInit: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.error = null;

      adapter.updateOne(state.data, {
        id: payload.id,
        changes: { meta: { isLoading: true } },
      });
    },
    deleteAlarmAutomationSuccess: (state, { payload }: PayloadAction<AlarmAutomation['id']>) => {
      adapter.removeOne(state.data, payload);
    },
    deleteAlarmAutomationFailed: (
      state,
      { payload }: PayloadAction<{ error: ResponseError; id: AlarmAutomation['id'] }>
    ) => {
      state.error = payload.error;
      adapter.updateOne(state.data, {
        id: payload.id,
        changes: { meta: { isLoading: false } },
      });
    },

    // Reset alarm automation status
    resetAlarmAutomationStatus(state) {
      state.status = null;
    },

    resetAlarmAutomation: state => {
      Object.assign(state, initialState);
    },
  },

  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getAlarmAutomationState = (state: AES.RootState) => state.alarmAutomation;

const alarmAutomationSelectors = adapter.getSelectors();

export const selectors = {
  getAlarmAutomationState,

  getAlarmAutomationLoading: createDraftSafeSelector(getAlarmAutomationState, state => state.loading),
  isAlarmAutomationCreated: createDraftSafeSelector(getAlarmAutomationState, state => state.status === 'created'),
  isAlarmAutomationEdited: createDraftSafeSelector(getAlarmAutomationState, state => state.status === 'edited'),
  getAlarmAutomationData: createDraftSafeSelector(getAlarmAutomationState, state =>
    alarmAutomationSelectors.selectAll(state.data)
  ),
  getAlarmAutomationById: (id: AlarmAutomation['id']) =>
    createDraftSafeSelector(getAlarmAutomationState, state => alarmAutomationSelectors.selectById(state.data, id)),
  getAlarmAutomationError: createDraftSafeSelector(getAlarmAutomationState, state => state.error),
  getPrimarySockets: createDraftSafeSelector(getAlarmAutomationState, state => state.primarySockets),
};

const fetchAlarmAutomation = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAlarmAutomationInit.match),
    mergeMap(() =>
      http.getJSON<PaginationResponse<AlarmAutomation>>(api.alarmAutomation.all).pipe(
        mergeMap(values =>
          of(
            actions.fetchAlarmAutomationSuccess(values),
            pageActions.setPagePagination(extractPaginationValues(values))
          )
        ),
        catchError(err => of(actions.fetchAlarmAutomationFailed(getResponseError(err))))
      )
    )
  );

const fetchAlarmAutomationById = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAlarmAutomationByIdInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<AlarmAutomation>(api.alarmAutomation.byId(payload)).pipe(
        mergeMap(values => of(actions.fetchAlarmAutomationByIdSuccess(values), pageActions.setPageFound())),
        catchError(err =>
          of(actions.fetchAlarmAutomationByIdFailed(getResponseError(err)), pageActions.setPageNotFound())
        )
      )
    )
  );

const fetchAlarmAutomationPrimarySockets = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAlarmAutomationPrimarySocketsInit.match),
    mergeMap(() =>
      http.getJSON<AlarmAutomationPrimarySocket[]>(api.alarmAutomation.primarySockets).pipe(
        mergeMap(values => of(actions.fetchAlarmAutomationPrimarySocketsSuccess(values))),
        catchError(err => of(actions.fetchAlarmAutomationPrimarySocketsFailed(getResponseError(err))))
      )
    )
  );

const createAlarmAutomationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createAlarmAutomationInit.match),
    mergeMap(({ payload }) =>
      http.post(api.alarmAutomation.all, payload).pipe(
        mergeMap(() => of(actions.createAlarmAutomationSuccess())),
        catchError(err => of(actions.createAlarmAutomationFailed(getResponseError(err))))
      )
    )
  );

const updateAlarmAutomation = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateAlarmAutomationInit.match),
    mergeMap(({ payload }) =>
      http.put(api.alarmAutomation.byId(payload.id as number), payload).pipe(
        mergeMap(values =>
          of(
            actions.updateAlarmAutomationSuccess(getResponsePayload(values)),
            notificationsActions.enqueue({
              message: 'Alarm Automation updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateAlarmAutomationFailed(getResponseError(err))))
      )
    )
  );

const deleteAlarmAutomationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteAlarmAutomationInit.match),
    mergeMap(({ payload: { id } }) =>
      http.delete(api.alarmAutomation.byId(id)).pipe(
        mergeMap(() => of(actions.deleteAlarmAutomationSuccess(id))),
        catchError(err => of(actions.deleteAlarmAutomationFailed({ error: getResponseError(err), id })))
      )
    )
  );

export const epics = combineEpics(
  fetchAlarmAutomation,
  fetchAlarmAutomationById,
  fetchAlarmAutomationPrimarySockets,
  createAlarmAutomationEpic,
  updateAlarmAutomation,
  deleteAlarmAutomationEpic
);
export const allEpics = {
  fetchAlarmAutomation,
  fetchAlarmAutomationById,
  fetchAlarmAutomationPrimarySockets,
  createAlarmAutomationEpic,
  updateAlarmAutomation,
  deleteAlarmAutomationEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      alarmAutomation: typeof actions;
    }
    export interface RootState {
      alarmAutomation: AlarmAutomationState;
    }
    export interface Selectors {
      alarmAutomation: typeof selectors;
    }
  }
}
