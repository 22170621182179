import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { styles } from './CardDetails.styles';
import { CardStatus } from './CardSummary';

type CustomCardDetailsProps = AccordionDetailsProps &
  WithStyles<typeof styles> & {
    status?: CardStatus;
  };

export const CardDetails = withStyles(styles)(({ status, ...props }: CustomCardDetailsProps) => (
  <MuiAccordionDetails {...props} className={status} />
));
