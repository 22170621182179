import { equals } from 'ramda';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { userHasPermissions } from '~features/users/usersUtils';

import { appRoutes } from '~constants';
import { Permission } from '~models';
import { selectors } from '~store';

export interface ProtectedRouteProps extends RouteProps {
  fallback?: string;
  permissions?: Permission['name'][];
}

export const ProtectedRoute = ({ fallback, permissions, ...props }: ProtectedRouteProps) => {
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
  const isAuthorized = useSelector(selectors.auth.isAuthorized);

  if (!isAuthorized) {
    return <Redirect to={appRoutes.authenticate} />;
  }

  if (!userHasPermissions(currentUser, permissions)) {
    return <Redirect to={appRoutes.notFound} />;
  }

  return <Route {...props} />;
};
