/* eslint-disable newline-per-chained-call */
import { Button } from '@material-ui/core';
import { equals } from 'ramda';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermissions } from '~features/users/usersUtils';

import { PageLoader } from '~components';
import { PermissionName } from '~models';
import { boundActions, selectors } from '~store';
import { Dialog, DialogTitle, DialogContent, DialogActions, FileSelectField } from '~ui-kit';

export const UploadMigratedDBDialog = () => {
  const [files, setFiles] = useState<File[]>([]);
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
  const hasPermissions = userHasPermissions(currentUser, [PermissionName.SETTINGS_VIEW]);
  const { uploadMigratedDB } = useSelector(selectors.app.getAppDialogs, equals);
  const {
    loading: { uploadFile: isLoading },
    status,
  } = useSelector(selectors.app.getDatabaseMigrationState, equals);

  const onCancel = useCallback(
    () =>
      boundActions.app.setAppDialog({
        dialog: 'uploadMigratedDB',
        value: false,
      }),
    []
  );

  const onSubmit = useCallback(() => {
    boundActions.app.uploadDatabaseFileInit({ files });
  }, [files]);

  const onFileRemove = useCallback(() => setFiles([]), [files, setFiles]);

  const onResetStatus = useCallback(() => {
    boundActions.app.resetDatabaseMigrationStatus();
  }, []);

  useEffect(() => {
    if (currentUser && uploadMigratedDB && !hasPermissions) {
      onCancel();
    }

    return () => {
      onResetStatus();
      onFileRemove();
    };
  }, [currentUser, hasPermissions, uploadMigratedDB]);

  useEffect(() => {
    if (status === 'UPLOADED_FILE') {
      onCancel();

      boundActions.app.setAppDialog({
        dialog: 'selectMigratedBU',
        value: true,
      });
    }
  }, [status]);

  return (
    <Dialog open={uploadMigratedDB && hasPermissions} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
      <DialogTitle title="Database migration" />

      <DialogContent>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Fragment>
            <FileSelectField
              helperText={
                'Please select tar.gz file with DB dump and schema. \n Do not close or refresh this window until migration is complete'
              }
              accept=".gz"
              files={files}
              onChange={setFiles}
              onRemove={onFileRemove}
            />
          </Fragment>
        )}
      </DialogContent>

      <DialogActions>
        <Button type="button" variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!files.length || isLoading}
          onClick={onSubmit}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};
