import { createStyles, StyleRules, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 4,
      paddingBottom: 9,
      lineHeight: '14px',
    },
  } as StyleRules);
