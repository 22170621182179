import { Grid, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import selfMonitoringLogo from '~assets/images/SelfMonitoringLogo.svg';

import { styles } from './styles';

import { selectors } from '~store';

export const SelfMonitoringLogo = withStyles(styles)(({ classes, ...props }: WithStyles<typeof styles>) => {
  const isExpanded = useSelector(selectors.layout.isExpanded);

  if (!isExpanded) {
    return <img src={selfMonitoringLogo} className={clsx(classes.logo, { collapsed: !isExpanded })} />;
  }

  return (
    <Grid className={classes.wrapper}>
      <img src={selfMonitoringLogo} className={classes.logo} />

      <span>NFPA Compliant</span>
    </Grid>
  );
});
