import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { FormSwitchFieldProps } from '~ui-kit';

export const GridItem = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(0, 1)} !important`,
  },
}))(Grid);

export const getModeFields = (loaders: Partial<AES.RootState['subscribers']['loading']['settings']>) =>
  [
    {
      label: 'Intellipro Messages',
      name: 'intelliTap',
      text: 'Turn On',
      activeText: 'Turn Off',
      disabled: loaders.modes?.intellitap,
    },
    {
      label: 'Subscriber Repeater Function',
      name: 'repeater',
      text: 'Turn On',
      activeText: 'Turn Off',
      disabled: loaders.modes?.repeater,
    },
    {
      label: 'Telephone Line Cut Function ',
      name: 'lineCut',
      text: 'Turn On',
      activeText: 'Turn Off',
      disabled: loaders.modes?.['line-cut'],
    },
  ] as FormSwitchFieldProps[];
