import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    switch: {
      marginRight: 0,
      marginLeft: -12,
    },
  });
