import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(3),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    switch: {},
  });
