import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    accordion: {
      margin: 0,
      padding: 0,
      transition: 'none',

      '&.Mui-expanded': {
        margin: 0,
        boxShadow: 'none !important'
      }
    },

    nestedAccordion: {
      margin: 0,
      backgroundColor: palette.blueColor.dark,
      borderBottom: `1px solid ${palette.blueColor.main}`,

      '&:last-of-type': {
        borderBottom: 'none'
      },
    },

    accordionIcon: {
      width: '100%',
      borderRadius: 0,
    },

    accordionSummary: {
      '& .Mui-expanded': {
        margin: 0,
      },

      '&.disabled .MuiIconButton-edgeEnd': {
        visibility: 'hidden',
      },

      '& .MuiIconButton-edgeEnd': {
        marginRight: 0,
      },

      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
      },
    },

    mainAccordionDetails: {
      maxHeight: 300,
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    accordionDetails: {
      flexDirection: 'column',
      padding: 0,

      '& > label': {
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        marginRight: spacing(6.5),

        '& > .MuiFormControlLabel-label': {
          width: '100%',
        }
      }
    },
  });
