import { Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PropsWithChildren, forwardRef } from 'react';

import { CardStatus } from '../Card/CardSummary';

import { styles } from './StatusText.styles';

type StatusTextProps = PropsWithChildren<WithStyles<typeof styles>> & {
  status: CardStatus;
};

export const StatusText = withStyles(styles)(
  forwardRef(({ status, children, classes, ...props }: StatusTextProps, ref) => (
    <Typography
      {...props}
      ref={ref as () => HTMLDivElement | null}
      variant="body1"
      className={clsx(classes.root, status)}
    >
      {children}
    </Typography>
  ))
);
