import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {
      '& > :nth-child(3)': {
        paddingBottom: 0,
      },
    },

    range: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px 0 30px'
    },

    button: {
      flexShrink: 0,
      height: 'fit-content'
    }
  });
