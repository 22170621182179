import { Theme, createStyles } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 1, 1, 3),
      borderBottom: `1px solid ${matchStyles(theme.palette.type)(
        theme.palette.greyColor.dark,
        theme.palette.greyColor.main
      )}`,
    },
  });
