import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      margin: theme.spacing(3, 0),
    },
    inner: {
      display: 'flex',
      flexGrow: 0,
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      borderRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    icon: {
      background: `${theme.palette.text.primary} !important`,
      '&:hover': {
        background: `${theme.palette.text.primary} !important`,
      },
    },
    description: {
      margin: 0,
      paddingLeft: theme.spacing(1),
    },
  });
