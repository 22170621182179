import { createStyles, Theme } from '@material-ui/core';

import { matchStyles } from '~utils/styles';

export const styles = (theme: Theme) => {
  const styles = matchStyles(theme.palette.type);
  const greyColor = styles(theme.palette.greyColor.light, theme.palette.greyColor.dark);

  return createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2, 0),
    },
    wrapper: {},
    rail: {
      height: theme.spacing(0.5),
      width: '100%',
      backgroundColor: greyColor,
    },
    markLabel: {
      color: greyColor,
      fontStyle: 'italic',
      fontSize: '11px',
    },
    track: {
      height: theme.spacing(0.5),
    },
    valueLabel: {
      fontSize: '11px',
      top: -16,
      lineHeight: '20px',
      '& *': {
        background: 'transparent',
        color: greyColor,
        fontStyle: 'italic',
      },
    },
    mark: {
      display: 'none',
    },
  });
};
