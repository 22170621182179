import { mergeDeepRight } from 'ramda';

import { IconType } from '~icons';
import { AppTtlThresholds, Subscriber, SubscriberHardware, UnitRoute, UnitType } from '~models';
import { CardStatus } from '~ui-kit';
import { hoursAndMinutes, updateArrayWithCallback } from '~utils';

type SubscriberMeta = {
  status: {
    value: CardStatus;
    title: string;
  };
  hybrid?: string;
  iconType: IconType;
};

export const getUnitIconByUnitType = (type: UnitType): IconType => {
  switch (type) {
    case 'HYBRID':
      return 'hybrid-outlined';

    case 'IP_LINK':
      return 'ip-link-outlined';

    default:
      return 'subscribers';
  }
};

export const getPeersMeta = (subscriber): SubscriberMeta => {
  if (!subscriber) {
    // TODO: rename iconType response after updating IconType
    return { status: { value: 'default', title: 'N/A' }, iconType: 'subscribers' };
  }

  const icon = getUnitIconByUnitType(subscriber?.unitType);

  if (subscriber.quality.label === 'Good') {
    return { status: { value: 'success', title: `${subscriber.quality.label}` }, iconType: icon };
  }

  if (subscriber.quality.label === 'Marginal') {
    return { status: { value: 'warning', title: `${subscriber.quality.label}` }, iconType: icon };
  }

  return { status: { value: 'default', title: `${subscriber.quality.label}` }, iconType: icon };
};

// TODO: rename response after updating IconType
const getSubscriberIconType = (type: SubscriberHardware['type']): IconType => {
  switch (type) {
    case 'Burg':
      return 'burglary-outlined';

    case 'Fire':
      return 'fire-outlined';

    default:
      return 'subscribers';
  }
};

export const getSubscriberMeta = (subscriber?: Subscriber): SubscriberMeta => {
  if (!subscriber) {
    // TODO: rename iconType response after updating IconType
    return { status: { value: 'default', title: 'N/A' }, hybrid: 'N/A', iconType: 'subscribers' };
  }

  const hybrid = subscriber?.subscriberInfo?.hardware?.isHybrid || subscriber?.isHybrid ? 'Yes' : 'No';

  const iconType = getSubscriberIconType(subscriber?.subscriberInfo?.hardware?.type || subscriber?.type);

  if (subscriber?.status === 'NORMAL') {
    return { status: { value: 'success', title: 'Normal' }, hybrid, iconType };
  }

  if (subscriber.status === 'INACTIVE') {
    return { status: { value: 'default', title: 'Inactive' }, hybrid, iconType };
  }

  return { status: { value: 'error', title: 'Off normal' }, hybrid, iconType };
};

export const updateSubscribersWithRoutes = (
  subscribers: Subscriber[],
  routes: UnitRoute[],
  businessUnitId: Subscriber['businessUnitId'],
  id: Subscriber['id']
): Subscriber[] => {
  const subscriber = subscribers.find(sub => sub.id === id && sub.businessUnitId === businessUnitId) as Subscriber;

  if (!subscriber) {
    return subscribers;
  }

  return updateArrayWithCallback<Subscriber>(
    subscribers,
    { ...subscriber, routes },
    (existing, update) => existing.id === update?.id && existing?.businessUnitId === update.businessUnitId
  );
};

export const updateSubscriberInfo = (subscriber: Subscriber, updates: Partial<Subscriber>) =>
  mergeDeepRight(subscriber, updates) as Subscriber;

type colorValue = {
  color: 'inherit' | 'error';
  value: string;
};

type NonRecommendeValue = {
  checkIn: colorValue;
  status: colorValue;
  alarm: colorValue;
  trouble: colorValue;
  restoral: colorValue;
  intelliTap: colorValue;
  special: colorValue;
  rfCheckIn: colorValue;
  intCheckIn: colorValue;
};

// eslint-disable-next-line complexity
export const getNonRecommendedColorValue = (
  subscriber: Partial<Subscriber>,
  recommended: AppTtlThresholds
): NonRecommendeValue => {
  const nonRecommended: NonRecommendeValue = {
    checkIn: {
      color: subscriber.checkIn === recommended.checkInStatusSpecialTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, subscriber.checkIn as number) || 'N/A',
    },
    status: {
      color: subscriber.status === recommended.checkInStatusSpecialTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, (subscriber.status as unknown) as number) || 'N/A',
    },
    alarm: {
      color: subscriber.alarm === recommended.restoralIntelliTapAlarmTroubleTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, subscriber.alarm as number) || 'N/A',
    },
    trouble: {
      color: subscriber.trouble === recommended.restoralIntelliTapAlarmTroubleTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, subscriber.trouble as number) || 'N/A',
    },
    restoral: {
      color: subscriber.restoral === recommended.restoralIntelliTapAlarmTroubleTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, (subscriber.restoral as unknown) as number) || 'N/A',
    },
    intelliTap: {
      color: subscriber.intelliTap === recommended.restoralIntelliTapAlarmTroubleTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, subscriber.intelliTap as number) || 'N/A',
    },
    special: {
      color: subscriber.special === recommended.checkInStatusSpecialTtl ? 'inherit' : 'error',
      value: hoursAndMinutes(0, subscriber.special as number) || 'N/A',
    },
    rfCheckIn: {
      color:
        subscriber.radioCheckInHrs === recommended.radioCheckinHrs
          ? subscriber.radioCheckInMin === recommended.radioCheckinMins
            ? 'inherit'
            : 'error'
          : 'error',
      value: hoursAndMinutes(subscriber.radioCheckInHrs as number, subscriber.radioCheckInMin as number) || 'N/A',
    },
    intCheckIn: {
      color:
        subscriber.ipCheckInHrs === recommended.ipCheckinHrs
          ? subscriber.ipCheckInMin === recommended.ipCheckinMins
            ? 'inherit'
            : 'error'
          : 'error',
      value: hoursAndMinutes(subscriber.ipCheckInHrs as number, subscriber.ipCheckInMin as number) || 'N/A',
    },
  };

  return nonRecommended;
};
