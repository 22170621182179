import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback, memo } from 'react';

import { SliderField, SliderFieldProps } from '../Fields/SliderField';

type FormSliderFieldProps = Partial<SliderFieldProps> & {
  name: string;
  names?: [string, string];
};

export const FormSliderField = memo(({ name, names, ...props }: FormSliderFieldProps) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: number[]) => {
      setFieldValue(name, value);

      if (names?.length) {
        const [min, max] = value;
        const [minFieldName, maxFieldName] = names;

        setFieldValue(minFieldName, min);
        setFieldValue(maxFieldName, max);
      }
    },
    [...(names || [])]
  );

  return (
    <Field name={name}>
      {({ field: { onChange, onBlur, ...field } }: FieldProps) => (
        <SliderField
          {...props}
          {...field}
          id={name}
          range={props.range as number[]}
          // @ts-ignore
          onChange={handleChange}
        />
      )}
    </Field>
  );
});
