import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    buList: {
      maxHeight: 280,
      overflowY: 'auto'
    },

    divider: {
      margin: spacing(2, -3, 2),
    },

    select: {
      marginBottom: spacing(-3)
    },

    notifications: {
      wordBreak: 'break-word',
    },

    inlineSubtitle: {
      marginTop: spacing(2)
    },

    loadingText: {
      marginTop: spacing(-2),
      textAlign: 'center'
    },

    checkboxItem: {
      width: '50%',
      paddingRight: spacing(2)
    },
  });
