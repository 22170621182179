import { ArgumentScale, EventTracker, ValueScale } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis,
  ValueAxis,
  Chart as DevXChart,
  LineSeries,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Plugin } from '@devexpress/dx-react-core';
import { Theme, WithStyles, withStyles, Grid, createStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { scaleTime } from 'd3-scale';
import { max, min } from 'date-fns';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { DATE_FORMAT } from '~constants/date';

import { GridLine } from './ChartGridLine';

import { selectors } from '~store';
import { FilterOption } from '~ui-kit';
import { formatDate } from '~utils';

const styles = ({ palette: { background, primary, blueColor, greyColor, text }, spacing }: Theme) =>
  createStyles({
    chart: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      background: background.paper,
      overflow: 'hidden',
      paddingTop: spacing(1),
    },
    legend: {
      display: 'flex',
      flexWrap: 'wrap',
    } as CSSProperties,
    legendItem: {
      width: 'auto',
      padding: spacing(0, 2, 0, 0),
    },
    legendLabel: {
      paddingRight: 0,
    },
    titleText: {
      textAlign: 'left',
      color: primary.main,
      fontWeight: 500,
    } as CSSProperties,
    axisLabel: {
      fontStyle: 'italic',
      fontSize: 9,
    },

    tooltip: {
      backgroundColor: blueColor.dark,
      padding: spacing(1, 2),
      borderRadius: spacing(0.5),
      lineHeight: 1.5,
    },

    tooltipArrow: {
      right: 'calc(50% - 10px)',

      '&::after': {
        backgroundColor: blueColor.dark,
      },
    },

    tooltipContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: text.primary,
      fontSize: 14,
    },

    tooltipIndicator: {
      display: 'flex',
      width: spacing(1),
      height: spacing(1),
      borderRadius: '100px',
      marginRight: spacing(1),
    },

    tooltipDate: {
      color: greyColor.main,
      fontStyle: 'italic',
      fontSize: 11,
    },
  });

export type LineData = {
  [key: string]: number | string;
};

export interface SimpleLineChartProps extends WithStyles<typeof styles> {
  data: LineData[];
  argumentField?: string;
  argumentValues: FilterOption[];
  maxValue?: number;
  timeFormat?: string;
  timeStamps?: number[];
}

type ChartRootProps = WithStyles<typeof styles> & DevXChart.RootProps;
const ChartRootComponent = withStyles(styles)(({ classes, children, ...restProps }: ChartRootProps) => (
  <Grid {...restProps} className={classes.chart}>
    {children}
  </Grid>
));

type LegendRootProps = WithStyles<typeof styles> & Legend.RootProps;
const LegendRoot = withStyles(styles)(({ classes, ...restProps }: LegendRootProps) => (
  <Legend.Root className={classes.legend} {...restProps} />
));

type LegendItemProps = WithStyles<typeof styles> & Legend.ItemProps;
const LegendItem = withStyles(styles)(({ classes, ...restProps }: LegendItemProps) => (
  <Legend.Item {...restProps} className={classes.legendItem} />
));

type LegendLabelProps = WithStyles<typeof styles> & Legend.LabelProps;
const LegendLabel = withStyles(styles)(({ classes, ...restProps }: LegendLabelProps) => (
  <Legend.Label {...restProps} className={classes.legendLabel} />
));

type ArgumentAxisLabelProps = WithStyles<typeof styles> & ArgumentAxis.LabelProps;
const ArgumentAxisLabel = withStyles(styles)(({ classes, ...restProps }: ArgumentAxisLabelProps) => (
  <ArgumentAxis.Label {...restProps} className={classes.axisLabel} />
));

type ValueAxisLabelProps = WithStyles<typeof styles> & ValueAxis.LabelProps;
const ValueAxisLabel = withStyles(styles)(({ classes, ...restProps }: ValueAxisLabelProps) => (
  <ValueAxis.Label {...restProps} className={classes.axisLabel} />
));

type TooltipSheetProps = WithStyles<typeof styles> & Tooltip.SheetProps;
const TooltipSheet = withStyles(styles)(({ classes, children }: TooltipSheetProps) => (
  <Tooltip.Sheet className={classes.tooltip}>{children}</Tooltip.Sheet>
));

type TooltipContentProps = WithStyles<typeof styles> & Tooltip.ContentProps;
const TooltipContent = withStyles(styles)(
  ({ color, date, classes, text }: { color?: string; date: string | null } & TooltipContentProps) => (
    <Fragment>
      <div className={classes.tooltipContent}>
        <span className={classes.tooltipIndicator} style={{ backgroundColor: color }} />
        <span>{`${text} events`}</span>
      </div>
      <span className={classes.tooltipDate}>{date}</span>
    </Fragment>
  )
);

type TooltipArrowProps = WithStyles<typeof styles> & Tooltip.ArrowProps;
const TooltipArrow = withStyles(styles)(({ classes, ...props }: TooltipArrowProps) => (
  <Tooltip.Arrow className={classes.tooltipArrow} {...props} />
));

export const SimpleLineChart = withStyles(styles)(
  ({
    data = [],
    argumentValues = [],
    argumentField = '',
    maxValue = 0,
    timeFormat = DATE_FORMAT,
    timeStamps = [],
  }: SimpleLineChartProps) => {
    const modifyDomain = () => [0, maxValue];

    const modifyTimeDomain = () => [min(timeStamps), max(timeStamps)];

    if (!data?.length) {
      return null;
    }

    const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

    return (
      <DevXChart data={data} rootComponent={ChartRootComponent}>
        <Legend position="bottom" rootComponent={LegendRoot} itemComponent={LegendItem} labelComponent={LegendLabel} />
        <ArgumentAxis indentFromAxis={18} tickSize={8} labelComponent={ArgumentAxisLabel} />
        <ArgumentScale factory={scaleTime} modifyDomain={modifyTimeDomain} />
        <ValueAxis gridComponent={GridLine} labelComponent={ValueAxisLabel} />
        <ValueScale modifyDomain={modifyDomain} />
        <EventTracker />
        <Tooltip
          sheetComponent={TooltipSheet}
          contentComponent={props => (
            <TooltipContent
              // @ts-ignore
              color={argumentValues[props.targetItem.order].color}
              date={formatDate(data[props.targetItem.point].timestamps as number, false, timeFormat, timeZone)}
              {...props}
            />
          )}
          arrowComponent={props => <TooltipArrow {...props} />}
        />
        <Plugin name="series">
          {argumentValues.map(item => (
            <LineSeries
              key={item.value}
              name={item.label as string}
              valueField={item.value as string}
              argumentField={argumentField}
              color={item.color}
            />
          ))}
        </Plugin>
      </DevXChart>
    );
  }
);
