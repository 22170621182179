import { Typography, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

import { styles } from './ContentField.styles';

export type ContentFieldProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title: ReactNode;
  width?: number | string;
};

export const ContentField = withStyles(styles)(({ width, title, children, classes }: ContentFieldProps) => (
  <div className={clsx('content-field', classes.root)} style={{ width }}>
    <Typography variant="subtitle2" className={classes.title}>
      {title}
    </Typography>

    {children}
  </div>
));
