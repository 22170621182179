import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ElementType } from 'react';

import { FormTimeField, FormTextField, FormTimeFieldProps, FormTextFieldProps } from '~ui-kit';

export const GridItem = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(0, 1)} !important`,
  },
}))(Grid);

const WithGrid = Component => (props: FormTextFieldProps | FormTimeFieldProps) => (
  <GridItem item xs={12} md={6}>
    <Component {...props} />
  </GridItem>
);

export enum EventReporting {
  RADIO_AND_INTERNET = 0,
  INTERNET_ONLY = 1,
  RADIO_ONLY = 2,
  INTERNET_WITH_RADIO_BACKUP = 16,
  RADIO_WITH_INTERNET_BACKUP = 32,
}

export const timingFields = (eventReporting?: number) =>
  [
    {
      name: 'radioCheckin',
      label: 'Radio Check-In Interval',
      helperText: '00:00 - 24:00',
      Component: (props: FormTimeFieldProps) => {
        if (eventReporting === EventReporting.INTERNET_ONLY) {
          return null;
        }

        return (
          <GridItem item xs={12} md={6}>
            <FormTimeField {...props} />
          </GridItem>
        );
      },
    },
    {
      name: 'ipCheckin',
      label: 'IP Check-in Interval',
      helperText: '00:00 - 24:00',
      Component: (props: FormTimeFieldProps) => {
        if (eventReporting === EventReporting.RADIO_ONLY) {
          return null;
        }

        return (
          <GridItem item xs={12} md={6}>
            <FormTimeField {...props} />
          </GridItem>
        );
      },
    },
    {
      name: 'reportDelay',
      label: 'Secondary Alarm Delay',
      type: 'number',
      helperText: '1-330 sec',
      Component: WithGrid(FormTextField),
    },
    {
      name: 'loopResponse',
      label: 'Contact Debounce',
      helperText: '0-2.5 sec',
      Component: WithGrid(FormTextField),
    },
    {
      name: 'ackDelay',
      label: 'Acknowledgement Delay',
      type: 'number',
      helperText: '1-300 sec',
      Component: WithGrid(FormTextField),
    },
  ] as ((FormTextFieldProps | FormTimeFieldProps) & { Component: ElementType })[];
