import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      padding: '0 10px 0 0',
      minHeight: 36,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    icon: {
      background: `${getStyles(theme.palette.greyColor.light, theme.palette.greyColor.dark)} !important`,
    },
    checkedIcon: {
      background: `${theme.palette.primary.main} !important`,
    },
  });
};
