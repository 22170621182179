import { withStyles, WithStyles } from '@material-ui/core';
import { KeyboardTimePickerProps } from '@material-ui/pickers';
import clsx from 'clsx';
import { Field, FieldProps, useFormikContext } from 'formik';

import { TimePickerField } from '../Fields/TimePickerField';
import { Icon } from '../Icon/Icon';

import { styles } from './styles';

export type FormTimePickerFieldProps = Partial<KeyboardTimePickerProps> &
  WithStyles<typeof styles> & {
    name: string;
  };

export const FormTimePicker = withStyles(styles)(({ name, classes, ...props }: FormTimePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field: { onChange, ...field }, meta: { touched, error } }: FieldProps) => (
        <TimePickerField
          {...field}
          {...props}
          type="text"
          onChange={value => {
            setFieldValue(field.name, value);
          }}
          error={touched && Boolean(error)}
          helperText={(touched && error) || props.helperText}
          className={clsx(classes.root)}
          keyboardIcon={<Icon icon="time" />}
        />
      )}
    </Field>
  );
});
