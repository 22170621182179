import { WithStyles, withStyles, Tooltip, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import ulSticker from '~assets/images/ULSticker.svg';

import { styles } from './ULSticker.styles';

import { selectors } from '~store';

export const ULSticker = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const isExpanded = useSelector(selectors.layout.isExpanded);

  if (!isExpanded) {
    return <img src={ulSticker} className={clsx(classes.logo, { collapsed: !isExpanded })} />;
  }

  return (
    <Tooltip
      title={
        'Suitable for use as a {Fire and/or Security-Signaling} {Central-Station and/or Proprietary-Station} Software Receiver'
      }
      placement="right-start"
      arrow
    >
      <Grid className={classes.wrapper}>
        <img src={ulSticker} className={classes.logo} />

        <span>Software Receiver 35PB</span>
      </Grid>
    </Tooltip>
  );
});
