export type RelayState = {
  businessUnitId: number;
  unitId: number;
  controlRelayId: number;
  relayId: number;
  name: string;
  value: boolean;
};

export type Relay = {
  id?: number;
  name: string;
  value?: boolean;
  openIsRed: boolean;
  greenLabel: string;
  redLabel: string;
};

export type ControlRelay = {
  id: number;
  businessUnitId: number;
  unitId: number;
  buName: string;
  model: string;
  description: string;
  isActive: boolean;
  reported: number;
  lastUpdated: number;
  zones: RelayZones[];
  relays: Relay[];
};

export enum RelayZones {
  'DEFAULT' = 'DEFAULT',
  'GREEN' = 'GREEN',
  'RED' = 'RED',
}
