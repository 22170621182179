import { Theme, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { PropsWithChildren } from 'react';

import { PageLoader } from './PageLoader';

import { RefreshButton } from '~ui-kit';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    title: {
      padding: theme.spacing(3, 0),
    },
    inner: {
      height: '100%'
    },
  });

type PageSectionHeadingProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title: string;
  onRefresh?: () => void;
  loading?: boolean;
};

export const PageSection = withStyles(styles)(
  ({ title, classes, children, loading, onRefresh, ...props }: PageSectionHeadingProps) => (
    <div className={classes.root}>
      <Typography variant="h3" color="primary" className={classes.title}>
        {title}
        {onRefresh ? <RefreshButton onClick={onRefresh} loading={loading} /> : null}
      </Typography>
      <div className={classes.inner}>{loading ? <PageLoader /> : children}</div>
    </div>
  )
);
