import { Button, createStyles, styled, Theme, WithStyles, Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { IPLinkFaultRestore } from '~pages/IPLinkDetails/Faults/IPLinkFaultRestore';

import { BusinessUnit, Fault, UnitID } from '~models';
import { boundActions, selectors } from '~store';
import { CardSummary, ContentField, IconText, Divider, NavLink, ContentWrapper } from '~ui-kit';
import { formatDate, getLinkByUnitType, getUnitLabelByUnitType, intToHex } from '~utils';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      minWidth: 200,
    },
    linksWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    link: {
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
    },
    dealersCount: {
      display: 'inline-flex',
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  });

const RestoreButton = styled(Button)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '4px',
  paddingBottom: '4px',
  marginLeft: 'auto',
});

interface FaultListCardProps extends WithStyles<typeof styles> {
  fault: Fault;
  businessUnitId: BusinessUnit['id'];
  timeFormat?: string;
  restore?: boolean;
  unit?: boolean;
  onClick: (unitID: UnitID) => void;
}

export const FaultsListCard = withStyles(styles)(
  ({ classes, fault, restore, businessUnitId, unit, timeFormat, onClick }: FaultListCardProps) => {
    const [isRestore, setRestore] = useState(false);
    const { unitId, unitType, cid, eventCode, zoneCode, description } = fault;

    const onRestoreFault = useCallback(() => setRestore(!isRestore), [isRestore]);
    const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

    const restoreAlert = useCallback(() => {
      boundActions.alarms.restoreFaults({
        buId: businessUnitId,
        cid,
        code: eventCode?.toString() as string,
        id: intToHex(unitId).toString(),
        zone: zoneCode?.toString(),
      });
    }, []);

    const onRestoreConfirm = useCallback(() => {
      boundActions.ipLinks.restoreIPLinkFaultInit({
        buId: businessUnitId,
        cid,
        eventCode,
        id: unitId,
        zoneCode,
      });
      setRestore(!isRestore);
      restoreAlert();
    }, [isRestore]);

    return (
      <CardSummary status="error">
        <IconText status="error" label={description} icon="subscribers" classes={{ root: clsx(classes.title) }} />

        <ContentWrapper spacing="right">
          <Divider flexItem orientation="vertical" spacing="right" />

          <ContentField title="Event code" width={120}>
            {cid}
          </ContentField>

          {unit && (
            <Fragment>
              <ContentField title={getUnitLabelByUnitType(unitType)} width={100}>
                <NavLink to={getLinkByUnitType(unitType, businessUnitId, unitId)}>{intToHex(unitId)}</NavLink>
              </ContentField>

              <ContentField title="Dealer(s)" width={100}>
                {fault?.dealersCount ? (
                  <Grid className={classes.dealersCount} onClick={() => onClick({ unitId, unitType })}>
                    {fault?.dealersCount}
                  </Grid>
                ) : (
                  'N/A'
                )}
              </ContentField>
            </Fragment>
          )}

          <ContentField title="# of Dependents" width={100}>
            <Tooltip title="10 days / 24 hours events" placement="top" arrow>
              <div>
                {fault.dependentDecadeCount} / {fault.dependentDayCount}
              </div>
            </Tooltip>
          </ContentField>

          <ContentField title="Date & Time">{formatDate(fault.timestamp, false, timeFormat, timeZone)}</ContentField>

          {restore && (
            <Fragment>
              <RestoreButton color="primary" variant="contained" onClick={onRestoreFault}>
                Restore
              </RestoreButton>

              <IPLinkFaultRestore open={isRestore} onClose={onRestoreFault} onConfirm={onRestoreConfirm} />
            </Fragment>
          )}
        </ContentWrapper>
      </CardSummary>
    );
  }
);
