import { withStyles, WithStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { is } from 'ramda';
import { memo, PropsWithChildren } from 'react';

import { ContentField, ContentFieldProps } from '../ContentField/ContentField';
import { Icon } from '../Icon/Icon';
import { NavLink } from '../NavLink/NavLink';

import { styles } from './CardTitle.styles';

import { IconType } from '~icons';

type CardTitleProps = WithStyles<typeof styles> &
  Omit<ContentFieldProps, 'classes'> &
  PropsWithChildren<{
    icon?: IconType;
    linkTo?: string;
    linkLabel?: string | number;
  }>;

export const CardTitle = memo(
  withStyles(styles)(({ classes, title, width, icon, linkTo, linkLabel, ...props }: CardTitleProps) => (
    <div style={{ width }} className={classes.root}>
      {icon ? <Icon icon={icon} classes={{ root: clsx(classes.icon) }} /> : null}

      <ContentField title={title}>
        {linkTo ? (
          <NavLink to={linkTo} classes={{ root: clsx(classes.title) }}>
            {linkLabel}
          </NavLink>
        ) : is(String, props.children) || is(Number, props.children) ? (
          <Typography classes={{ root: clsx(classes.title) }}>{props.children}</Typography>
        ) : (
          props.children
        )}
      </ContentField>
    </div>
  ))
);
