import { Typography, Paper } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

import { styles } from './FormPanel.styles';

import { IconType } from '~icons';
import { Icon, RefreshButton } from '~ui-kit';

export type FormPanelProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title?: string;
  subTitle?: string | ReactNode;
  searchField?: () => ReactNode;
  onRefresh?: () => void;
  renderActions?: () => ReactNode;
  loading?: boolean;
  icon?: IconType;
};

export const FormPanel = withStyles(styles)(
  ({ title, subTitle, searchField, classes, onRefresh, loading, icon, children, renderActions }: FormPanelProps) => (
    <Paper classes={{ root: clsx(classes.root) }} className="form-panel">
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          {title && (
            <Typography variant="h3" color="primary" classes={{ root: clsx(classes.title) }}>
              {icon ? <Icon icon={icon} color="blueColor" classes={{ root: clsx(classes.titleIcon) }} /> : null}
              {title}
              {onRefresh ? <RefreshButton onClick={onRefresh} loading={loading} /> : null}
            </Typography>
          )}

          {searchField?.()}

          {subTitle && (
            <Typography variant="subtitle1" classes={{ root: clsx(classes.subTitle) }}>
              {subTitle}
            </Typography>
          )}
        </div>

        {renderActions ? <div className={classes.actions}>{renderActions()}</div> : null}
      </div>

      {children}
    </Paper>
  )
);

export const FormPanelWrapper = withStyles({
  root: { height: '100%' },
})(FormPanel);
