import { Button } from '@material-ui/core';
import { equals } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserTimeFormat } from '~features/users/usersUtils';

import { appRoutes } from '~constants';
import { useSubscription } from '~hooks';
import { boundActions, selectors } from '~store';
import { Dialog, DialogContent, DialogActions, NavLink } from '~ui-kit';
import { formatDate } from '~utils';

export const LicenseDialog = () => {
  const [isOpen, setOpen] = useState(false);
  const data = useSelector(selectors.license.getLimitedLicenseDetails);

  const { firstLoginStepper: isFirstLoginStepper } = useSelector(selectors.app.getAppDialogs, equals);
  const isLimitedLicense = Boolean(data.message.length);
  const account = useSelector(selectors.profile.getAccount, equals);
  const timeFormat = getUserTimeFormat(account);
  const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

  const licenseTimeLimit = formatDate(data.timeStamp, false, timeFormat, timeZone) as string;

  useSubscription<{ message: string, timeStamp: number }>('license/status', msg => {
    const { message, timeStamp } = msg.data;

    if (message) {
      boundActions.license.setLimitedLicenseDetails({ message, timeStamp });
    }
  });

  const onClose = useCallback(() => {
    setOpen(false);
    boundActions.license.clearLimitedLicenseDetails();
  }, []);

  useEffect(() => {
    if (isLimitedLicense && !isFirstLoginStepper) {
      setOpen(true);
    }
  }, [isLimitedLicense, isFirstLoginStepper]);

  return (
    <Dialog open={isOpen} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
      <DialogContent>{data.message + (licenseTimeLimit ?? '')}</DialogContent>

      <DialogActions>
        <NavLink to={appRoutes.profile}>
          <Button type="submit" variant="contained" color="primary" onClick={onClose}>
            Go to Profile
          </Button>
        </NavLink>
      </DialogActions>
    </Dialog>
  );
};
