export const focusedClassName = 'Mui-focused';
export const disabledClassName = 'Mui-disabled';
export const errorClassName = 'Mui-error';
export const filledClassName = 'Mui-filled';

export const classNames = {
  focused: focusedClassName,
  disabled: disabledClassName,
  filled: filledClassName,
  error: errorClassName,
};
