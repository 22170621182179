import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 1),
    },
    icon: {
      background: `${theme.palette.primary.main} !important`,
    },
    iconDisabled: {
      background: `${theme.palette.greyColor.dark} !important`,
    },
  });
