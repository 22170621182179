import { Button } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { SubscriberMode } from '~models';
import { Dialog, DialogContent, DialogActions, DialogProps } from '~ui-kit';

type NCTModeDialogProps = Partial<DialogProps> &
  PropsWithChildren<{
    loading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    subscriberMode: SubscriberMode;
  }>;

export const NCTModeDialog = ({
  open = false,
  onConfirm,
  onCancel,
  children,
  loading,
  subscriberMode,
  ...props
}: NCTModeDialogProps) => (
  <Dialog open={open} {...props} maxWidth="sm">
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm} disabled={loading}>
        {subscriberMode === 'NCT' ? 'Unset from NCT' : 'Set to NCT'}
      </Button>
    </DialogActions>
  </Dialog>
);
