import { LiveTrafficPacket } from '~models';

export const sortLiveTraffic = (liveTraffic: LiveTrafficPacket[]) => {
  // eslint-disable-next-line
  let dataMap: {
    [key: number]: LiveTrafficPacket;
  } = {};
  liveTraffic.forEach(item => (dataMap[item?.uniqueKey] = item));
  if (dataMap) {
    const traffic = Object.values(dataMap as LiveTrafficPacket[]).sort(
      (a: LiveTrafficPacket, b: LiveTrafficPacket) => (a.createdAt > b.createdAt && -1) || 1
    );
    return traffic;
  }
};
