/* eslint-disable newline-per-chained-call */
import * as yup from 'yup';

import { SubscriberSettings } from '~models';

export type SubscriberRadioPacketLifeSettings = {
  checkin: number;
  status: number;
  alarm: number;
  trouble: number;
  restoral: number;
  intelliTap: number;
  specials: number;
};

export const getSubscriberRadioPacketLifeInitialValues = (
  values?: SubscriberSettings['radio-packet-life']
): SubscriberRadioPacketLifeSettings => ({
  alarm: values?.alarm || 0,
  checkin: values?.checkin || 0,
  intelliTap: values?.intelliTap || 0,
  restoral: values?.restoral || 0,
  specials: values?.specials || 0,
  status: values?.status || 0,
  trouble: values?.trouble || 0,
});

export const prepareSubscriberRadioPacketLifeRequestPayload = (
  values: SubscriberRadioPacketLifeSettings
): Partial<SubscriberSettings['radio-packet-life']> => ({
  alarm: values.alarm,
  checkin: values.checkin,
  intelliTap: values.intelliTap,
  restoral: values.restoral,
  specials: values.specials,
  status: values.status,
  trouble: values.trouble,
});

export const subscriberRadioPacketLifeValidationSchema = yup.object().shape({
  alarm: yup.number().min(10).max(1440).required('Alarm is required'),
  checkin: yup.number().min(10).max(1440).required('Check-in is required'),
  intelliTap: yup.number().min(10).max(1440).required('Intellipro is required'),
  restoral: yup.number().min(10).max(1440).required('Restoral is required'),
  specials: yup.number().min(10).max(1440).required('Specials is required'),
  status: yup.number().min(10).max(1440).required('Status is required'),
  trouble: yup.number().min(10).max(1440).required('Trouble is required'),
});
