import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field, FieldProps, useFormikContext } from 'formik';

import { TextField, TextFieldProps } from '../Fields/TextField';

import { styles } from './styles';

import { geoCordinateHelper } from '~utils';

export type FormTextFieldProps = Partial<TextFieldProps> &
  WithStyles<typeof styles> & {
    name: string;
    cleanable?: boolean;
  };

export const FormTextField = withStyles(styles)(
  ({ name, classes, onClean, cleanable, ...props }: FormTextFieldProps) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Field name={name}>
        {({ field, meta: { touched, error } }: FieldProps) => (
          <TextField
            {...props}
            {...field}
            error={touched && Boolean(error)}
            helperText={(touched && error) || props.helperText}
            classes={{ root: clsx(classes.root) }}
            onClean={
              (onClean || cleanable) && !props.showing
                ? () => {
                  setFieldValue(name, typeof field.value === 'string' || geoCordinateHelper(field.name) ? '' : null);
                  onClean?.();
                }
                : undefined
            }
            onChange={e => {
              if (props.type === 'number') {
                const value = e.target.value ? parseInt(e.target.value) : null;

                return setFieldValue(name, value);
              }
              return field.onChange(e);
            }}
          />
        )}
      </Field>
    );
  }
);
