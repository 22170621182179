import { format } from 'date-fns';
import { findIndex, prop } from 'ramda';

import { DATE_DTO_FORMAT } from '~constants/date';

export const updateArray = <T>(data: T[], updates: T | T[], propName = 'id'): T[] => {
  const features = ((updates as T[]).length ? updates : [updates]) as T[];
  const existingData = data.map(value => value[propName]);

  const existingUpdates = features.filter(v => existingData.includes(v[propName]));
  const nonExistingUpdates = features.filter(v => !existingData.includes(v[propName]));

  if (!data.length) {
    return features;
  }

  const values = data.map(item => {
    const update = existingUpdates.find(value => value[propName] === item[propName]);

    return update ? { ...item, ...update } : item;
  });

  return [...values, ...nonExistingUpdates];
};

export const updateArrayWithCallback = <T>(
  data: T[],
  updates: T | T[],
  callback: (existing: T, update: T) => boolean = (e, u) => prop('id' as keyof T, e) === prop('id' as keyof T, u)
) => {
  let features = Array.isArray(updates) ? updates : ([updates] as T[]);

  if (!features.length) {
    return data;
  }

  if (!data?.length) {
    return features;
  }

  const values = data.reduce<T[]>((acc, value, i) => {
    const updateIndex = findIndex<T>(update => callback(value, update), features);
    const update = features[updateIndex];

    if (update) {
      features = features.filter((v, i) => i !== updateIndex);

      acc.push({ ...value, ...update });
    } else {
      acc.push(value);
    }

    if (i === data.length - 1 && features.length) {
      acc.push(...features);
    }

    return acc;
  }, []);

  return values;
};

export const findDataByCallback = <T>(data: T[], callback: (value: T, i: number) => boolean) => data.find(callback);

export const getNotEmptyKeys = <T>(data: T, keys: string[]): string[] => keys.filter(key => data[key]?.length && key);

export const getYesterdayDate = () => {
  const date = new Date();
  const yesterdayDate = date.setDate(date.getDate() - 1);

  return format(new Date(yesterdayDate), DATE_DTO_FORMAT);
};

export const todayDateHelper = date => {
  if (date) {
    return format(new Date(date as string), DATE_DTO_FORMAT);
  }
  return format(new Date(), DATE_DTO_FORMAT);
};

export const getTodayDate = () => {
  const date = new Date();
  const todayDate = date.setDate(date.getDate());

  return format(new Date(todayDate), DATE_DTO_FORMAT);
};

export const getWeekPriorDate = () => {
  const date = new Date();
  const weekPriorDate = date.setDate(date.getDate() - 7);

  return format(new Date(weekPriorDate), DATE_DTO_FORMAT);
};
