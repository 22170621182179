import { Typography } from '@material-ui/core';

export const getInfoDialogContent = (): { title: string; content } => {
  const title = 'Network Health Score';

  const content = (
    <Typography variant="body2">
      The Network Health Score measures overall Network Health as a rating from 1 (poor) to 100
      (excellent). The calculation is based on several parameters, including active IP Link
      (Hybrid Subscriber) and Subscriber Faults, late Check-ins, and Ack Delays.
    </Typography>
  );

  return { title, content };
};
