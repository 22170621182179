import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { FormCheckbox } from '~ui-kit/Forms';

import { PageLoader } from '~components';
import { boundActions, selectors } from '~store';
import { Divider, DialogContent, DialogActions, FormTextField, Required } from '~ui-kit';
import { alarmAutomationValidators, businessUnitsValidators, getFormErrors } from '~utils';

const initialValues = {
  name: '',
  portNumber: 0,
  ipAddress: '',
  isDatabaseMigration: false,
};

const validationSchema = yup.object().shape({
  name: yup.string().when('isDatabaseMigration', {
    is: false,
    then: businessUnitsValidators.name,
    otherwise: yup.string().notRequired(),
  }),
  portNumber: yup.number().when('isDatabaseMigration', {
    is: false,
    then: alarmAutomationValidators.portNumber,
    otherwise: yup.number().notRequired(),
  }),
  ipAddress: yup.string().when('isDatabaseMigration', {
    is: false,
    then: alarmAutomationValidators.ipAddress,
    otherwise: yup.string().notRequired(),
  }),
  isDatabaseMigration: yup.boolean(),
});

const MigrationCheckbox = withStyles(() => ({ root: { marginBottom: 0 } }))(FormCheckbox);

export const DefaultBusinessUnit = () => {
  const unitCreated = useSelector(selectors.businessUnits.isBusinessUnitCreated, equals);
  const portRanges = useSelector(selectors.app.getAllowedPortRanges, equals);
  const error = useSelector(selectors.businessUnits.getBusinessUnitError, equals);
  const businessUnitLoaders = useSelector(selectors.businessUnits.getBusinessUnitsLoaders, equals);
  const isLoading = businessUnitLoaders.create || unitCreated;
  const isDatabaseMigrationInProgress = useSelector(selectors.app.isDatabaseMigrationInProgress);

  useEffect(
    () => () => {
      boundActions.businessUnits.resetUnitStatus();
    },
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ name, ipAddress, portNumber, isDatabaseMigration }) => {
        if (isDatabaseMigration) {
          boundActions.app.setAppDialog({
            dialog: 'uploadMigratedDB',
            value: true,
          });

          return;
        }

        boundActions.businessUnits.createDefaultBusinessUnitInit({
          alarmAutomation: {
            isServer: true,
            sockets: [
              {
                ipAddress,
                portNumber,
                number: 0,
              },
            ],
          },
          name,
          automationFormat: 1,
          oldAlarms: 1,
          receiverNumber: 1,
          isDefault: true,
        });
      }}
    >
      {({ values, setFieldValue, setErrors }) => {
        const { isDatabaseMigration } = values;

        useEffect(() => {
          setErrors(
            getFormErrors(error, {
              alarmAutomation: 'tcpServer',
            })
          );
        }, [error]);

        useEffect(() => {
          if (isDatabaseMigration) {
            setFieldValue('name', initialValues.name);
            setFieldValue('portNumber', initialValues.portNumber);
            setFieldValue('ipAddress', initialValues.ipAddress);
          }
        }, [isDatabaseMigration]);

        return (
          <Form>
            {isLoading && <PageLoader fullscreen />}

            <DialogContent>
              <FormTextField
                cleanable
                name="name"
                label={<Required>BU Name</Required>}
                disabled={isDatabaseMigration}
              />

              <FormTextField
                cleanable
                name="portNumber"
                label={<Required>Alarm automation port number</Required>}
                type="number"
                helperText={portRanges.alarmAutomation}
                disabled={isDatabaseMigration}
              />

              <FormTextField
                cleanable
                name="ipAddress"
                label={<Required>Alarm automation IP address</Required>}
                disabled={isDatabaseMigration}
              />

              <MigrationCheckbox
                name="isDatabaseMigration"
                label="I will import BU from existing MNR"
                disabled={isDatabaseMigrationInProgress}
              />
            </DialogContent>

            <Divider />

            <DialogActions>
              <Button type="submit" variant="contained" color="primary">
                Next
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};
