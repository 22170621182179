import { createAction } from '@reduxjs/toolkit';

export const name = 'shared';

export const actions = {
  init: createAction('init'),
  reset: createAction('reset'),
};

declare global {
  namespace AES {
    export interface Actions {
      shared: typeof actions;
    }
  }
}
