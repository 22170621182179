import { AssignedDealer } from './dealer';
import { GeoCoordinates } from './geography';
import { UnitDependent, UnitID } from './unit';

export type IPLinkServiceLog = {
  address1?: string;
  address2?: string;
  buId?: number;
  city?: string;
  eventLabel?: string;
  modelNumber?: string;
  modelRev?: string;
  state?: string;
  status?: string;
  unitId?: number;
  zipCode?: string;
};

export type IPLink = GeoCoordinates &
  IPLinkServiceLog & {
    id: number;
    businessUnitId: number;
    businessUnitName: string;
    connectCount: number;
    connectionInterval: number;
    faultCount: number;
    packetCount: number;
    ppPacketCount: number;
    numbOfDependantSubscribers: number;
    supervisionIntervalSecs: number;
    isOffline: boolean;
    dependentCalculationTime?: number;
    dependentDay: IPLink['id'][];
    dependentDayCount: number;
    dependentDecade: IPLink['id'][];
    dependentDecadeCount: number;
		dependencies: UnitDependent[];
    dependent: UnitID[];
    /** First connection  */
    firstConnectionTime: number;
    /** Last connection */
    lastConnectionTime: number;
    /** Last Packet */
    lastUpdate: number;
    model?: string;
    address1?: string;
    address2?: string;
    antenna?: string;
    city?: string;
    compassNumber?: number;
    connectInterval?: number;
    country?: string;
    customer?: string;
    dealersCount?: string;
    note?: string;
    revision?: string;
    stateOrProvince?: string;
    zipOrPostalCode?: string;
    eventHistory?: IPLinkHistory[];
    notifications?: IPLinkNotifications;
    dealers?: AssignedDealer[];
  };

export type IPLinksListSection = 'all' | 'service-log';

export enum IPLinksLocation {
  IpLinks,
  BusinessUnits,
}

export type IPLinkHistory = {
  alarmTime: number;
  businessUnitId: number;
  eventCode: string;
  eventName: string;
  ipLinkId: number;
};

export type IPLinkHistoryPeriod = 'TEN_DAYS' | 'MONTH';

export type IPLinkNotifications = {
  emails: string[],
  sendRestoral: boolean,
  sendNotification: boolean,
  allEvents:
    {
      eventName: string,
      eventLabel: string,
      faultCode: string
    }[],
  events:
    {
      event: string,
      isEnable: boolean
    }[],
    mobileInfo:
    { mobileCarrierId: number | null,
      phoneNumber: string | null
    }[] | null
};
