import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      padding: 10,
      marginRight: 6,
    },
    icon: {
      background: `${getStyles(theme.palette.greyColor.light, theme.palette.greyColor.dark)} !important`,
    },
    checkedIcon: {
      background: `${theme.palette.primary.main} !important`,
    },
    label: {
      marginLeft: -10,
    },
  });
};
