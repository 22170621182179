import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { isNil } from 'ramda';
import { useEffect } from 'react';

import { SubscriberSettingsSection } from '~features/subscribers/subscribersSlice';

import { ResponseError, Subscriber, SubscriberSettings } from '~models';
import { FormContent, FormPanelWrapper, SwitchField } from '~ui-kit';

type AutoTestSupervisionProps = {
    subscriber: Subscriber;
    loader: boolean;
    errors: ResponseError | null;
    onUpdate(value: {
      id: Subscriber['id'];
      businessUnitId: Subscriber['businessUnitId'];
      section: SubscriberSettingsSection;
      values: Partial<SubscriberSettings['supervision']>;
}): void;
onRefresh(section: SubscriberSettingsSection): void;
};

export const SubscriberAutoTestSupervision = ({
  subscriber,
  loader,
  errors,
  onUpdate,
  onRefresh,
}: AutoTestSupervisionProps) => {
  const { id, businessUnitId, settings } = subscriber;
  const initialValues = { supervisionEnable: settings?.supervision?.supervisionEnable || false };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        useEffect(() => {
          if (isNil(settings?.supervision.supervisionEnable)
          || settings?.supervision.supervisionEnable === values.supervisionEnable) {
            return;
          }

          onUpdate({
            id,
            businessUnitId,
            section: 'supervision',
            values: { ['supervisionEnable']: values.supervisionEnable }
          });
        }, [values.supervisionEnable]
        );

        return (
          <FormPanelWrapper
            title="Auto Test Supervision"
            loading={loader}
            onRefresh={() => onRefresh('supervision')}
          >
            <FormContent error={errors}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} key={1}>
                  <SwitchField
                    name="supervisionEnable"
                    text="Turn On"
                    activeText="Turn Off"
                    value={values.supervisionEnable}
                    onChange={() => setFieldValue('supervisionEnable', !values.supervisionEnable)}
                    disabled={loader}
                  />
                </Grid>
              </Grid>
            </FormContent>
          </FormPanelWrapper>
        );
      }}
    </Formik>
  );
};

