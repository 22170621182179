import { Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermissions } from '~features/users/usersUtils';

import { Permission } from '~models';
import { selectors } from '~store';

export type ProtectedContentProps = PropsWithChildren<{
  permissions?: Permission['name'][];
}>;

export const ProtectedContent = ({ permissions, children }: ProtectedContentProps) => {
  const currentUser = useSelector(selectors.auth.getCurrentUser);

  if (!permissions?.length || userHasPermissions(currentUser, permissions)) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};
