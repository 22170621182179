import { createStyles, Grid, Theme, Paper, Button, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import * as Yup from 'yup';

import backgroundImage from '~assets/images/login-background.png';
import { appRoutes } from '~constants/routes';

import { boundActions, selectors } from '~store';
import { FormTextField, Logo, Required } from '~ui-kit';
import { getFormErrors } from '~utils';

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      // backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      background: `linear-gradient(90deg, rgba(38, 50, 76, 0.53) 17.57%, #182134 100%), url(${backgroundImage})`,
    },
    container: {
      margin: spacing(0, 'auto'),
    },
    paper: {
      padding: spacing(7),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: 170,
      height: 57,
      marginBottom: spacing(3),
    },
    subheader: {
      marginBottom: spacing(4),
    },
    title: {
      marginBottom: spacing(1),
    },
    instance: {
      marginBottom: spacing(5),
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    input: {
      width: '100%',
      marginBottom: spacing(6),
    },
    submit: {
      margin: spacing(0, 'auto', 4),
    },
    forgotPassword: {
      fontWeight: 600,
    },
    form: {
      textAlign: 'center',
    },
  });

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const Auth = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const info = useSelector(selectors.app.getInstanceInfo, equals);
  const error = useSelector(selectors.auth.getAuthError, equals);
  const isLoading = useSelector(selectors.auth.isLoginProcessing);
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);

  if (currentUser) {
    return <Redirect to={appRoutes.root} />;
  }

  return (
    <Grid container alignContent="center" alignItems="center" className={classes.root}>
      <Grid item className={classes.container} xs={4}>
        <Paper className={classes.paper}>
          <Logo classes={{ logo: classes.logo }} />
          <span className={classes.subheader}>IntelliNet® Network Control Center</span>
          <Typography className={classes.title} variant="h6" color="primary">
            Sign In
          </Typography>

          {info.instanceType ? (
            <Typography className={classes.instance} variant="h4" color="primary">
              to {info.instanceType} Instance
            </Typography>
          ) : null}

          <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={values => {
              boundActions.auth.loginInit(values);
            }}
          >
            {({ setErrors }) => {
              useEffect(() => {
                setErrors(getFormErrors(error));
              }, [error]);

              return (
                <Form className={classes.form}>
                  <FormTextField name="username" label={<Required>Username</Required>} type="text" cleanable />
                  <FormTextField name="password" label={<Required>Password</Required>} type="password" cleanable />

                  <Button
                    fullWidth
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Sign In
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default Auth;
