import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actions as sharedActions } from '../sharedSlice';

import { Notification } from '~models';

export interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const { actions, reducer, name } = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    enqueue: (state, { payload: { message: text, key, options } }: PayloadAction<Notification>) => {
      const existing = state.notifications.find(not => not.key === key);

      if (!existing || !key) {
        const notification = {
          message: text,
          key: key ?? new Date().getTime() + Math.random(),
          options,
        };
        // @ts-ignore
        state.notifications.push(notification);
      }
    },
    close: (state, { payload }: PayloadAction<Notification['key']>) => {
      state.notifications = state.notifications.map(notification => ({
        ...notification,
        dismissed: notification.key === payload,
      }));
    },
    remove: (state, { payload }: PayloadAction<Notification['key']>) => {
      state.notifications = state.notifications.filter(notification => notification.key !== payload);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

export const selectors = {
  getNotifications: (state: AES.RootState) => state.notifications.notifications,
};

declare global {
  namespace AES {
    export interface Actions {
      notifications: typeof actions;
    }
    export interface RootState {
      notifications: NotificationsState;
    }

    export interface Selectors {
      notifications: typeof selectors;
    }
  }
}
