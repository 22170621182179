import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    inner: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -2,
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    assignItem: {
      fontSize: 12,
      marginBottom: 10,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      lineHeight: 1.5,
      fontWeight: 500,
      padding: '2px 8px',
      borderRadius: 4,
      margin: 2,
      cursor: 'pointer',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.greyColor.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&.selected': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: getStyles(theme.palette.blueColor.dark, theme.palette.whiteColor.main),
      },
    },
    item: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 500,
      padding: '2px 8px',
      borderRadius: 12,
      margin: 2,
      cursor: 'pointer',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.greyColor.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&.selected': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: getStyles(theme.palette.blueColor.dark, theme.palette.whiteColor.main),
      },
    },
  });
};
