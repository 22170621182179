import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { Fragment, useMemo, useState } from 'react';

import { SubscriberSettingsSection } from '~features/subscribers/subscribersSlice';

import {
  getSubscriberTimingInitialValues,
  prepareSubscriberTimingRequestPayload,
  subscriberTimingValidationSchema,
} from './config';
import { timingFields } from './fields';

import { FullHeightPage } from '~hocs';
import { ResponseError, Subscriber, SubscriberSettings } from '~models';
import { FormContent, FormPanelWrapper, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';

const FormWrapper = FullHeightPage(Form);
const DefaultButton = withStyles({ root: { marginRight: 4 } })(Button);

type SubscriberTimingSettingsProps = {
  subscriber: Subscriber;
  loaders: {
    sections: { [key in SubscriberSettingsSection]: boolean };
    request: { [key in SubscriberSettingsSection]: boolean };
    defaultSetting: { timing: boolean };
  };
  errors: ResponseError | null;
  timeFormat: string;
  onSubmit(value: {
    id: Subscriber['id'];
    businessUnitId: Subscriber['businessUnitId'];
    section: SubscriberSettingsSection;
    values: Partial<SubscriberSettings['timing']>;
  }): void;
  onRefresh(section: SubscriberSettingsSection): void;
  onSetDefault(secton: SubscriberSettingsSection, isSetDefault: boolean): void;
};

export const SubscriberTimingSettings = ({
  subscriber,
  loaders,
  errors,
  timeFormat,
  onSubmit,
  onRefresh,
  onSetDefault,
}: SubscriberTimingSettingsProps) => {
  const { id, businessUnitId, settings } = subscriber;
  const eventReporting = useMemo(() => settings?.timing?.eventReporting, [loaders.sections.timing]);
  const lastUpdated = useMemo(() => settings?.timing?.lastUpdated, [loaders.sections.timing]);
  const fields = useMemo(() => timingFields(eventReporting), [eventReporting]);
  const [isSetDefault, setIsSetDefault] = useState<boolean>(true);

  return (
    <Formik
      initialValues={getSubscriberTimingInitialValues(settings?.timing)}
      enableReinitialize
      validationSchema={subscriberTimingValidationSchema}
      onSubmit={payload => {
        onSubmit({
          id,
          businessUnitId,
          section: 'timing',
          values: prepareSubscriberTimingRequestPayload(payload),
        });
        setIsSetDefault(isSetDefault ? isSetDefault : !isSetDefault);
      }}
    >
      {({ handleSubmit, isValid }) => (
        <FormWrapper>
          <FormPanelWrapper
            title="Timing"
            loading={loaders.request.timing}
            onRefresh={() => onRefresh('timing')}
            renderActions={() => (
              <Fragment>
                <DefaultButton
                  onClick={() => {
                    onSetDefault('timing', isSetDefault);
                    setIsSetDefault(!isSetDefault);
                  }}
                  size="small"
                  disabled={loaders.sections.timing || loaders.defaultSetting.timing}
                >
                  {isSetDefault ? 'Set Default' : 'Cancel'}
                </DefaultButton>

                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={loaders.sections.timing || loaders.defaultSetting.timing || !isValid}
                >
                  Save
                </Button>
              </Fragment>
            )}
            subTitle={
              <LastDataUpdatedFormPanelSubtitle
                title="Last Updated: "
                timestamp={lastUpdated}
                timeFormat={timeFormat}
              />
            }
          >
            <FormContent error={errors} loading={loaders.sections.timing || loaders.defaultSetting.timing}>
              <Grid container spacing={2}>
                {fields.map(({ Component, ...field }, i) => (
                  <Component {...field} key={i} cleanable />
                ))}
              </Grid>
            </FormContent>
          </FormPanelWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
};
