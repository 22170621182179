import FileSaver from 'file-saver';

import { userHasPermissions } from '~features/users/usersUtils';

import { PageRoute, PageQuery, User } from '~models';

export const getNavigationRoutes = (routes: PageRoute[], user?: User | null): PageRoute[] =>
  routes.filter(route => {
    if (!user) {
      return !route.hideFromNavigation;
    }

    return userHasPermissions(user, route.permissions) && !route.hideFromNavigation;
  });

export const withQueryParams = (path: string, query?: PageQuery): string => {
  if (!path || !query || !Object.keys(query).length) {
    return path || '';
  }

  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => params.set(key, value as string));

  return `${path}?${params.toString()}`;
};

export const parseQueryParams = (search = ''): PageQuery => {
  const params = new URLSearchParams(search);
  const result = {};

  for (const [key, value] of params) {
    result[key] = decodeURIComponent(value);
  }

  return result;
};

// eslint-disable-next-line import/no-named-as-default-member
export const saveFile = (blob: Blob | string, name: string) => FileSaver.saveAs(blob, name);

export const getCountTitle = (numberCount: number, titleName?: string, returnEmpty = false): string => {
  if (!titleName) {
    return '';
  }

  if (numberCount === 0) {
    return returnEmpty ? '' : titleName;
  }

  return `${numberCount} ${titleName}`;
};
