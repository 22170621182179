import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    title: {
      justifyContent: 'center',
    },

    horizontal: {
      padding: 0,
      boxShadow: 'none !important',
    },

    divider: {
      marginTop: spacing(2),
    },

    line: {
      display: 'none',
    },

    completedStep: {
      '& span, svg': {
        color: `${palette.greyColor.main} !important`,
      },
    },

    label: {
      marginTop: `${spacing(1)}px !important`,
      color: `${palette.primary.main} !important`,
      fontStyle: 'italic',
    },

    icon: {
      '& > svg': {
        width: '32px',
        height: '32px',
      },
    },
  });
