import { createDraftSafeSelector, createSlice, PayloadAction, AnyAction, createEntityAdapter } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, filter, mergeMap } from 'rxjs/operators';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { api, appRoutes } from '~constants';
import { Dealer, DealerAssignedUser, PaginationRequestPayload, PaginationResponse, ResponseError, User } from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload, intToHex } from '~utils';

export const adapter = createEntityAdapter<Dealer>({
  selectId: dealer => dealer.id,
});

type DealersState = {
  data: ReturnType<typeof adapter.getInitialState>;
  createdDealer: Dealer | null;
  loading: {
    list: boolean;
    details: {
      general: boolean;
      update: boolean;
    };
    create: boolean;
    update: boolean;
    delete: boolean;
    import: {
      template: boolean;
      units: boolean;
    };
    assignedUsers: boolean;
    assignedUnits: boolean;
    assignedIPLinks: boolean;
  };
  error?: ResponseError | null;
  status: 'created' | 'updated' | 'deleted' | 'imported' | null;
};

export const initialState: DealersState = {
  data: adapter.getInitialState(),
  createdDealer: null,
  loading: {
    list: false,
    details: {
      general: false,
      update: false,
    },
    create: false,
    update: false,
    delete: false,
    import: {
      template: false,
      units: false,
    },
    assignedUsers: false,
    assignedUnits: false,
    assignedIPLinks: false,
  },
  error: null,
  status: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'dealers',
  initialState,
  reducers: {
    //fetch Dealers
    fetchDealersInit(state, payload: PayloadAction<PaginationRequestPayload>) {
      state.error = null;
      state.loading.list = true;
    },
    fetchDealersSuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<Dealer>>) {
      state.loading.list = false;
      adapter.setAll(state.data, content);
    },
    fetchDealersFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.list = false;
      state.error = payload;
    },

    //fetch Dealer ids
    fetchDealerIdsInit(state) {
      state.error = null;
      state.loading.list = true;
    },
    fetchDealerIdsSuccess(state, { payload }: PayloadAction<Dealer[]>) {
      state.loading.list = false;
      adapter.setAll(state.data, payload);
    },
    fetchDealerIdsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.list = false;
      state.error = payload;
    },

    //fetch Dealer By Id
    fetchDealersByIdInit(state, { payload: { id } }: PayloadAction<{ id: Dealer['id'] }>) {
      state.error = null;
      state.loading.details.general = true;
      state.status = null;
    },
    fetchDealersByIdSuccess(state, { payload }: PayloadAction<Dealer>) {
      state.loading.details.general = false;
      adapter.upsertOne(state.data, payload);
    },
    fetchDealersByIdFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.general = false;
    },

    // create Dealer
    createDealerInit(state, action: PayloadAction<Partial<Dealer>>) {
      state.error = null;
      state.status = null;
      state.createdDealer = null;
      state.loading.create = true;
    },
    createDealerSuccess(state, { payload }: PayloadAction<Dealer>) {
      state.loading.create = false;
      state.createdDealer = payload;
      state.status = 'created';
    },
    createDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.create = false;
      state.error = payload;
    },

    //update Dealer Info
    updateDealerInfoInit(state, { payload }: PayloadAction<Dealer>) {
      state.error = null;
      state.status = null;
      state.loading.details.update = true;
    },
    updateDealerInfoSuccess(state, { payload }: PayloadAction<Dealer>) {
      state.loading.details.update = false;
      state.status = 'updated';
      adapter.upsertOne(state.data, payload);
    },
    updateDealerInfoFailed(
      state,
      { payload }: PayloadAction<ResponseError>
    ) {
      state.loading.details.update = false;
      state.error = payload;
    },

    // delete Dealer
    deleteDealerInit(state, { payload }: PayloadAction<Dealer['id']>) {
      state.status = null;
      state.loading.delete = true;
    },
    deleteDealerSuccess(state, { payload }: PayloadAction<Dealer['id']>) {
      state.loading.delete = false;
      state.status = 'deleted';
    },
    deleteDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.delete = false;
      state.error = payload;
    },

    // fetch dealer assigned users
    fetchDealerAssignedUsersInit(state, { payload }: PayloadAction<Dealer['id']>) {
      state.loading.assignedUsers = true;
    },
    fetchDealerAssignedUsersSuccess(
      state,
      { payload: { id, users } }: PayloadAction<{ id: Dealer['id']; users: Dealer['assignedUsers'] }>
    ) {
      state.loading.assignedUsers = false;

      adapter.updateOne(state.data, {
        id,
        changes: { assignedUsers: users },
      });
    },
    fetchDealerAssignedUsersFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUsers = false;
      state.error = payload;
    },

    // assign user to dealer
    assignUserToDealerInit(
      state,
      {
        payload,
      }: PayloadAction<{ dealerId: Dealer['id']; userId: User['id']; username?: DealerAssignedUser['username'] }>
    ) {
      state.loading.assignedUsers = true;
    },
    assignUserToDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUsers = false;
      state.error = payload;
    },

    // upload users to dealer via csv
    uploadUsersViaCSVInit(state, { payload: { files } }: PayloadAction<{ dealerId: Dealer['id']; files: File[] }>) {
      state.loading.assignedUsers = true;
    },
    uploadUsersViaCSVFailed(state) {
      state.loading.assignedUsers = false;
    },

    // delete dealer assigned user
    deleteDealerAssignedUserInit(
      state,
      {
        payload,
      }: PayloadAction<{
        dealerId: Dealer['id'];
        userId: DealerAssignedUser['id'];
        username?: DealerAssignedUser['username'];
      }>
    ) {
      state.loading.assignedUsers = true;
    },
    deleteDealerAssignedUserFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUsers = false;
      state.error = payload;
    },

    // fetch dealer assigned units
    fetchDealerAssignedUnitsInit(state, { payload }: PayloadAction<Dealer['id']>) {
      state.loading.assignedUnits = true;
    },
    fetchDealerAssignedUnitsSuccess(
      state,
      { payload: { id, units } }: PayloadAction<{ id: Dealer['id']; units: Dealer['units'] }>
    ) {
      state.loading.assignedUnits = false;

      adapter.updateOne(state.data, {
        id,
        changes: { units },
      });
    },
    fetchDealerAssignedUnitsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUnits = false;
      state.error = payload;
    },
    // assign units to dealer
    assignUnitsToDealerInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; unitIds: number[] }>) {
      state.loading.assignedUnits = true;
    },
    assignUnitsToDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUnits = false;
      state.error = payload;
    },
    // assign units to dealer
    assignUnitToDealerInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; unitId: number }>) {
      state.loading.assignedUnits = true;
    },
    assignUnitToDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUnits = false;
      state.error = payload;
    },

    // upload units to dealer via csv
    uploadUnitsViaCSVInit(state, { payload: { files } }: PayloadAction<{ dealerId: Dealer['id']; files: File[] }>) {
      state.loading.assignedUnits = true;
    },
    uploadUnitsViaCSVFailed(state) {
      state.loading.assignedUnits = false;
    },

    // delete dealer assigned unit
    deleteDealerAssignedUnitInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; unitId: number }>) {
      state.loading.assignedUnits = true;
    },
    deleteDealerAssignedUnitFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedUnits = false;
      state.error = payload;
    },

    // fetch dealer assigned ip links
    fetchDealerAssignedIPLinksInit(state, { payload }: PayloadAction<Dealer['id']>) {
      state.loading.assignedIPLinks = true;
    },
    fetchDealerAssignedIPLinksSuccess(
      state,
      { payload: { id, ipLinks } }: PayloadAction<{ id: Dealer['id']; ipLinks: Dealer['ipLinks'] }>
    ) {
      state.loading.assignedIPLinks = false;

      adapter.updateOne(state.data, {
        id,
        changes: { ipLinks },
      });
    },
    fetchDealerAssignedIPLinksFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedIPLinks = false;
      state.error = payload;
    },

    // assign ip links to dealer
    assignIPLinksToDealerInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; ipLinkIds: number[] }>) {
      state.loading.assignedIPLinks = true;
    },
    assignIPLinksToDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedIPLinks = false;
      state.error = payload;
    },

    // assign ip link to dealer
    assignIPLinkToDealerInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; ipLinkId: number }>) {
      state.loading.assignedIPLinks = true;
    },
    assignIPLinkToDealerFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedIPLinks = false;
      state.error = payload;
    },

    // upload ip-links to dealer via csv
    uploadIPLinksViaCSVInit(state, { payload: { files } }: PayloadAction<{ dealerId: Dealer['id']; files: File[] }>) {
      state.loading.assignedIPLinks = true;
    },
    uploadIPLinksViaCSVFailed(state) {
      state.loading.assignedIPLinks = false;
    },

    // delete dealer assigned ip link
    deleteDealerAssignedIPLinkInit(state, { payload }: PayloadAction<{ dealerId: Dealer['id']; ipLinkId: number }>) {
      state.loading.assignedIPLinks = true;
    },
    deleteDealerAssignedIPLinkFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.assignedIPLinks = false;
      state.error = payload;
    },

    // Import CSV file
    importCSVFileInit(state, action: PayloadAction<{ files: File[] }>) {
      state.loading.import.units = true;
      state.status = null;
    },
    importCSVFileSuccess(state) {
      state.loading.import.units = false;
      state.status = 'imported';
    },
    importCSVFileFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.import.units = false;
    },

    resetDealerStatus(state) {
      state.status = null;
    },

    resetDealersData(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: {
    [sharedActions.reset.toString()]: state => {
      Object.assign(state, initialState);
    },
  },
});

const getDealersState = (state: AES.RootState) => state.dealers;

const dealersSelectors = adapter.getSelectors();

export const selectors = {
  getDealersState,

  getDealersList: createDraftSafeSelector(getDealersState, state => dealersSelectors.selectAll(state.data)),
  getDealerLoaders: createDraftSafeSelector(getDealersState, state => state.loading),
  getDealerById: (id: Dealer['id']) =>
    createDraftSafeSelector(getDealersState, state => dealersSelectors.selectById(state.data, id)),
  getCreatedDealer: createDraftSafeSelector(getDealersState, state => state.createdDealer),
  getDealerError: createDraftSafeSelector(getDealersState, state => state.error),

  getDealerStatus: createDraftSafeSelector(getDealersState, state => state.status),

  isDealerUpdated: createDraftSafeSelector(getDealersState, state => state.status === 'updated'),
  isDealerCreated: createDraftSafeSelector(getDealersState, state => state.status === 'created'),
  isDealerImported: createDraftSafeSelector(getDealersState, state => state.status === 'imported'),
};

const fetchDealersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealersInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<Dealer>>(api.dealers.all(searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchDealersSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchDealersFailed(error));
        })
      );
    })
  );

const fetchDealerIdsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealerIdsInit.match),
    mergeMap(() =>
      http.getJSON<Dealer[]>(api.dealers.ids).pipe(
        mergeMap(res => of(actions.fetchDealerIdsSuccess(res))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchDealerIdsFailed(error));
        })
      )
    )
  );

const fetchDealersByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealersByIdInit.match),
    mergeMap(({ payload: { id } }) =>
      http.getJSON<Dealer>(api.dealers.byId(id)).pipe(
        mergeMap(unit => of(actions.fetchDealersByIdSuccess(unit))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchDealersByIdFailed(error), pageActions.setPageNotFound());
        })
      )
    )
  );

const updateDealerInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateDealerInfoInit.match),
    mergeMap(({ payload }) =>
      http.put(api.dealers.update, payload).pipe(
        mergeMap(res =>
          of(
            actions.updateDealerInfoSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'Dealer info updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateDealerInfoFailed(error));
        })
      )
    )
  );

const createDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createDealerInit.match),
    mergeMap(({ payload }) =>
      http.post(api.dealers.create, payload).pipe(
        mergeMap(res => {
          const createDealerActions: AnyAction[] = [
            actions.createDealerSuccess(res.response),
            notificationsActions.enqueue({
              message: 'Dealer added',
              options: { variant: 'success' },
            }),
          ];

          return of(...createDealerActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.createDealerFailed(error));
        })
      )
    )
  );

const deleteDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteDealerInit.match),
    mergeMap(({ payload }) =>
      http.delete(api.dealers.byId(payload)).pipe(
        mergeMap(() =>
          of(
            actions.deleteDealerSuccess(payload),
            notificationsActions.enqueue({
              message: 'Dealer deleted',
              options: { variant: 'success' },
            }),
            push(appRoutes.dealers)
          )
        ),
        catchError(err => of(actions.deleteDealerFailed(getResponseError(err))))
      )
    )
  );

const fetchDealerAssignedUsersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealerAssignedUsersInit.match),
    mergeMap(({ payload: id }) =>
      http.getJSON<PaginationResponse<DealerAssignedUser>>(api.dealers.users.all(id)).pipe(
        mergeMap(res => of(actions.fetchDealerAssignedUsersSuccess({ users: res.content, id }))),
        catchError(err => of(actions.fetchDealerAssignedUsersFailed(getResponseError(err))))
      )
    )
  );

const assignUserToDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.assignUserToDealerInit.match),
    mergeMap(({ payload: { dealerId, userId, username } }) =>
      http.post(api.dealers.users.assign(dealerId, userId)).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUsersInit(dealerId),

            notificationsActions.enqueue({
              message: `User ${username} is assigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.assignUserToDealerFailed(getResponseError(err))))
      )
    )
  );

const uploadUsersViaCSVEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.uploadUsersViaCSVInit.match),
    mergeMap(({ payload: { dealerId, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.dealers.users.uploadUserCSV(dealerId),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUsersInit(dealerId),

            notificationsActions.enqueue({
              message: 'CSV file with user(\'s) uploaded',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err =>
          of(
            actions.uploadUsersViaCSVFailed(),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const deleteDealerAssignedUserEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteDealerAssignedUserInit.match),
    mergeMap(({ payload: { dealerId, userId, username } }) =>
      http.post(api.dealers.users.delete(dealerId, userId)).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUsersInit(dealerId),

            notificationsActions.enqueue({
              message: `User ${username} is unassigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteDealerAssignedUserFailed(getResponseError(err))))
      )
    )
  );

const fetchDealerAssignedUnitsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealerAssignedUnitsInit.match),
    mergeMap(({ payload: id }) =>
      http.getJSON<PaginationResponse<number>>(api.dealers.units.all(id)).pipe(
        mergeMap(res => of(actions.fetchDealerAssignedUnitsSuccess({ units: res.content, id }))),
        catchError(err => of(actions.fetchDealerAssignedUnitsFailed(getResponseError(err))))
      )
    )
  );

const assignUnitToDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.assignUnitToDealerInit.match),
    mergeMap(({ payload: { dealerId, unitId } }) =>
      http.put(api.dealers.units.byId(dealerId, unitId), { unitId }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUnitsInit(dealerId),

            notificationsActions.enqueue({
              message: `Unit ${intToHex(unitId)} is assigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.assignUnitToDealerFailed(getResponseError(err))))
      )
    )
  );

const assignUnitsToDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.assignUnitsToDealerInit.match),
    mergeMap(({ payload: { dealerId, unitIds } }) =>
      http.post(api.dealers.units.assign(dealerId), { unitIds }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUnitsInit(dealerId),

            notificationsActions.enqueue({
              message: `${unitIds.length} Units are assigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.assignUnitsToDealerFailed(getResponseError(err))))
      )
    )
  );

const uploadUnitsViaCSVEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.uploadUnitsViaCSVInit.match),
    mergeMap(({ payload: { dealerId, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.dealers.units.uploadUnitCSV(dealerId),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUnitsInit(dealerId),

            notificationsActions.enqueue({
              message: 'CSV file with unit(\'s) uploaded',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err =>
          of(
            actions.uploadUnitsViaCSVFailed(),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const deleteDealerAssignedUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteDealerAssignedUnitInit.match),
    mergeMap(({ payload: { dealerId, unitId } }) =>
      http.delete(api.dealers.units.byId(dealerId, unitId), { unitId }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedUnitsInit(dealerId),

            notificationsActions.enqueue({
              message: `Unit ${intToHex(unitId)} is unassigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteDealerAssignedUnitFailed(getResponseError(err))))
      )
    )
  );

const fetchDealerAssignedIPLinksEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDealerAssignedIPLinksInit.match),
    mergeMap(({ payload: id }) =>
      http.getJSON<PaginationResponse<number>>(api.dealers.ipLinks.all(id)).pipe(
        mergeMap(res => of(actions.fetchDealerAssignedIPLinksSuccess({ ipLinks: res.content, id }))),
        catchError(err => of(actions.fetchDealerAssignedIPLinksFailed(getResponseError(err))))
      )
    )
  );

const assignIPLinksToDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.assignIPLinksToDealerInit.match),
    mergeMap(({ payload: { dealerId, ipLinkIds } }) =>
      http.post(api.dealers.ipLinks.assign(dealerId), { ipLinkIds }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedIPLinksInit(dealerId),

            notificationsActions.enqueue({
              message: `${ipLinkIds.length} IP Links are assigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.assignIPLinksToDealerFailed(getResponseError(err))))
      )
    )
  );

const assignIPLinkToDealerEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.assignIPLinkToDealerInit.match),
    mergeMap(({ payload: { dealerId, ipLinkId } }) =>
      http.put(api.dealers.ipLinks.byId(dealerId, ipLinkId), { ipLinkId }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedIPLinksInit(dealerId),

            notificationsActions.enqueue({
              message: `IP Link ${intToHex(ipLinkId)} is assigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.assignIPLinkToDealerFailed(getResponseError(err))))
      )
    )
  );

const uploadIPLinksViaCSVEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.uploadIPLinksViaCSVInit.match),
    mergeMap(({ payload: { dealerId, files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.dealers.ipLinks.uploadIPLinkCSV(dealerId),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedIPLinksInit(dealerId),

            notificationsActions.enqueue({
              message: 'CSV file with ip link(\'s) uploaded',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err =>
          of(
            actions.uploadIPLinksViaCSVFailed(),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const deleteDealerAssignedIPLinkEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteDealerAssignedIPLinkInit.match),
    mergeMap(({ payload: { dealerId, ipLinkId } }) =>
      http.delete(api.dealers.ipLinks.byId(dealerId, ipLinkId)).pipe(
        mergeMap(() =>
          of(
            actions.fetchDealerAssignedIPLinksInit(dealerId),

            notificationsActions.enqueue({
              message: `IP Link ${intToHex(ipLinkId)} is unassigned`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteDealerAssignedIPLinkFailed(getResponseError(err))))
      )
    )
  );

const importCSVFilesEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.importCSVFileInit.match),
    mergeMap(({ payload: { files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));
      return ajax({
        method: 'POST',
        url: api.dealers.import,
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(() =>
          of(
            actions.importCSVFileSuccess(),
            notificationsActions.enqueue({
              message: 'Dealer CSV File Uploaded',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.importCSVFileFailed(getResponseError(err)),
            notificationsActions.enqueue({
              message: getResponseError(err),
              options: { variant: 'error' },
            })
          )
        )
      );
    })
  );

export const epics = combineEpics(
  fetchDealersEpic,
  fetchDealerIdsEpic,
  fetchDealersByIdEpic,
  updateDealerInfoEpic,
  createDealerEpic,
  deleteDealerEpic,
  fetchDealerAssignedUsersEpic,
  assignUserToDealerEpic,
  deleteDealerAssignedUserEpic,
  fetchDealerAssignedUnitsEpic,
  deleteDealerAssignedUnitEpic,
  fetchDealerAssignedIPLinksEpic,
  assignIPLinksToDealerEpic,
  assignIPLinkToDealerEpic,
  deleteDealerAssignedIPLinkEpic,
  importCSVFilesEpic,
  uploadUsersViaCSVEpic,
  assignUnitToDealerEpic,
  assignUnitsToDealerEpic,
  uploadUnitsViaCSVEpic,
  uploadIPLinksViaCSVEpic
);

export const allEpics = {
  fetchDealersEpic,
  fetchDealerIdsEpic,
  fetchDealersByIdEpic,
  updateDealerInfoEpic,
  createDealerEpic,
  deleteDealerEpic,
  fetchDealerAssignedUsersEpic,
  assignUserToDealerEpic,
  deleteDealerAssignedUserEpic,
  fetchDealerAssignedUnitsEpic,
  deleteDealerAssignedUnitEpic,
  fetchDealerAssignedIPLinksEpic,
  assignIPLinksToDealerEpic,
  assignIPLinkToDealerEpic,
  deleteDealerAssignedIPLinkEpic,
  importCSVFilesEpic,
  uploadUsersViaCSVEpic,
  assignUnitToDealerEpic,
  assignUnitsToDealerEpic,
  uploadUnitsViaCSVEpic,
  uploadIPLinksViaCSVEpic,
};
declare global {
  namespace AES {
    export interface Actions {
      dealers: typeof actions;
    }
    export interface RootState {
      dealers: DealersState;
    }
    export interface Selectors {
      dealers: typeof selectors;
    }
  }
}
