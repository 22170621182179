import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectors } from '~features/theme/themeSlice';
import * as themes from '~theme/themes';

export const ThemeWrapper = ({ children }) => {
  const theme = useSelector(selectors.selectTheme);

  return (
    <MuiThemeProvider theme={{ ...themes[theme] }}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
