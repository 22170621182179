import { Theme, WithStyles, DividerProps, createStyles } from '@material-ui/core';

import { matchStyles } from '~utils';

export type CustomDividerProps = DividerProps &
  WithStyles<typeof styles> & {
    spacing?: 'left' | 'right' | 'top' | 'bottom' | 'both';
  };

export const styles = ({ spacing, palette: { type, greyColor } }: Theme) => {
  const getStyles = matchStyles(type);

  return createStyles({
    root: {
      backgroundColor: getStyles(greyColor.dark, greyColor.main),
      opacity: getStyles(0.8, 0.5),
      '&.horizontal': {
        '&.top': {
          marginTop: spacing(3),
        },
        '&.bottom': {
          marginBottom: spacing(3),
        },
        '&.both': {
          marginTop: spacing(3),
          marginBottom: spacing(3),
        },
      },
      '&.vertical': {
        width: 1,
        '&.left': {
          marginLeft: spacing(3),
        },
        '&.right': {
          marginRight: spacing(3),
        },
        '&.both': {
          marginLeft: spacing(3),
          marginRight: spacing(3),
        },
      },
    },
  });
};
