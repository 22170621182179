import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    divider: {
      margin: theme.spacing(3, -4, 2, -4),
    },
    sections: {
      marginRight: theme.spacing(-4),
      paddingRight: theme.spacing(4),
      maxHeight: 250,
      overflowY: 'auto',
    },
    placeholder: {
      padding: 0,
    },
  });
