import { styled } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

import { matchStyles } from '~utils';

export const NavLink = styled(RouterLink)(({ theme }) => {
  const getStyles = matchStyles(theme.palette.type);

  return {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: getStyles(theme.palette.primary.light, theme.palette.primary.dark),
    },
  };
});
