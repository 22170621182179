import { InputClassKey, StyleRules, Theme } from '@material-ui/core';
import { mergeDeepRight } from 'ramda';

import { classNames } from '../muiClassNames';

type InputStyles = Partial<StyleRules<InputClassKey>>;

const commonStyles = (): InputStyles => ({
  root: {
    fontSize: 14,
    minHeight: 30
  },
  underline: {
    ['&:before']: {
      borderBottomWidth: 1,
    },
    ['&:after']: {
      borderBottomWidth: 1,
    },
    [`&:hover:not(.${classNames.disabled}):before`]: {
      borderBottomWidth: 1,
    },
  },
  input: {
    [`
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active
    `]: {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
});

export const lightInputStyles = (theme: Theme) => mergeDeepRight<InputStyles, InputStyles>(commonStyles(), {
  root: {
    color: theme.palette.blueColor.main,
  },
  underline: {
    ['&:before']: {
      borderBottomColor: theme.palette.greyColor.main,
    },
  }
}) as InputStyles;
export const darkInputStyles = (theme: Theme) => mergeDeepRight<InputStyles, InputStyles>(commonStyles(), {
  root: {
    color: theme.palette.greyColor.light
  },
  underline: {
    ['&:before']: {
      borderBottomColor: theme.palette.greyColor.dark,
    },
  }
}) as InputStyles;
