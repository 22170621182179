import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    dialog: {
      paddingBottom: 0,
      overflowX: 'hidden',
    },

    divider: {
      marginRight: spacing(-8),
      marginLeft: spacing(-8),
    },

    control: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    checkbox: {
      marginBottom: 0
    },

    buttons: {
      justifyContent: 'space-between',
    },

    loader: {
      height: spacing(50),
    }
  });
