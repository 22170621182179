import { Theme, Container, Grid } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { PageLoader, PageContent, PagePagination } from '../Page';

import { Pagination } from '~models';
import { selectors } from '~store';
import { DataPlaceholder } from '~ui-kit';

export interface PaginatedUnitsListProps<T> {
  data: T[];
  loading?: boolean;
  renderItem: (item: T, i: number) => unknown;
  onPaginationChange: (pagination: Pagination) => void;
}

const GridContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      flexDirection: 'row',
      paddingLeft: 12,
      marginLeft: 12,
      justifyContent: 'space-between',
      gridTemplateColumns: 'repeat(10, 1fr)',
      width: '100%',
      '&:nth-child(n)': {
        marginBottom: '30px',
      },
    },
  })
)(Grid);

const PaginationContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginRight: -12,
      justifyContent: 'flex-end',
    },
  })
)(Container);

export const PaginatedUnitsList = <T extends unknown>({
  data,
  loading,
  renderItem,
  onPaginationChange,
}: PaginatedUnitsListProps<T>) => {
  const pagination = useSelector(selectors.page.getPagePagination, equals);

  if (loading) {
    return (
      <PageContent>
        <PageLoader />
      </PageContent>
    );
  }

  return (
    <Fragment>
      <PageContent>
        {data?.length ? <GridContainer>{data.map(renderItem)} </GridContainer> : <DataPlaceholder />}
      </PageContent>

      {pagination.totalPages > 1 ? (
        <PaginationContainer>
          <PagePagination isHideTitle={true} pagination={pagination} loading={loading} onChange={onPaginationChange} />
        </PaginationContainer>
      ) : null}
    </Fragment>
  );
};
