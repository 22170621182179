import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, filter, mergeMap } from 'rxjs/operators';

import { api } from '~constants/api';

import { actions as appActions } from '../app/appSlice';
import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as sharedActions } from '../sharedSlice';

import { getSettingsRequestConfig } from './settingsUtils';

import {
  NetworkSettings,
  ServerSettings,
  SystemSettings,
  TechOption,
  ResponseError,
  SubToolsSettings,
  SubToolsExecuteSection,
  SystemCoordinateSettings,
  SystemInfoConfigurationSettings,
  SystemCheckInGraceConfiguration,
  Antenna,
  FCCData,
  SecondaryInstanceInfo,
  SSLRequest,
  DatabaseDump,
} from '~models';
import { http } from '~services';
import { getResponseError, saveFile } from '~utils';

export type SettingsTab =
  | 'system'
  | 'server'
  | 'network'
  | 'tech-options'
  | 'sub-tools'
  | 'coordinate'
  | 'length-unit'
  | 'check-in'
  | 'antennas'
  | 'call-sign'
  | 'instances'
  | 'maintenance';

export interface SettingsState {
  data: {
    system: SystemSettings | null;
    server: ServerSettings | null;
    network: NetworkSettings | null;
    'tech-options': TechOption[] | null;
    'sub-tools': SubToolsSettings | null;
    coordinate: SystemCoordinateSettings | null;
    'length-unit': SystemInfoConfigurationSettings | null;
    'check-in': SystemCheckInGraceConfiguration | null;
    'call-sign': FCCData | null;
    instances: SecondaryInstanceInfo | null;
    databaseDumps: DatabaseDump[] | null;
  };
  error: { [key in SettingsTab]: ResponseError | null };
  loading: {
    details: { [key in SettingsTab]: boolean };
    update: boolean;
    'sub-tools': { [key in SubToolsExecuteSection]: boolean };
    coordinate: boolean;
    'length-unit': boolean;
    'check-in': boolean;
    'call-sign': boolean;
    instances: boolean;
    maintenance: boolean;
    'ssl-upload': boolean;
    import: boolean;
    databaseDumps: boolean,
  };
  status: 'updated' | 'ip-changed' | 'ssl-created' | 'address-imported' | null;
  antennas: {
    list: Antenna[] | null;
    loading: {
      add: boolean;
      list: boolean;
      delete: boolean;
    };
    status: 'created' | null;
  };
}

export const initialState: SettingsState = {
  data: {
    system: null,
    server: null,
    network: null,
    'tech-options': null,
    'sub-tools': null,
    coordinate: null,
    'length-unit': null,
    'check-in': null,
    'call-sign': null,
    instances: null,
    databaseDumps: null,
  },
  error: {
    system: null,
    server: null,
    network: null,
    'tech-options': null,
    'sub-tools': null,
    coordinate: null,
    'length-unit': null,
    'check-in': null,
    antennas: null,
    'call-sign': null,
    instances: null,
    maintenance: null,
  },
  loading: {
    details: {
      system: false,
      server: false,
      network: false,
      'tech-options': false,
      'sub-tools': false,
      coordinate: false,
      'length-unit': false,
      'check-in': false,
      antennas: false,
      'call-sign': false,
      instances: false,
      maintenance: false,
    },
    update: false,
    'sub-tools': {
      'first-data': false,
      'refresh-data': false,
    },
    coordinate: false,
    'length-unit': false,
    'check-in': false,
    'call-sign': false,
    instances: false,
    maintenance: false,
    'ssl-upload': false,
    import: false,
    databaseDumps: false,
  },
  status: null,
  antennas: {
    list: null,
    loading: {
      add: false,
      list: false,
      delete: false,
    },
    status: null,
  },
};

export const { name, actions, reducer } = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // Fetch settings
    fetchSettingsInit(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab; silent?: boolean }>) {
      state.loading.details[tab] = true;
      state.status = null;
      state.error = initialState.error;
      state.data[tab] = initialState.data[tab];
    },
    fetchSettingsSuccess<T>(state, { payload: { tab, values } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading.details[tab] = false;
      state.data[tab] = values;
    },
    fetchSettingsFail(state, { payload: { tab, error } }: PayloadAction<{ tab: SettingsTab; error: ResponseError }>) {
      state.loading.details[tab] = false;
      state.error[tab] = error;
    },

    // Update settings
    updateSettingsInit<T>(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading.update = true;
      state.status = null;
      state.error = initialState.error;
    },
    updateSettingsSuccess<T>(state, { payload: { tab, values } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading.update = false;
      state.status = 'updated';
    },
    updateSettingsFailed(
      state,
      { payload: { tab, error } }: PayloadAction<{ tab: SettingsTab; error: ResponseError }>
    ) {
      state.loading.update = false;
      state.status = null;

      state.error[tab] = error;
    },

    // Update server coordinates
    updateServerCoordinatesInit<T>(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading[tab] = true;
      state.error[tab] = initialState.error;
    },
    updateServerCoordinatesSuccess<T>(
      state,
      { payload: { tab, values } }: PayloadAction<{ tab: SettingsTab; values: T }>
    ) {
      state.data[tab] = values;
      state.loading[tab] = false;
    },
    updateServerCoordinatesFailed(
      state,
      { payload: { tab, error } }: PayloadAction<{ tab: SettingsTab; error: ResponseError }>
    ) {
      state.error[tab] = error;
      state.loading[tab] = false;
    },

    //Update system configuration
    updateSystemConfigurationInit<T>(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading['length-unit'] = true;
      state.status = null;
      state.error = initialState.error;
    },
    updateSystemConfigurationSuccess(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab }>) {
      state.loading['length-unit'] = false;
      state.status = 'updated';
    },
    updateSystemConfigurationFailed(
      state,
      { payload: { tab, error } }: PayloadAction<{ tab: SettingsTab; error: ResponseError }>
    ) {
      state.loading['length-unit'] = false;
      state.status = null;
      state.error[tab] = error;
    },

    // Update network settings
    updateNetworkSettingsInit<T>(state, { payload }: PayloadAction<{ values: T }>) {
      state.loading.update = true;
      state.status = null;
      state.error = initialState.error;
    },
    updateNetworkSettingsSuccess<T>(state, { payload }: PayloadAction<{ ipChanged: boolean; values: T }>) {
      state.data.network = payload?.values;
      if (payload?.ipChanged) {
        state.status = 'ip-changed';
      } else {
        state.status = 'updated';
      }
      state.loading.update = false;
    },
    updateNetworkSettingsFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.update = false;
      state.error.network = error;
    },

    // Execute sub-tools
    executeSubtoolsJobInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubToolsExecuteSection;
        values: Partial<SubToolsSettings['firstData'] | SubToolsSettings['refreshData']>;
      }>
    ) {
      state.loading['sub-tools'][section] = true;
    },
    executeSubtoolsJobSuccess(state, { payload: { section } }: PayloadAction<{ section: SubToolsExecuteSection }>) {
      state.loading['sub-tools'][section] = false;
    },
    executeSubtoolsJobFailed(
      state,
      { payload: { section } }: PayloadAction<{ section: SubToolsExecuteSection; error: ResponseError }>
    ) {
      state.loading['sub-tools'][section] = false;
    },

    updateCheckInGracePeriodInit<T>(state, { payload: { tab } }: PayloadAction<{ tab: SettingsTab; values: T }>) {
      state.loading['check-in'] = true;
      state.status = null;
      state.error = initialState.error;
    },
    updateCheckInGracePeriodSuccess<T>(
      state,
      { payload: { tab, values } }: PayloadAction<{ tab: SettingsTab; values: T }>
    ) {
      state.loading['check-in'] = false;
      state.status = 'updated';
    },
    updateCheckInGracePeriodFailed(
      state,
      { payload: { tab, error } }: PayloadAction<{ tab: SettingsTab; error: ResponseError }>
    ) {
      state.loading['check-in'] = false;
      state.status = null;
      state.error[tab] = error;
    },

    fetchAntennasInit(state) {
      state.status = null;
      state.error = initialState.error;
      state.antennas.list = initialState.antennas.list;
      state.antennas.loading.list = true;
    },

    fetchAntennasSuccess<T>(state, { payload: { values } }: PayloadAction<{ values: T }>) {
      state.antennas.loading.list = false;
      state.antennas.list = values;
    },
    fetchAntennasFail(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.antennas.loading.list = false;
      state.error.antennas = error;
    },

    deleteAntennaInit(state, { payload }: PayloadAction<{ id: Antenna['id']; name: Antenna['name'] }>) {
      state.antennas.loading.delete = true;
    },

    deleteAntennaSuccess(state, { payload }: PayloadAction<Antenna['id']>) {
      state.antennas.loading.delete = false;
    },
    deleteAntennaFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.antennas.loading.delete = false;
      state.error.antennas = payload;
    },

    addAntennaInit(state, { payload }: PayloadAction<{ antennaName: Antenna['name'] }>) {
      state.antennas.loading.add = true;
    },

    addAntennaSuccess(state, { payload }: PayloadAction<Antenna>) {
      state.antennas.loading.add = false;
      state.antennas.status = 'created';
    },

    addAntennaFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.antennas.loading.add = false;
      state.error.antennas = payload;
    },

    // maintenance: SSL certificate request
    createSSLRequestInit(state, { payload }: PayloadAction<SSLRequest>) {
      state.loading.maintenance = true;
    },

    createSSLRequestSuccess(state) {
      state.loading.maintenance = false;
      state.status = 'ssl-created';
    },

    createSSLRequestFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.maintenance = false;
      state.error.maintenance = payload;
    },

    // upload SSL files
    upLoadSSLFilesInit(state, { payload: { files } }: PayloadAction<{ files: File[] }>) {
      state.loading['ssl-upload'] = true;
      state.status = null;
    },

    upLoadSSLFilesSuccess(state) {
      state.loading['ssl-upload'] = false;
      state.status = 'updated';
    },

    upLoadSSLFilesFailed(state) {
      state.loading['ssl-upload'] = false;
    },

    // Import CSV file
    importUnitsBulkAddressInit: (state, action: PayloadAction<{ files: File[] }>) => {
      state.loading.import = true;
      state.status = null;
    },
    importUnitsBulkAddressSuccess: state => {
      state.loading.import = false;
      state.status = 'address-imported';
    },
    importUnitsBulkAddressFailed: state => {
      state.loading.import = false;
    },

    // Create Database Dump
    createDatabaseDumpInit: () => {},
    createDatabaseDumpFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.error.system = payload;
    },

    // Fetch Database Dumps
    fetchDatabaseDumpsInit: state => {
      state.loading.databaseDumps = true;
    },
    fetchDatabaseDumpsSuccess: (state, action: PayloadAction<DatabaseDump[]>) => {
      state.loading.databaseDumps = false;
      state.data.databaseDumps = action.payload;
    },
    fetchDatabaseDumpsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.databaseDumps = false;
      state.error.system = payload;
    },

    // Fetch Database Dump by id
    fetchDatabaseDumpByIdInit: (state, action: PayloadAction<string>) => {},
    fetchDatabaseDumpByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.error.system = payload;
    },

    // Reset settings
    reset(state) {
      Object.assign(state, initialState);
    },

    // Reset status
    resetStatus(state) {
      state.status = null;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getSettingsState = (state: AES.RootState) => state.settings;

export const selectors = {
  getSettingsValues: <T>(tab: SettingsTab) =>
    createDraftSafeSelector(getSettingsState, state => (state.data[tab] as unknown) as T),

  getSettingsError: (tab: SettingsTab) => createDraftSafeSelector(getSettingsState, state => state.error[tab]),
  getSettingsLoaders: createDraftSafeSelector(getSettingsState, state => state.loading),
  getSettingsStatus: createDraftSafeSelector(getSettingsState, state => state.status),
  getAntennaValues: createDraftSafeSelector(getSettingsState, state => state.antennas),
  getDatabaseDumps: createDraftSafeSelector(getSettingsState, state => state.data.databaseDumps),
  areAddressImported: createDraftSafeSelector(getSettingsState, state => state.status === 'address-imported'),
};

const fetchSettings = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSettingsInit.match),
    mergeMap(({ payload: { tab, silent } }) => {
      const { url } = getSettingsRequestConfig(tab);

      return http.getJSON(url).pipe(
        mergeMap(values => {
          const fetchSettingsSuccess = actions.fetchSettingsSuccess({ tab, values });

          if (silent) {
            return of(fetchSettingsSuccess);
          }

          return of(fetchSettingsSuccess);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSettingsFail({ tab, error }));
        })
      );
    })
  );

const updateSettings = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSettingsInit.match),
    mergeMap(({ payload: { tab, values } }) => {
      const { url, title, handler } = getSettingsRequestConfig(tab);

      return http[handler](url, values).pipe(
        mergeMap(() =>
          of(
            actions.updateSettingsSuccess({ tab, values }),
            actions.fetchSettingsInit({ tab }),
            notificationsActions.enqueue({
              message: `${title} settings updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateSettingsFailed({ tab, error }));
        })
      );
    })
  );

export const executeSubtoolsJobEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.executeSubtoolsJobInit.match),
    mergeMap(({ payload: { section, values } }) =>
      http.post(api.settings.executeSubTools(section), values).pipe(
        mergeMap(() =>
          of(
            actions.executeSubtoolsJobSuccess({ section }),
            notificationsActions.enqueue({
              message: `Subtools ${section} job execution started`,
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.executeSubtoolsJobFailed({ section, error: getResponseError(err) })))
      )
    )
  );

const updateServerCoordinate = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateServerCoordinatesInit.match),
    mergeMap(({ payload: { tab, values } }) => {
      const { url, title, handler } = getSettingsRequestConfig(tab);

      return http[handler](url, values).pipe(
        mergeMap(() =>
          of(
            actions.updateServerCoordinatesSuccess({ tab, values }),
            notificationsActions.enqueue({
              message: `${title} settings updated`,
              options: { variant: 'success' },
            }),
            appActions.fetchSystemInfoInit()
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateServerCoordinatesFailed({ tab, error }));
        })
      );
    })
  );

const updateSystemConfiguration = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSystemConfigurationInit.match),
    mergeMap(({ payload: { tab, values } }) => {
      const { url, title, handler } = getSettingsRequestConfig(tab);

      return http[handler](url, values).pipe(
        mergeMap(() =>
          of(
            actions.updateSystemConfigurationSuccess({ tab }),
            notificationsActions.enqueue({
              message: `${title} settings updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateSystemConfigurationFailed({ tab, error }));
        })
      );
    })
  );

const updateNetworkSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateNetworkSettingsInit.match),
    mergeMap(({ payload: { values } }) =>
      http.put(api.settings.network, values).pipe(
        mergeMap(({ response }) => {
          const epicActions: AnyAction[] = [
            actions.updateNetworkSettingsSuccess({ ipChanged: response?.ipChanged, values }),
          ];

          if (response?.message) {
            epicActions.push(
              notificationsActions.enqueue({
                message: response?.message,
                options: { variant: 'success' },
              })
            );
          }
          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateNetworkSettingsFailed({ error }));
        })
      )
    )
  );

const updateCheckInGracePeriodEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateCheckInGracePeriodInit.match),
    mergeMap(({ payload: { tab, values } }) => {
      const { url, title, handler } = getSettingsRequestConfig(tab);

      return http[handler](url, values).pipe(
        mergeMap(() =>
          of(
            actions.updateCheckInGracePeriodSuccess({ tab, values }),
            notificationsActions.enqueue({
              message: `${title} period updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateCheckInGracePeriodFailed({ tab, error }));
        })
      );
    })
  );

const fetchAntennaSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAntennasInit.match),
    mergeMap(({ payload }) =>
      http.getJSON(api.antenna.all).pipe(
        mergeMap(values => of(actions.fetchAntennasSuccess({ values }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchAntennasFail({ error }));
        })
      )
    )
  );

const deleteAntennaEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteAntennaInit.match),
    mergeMap(({ payload }) =>
      http.delete(api.antenna.byId(payload.id)).pipe(
        mergeMap(() =>
          of(
            actions.deleteAntennaSuccess(payload.id),
            notificationsActions.enqueue({
              message: `Antenna ${payload.name} was deleted`,
              options: { variant: 'success' },
            }),
            actions.fetchAntennasInit()
          )
        ),
        catchError(err => of(actions.deleteAntennaFailed(getResponseError(err))))
      )
    )
  );

const addAntennaEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.addAntennaInit.match),
    mergeMap(({ payload: { antennaName } }) =>
      http.post(api.antenna.all, { antennaName }).pipe(
        mergeMap(res =>
          of(
            actions.addAntennaSuccess(res.response),
            notificationsActions.enqueue({
              message: `Antenna ${antennaName} added to the list`,
              options: { variant: 'success' },
            }),
            actions.fetchAntennasInit()
          )
        ),
        catchError(err => of(actions.addAntennaFailed(getResponseError(err))))
      )
    )
  );

const createSSLRequestEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createSSLRequestInit.match),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'POST',
          url: api.settings.maintenance,
          body: payload,
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'incc.csr';
            saveFile(res.response, fileName);

            return of(
              actions.createSSLRequestSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been downloaded`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.createSSLRequestFailed(getResponseError(err))))
        )
    )
  );

const upLoadSSLFilesEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.upLoadSSLFilesInit.match),
    mergeMap(({ payload: { files } }) => {
      const formData = new FormData();

      files.forEach(file => {
        if (file.name.endsWith('.crt')) {
          formData.append('crtFile', file, file.name);
        }

        if (file.name.endsWith('.key')) {
          formData.append('sslFile', file, file.name);
        }
      });

      return ajax({
        method: 'POST',
        url: api.settings.sslFilesUpload,
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        mergeMap(() =>
          of(
            actions.upLoadSSLFilesSuccess(),
            notificationsActions.enqueue({
              message: 'SSL file(s) uploaded',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.upLoadSSLFilesFailed(),
            notificationsActions.enqueue({
              message: getResponseError(err).message,
              options: { variant: 'error' },
            })
          )
        )
      );
    })
  );

const importUnitsBulkAddressEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.importUnitsBulkAddressInit.match),
    mergeMap(({ payload: { files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.settings.import,
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
        responseType: 'text/plain',
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importUnitsBulkAddressSuccess(),
            notificationsActions.enqueue({
              message: res.response,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err =>
          of(
            actions.importUnitsBulkAddressFailed(),
            notificationsActions.enqueue({ message: err.response, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const createDatabaseDumpInit = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createDatabaseDumpInit.match),
    mergeMap(() =>
      http.post(api.system.databaseDumps.all).pipe(
        mergeMap(() => of(
          notificationsActions.enqueue({
            message: 'Database dump creating started',
            options: { variant: 'success' },
          })
        )),
        catchError(err => of(
          actions.createDatabaseDumpFailed(getResponseError(err))
        ))
      )
    )
  );

const fetchDatabaseDumpsInit = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDatabaseDumpsInit.match),
    mergeMap(() =>
      http.getJSON<DatabaseDump[]>(api.system.databaseDumps.all).pipe(
        mergeMap(res => of(actions.fetchDatabaseDumpsSuccess(res))),
        catchError(err => of(
          actions.fetchDatabaseDumpsFailed(getResponseError(err))
        ))
      )
    )
  );

const fetchDatabaseDumpByIdInit = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDatabaseDumpByIdInit.match),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'GET',
          url: api.system.databaseDumps.byId(payload),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            saveFile(res.response, payload);

            return of(
              notificationsActions.close('export-db-dump-started'),
              notificationsActions.enqueue({
                message: 'Database Dump has been exported',
                options: {
                  variant: 'success',
                  autoHideDuration: null,
                },
              })
            );
          }),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchDatabaseDumpByIdFailed(error));
          })
        )
    )
  );

export const epics = combineEpics(
  fetchSettings,
  updateSettings,
  executeSubtoolsJobEpic,
  updateServerCoordinate,
  updateSystemConfiguration,
  updateCheckInGracePeriodEpic,
  updateNetworkSettingsEpic,
  fetchAntennaSettingsEpic,
  deleteAntennaEpic,
  addAntennaEpic,
  createSSLRequestEpic,
  upLoadSSLFilesEpic,
  importUnitsBulkAddressEpic,
  createDatabaseDumpInit,
  fetchDatabaseDumpsInit,
  fetchDatabaseDumpByIdInit,
);
export const allEpics = {
  fetchSettings,
  updateSettings,
  executeSubtoolsJobEpic,
  updateServerCoordinate,
  updateSystemConfiguration,
  updateCheckInGracePeriodEpic,
  updateNetworkSettingsEpic,
  fetchAntennaSettingsEpic,
  deleteAntennaEpic,
  addAntennaEpic,
  createSSLRequestEpic,
  upLoadSSLFilesEpic,
  importUnitsBulkAddressEpic,
  createDatabaseDumpInit,
  fetchDatabaseDumpsInit,
  fetchDatabaseDumpByIdInit,
};

declare global {
  namespace AES {
    export interface RootState {
      settings: SettingsState;
    }
    export interface Actions {
      settings: typeof actions;
    }

    export interface Selectors {
      settings: typeof selectors;
    }
  }
}
