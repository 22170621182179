import { Grid } from '@material-ui/core';
import { omit } from 'ramda';

import { getStartDayTimestamp, monthAndDayDateFormatter } from '~utils/date';

import {
  FormAutocompleteField,
  FormDatePicker,
  FormSliderField,
  mapSelectOptions,
  SelectOption,
  SideFiltersCategory } from '~ui-kit';

const DateTimePeriod = () => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <FormDatePicker name="fromTimestamp" placeholder="From" />
    </Grid>
    <Grid item xs={6}>
      <FormDatePicker name="toTimestamp" placeholder="To" />
    </Grid>
  </Grid>
);

export type FaultsListFiltersValues = {
  name: string;
	cid: string;
  dealerId: string;
  unitType: string;
	dependentDayCount: number[];
	minDependentDayCount: number;
	maxDependentDayCount: number;
	minDependentDecadeCount: number;
	maxDependentDecadeCount: number;
	dependentDecadeCount: number[];
	fromTimestamp: string;
  toTimestamp: string;
  minTimestamp: number;
  maxTimestamp: number;
};

export type FaultsListFiltersPayload = {
	names: string[];
	cids: string[];
  dealers: SelectOption[];
  unitTypes: string[];
	minDependentDayCount: number;
	maxDependentDayCount: number;
	minDependentDecadeCount: number;
	maxDependentDecadeCount: number;
	minTimestamp: number;
	maxTimestamp: number;
};

export type FaultsListFiltersCategories = {
	name: SideFiltersCategory;
	cid: SideFiltersCategory;
  dealerId: SideFiltersCategory;
  unitType: SideFiltersCategory;
	dependentDayCount: SideFiltersCategory;
	dependentDecadeCount: SideFiltersCategory;
	fromTimestamp: SideFiltersCategory;
  toTimestamp: SideFiltersCategory;
};

export const mapFaultsFiltersOptions = ({
  names,
  cids,
  dealers,
  unitTypes,
  minDependentDayCount,
  maxDependentDayCount,
  minDependentDecadeCount,
  maxDependentDecadeCount,
  minTimestamp,
  maxTimestamp,
}: FaultsListFiltersPayload): {
  values: FaultsListFiltersValues;
  categories: FaultsListFiltersCategories;
} => {
  const categories: FaultsListFiltersCategories = {
    name: {
      title: 'Event Name',
      options: mapSelectOptions(names),
    },
    cid: {
      title: 'CID Code',
      options: mapSelectOptions(cids),
    },
    dealerId: {
      title: 'Dealer',
      options: dealers,
    },
    unitType: {
      title: 'Unit Type',
      options: mapSelectOptions(unitTypes),
    },
    dependentDayCount: {
      title: 'Dependent subscribers in the last 24 hrs',
      range: [minDependentDayCount, maxDependentDayCount],
      names: ['minDependentDayCount', 'maxDependentDayCount'],
    },
    dependentDecadeCount: {
      title: 'Dependent subscribers in the last 10 days',
      range: [minDependentDecadeCount, maxDependentDecadeCount],
      names: ['minDependentDecadeCount', 'maxDependentDecadeCount'],
    },
    fromTimestamp: {
      title: 'From',
      formatter: monthAndDayDateFormatter,
    },
    toTimestamp: {
      title: 'To',
      formatter: monthAndDayDateFormatter,
    },
  };

  return {
    categories,
    values: {
      name: '',
      cid: '',
      dealerId: '',
      unitType: '',
      dependentDayCount: categories.dependentDayCount.range as number[],
      minDependentDayCount,
      maxDependentDayCount,
      dependentDecadeCount: categories.dependentDecadeCount.range as number[],
      minDependentDecadeCount,
      maxDependentDecadeCount,
      fromTimestamp: '',
      toTimestamp: '',
      minTimestamp,
      maxTimestamp,
    }
  };
};

export const getFaultsListFiltersFields = (categories: FaultsListFiltersCategories) => {
  if (!Object.keys(categories).length) {
    return [];
  }

  return [
    {
      title: categories.name?.title,
      name: 'name',
      placeholder: 'Enter event name',
      options: categories.name?.options || [],
      Component: FormAutocompleteField,
    },
    {
      title: categories.cid?.title,
      name: 'cid',
      placeholder: 'Enter CID code',
      options: categories.cid?.options || [],
      Component: FormAutocompleteField,
    },
    {
      title: categories.dealerId?.title,
      name: 'dealerId',
      placeholder: 'Enter dealer name',
      options: categories.dealerId?.options || [],
      Component: FormAutocompleteField,
    },
    /*{
      title: categories.unitType?.title,
      name: 'unitType',
      placeholder: 'Enter unit type',
      options: categories.unitType?.options || [],
      Component: FormAutocompleteField,
    },*/
    {
      title: categories.dependentDayCount?.title,
      name: 'dependentDayCount',
      names: ['minDependentDayCount', 'maxDependentDayCount'],
      range: categories.dependentDayCount?.range || [],
      Component: FormSliderField,
    },
    {
      title: categories.dependentDecadeCount?.title,
      name: 'dependentDecadeCount',
      names: ['minDependentDecadeCount', 'maxDependentDecadeCount'],
      range: categories.dependentDecadeCount?.range || [],
      Component: FormSliderField,
    },
    {
      title: 'Date',
      Component: DateTimePeriod,
    },
  ];
};

export const faultListFiltersCounterOmitFields = [
  'minDependentDayCount',
  'maxDependentDayCount',
  'minDependentDecadeCount',
  'maxDependentDecadeCount',
];

export const faultsListFiltersRequestOmitFields = [
  'unitType',
  'dependentDayCount',
  'dependentDecadeCount',
  'minTimestamp',
  'maxTimestamp',
];

export const prepareFiltersPayload = (filtersValues: FaultsListFiltersValues) => {
  const filters = {
    ...filtersValues,
    fromTimestamp:
              getStartDayTimestamp(filtersValues.fromTimestamp) ||
              (filtersValues.minTimestamp as unknown as string),
    toTimestamp:
              getStartDayTimestamp(filtersValues.toTimestamp) ||
              (filtersValues.maxTimestamp as unknown as string),
  };

  return omit(faultsListFiltersRequestOmitFields, filters);
};

