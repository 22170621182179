import { Accordion as MuiAccordion, AccordionProps, Paper, WithStyles, withStyles } from '@material-ui/core';

import { styles } from './Card.styles';

type CustomCardProps = AccordionProps &
  WithStyles<typeof styles> & {
    expandable?: boolean;
  };

export const Card = withStyles(styles)(({ expandable, ...props }: CustomCardProps) => {
  if (!expandable) {
    return <Paper className={props.classes.root}>{props.children}</Paper>;
  }

  return <MuiAccordion {...props} onClick={e => e.stopPropagation()} />;
});
