import { Box, WithStyles, withStyles } from '@material-ui/core';
import { Fragment, PropsWithChildren } from 'react';

import { styles } from './CardActions.styles';

import { Divider } from '~ui-kit';

type CardActionsProps = PropsWithChildren<WithStyles<typeof styles>>;

export const CardActions = withStyles(styles)(({ classes, children }: CardActionsProps) => (
  <Fragment>
    <Divider flexItem orientation="vertical" spacing="right" />

    <Box className={classes.root}>{children}</Box>
  </Fragment>
));
