import { Theme } from '@material-ui/core';

import { colors } from '~styles/colors';

import { classNames } from '../muiClassNames';

export const styles = ({ palette: { transparentColor } }: Theme) => ({
  root: {
    boxShadow: 'none !important',
    backgroundColor: transparentColor.main,
    marginBottom: 4,
    padding: 0,
    '&:before': {
      content: 'none',
    },
    '&:last-child:not(:first-child)': {
      marginBottom: 0,
    },
    '& .MuiCollapse-container': {
      boxShadow: colors.BOX_SHADOW,
      borderRadius: 4,
      overflow: 'hidden',
    },
    [`&.${classNames.expanded}`]: {
      marginTop: 0,
      marginBottom: 4,
    },
  },
});
