import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import { load } from 'redux-localstorage-simple';

export interface SidebarState {
  isExpanded: boolean;
  isAppPanelVisible?: boolean;
}

export const { layout: initialState = { isExpanded: true, isAppPanelVisible: true } } = load({
  states: ['layout'],
  disableWarnings: true,
}) as {
  layout: SidebarState;
};

export const { actions, reducer, name } = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    expand: state => {
      state.isExpanded = true;
    },
    collapse: state => {
      state.isExpanded = false;
    },
    toggle: state => {
      state.isExpanded = !state.isExpanded;
    },

    // App panel appearance
    showAppPanel: state => {
      state.isAppPanelVisible = true;
    },
    hideAppPanel: state => {
      state.isAppPanelVisible = false;
    },
  },
});

const getLayoutState = (state: AES.RootState) => state.layout;
export const selectors = {
  getLayoutState,

  isExpanded: createDraftSafeSelector(getLayoutState, state => state.isExpanded),
  isAppPanelVisible: createDraftSafeSelector(getLayoutState, state => state.isAppPanelVisible),
};

declare global {
  namespace AES {
    export interface RootState {
      layout: SidebarState;
    }
    export interface Actions {
      layout: typeof actions;
    }
    export interface Selectors {
      layout: typeof selectors;
    }
  }
}
