import { createSlice, EntityAdapter, PayloadAction, AnyAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { groupBy, prop, keys } from 'ramda';
import { ViewportProps } from 'react-map-gl';
import { load } from 'redux-localstorage-simple';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { http } from '~services/http';

import { actions as sharedActions } from '../sharedSlice';

import { entities } from './geographyAdapters';
import {
  getLinkLayersIds,
  getDependencyIds,
  mapGeoUnits,
  mapRoutesToFeatureCollection,
  mapRoutesWithGeolocation,
  mapRoutesWithKeyField,
  mapUnitsToFeatureCollection,
} from './geographyUtils';

import { api } from '~constants';
import {
  BusinessUnit,
  UnitType,
  GeoUnitRoute,
  GeoCoordinates,
  ResponseError,
  GeoUnitModel,
  UnitRoute,
  MapLayerType,
  MapFilter,
  GeoFaultsByUnitType,
  GeoFaultsByCode,
  GeoLinkLayers,
  GeoDependency,
  BusinessUnitGeoCenter,
  DependencyTypeColor
} from '~models';
import { getResponseError } from '~utils';

export type GeographyState = {
  businessUnitId: BusinessUnit['id'] | null;
  mapLayerType: MapLayerType;
  mapFilter: MapFilter;
  viewport: ViewportProps;
  geoCenterPerBU: BusinessUnitGeoCenter | null;
  loader: boolean;
  error?: ResponseError | null;

  units: {
    SUBSCRIBER: ReturnType<typeof entities.units.SUBSCRIBER.getInitialState>;
    HYBRID: ReturnType<typeof entities.units.HYBRID.getInitialState>;
    IP_LINK: ReturnType<typeof entities.units.IP_LINK.getInitialState>;
    NON_AES: ReturnType<typeof entities.units.NON_AES.getInitialState>;
  };
  routes: ReturnType<typeof entities.routes.getInitialState>;
  selected: {
    unit: GeoUnitModel | null;
    units: GeoUnitModel[] | null;
    route: GeoUnitRoute | null;
  };
  faults: {
    byUnitType: GeoFaultsByUnitType | null;
    byCode: GeoFaultsByCode | null;
  }
  linkLayers: {
    all: GeoLinkLayers;
    ids: number[];
  } | null;
  dependency: {
    all: GeoDependency;
    ids: { id: number, dependency: DependencyTypeColor }[];
  } | null;
};

const loaded = load({ states: [
  'geography.businessUnitId',
  'geography.mapLayerType',
  'geography.viewport',
  'geography.mapFilter'
],
disableWarnings: true }) as {
  geography: Partial<GeographyState>;
};

const defaultState: GeographyState = {
  businessUnitId: null,
  mapLayerType: MapLayerType.STREET,
  mapFilter: MapFilter.All,
  geoCenterPerBU: null,
  viewport: {
    latitude: 42.55563043952694,
    longitude: -70.97894693424195,
    zoom: 14,
    minZoom: 5,
  },
  loader: false,
  error: null,
  units: {
    SUBSCRIBER: entities.units.SUBSCRIBER.getInitialState(),
    IP_LINK: entities.units.IP_LINK.getInitialState(),
    HYBRID: entities.units.HYBRID.getInitialState(),
    NON_AES: entities.units.NON_AES.getInitialState(),
  },
  routes: entities.routes.getInitialState(),
  selected: {
    unit: null,
    units: [],
    route: null,
  },
  faults: {
    byUnitType: null,
    byCode: null,
  },
  linkLayers: null,
  dependency: null,
};
export const initialState: GeographyState = {
  ...defaultState,
  ...(loaded.geography || {}),
};

export const { name, reducer, actions } = createSlice({
  name: 'geography',
  initialState,
  reducers: {
    // Select business unit
    selectBusinessUnit(state, { payload }: PayloadAction<BusinessUnit['id']>) {
      state.businessUnitId = payload;
      state.selected.route = null;
      state.selected.unit = null;
    },

    // Fetch units
    fetchUnitsInit(state, action: PayloadAction<{ businessUnitId: BusinessUnit['id'] }>) {
      state.loader = true;
    },
    fetchUnitsSuccess(state, action: PayloadAction<(GeoUnitModel & { unitType: UnitType })[]>) {
      const groups = groupBy(prop('unitType'), action.payload);

      (['HYBRID', 'SUBSCRIBER', 'IP_LINK', 'NON_AES'] as UnitType[]).forEach(unitType => {
        ((entities.units[unitType] as unknown) as EntityAdapter<GeoUnitModel>).setAll(
          state.units[unitType],
          mapGeoUnits(groups[unitType] || [], unitType)
        );
      });
      state.loader = false;
    },
    fetchUnitsFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },

    // Select unit
    selectUnit<T>(state, { payload: { unitType, id } }: PayloadAction<{ unitType: UnitType; id: number }>) {
      const unit = state.units[unitType].entities[id];

      if (unit) {
        state.selected.unit = unit as T;
      }
    },
    clearSelectedUnit(state) {
      state.selected.unit = null;
    },

    // Select units
    selectUnits(state, { payload }: PayloadAction<GeoUnitModel[]>) {
      state.selected.units = payload;
    },

    // Select route
    selectRoute(state, { payload: { key, position } }: PayloadAction<{ key: number; position: GeoCoordinates }>) {
      const route = state.routes.entities[key];

      if (route) {
        state.selected.route = { ...route, position };
      }
    },
    clearSelectedRoute(state) {
      state.selected.route = null;
    },
    clearRoutes(state) {
      state.routes = initialState.routes;
    },

    // Fetch routes
    fetchRoutesInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader = true;
    },
    fetchRoutesSuccess(state, { payload }: PayloadAction<(UnitRoute & { key: number })[]>) {
      entities.routes.setAll(state.routes, payload);
      state.loader = false;
    },
    fetchRoutesFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },

    // Fetch faults by unit type
    fetchFaultsByUnitTypeInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader = true;
    },
    fetchFaultsByUnitTypeSuccess(state, { payload }: PayloadAction<GeoFaultsByUnitType>) {
      state.loader = false;
      if (keys(payload).length) {
        state.faults.byUnitType = payload;
        return;
      }

      state.faults.byUnitType = null;
    },
    fetchFaultsByUnitTypeFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },

    // Fetch faults by code
    fetchFaultsByCodeInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader = true;
    },
    fetchFaultsByCodeSuccess(state, { payload }: PayloadAction<GeoFaultsByCode>) {
      state.loader = false;
      if (keys(payload).length) {
        state.faults.byCode = payload;
        return;
      }

      state.faults.byCode = null;
    },
    fetchFaultsByCodeFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },
    clearFaults(state) {
      state.faults = initialState.faults;
    },

    // Fetch link layers
    fetchLinkLayersInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader = true;
    },
    fetchLinkLayersSuccess(state, { payload }: PayloadAction<GeoLinkLayers>) {
      state.loader = false;
      if (keys(payload).length) {
        state.linkLayers = {
          all: payload,
          ids: getLinkLayersIds(payload),
        };
        return;
      }

      state.linkLayers = null;
    },
    fetchLinkLayersFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },
    clearLinkLayers(state) {
      state.linkLayers = initialState.linkLayers;
    },

    // Fetch dependency
    fetchDependencyInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader = true;
    },
    fetchDependencySuccess(state, { payload }: PayloadAction<GeoDependency>) {
      state.loader = false;
      if (keys(payload).length) {
        state.dependency = {
          all: payload,
          ids: getDependencyIds(payload)
        };
        return;
      }

      state.dependency = null;
    },
    fetchDependencyFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },
    clearDependency(state) {
      state.dependency = initialState.dependency;
    },

    // Fetch geo center per bu
    fetchGeoCenterPerBUInit: (state, { payload }: PayloadAction<BusinessUnit['id']>) => {
      state.loader = true;
    },
    fetchGeoCenterPerBUSuccess: (
      state,
      { payload: { latitude, longitude } }: PayloadAction<BusinessUnitGeoCenter>
    ) => {
      state.loader = false;
      if (longitude || latitude) {
        state.geoCenterPerBU = { longitude, latitude };
        return;
      }

      state.geoCenterPerBU = null;
    },
    fetchGeoCenterPerBUFailed(state, action: PayloadAction<ResponseError>) {
      state.loader = false;
      state.error = action.payload;
    },

    // Set viewport
    setViewport(state, { payload }: PayloadAction<ViewportProps>) {
      state.viewport = payload;
    },

    // Set map layer type
    setMapLayerType(state, { payload }: PayloadAction<GeographyState['mapLayerType']>) {
      state.mapLayerType = payload;
    },

    // Set map filter
    setMapFilter(state, { payload }: PayloadAction<GeographyState['mapFilter']>) {
      state.mapFilter = payload;
    },

    // Clear
    clear(state) {
      state.units = initialState.units;
      state.routes = initialState.routes;
      state.selected = initialState.selected;
    },

    // Clear filtering
    clearFiltering(state) {
      state.faults = initialState.faults;
      state.routes = initialState.routes;
      state.linkLayers = initialState.linkLayers;
      state.dependency = initialState.dependency;
    },

    // Reset
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, defaultState),
  },
});

const getGeographyState = (state: AES.RootState) => state.geography;
const subscriberSelectors = entities.units.SUBSCRIBER.getSelectors<AES.RootState>(
  state => state.geography.units.SUBSCRIBER
);
const hybridSelectors = entities.units.HYBRID.getSelectors<AES.RootState>(state => state.geography.units.HYBRID);
const ipLinksSelectors = entities.units.IP_LINK.getSelectors<AES.RootState>(state => state.geography.units.IP_LINK);
const nonAESUnitsSelectors = entities.units.NON_AES.getSelectors<AES.RootState>(state => state.geography.units.NON_AES);
const routesSelectors = entities.routes.getSelectors<AES.RootState>(state => state.geography.routes);
const getUnits = createDraftSafeSelector(
  subscriberSelectors.selectAll,
  hybridSelectors.selectAll,
  ipLinksSelectors.selectAll,
  nonAESUnitsSelectors.selectAll,
  (subscribers, hybrid, ipLinks, nonAes) => [...subscribers, ...hybrid, ...ipLinks, ...nonAes]
);
const getSelectedUnit = createDraftSafeSelector(getGeographyState, state => state.selected.unit);
const getSelectedUnits = createDraftSafeSelector(getGeographyState, state => state.selected.units);

const getRoutes = routesSelectors.selectAll;
const getSelectedRoute = createDraftSafeSelector(getGeographyState, state => state.selected.route);

export const selectors = {
  getGeographyState,

  getSelectedBusinessUnitId: createDraftSafeSelector(getGeographyState, state => state.businessUnitId),
  getUnits,
  getSelectedUnit,
  getSelectedUnits,
  getCurrentUnits: (ids: number[]) => createDraftSafeSelector(
    getUnits,
    units => units?.filter(unit => ids?.includes(unit.id))
  ),

  getAllUnitsGeoCollection: createDraftSafeSelector(getUnits, units => mapUnitsToFeatureCollection(units)),
  getSelectedUnitGeoCollection: createDraftSafeSelector(getSelectedUnit, unit =>
    mapUnitsToFeatureCollection(unit ? [unit] : [])
  ),
  getSelectedUnitsGeoCollection: createDraftSafeSelector(getSelectedUnits, units =>
    mapUnitsToFeatureCollection(units ?? [])
  ),

  getRoutes,
  getRoutesGeoCollection: createDraftSafeSelector(
    getRoutes,
    subscriberSelectors.selectEntities,
    ipLinksSelectors.selectEntities,
    hybridSelectors.selectEntities,
    (routes, subscribers, ipLinks, hybrid) => {
      const values = mapRoutesWithGeolocation(routes, subscribers, ipLinks, hybrid);

      return mapRoutesToFeatureCollection(values);
    }
  ),
  getSelectedRoute,
  getSelectedRouteGeoCollection: createDraftSafeSelector(
    getSelectedRoute,
    subscriberSelectors.selectEntities,
    ipLinksSelectors.selectEntities,
    hybridSelectors.selectEntities,
    (route, subscribers, ipLinks, hybrid) => {
      const values = mapRoutesWithGeolocation(route ? [route] : [], subscribers, ipLinks, hybrid);

      return mapRoutesToFeatureCollection(values);
    }
  ),

  getGeoFaults: createDraftSafeSelector(getGeographyState, state => state.faults),
  areGeoFaults: createDraftSafeSelector(
    getGeographyState, state => Object.values(state.faults).every(value => value !== null)
  ),

  getLinkLayers: createDraftSafeSelector(
    getGeographyState, state => state.linkLayers
  ),
  getDependency: createDraftSafeSelector(getGeographyState, state => state.dependency),

  getGeoCenterPerBU: createDraftSafeSelector(getGeographyState, state => state.geoCenterPerBU),
  getGeoViewport: createDraftSafeSelector(getGeographyState, state => state.viewport),

  getMapLayerType: createDraftSafeSelector(getGeographyState, state => state.mapLayerType),
  getMapFilter: createDraftSafeSelector(getGeographyState, state => state.mapFilter),
  getMapLoader: createDraftSafeSelector(getGeographyState, state => state.loader),
};

const fetchUnitsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUnitsInit.match),
    mergeMap(({ payload: { businessUnitId } }) =>
      http.getJSON<(GeoUnitModel & { unitType: UnitType })[]>(api.geography.coordinates(businessUnitId)).pipe(
        mergeMap(units => of(actions.fetchUnitsSuccess(units))),
        catchError(err => of(actions.fetchUnitsFailed(getResponseError(err))))
      )
    )
  );

const fetchRoutesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchRoutesInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoUnitRoute[]>(api.geography.routes(payload)).pipe(
        mergeMap(routes => of(actions.fetchRoutesSuccess(mapRoutesWithKeyField(routes)))),
        catchError(err => of(actions.fetchRoutesFailed(getResponseError(err))))
      )
    )
  );

const fetchFaultsByUnitTypeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchFaultsByUnitTypeInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoFaultsByUnitType>(api.geography.faults.byUnitType(payload)).pipe(
        mergeMap(faults => of(actions.fetchFaultsByUnitTypeSuccess(faults))),
        catchError(err => of(actions.fetchFaultsByUnitTypeFailed(getResponseError(err))))
      )
    )
  );

const fetchFaultsByCodeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchFaultsByCodeInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoFaultsByCode>(api.geography.faults.byUnitCode(payload)).pipe(
        mergeMap(faults => of(actions.fetchFaultsByCodeSuccess(faults))),
        catchError(err => of(actions.fetchFaultsByCodeFailed(getResponseError(err))))
      )
    )
  );

const fetchLinkLayersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLinkLayersInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoLinkLayers>(api.geography.linkLayers(payload)).pipe(
        mergeMap(linkLayers => of(actions.fetchLinkLayersSuccess(linkLayers))),
        catchError(err => of(actions.fetchLinkLayersFailed(getResponseError(err))))
      )
    )
  );

const fetchDependencyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDependencyInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<GeoDependency>(api.geography.dependency(payload)).pipe(
        mergeMap(dependency => of(actions.fetchDependencySuccess(dependency))),
        catchError(err => of(actions.fetchDependencyFailed(getResponseError(err))))
      )
    )
  );

const fetchGeoCenterPerBUEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchGeoCenterPerBUInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<BusinessUnitGeoCenter>(
          api.geography.geoCenter(payload)
        )
        .pipe(
          mergeMap(res => of(actions.fetchGeoCenterPerBUSuccess(res))),
          catchError(err => of(actions.fetchGeoCenterPerBUFailed(getResponseError(err))))
        )
    )
  );

export const epics = combineEpics(
  fetchUnitsEpic,
  fetchRoutesEpic,
  fetchFaultsByUnitTypeEpic,
  fetchFaultsByCodeEpic,
  fetchLinkLayersEpic,
  fetchGeoCenterPerBUEpic,
  fetchDependencyEpic
);
export const allEpics = {
  fetchUnitsEpic,
  fetchRoutesEpic,
  fetchFaultsByUnitTypeEpic,
  fetchFaultsByCodeEpic,
  fetchLinkLayersEpic,
  fetchGeoCenterPerBUEpic,
  fetchDependencyEpic
};

declare global {
  namespace AES {
    export interface Actions {
      geography: typeof actions;
    }

    export interface Selectors {
      geography: typeof selectors;
    }

    export interface RootState {
      geography: GeographyState;
    }
  }
}
