import { Theme, createStyles } from '@material-ui/core';

import { matchStyles } from '~utils';

const showingClassName = 'showing';

export const styles = ({ palette: { greyColor, blueColor, error, type }, spacing }: Theme) => {
  const getStyles = matchStyles(type);

  return createStyles({
    paper: {
      padding: spacing(1, 0),
    },
    root: {
      width: '100%',
      color: greyColor.main,
      [`&.${showingClassName} .MuiInputBase-root`]: {
        color: getStyles(greyColor.light, blueColor.main),
      },
      [`&.${showingClassName} .MuiInput-underline:after`]: {
        display: 'none !important',
      },
      [`&.${showingClassName} .MuiInput-underline:before`]: {
        display: 'none !important',
      },
      [`&.${showingClassName} .MuiSelect-icon`]: {
        display: 'none !important',
      },
    },
    helperText: {
      color: error.main,
    },
  });
};
