import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  close: {
    cursor: 'pointer',
    background: `${palette.whiteColor.main} !important`,
  },
  icon: {
    background: `${palette.whiteColor.main} !important`,
    marginRight: spacing(1),
  },
}));

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    containerRoot: {
      alignItems: 'flex-end',
    },
    root: {
      maxWidth: 400,
      '& $variantError, & $variantInfo, & $variantWarning, & $variantSuccess': {
        padding: spacing(0, 2),
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'pre-line',
        '& a': {
          color: 'white',
        },
      },
    },
    variantError: {
      background: `${palette.error.main} !important`,
    },
    variantInfo: {
      background: `${palette.primary.main} !important`,
    },
    variantWarning: {
      background: `${palette.warning.main} !important`,
    },
    variantSuccess: {
      background: `${palette.success.main} !important`,
    },
  });
