import { AccordionDetails as MuiAccordionDetails, AccordionDetailsProps as MuiAccordionDetailsProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { styles } from './Accordion.styles';

type AccordionDetailsProps = MuiAccordionDetailsProps & WithStyles<typeof styles>;

export const AccordionDetails = withStyles(styles)((props: AccordionDetailsProps) => (
  <MuiAccordionDetails
    {...props}
    classes={{ root: props.classes.accordionDetails }}
    className={props.classes[props?.className ?? '']}
  >
    {props.children}
  </MuiAccordionDetails>
));
