import { Typography, withStyles, WithStyles } from '@material-ui/core';
import { useState } from 'react';

import { SelectOption } from '../Select/Select';

import { Chip } from './Chip';
import { styles } from './Chips.styles';

export type ChipsProps = WithStyles<typeof styles> & {
  title?: string;
  options: SelectOption[];
  selected?: SelectOption['value'][];
  onSelect(selected: SelectOption['value'][]): void;
};

export const Chips = withStyles(styles)(
  ({ title = '', options = [], selected = [], classes, onSelect }: ChipsProps) => {
    const [selectedOptions, setSelectedOptions] = useState<SelectOption['value'][]>(selected);

    const onOptionSelect = (e, value: SelectOption['value'], isSelected: boolean) => {
      e.stopPropagation();

      const updated = isSelected ? selectedOptions.filter(v => v !== value) : selectedOptions.concat(value);

      setSelectedOptions(updated);

      onSelect(updated);
    };

    return (
      <div className={classes.root}>
        {title ? (
          <Typography variant="h4" color="primary" className={classes.title}>
            {title}
          </Typography>
        ) : null}

        <ul className={classes.inner}>
          {options.map((item, i) => {
            const isSelected = selectedOptions.find(value => value === item.value);

            return (
              <Chip
                key={i}
                label={item.label}
                value={item.value}
                isSelected={isSelected}
                onOptionSelect={onOptionSelect}
              />
            );
          })}
        </ul>
      </div>
    );
  }
);
