import { Typography, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { styles } from './DataPlaceholder.styles';
import { getPlaceholderImageSrc } from './utils';

import { selectors } from '~store';

export type CardListPlaceholderImage = 'alerts' | 'no-results' | 'no-messages' | 'loop';

type CardListPlaceholderProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title?: string;
  image?: CardListPlaceholderImage;
};

export const DataPlaceholder = withStyles(styles)(
  ({ title = 'No Data', image = 'alerts', classes, children }: CardListPlaceholderProps) => {
    const theme = useSelector(selectors.theme.selectTheme);
    const imageSrc = getPlaceholderImageSrc(image, theme);

    return (
      <div className={classes.root}>
        <img src={imageSrc} className={clsx(classes.image, image)} />

        {title ? (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        ) : null}
        {children}
      </div>
    );
  }
);
