import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    menuItem: {
      '&.active': {
        color: theme.palette.primary.main,
      },
      '& .content': {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .title': {
        paddingRight: theme.spacing(1),
      },
    },
    menuItemActiveIcon: {
      background: `${theme.palette.primary.main} !important`,
    },
    actionButton: {
      minWidth: 'auto',
      padding: '6px 12px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 3, 1),
      '& button:last-child': {
        marginLeft: theme.spacing(1),
      },
    },
  });
