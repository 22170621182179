import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    input: {
      margin: spacing(-2.5, 2, 0, -3),

      '& input': {
        paddingLeft: spacing(4),

      },

      '& label': {
        paddingLeft: spacing(4),
      }
    },

    button: {
      maxHeight: spacing(3.2),
    }
  });
