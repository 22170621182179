import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) => {
  const borderStyles = `1px solid ${theme.palette.greyColor.dark}`;

  return createStyles({
    card: {},
    summary: {
      borderTop: borderStyles,
      borderBottom: borderStyles,
      borderRight: borderStyles,
    },
    details: {
      border: borderStyles,
      marginTop: -1,
      borderRadius: 4,
      boxShadow: 'none',
    },
  });
};
