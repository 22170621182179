import { createStyles, Typography, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

import { PagePanel, ProtectedContent } from '~components';
import { appRoutes } from '~constants';
import { PermissionName } from '~models';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    display: 'flex',
    margin: 'auto',
  },
  content: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
  },
});

type NoLicenseProps = WithStyles<typeof styles> & {
  hideTitle?: boolean;
};

export const NoLicense = withStyles(styles)(({ classes, hideTitle = false }: NoLicenseProps) => (
  <div className={classes.root}>
    <ProtectedContent permissions={[PermissionName.PROFILE_LICENSE_DETAILS]}>
      {!hideTitle && <PagePanel title="Go to Profile" backTo={appRoutes.profile} />}
    </ProtectedContent>

    <div className={classes.content}>
      <Typography variant="h2" color="primary" className={classes.title}>
        No license
      </Typography>
    </div>
  </div>
));
