import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing, palette: { greyColor } }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: spacing(2),
    },
    image: {
      marginBottom: spacing(2),
    },
    title: {
      color: greyColor.dark,
    },
  });
