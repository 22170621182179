import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette: { success, error, primary, greyColor } }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    },
    link: {
      color: error.main,
      textDecoration: 'none',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:hover span': {
        color: `${primary.main} !important`,
      },
      '& span': {
        '&.default': {
          color: greyColor.main,
        },
        '&.success': {
          color: success.main,
        },
        '&.error': {
          color: error.main,
        },
      },
    },
    contentField: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  });
