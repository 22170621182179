import {
  DialogContent as ModalContent,
  DialogContentProps as ModalContentProps,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import { styles } from './DialogContent.styles';

type DialogContentProps = ModalContentProps & WithStyles<typeof styles>;

export const DialogContent = withStyles(styles)(({ classes, children, ...props }: DialogContentProps) => (
  <ModalContent classes={{ root: clsx(classes.root) }} {...props}>
    {children}
  </ModalContent>
));
