import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { PropsWithChildren } from 'react';

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

type PageContentProps = PropsWithChildren<WithStyles<typeof styles>>;

export const PageContent = withStyles(styles)(({ classes, children }: PageContentProps) => (
  <div className={classes.root}>{children}</div>
));
