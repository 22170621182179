import { ButtonProps, Button } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Icon } from '../../Icon/Icon';

import { styles } from './AddMoreButton.styles';

type AddMoreButtonProps = ButtonProps & WithStyles<typeof styles>;

export const AddMoreButton = withStyles(styles)(({ children, classes, ...props }: AddMoreButtonProps) => (
  <Button {...props} variant="text" color="primary" classes={{ root: clsx(classes.root) }} disableRipple>
    <Icon icon="add" classes={{ root: clsx(classes.icon) }} />
    {children}
  </Button>
));
