import { IconType } from '~icons';
import { LiveTrafficAlarmType } from '~models';
import { CardStatus } from '~ui-kit';

export const getLiveTrafficPacketMeta = (type?: LiveTrafficAlarmType) => {
  let status: CardStatus = 'default';
  let title: string;
  let icon: IconType = 'info';

  switch (type) {
    case 'FIRE': {
      title = 'Fire alarm';
      status = 'error';
      icon = 'fire';
      break;
    }

    case 'MEDICAL': {
      title = 'Medical alarm';
      status = 'error';
      break;
    }

    case 'PANIC': {
      title = 'Panic alarm';
      status = 'error';
      break;
    }

    case 'BURGLARY': {
      title = 'Burglary alarm';
      status = 'warning';
      icon = 'burglary';
      break;
    }

    case 'SUPERVISORY': {
      title = 'Supervisory alarm';
      icon = 'system-outlined';
      break;
    }

    case 'OTHER': {
      title = 'Other event';
      break;
    }

    default:
      title = 'Unknown event';
      break;
  }

  return {
    status,
    title,
    icon,
  };
};
