import { withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Field, FieldProps, useFormikContext } from 'formik';

import { DatePickerField, DatePickerFieldProps } from '../Fields/DatePickerField';
import { Icon } from '../Icon/Icon';

import { styles } from './styles';

type FormDatePickerFieldProps = Partial<DatePickerFieldProps> &
  WithStyles<typeof styles> & {
    name: string;
  };

export const FormDatePicker = withStyles(styles)(({ name, classes, ...props }: FormDatePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }: FieldProps) => (
        <DatePickerField
          {...field}
          {...props}
          type="text"
          onChange={value => {
            setFieldValue(field.name, value);
          }}
          error={touched && Boolean(error)}
          helperText={(touched && error) || props.helperText}
          className={clsx(classes.root)}
          keyboardIcon={<Icon icon="calendar" />}
        />
      )}
    </Field>
  );
});
