import { equals, not } from 'ramda';

import { SideFiltersCategory } from './types';

export const getAppliedSideFiltersCount = (initial, values, omit: string[] = []): number =>
  Object.keys(initial)
    .filter(key => not(omit.includes(key)))
    .reduce((acc, key) => {
      if (not(equals(initial[key], values[key]))) {
        return acc + 1;
      }

      return acc;
    }, 0);

export const getAppliedSideFiltersCategories = (
  initial,
  values,
  categories: { [key: string]: SideFiltersCategory }
): { [key: string]: string } =>
  Object.keys(categories).reduce((acc, key) => {
    const category = categories[key];
    const formatter = category.formatter || ((value => value) as (value: number | string | Date) => string | number);

    if (not(equals(initial[key], values[key]))) {
      let label = category.options
        ? category.options?.find(option => option.value === values[key])?.label
        : formatter(values[key]);

      if (Array.isArray(values[key])) {
        if (category.range) {
          label = `${formatter(values[key][0])} - ${formatter(values[key][1])}`;
        } else {
          const labels = category.options
            ?.filter(option => values[key].includes(option.value))
            .map(option => option.label);
          label = labels?.join(', ');
        }
      }

      label = `${category.title}: ${label}`;

      acc[key] = label;
    }

    return acc;
  }, {});
