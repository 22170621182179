import { createStyles, FormHelperText, Theme, withStyles, WithStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { PageLoader } from '~components';
import { RequestStatus } from '~models';
import { boundActions, selectors } from '~store';
import { DialogContent, DialogActions, FormTextField, Required } from '~ui-kit';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is a required field'),
  newPassword: Yup.string().required('New password is a required field'),
  passwordRepeat: Yup.string()
    .required('You should confirm a New password')
    .oneOf([Yup.ref('newPassword'), null], 'Confirm Password should match to New password'),
});

const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {
      paddingBottom: spacing(0),
    },
    errorMessage: {
      padding: spacing(0, 0, 3, 0),
      whiteSpace: 'break-spaces',
    },
  });

type PasswordResetProps = PropsWithChildren<WithStyles<typeof styles>>;

export const PasswordReset = withStyles(styles)(({ classes, children }: PasswordResetProps) => {
  const error = useSelector(selectors.profile.getError);
  const passwordStatus = useSelector(selectors.profile.getStatus);
  const isLoader = passwordStatus === RequestStatus.Pending;

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        passwordRepeat: '',
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={({ currentPassword, newPassword }) => {
        boundActions.profile.updatePassword({
          currentPassword,
          newPassword,
        });
      }}
    >
      {({ values }) => {
        useEffect(() => {
          boundActions.profile.updatePasswordFailure(null);
        }, [values.newPassword, values.passwordRepeat, values.currentPassword]);

        return (
          <Form>
            {isLoader && <PageLoader fullscreen />}

            <DialogContent classes={{ root: classes.content }}>
              <FormTextField type="password" label={<Required>Current password</Required>} name="currentPassword" />
              <FormTextField type="password" label={<Required>New password</Required>} name="newPassword" />
              <FormTextField type="password" label={<Required>Confirm New password</Required>} name="passwordRepeat" />

              <FormHelperText className={classes.errorMessage} error>
                {error?.message}
              </FormHelperText>
            </DialogContent>

            <DialogActions>{children}</DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
});
