import { Theme } from '@material-ui/core';

export const styles = ({ palette: { error, whiteColor } }: Theme) => ({
  root: {
    cursor: 'pointer',
  },
  badge: {
    height: 14,
    padding: 0,
    fontSize: 11,
    fontWeight: 500,
    minWidth: 14,
    borderRadius: 7,
    color: whiteColor.main,
    backgroundColor: error.main,
  },
});
