import { Theme, createStyles, fade } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = ({
  palette: { type, blueColor, whiteColor, text, warning, success, background },
  spacing,
}: Theme) => {
  const getStyles = matchStyles(type);

  return createStyles({
    '@keyframes soundAnimation': {
      from: {
        boxShadow: `0px 0px 5px 0px ${fade(success.light, 0.5)}`,
      },
      to: {
        boxShadow: `0px 0px 5px 5px ${fade(success.light, 0.5)}`,
      },
    },
    '@keyframes soundOffAnimation': {
      from: {
        boxShadow: `0px 0px 5px 0px ${fade(warning.light, 0.5)}`,
      },
      to: {
        boxShadow: `0px 0px 5px 5px ${fade(warning.light, 0.5)}`,
      },
    },
    root: {
      padding: spacing(2, 0),
      color: text.primary,
      background: getStyles(blueColor.main, whiteColor.main),
      boxShadow: getStyles('0px 0px 16px rgba(0, 0, 0, 0.14)', '3px 0px 13px rgba(0, 0, 0, 0.06)') as string,
    },
    container: {
      padding: spacing(0, 3),
    },
    inner: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    stats: {
      display: 'flex',
      '& .content-field': {
        marginRight: spacing(3),
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },

    avatar: {
      marginRight: spacing(2),
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    userName: {
      width: 130,
    },

    serverTime: {
      display: 'flex',
      '& .time': {
        padding: '0px 2px',
        minWidth: 65,
        textAlign: 'center',
      },
    },
    sound: {
      alignSelf: 'center',
      minWidth: 100,
      padding: '1px 16px',
      color: background.default,
      backgroundColor: warning.main,
      borderColor: warning.main,
      animationName: '$soundOffAnimation',
      animationDuration: '0.5s',
      animationDirection: 'alternate',
      animationIterationCount: 'infinite',
      borderWidth: 1,
      borderStyle: 'solid',
      marginLeft: 'auto',
      '&:hover': {
        color: warning.main,
        borderColor: warning.main,
        backgroundColor: 'transparent',
      },
      '&.enabled': {
        backgroundColor: success.main,
        borderColor: success.main,
        animationName: '$soundAnimation',
        animationDuration: '0.5s',
        animationDirection: 'alternate',
        animationIterationCount: 'infinite',
        '&:hover': {
          color: success.main,
          borderColor: success.main,
          backgroundColor: 'transparent',
        },
      },
    },
  });
};
