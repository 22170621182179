import { Paper } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Fragment, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { PageLoader } from '~components/Page';

import { ScrollableDataListItem } from './ScrollableDataListItem';
import { ScrollableDataListLoader } from './ScrollableDataListLoader';

import { DataPlaceholder, Placeholder } from '~ui-kit';
import { debounce } from '~utils';

interface ScrollableDataListProps<DataType> {
  itemSize: number;
  estimatedItemSize?: number;
  data: DataType[];
  isLoading?: boolean;
  count: number;
  overscanCount?: number;
  threshold?: number;
  getItemSize?(value: DataType, itemSize: number, estimatedItemSize?: number): number;
  renderRow?(item: DataType, index: number);
  onLoadMore?(): void;
  associatedList?: boolean;
}

export const ScrollableDataList = <DataType extends unknown>({
  itemSize,
  estimatedItemSize,
  data,
  isLoading,
  count,
  overscanCount = 3,
  threshold = 2,
  renderRow = (item: DataType, index) => <div>{index}</div>,
  onLoadMore = () => {},
  getItemSize = () => itemSize,
  associatedList = false,
}: ScrollableDataListProps<DataType>) => {
  if (!data || !data.length) {
    if (isLoading) {
      return <PageLoader />;
    }

    if (associatedList) {
      return (<Paper>
        <Placeholder />
      </Paper>);
    }

    return <DataPlaceholder />;
  }

  const listRef = useRef<VariableSizeList | null>(null);
  const [scrollOffset, setOffsetTop] = useState(0);
  const hasMoreItems = data.length !== count;
  const itemsCount = hasMoreItems ? data.length + 1 : data.length;

  useEffect(() => {
    if (estimatedItemSize) {
      // TODO: Calculate items reset after index
      listRef.current?.resetAfterIndex(0);
    }
  });

  const onScrollHandler = debounce(({ scrollDirection, scrollOffset: value }, height) => {
    if (value === scrollOffset) {
      return;
    }

    setOffsetTop(value);

    const totalScroll = itemSize * itemsCount - height;
    const padding = itemSize * threshold;

    if (scrollDirection === 'forward' && value > totalScroll - padding && !isLoading && hasMoreItems) {
      onLoadMore();
    }
  }, 100);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Fragment>
          <VariableSizeList
            itemCount={itemsCount}
            itemSize={index => getItemSize(data[index], itemSize, estimatedItemSize)}
            estimatedItemSize={estimatedItemSize ?? itemSize}
            height={height}
            width={width}
            onScroll={params => onScrollHandler(params, height)}
            ref={listRef}
            itemData={data}
            overscanCount={overscanCount}
          >
            {({ index, data: inner, style }) => {
              if (hasMoreItems && inner.length === index && isLoading) {
                return <ScrollableDataListLoader style={style as CSSProperties} />;
              }

              return (
                <ScrollableDataListItem style={style as CSSProperties}>
                  {renderRow(inner[index], index)}
                </ScrollableDataListItem>
              );
            }}
          </VariableSizeList>
        </Fragment>
      )}
    </AutoSizer>
  );
};
