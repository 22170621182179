/* eslint-disable no-confusing-arrow */
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';

import { SubscriberSettingsMode, SubscriberSettingsSection } from '~features/subscribers/subscribersSlice';

import { getSubscriberModesValues } from './config';
import { getModeFields, GridItem } from './fields';
import { useSettingsModeChange } from './useSettingsModeChange';

import { Subscriber, ResponseError, SubscriberSettings } from '~models';
import { FormContent, FormPanelWrapper, FormSwitchField, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';

type SubscriberModesSettingsProps = {
  subscriber: Subscriber;
  loaders: {
    sections: { [key in SubscriberSettingsSection]: boolean };
    modes: { [key in SubscriberSettingsMode]: boolean };
    request: { [key in SubscriberSettingsSection]: boolean };
    'remote-reset': boolean;
    rf: boolean;
  };
  errors: ResponseError | null;
  timeFormat: string;
  onRefresh(section: SubscriberSettingsSection): void;
  onUpdateMode(value: {
    businessUnitId: Subscriber['id'];
    id: Subscriber['id'];
    mode: SubscriberSettingsMode;
    values: Partial<SubscriberSettings['modes']>;
    fieldName: string;
  });
};

export const SubscriberModesSettings = ({
  subscriber,
  loaders,
  errors,
  timeFormat,
  onRefresh,
  onUpdateMode,
}: SubscriberModesSettingsProps) => {
  const { id, businessUnitId } = subscriber;

  return (
    <Formik
      initialValues={getSubscriberModesValues(subscriber.settings?.modes)}
      enableReinitialize
      onSubmit={payload => {
        console.info(payload);
      }}
    >
      {({ values }) => {
        useSettingsModeChange(id, businessUnitId, subscriber, values, 'intellitap', 'intelliTap', onUpdateMode);
        useSettingsModeChange(id, businessUnitId, subscriber, values, 'repeater', 'repeater', onUpdateMode);
        useSettingsModeChange(id, businessUnitId, subscriber, values, 'line-cut', 'lineCut', onUpdateMode);

        return (
          <FormPanelWrapper
            title="Modes"
            loading={loaders.request.modes}
            onRefresh={() => onRefresh('modes')}
            subTitle={<LastDataUpdatedFormPanelSubtitle
              title="Last Updated: "
              timestamp={subscriber.settings?.modes?.lastUpdated}
              timeFormat={timeFormat}
            />}
          >
            <FormContent error={errors} loading={loaders.sections.modes}>
              <Grid container spacing={2}>
                {getModeFields(loaders).map((field, i) => (
                  <GridItem item xs={6} md={4} key={i}>
                    <FormSwitchField {...field} />
                  </GridItem>
                ))}
              </Grid>
            </FormContent>
          </FormPanelWrapper>
        );
      }}
    </Formik>
  );
};
