import { Typography } from '@material-ui/core';
import { FC, Fragment } from 'react';

import { PageLoader } from '~components';
import { ResponseError } from '~models';

export const FormContent: FC<{ error?: ResponseError | null; loading?: boolean }> = ({ error, loading, children }) => {
  if (error) {
    return <Typography>{error?.message}</Typography>;
  }

  if (loading) {
    return <PageLoader />;
  }

  return <Fragment>{children}</Fragment>;
};
