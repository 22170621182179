import { AjaxError, AjaxResponse } from 'rxjs/ajax';

import { api } from '~constants';
import { ResponseError } from '~models';

export const getResponseError = (err: AjaxError): ResponseError => ({
  ...err.response,
  message: err.response?.message || err.response?.error || 'Something went wrong',
  status: err.status,
});
export const getResponsePayload = <T>(res: AjaxResponse): T => res.response;

const isMethodAllowed = (method: string, methods: string[]) => methods.includes(method.toLocaleLowerCase());

export const shouldShowNotification = (url = '', method = '') => {
  switch (true) {
    case /^\/api\/business-units\/[0-9]*$/.test(url) && isMethodAllowed(method, ['delete']): {
      return false;
    }

    case url === api.users.updatePassword && isMethodAllowed(method, ['get']): {
      return false;
    }

    case url === api.users.profile && isMethodAllowed(method, ['get']): {
      return false;
    }

    default:
      return true;
  }
};
