import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette: { success, warning, error, greyColor }, spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingLeft: spacing(2),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: 0,
        width: 7,
        height: 7,
        transform: 'translateY(-50%)',
        borderRadius: '50%',
      },
      '&.success::before': {
        backgroundColor: success.main,
      },
      '&.warning::before': {
        backgroundColor: warning.main,
      },
      '&.error::before': {
        backgroundColor: error.main,
      },
      '&.inactive::before': {
        backgroundColor: greyColor.main,
      },
      '&.default::before': {
        backgroundColor: greyColor.main,
      },
    },
  });
