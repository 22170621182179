import { Button, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { RoutingLink } from '../Routing/RoutingLink';

import { styles } from './HealthScoreSection.styles';

import { LatestHelthScoreType, UnitID } from '~models';
import { getLinkByUnitType } from '~utils';

export type HealthScoreSectionProps = {
  title: string;
  point: number;
  businessUnitId: number;
  units: UnitID[];
  count: number;
  calls: LatestHelthScoreType;
  onViewAll: (calls: LatestHelthScoreType, title: string) => void;
};

export const HealthScoreSection = withStyles(styles)(
  ({
    title,
    point,
    units,
    businessUnitId,
    count,
    calls,
    onViewAll,
    classes,
  }: WithStyles<typeof styles> & HealthScoreSectionProps) => (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" color="primary">
          {count} {title}
        </Typography>
        <Typography variant="subtitle1">{point > 0 ? `-${point} Points` : `${point} Points`}</Typography>
      </div>

      {units.length ? (
        <div className={classes.links}>
          {units.map(({ unitId, unitType }) => (
            <RoutingLink key={unitId} id={unitId} linkTo={getLinkByUnitType(unitType, businessUnitId, unitId)} />
          ))}
          {units.length ? (
            <Button className={classes.button} size="small" onClick={() => onViewAll(calls, title)}>
              View All
            </Button>
          ) : (
            ''
          )}
        </div>
      ) : null}
    </div>
  )
);
