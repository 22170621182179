import { CircularProgress, createStyles, fade, Theme, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { memo } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      margin: 'auto',
      flexGrow: 1,
      height: '100%',
      '&.fullscreen': {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: fade(theme.palette.blackColor.main, 0.3),
      },
    },

    title: {
      marginTop: theme.spacing(2),
    },
  });

interface PageLoaderProps extends WithStyles<typeof styles> {
  title?: string;
  fullscreen?: boolean;
}

export const PageLoader = memo(
  withStyles(styles)(({ title = 'Please wait', fullscreen = false, classes }: PageLoaderProps) => (
    <div className={clsx(classes.root, { fullscreen })}>
      <CircularProgress size={30} />

      {title ? (
        <Typography variant="h4" color="primary" classes={{ root: clsx(classes.title) }}>
          {title}
        </Typography>
      ) : null}
    </div>
  ))
);
