import { Tooltip, Box, Button, IconButton } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { boundActions } from '~store';
import { Icon } from '~ui-kit';

export type ClipboardProps = PropsWithChildren<{
  text?: string;
  tipText?: string;
  isIconButton?: boolean;
}>;

export const Clipboard = ({ text, children, isIconButton = false, tipText }: ClipboardProps) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text ?? '').then(
      () => {
        boundActions.notifications.enqueue({
          message: 'Text was copied to clipboard',
          options: {
            variant: 'info',
          },
        });
      },
      err => {
        boundActions.notifications.enqueue({
          message: 'Could not copy the text to clipboard',
          options: {
            variant: 'error',
          },
        });
      }
    );
  };
  return (
    <>
      {isIconButton ? (
        <Tooltip title={tipText ?? ''} placement="top">
          <IconButton size="small" onClick={() => copyToClipboard()}>
            <Icon icon="copy" color="#3BAEDA" />
          </IconButton>
        </Tooltip>
      ) : (
        <Box display="flex" alignItems="center">
          <Tooltip title={text ?? ''}>
            <Box overflow="hidden" flexGrow={2} textOverflow="ellipsis">
              {children}
            </Box>
          </Tooltip>
          <Button size="small" onClick={() => copyToClipboard()}>
            Copy
          </Button>
        </Box>
      )}
    </>
  );
};
