import { values, omit, isNil } from 'ramda';
import * as yup from 'yup';

import { ReportingRoute, ReportingRouteSetting } from '~models';
import { hexIdValidator, hexToInt, intToHex } from '~utils';

export type ReportingRoutesFormValues = {
  routes: ReportingRoute[];
  manualRoute: string[];
  selected: string;
};

export const getInitialValues = (data: ReportingRouteSetting): ReportingRoutesFormValues => {
  const { ipLinkId } = data;
  const manualRoute = Array(8).fill('');
  const preparedRoutes = values(omit(['ipLinkId'], data)).filter(item => !isNil(item)) as ReportingRoute[];

  if (ipLinkId) {
    manualRoute[0] = intToHex(ipLinkId as number) as string;
  }

  return {
    routes: preparedRoutes,
    manualRoute,
    selected: '0',
  };
};

export const isManualPathSelected = (selected: string): boolean => selected === 'manual';

export const validationSchema = yup.object().shape({
  manualRoute: yup.array().when('selected', {
    is: isManualPathSelected,
    then: yup.array().of(
      hexIdValidator('Path should be between 0x0001 and 0xFFFF').test({
        name: 'manualPath',
        message: 'Empty first element',
        test(value) {
          const isFirstElementEmpty = this.path === `${yup.ref('manualRoute').path}[0]` && !value;

          if (isFirstElementEmpty) {
            return false;
          }

          return true;
        },
      })
    ),
    otherwise: schema => schema.optional(),
  }),
});

export const mapToOptions = (data: ReportingRoutesFormValues) => {
  const routeItems = data.routes.map((item: ReportingRoute, index) => ({
    label: `${item.label}: ${intToHex(item.route.cto)}`,
    value: String(index),
  }));

  const manualPathItem = {
    label: 'Manual RF Path',
    value: 'manual',
  };

  return [...routeItems, manualPathItem];
};

export const prepareReportingRouteRequestPayload = (values: ReportingRoutesFormValues) => {
  const manualPath = isManualPathSelected(values.selected);

  if (manualPath) {
    const routes = values.manualRoute.map(route => (route ? hexToInt(route) : 0));

    return {
      manualPath: true,
      route: {
        croute: routes,
        cto: hexToInt(values.manualRoute[0]),
      },
    };
  }

  return {
    manualPath: false,
    route: values.routes[values.selected].route,
  };
};
