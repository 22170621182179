import { IconButton } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { Fragment } from 'react';

import { styles } from './Accordion.styles';

import { Divider, Icon } from '~ui-kit';

type ExpandIconWrapperProps = WithStyles<typeof styles> & {
  opened: boolean;
  onClick: () => void;
};

export const ExpandIconWrapper = withStyles(styles)(({ opened, onClick, classes }: ExpandIconWrapperProps) => (
  <Fragment>
    <Divider spacing="top" />

    <IconButton className={classes.accordionIcon} onClick={onClick}>
      <Icon icon={opened ? 'up' : 'drop'} />
    </IconButton>
  </Fragment>
));
