import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  WithStyles,
  withStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';

import { Icon } from '~ui-kit/Icon/Icon';

import { styles } from './TextField.styles';

const showingClassName = 'showing';

export type TextFieldProps = WithStyles<typeof styles> &
  MuiTextFieldProps & {
    showing?: boolean;
    showPassword?: boolean; // for test purposes
    onClean?: () => void;
  };

export const TextField = withStyles(styles)(
  ({ className, showing, showPassword, onClean, ...props }: TextFieldProps) => {
    const showingClass = showing ? showingClassName : '';
    const isPassword = props.type === 'password';
    const [showAsPassword, toggleShowPassword] = useState(showPassword ?? isPassword);

    const adorments: ReactNode[] = [];

    if (onClean) {
      adorments.push(
        <IconButton key={'clear field'} size="small" aria-label="clear field" onClick={onClean}>
          <Icon icon={'close'} />
        </IconButton>
      );
    }

    if (isPassword) {
      adorments.push(
        <IconButton
          size="small"
          key={'toggle password visibility'}
          aria-label="toggle password visibility"
          onClick={() => toggleShowPassword(!showAsPassword)}
        >
          <Icon icon={(showAsPassword && 'show') || 'hide'} />
        </IconButton>
      );
    }

    return (
      <MuiTextField
        {...props}
        InputProps={
          (adorments.length && {
            endAdornment: <InputAdornment position="end">{adorments}</InputAdornment>,
          }) ||
          {}
        }
        type={(showAsPassword && 'password') || 'text'}
        disabled={props.disabled ?? showing}
        className={clsx(className, showingClass)}
      />
    );
  }
);
