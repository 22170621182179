import { Theme, createStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

export const styles = ({ palette: { background, primary, blueColor, greyColor, text }, spacing }: Theme) =>
  createStyles({
    chart: {
      padding: spacing(3),
      marginBottom: spacing(2),
      background: background.paper,
      '& #center-center': {
        height: 280,
      },
    },
    legend: {
      display: 'flex',
      flexWrap: 'wrap',
    } as CSSProperties,
    legendItem: {
      width: 'auto',
      paddingRight: 0,
    },
    legendLabel: {
      paddingRight: 0,
    },
    titleText: {
      textAlign: 'left',
      color: primary.main,
      fontWeight: 500,
    } as CSSProperties,
    axisLabel: {
      fontStyle: 'italic',
    },
    tooltip: {
      backgroundColor: blueColor.dark,
      padding: spacing(1, 2),
      borderRadius: spacing(0.5),
      lineHeight: 1.5,
    },

    tooltipArrow: {
      right: 'calc(50% - 10px)',

      '&::after': {
        backgroundColor: blueColor.dark,
      },
    },

    tooltipContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: text.primary,
      fontSize: 14,
    },

    tooltipIndicator: {
      display: 'flex',
      width: spacing(1),
      height: spacing(1),
      borderRadius: '100px',
      marginRight: spacing(1),
    },

    tooltipDate: {
      color: greyColor.main,
      fontStyle: 'italic',
      fontSize: 11,
    },
  });
