import { ConnectedRouter } from 'connected-react-router';
import { equals, isNil } from 'ramda';
import { Suspense, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import { getAlarms } from '~features/alarms/utils';

import { history } from './history';
import Auth from './pages/Auth/Auth';

import {
  Layout,
  ProtectedRoute,
  PageLoader,
  UploadMigratedDBDialog,
  FirstLoginStepper,
  ReportingRouteDialog,
  SelectMigratedBUDialog,
  LicenseDialog,
} from '~components';
import { appRoutes } from '~constants';
import { ProviderWrapper, ThemeWrapper } from '~hocs';
import { useSubscription, useAppLoaded, useAppHealthCheck } from '~hooks';
import { Alarm, ConnectivityAlarm, Notification } from '~models';
import { routes } from '~pages';
import { stomp } from '~services';
import { boundActions, selectors, store as defaultStore } from '~store';
import { SnackbarProvider, Notifier } from '~ui-kit';

import '~styles/fonts.css';

const Container = () => {
  const isAuthorized = useSelector(selectors.auth.isAuthorized);
  const accessToken = useSelector(selectors.auth.getAuthToken);
  const isWebsocketConnected = useSelector(selectors.app.isWebsocketConnected);
  const location = useLocation();

  useAppLoaded();
  useAppHealthCheck();

  // Subscriptions
  useSubscription<Alarm[]>('alarms/unacknowledged', async msg => {
    const { unacknowledged, test } = getAlarms(msg.data);

    boundActions.alarms.fetchAlarms({ alarms: unacknowledged, type: 'unacknowledged' });
    boundActions.alarms.fetchAlarms({ alarms: test, type: 'test' });
  });

  useSubscription<Alarm[]>('alarms/unacknowledged/remove', async msg => {
    const { unacknowledged, test } = getAlarms(msg.data);

    boundActions.alarms.removeAlarms({ alarms: unacknowledged, type: 'unacknowledged' });
    boundActions.alarms.removeAlarms({ alarms: test, type: 'test' });
  });

  useSubscription<ConnectivityAlarm[]>('connectivity', msg => {
    boundActions.alarms.fetchConnectivityAlarmsSuccess(msg.data);
  });

  useSubscription<Alarm[]>('alarms/system/unrestored', msg => {
    boundActions.alarms.fetchAlerts(msg.data);
  });

  useSubscription<Alarm[]>('alarms/system/restore', msg => {
    boundActions.alarms.restoreAlerts(msg.data);
  });

  useSubscription<{ count: number; alarm: Alarm }>('alarms/acknowledged', ({ data: { count, alarm } }) => {
    boundActions.alarms.setAlarmsCount({ count, tab: 'acknowledged' });

    if (alarm) {
      boundActions.alarms.updateAlarms({ alarm, type: alarm?.isTest ? 'test' : 'unacknowledged' });
    }
  });

  useSubscription<{
    unacknowledged: {
      add: Alarm[];
      remove: Alarm[];
    };
    acknowledged: {
      count: number;
      add: Alarm[];
    };
    alerts: {
      add: Alarm[];
      remove: Alarm[];
    };
  }>('alarms/sync', msg => {
    const { unacknowledged, acknowledged } = msg.data;

    if (unacknowledged.add.length) {
      const alarms = getAlarms(unacknowledged.add);

      boundActions.alarms.fetchAlarms({ alarms: alarms.unacknowledged, type: 'unacknowledged' });
      boundActions.alarms.fetchAlarms({ alarms: alarms.test, type: 'test' });
    }

    if (unacknowledged.remove.length) {
      const alarms = getAlarms(unacknowledged.remove);

      boundActions.alarms.removeAlarms({ alarms: alarms.unacknowledged, type: 'unacknowledged' });
      boundActions.alarms.removeAlarms({ alarms: alarms.test, type: 'test' });
    }

    if (acknowledged.add.length) {
      // TODO: bulk update
      acknowledged.add.forEach(alarm => boundActions.alarms.updateAlarms({ alarm, type: alarm?.isTest ? 'test' : 'unacknowledged' }));
    }

    if (acknowledged.count) {
      boundActions.alarms.setAlarmsCount({ tab: 'acknowledged', count: acknowledged.count });
    }
  });

  useSubscription<{ username: string; isLoggedOut: boolean }>('auth', ({ data: { isLoggedOut } }) => {
    if (isLoggedOut) {
      boundActions.auth.logoutSuccess();
    }
  });

  // Effect
  useEffect(() => {
    if (!accessToken) {
      return;
    }

    boundActions.auth.fetchCurrentUserInit({ loginOnSuccess: true });
  }, [accessToken]);

  useEffect(() => {
    if (isNil(isAuthorized)) {
      return;
    }

    if (!isAuthorized && accessToken) {
      stomp.disconnect(boundActions.app.setConnectionStatus);
      boundActions.auth.logoutInit();

      return;
    }

    if (isAuthorized) {
      stomp.connect(accessToken as string, boundActions.app.setConnectionStatus);
    }

    return () => {
      stomp.disconnect(boundActions.app.setConnectionStatus);
    };
  }, [isAuthorized]);

  useEffect(() => {
    if (isWebsocketConnected) {
      boundActions.alarms.fetchConnectivityAlarmsInit();
    }
  }, [isWebsocketConnected]);

  // Render
  if (isNil(isAuthorized) && location.pathname !== appRoutes.authenticate && Boolean(accessToken)) {
    return <PageLoader fullscreen />;
  }

  return (
    <Switch>
      <Route component={Auth} path={appRoutes.authenticate} />

      <Layout>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {routes.map((route, i) => (
              <ProtectedRoute fallback={appRoutes.authenticate} key={i} {...route} />
            ))}
          </Switch>
        </Suspense>
      </Layout>
    </Switch>
  );
};

const NotifierWrapper = () => {
  const notifications = useSelector(selectors.notifications.getNotifications, equals);

  const onNotificationRemove = useCallback((key: Notification['key']) => boundActions.notifications.remove(key), []);

  return <Notifier notifications={notifications} onRemove={onNotificationRemove} />;
};

export const App = () => (
  <ProviderWrapper store={defaultStore}>
    <ThemeWrapper>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        autoHideDuration={2000}
      >
        <NotifierWrapper />

        <ConnectedRouter history={history}>
          <Container />

          <FirstLoginStepper />

          <UploadMigratedDBDialog />

          <SelectMigratedBUDialog />

          <LicenseDialog />

          <ReportingRouteDialog />
        </ConnectedRouter>
      </SnackbarProvider>
    </ThemeWrapper>
  </ProviderWrapper>
);
