import {
  configureStore,
  combineReducers,
  AnyAction,
  Slice as ToolkitSlice,
  bindActionCreators,
  ActionCreatorsMapObject,
} from '@reduxjs/toolkit';
import withReduxEnhancer from 'addon-redux/enhancer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { save } from 'redux-localstorage-simple';
import { createEpicMiddleware, combineEpics, Epic } from 'redux-observable';

import { history } from './history';

import * as features from '~features';
import { RequestStatus } from '~models';
import { http } from '~services';

export const actions = ({} as unknown) as AES.Actions;
export const selectors = ({} as unknown) as AES.Selectors;
const reducers = {};
const epics: AES.Epics = [];

type State = ReturnType<typeof rootReducer>;

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, State>();

const slices = Object.values(features);

export const registerSlice = (slice: AES.Slice) => {
  if (slice.name) {
    if (slice.actions) {
      actions[slice.name] = slice.actions;
    }
    if (slice.reducer) {
      reducers[slice.name] = slice.reducer;
    }
    if (slice.selectors) {
      selectors[slice.name] = slice.selectors;
    }
    if (slice.epics) {
      epics.push(slice.epics);
    }
  }
};

slices.forEach(registerSlice);

const rootEpic = combineEpics(...epics);

const rootReducer = history => combineReducers({ ...reducers, router: connectRouter(history) });

export const store = configureStore({
  reducer: rootReducer(history),
  // @ts-ignore
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: true,
      serializableCheck: false,
    }).concat([
      save({
        states: [
          'theme',
          'layout',
          'auth',
          'app.dialogs',
          'geography.businessUnitId',
          'geography.mapLayerType',
          'geography.viewport',
          'geography.mapFilter'
        ],
      }),
      epicMiddleware,
      routerMiddleware(history),
    ]),
  enhancers: [withReduxEnhancer],
});

declare global {
  namespace AES {
    export interface ProcessProgress {
      total: number;
      loaded: number;
      completed: boolean;
    }
    export interface DataRepository<T> {
      status: RequestStatus;
      progress?: ProcessProgress;
      errorMessage: string;
      data: T;
    }
    export interface Actions {
      [key: string]: ActionCreatorsMapObject;
    }

    export interface Slice extends Partial<Omit<ToolkitSlice, 'caseReducers'>> {
      selectors?: {
        [key: string]: unknown;
      };
      epics?: Epic;
    }
    export type Epics = Epic[];

    export interface Selectors {
      [key: string]: unknown;
    }
  }
}

export const boundActions = ({} as unknown) as AES.Actions;
for (const key in actions) {
  if (actions[key]) {
    boundActions[key] = bindActionCreators(actions[key], store.dispatch);
  }
}

epicMiddleware.run(rootEpic);

http.setStore({ store, actions, selectors });
