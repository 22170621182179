import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { http } from '~services/http';
import { updateArrayWithCallback } from '~utils/data';

import { actions as sharedActions } from '../sharedSlice';

import { sortLiveTraffic } from './liveTrafficUtils';

import { LiveTrafficPacket, PaginationResponse } from '~models';

export interface LiveTrafficState {
  data: LiveTrafficPacket[];
  loader: boolean;
  recent: LiveTrafficPacket[];
}

export const initialState: LiveTrafficState = {
  data: [],
  loader: false,
  recent: [],
};

export const { name, reducer, actions } = createSlice({
  name: 'liveTraffic',
  initialState,
  reducers: {
    fetchLiveTrafficInit: (state, action: PayloadAction<{ apiUrl: string }>) => {
      state.loader = true;
    },

    fetchLiveTrafficSuccess(state, { payload }: PayloadAction<LiveTrafficPacket[]>) {
      state.loader = false;
      state.recent = updateArrayWithCallback(
        state.recent,
        sortLiveTraffic(payload)?.slice(0, 100)
      ) as LiveTrafficPacket[];
    },

    fetchLiveTrafficFailed: state => {
      state.loader = false;
    },

    addLiveTraffic: (state, { payload }: PayloadAction<LiveTrafficPacket[]>) => {
      state.loader = true;
      const liveTraffic = updateArrayWithCallback(
        state.data,
        sortLiveTraffic(payload)?.slice(0, 500)
      ) as LiveTrafficPacket[];
      state.data = liveTraffic.sort(
        (a: LiveTrafficPacket, b: LiveTrafficPacket) => (a.createdAt > b.createdAt && -1) || 1
      );
      state.loader = false;
    },

    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getLiveTrafficState = (state: AES.RootState) => state.liveTraffic;
export const selectors = {
  getLiveTrafficState,
  getLiveTraffic: createDraftSafeSelector(getLiveTrafficState, state => state.data),
  getRecentLiveTraffic: createDraftSafeSelector(getLiveTrafficState, state => state.recent),
  getLiveTrafficLoader: createDraftSafeSelector(getLiveTrafficState, state => state.loader),
};

const fetchLiveTrafficEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLiveTrafficInit.match),
    mergeMap(({ payload: { apiUrl } }) =>
      http.getJSON<PaginationResponse<LiveTrafficPacket>>(apiUrl).pipe(
        mergeMap(res => of(actions.fetchLiveTrafficSuccess(res.content))),
        catchError(() => of(actions.fetchLiveTrafficFailed()))
      )
    )
  );

export const epics = combineEpics(fetchLiveTrafficEpic);
export const allEpics = {
  fetchLiveTrafficEpic,
};
declare global {
  namespace AES {
    export interface RootState {
      liveTraffic: LiveTrafficState;
    }
    export interface Actions {
      liveTraffic: typeof actions;
    }
    export interface Selectors {
      liveTraffic: typeof selectors;
    }
  }
}
