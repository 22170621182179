export enum LicenseType {
  UP_TO_5000_UNITS = '1',
  UNLIMITED = '2',
  SELF_MONITORING = '3',
}

export type LicenseTier = {
  label: string;
  value: LicenseType;
};

export type LicenseDetails = {
  productKey: string;
  requestKey: string;
  licenseKey: string;
  availableLicenses: LicenseTier[];
  requestedLicenseType: LicenseTier;
  currentLicenseType: LicenseTier;
  requestedTimestamp: number;
  totalUnitsCount: number;
};

export type SelfMonitoringDetails = {
  lastUpdated: number,
  licenseKey: string,
  type: LicenseType,
  productKey: string,
  requestKey: string,
  requestedType: LicenseTier,
  requestedTimestamp: number,
};
