import { withStyles, WithStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { styles } from './ContentFieldChildren.styles';

export type ContentFieldChildrenProps = PropsWithChildren<WithStyles<typeof styles>>;

export const ContentFieldChildren = withStyles(styles)(({ classes, children }: ContentFieldChildrenProps) => (
  <div className={classes.root}>{children}</div>
));
