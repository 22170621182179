import { Box, createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef, HTMLProps, memo } from 'react';

import { styles } from './Icon.styles';

import { IconType } from '~icons';
import { matchStyles } from '~utils';

type IconProps = WithStyles<typeof styles> & {
  icon: IconType;
  size?: number | string;
  color?: string;
  hoverColor?: string;
  className?: string;
} & HTMLProps<HTMLSpanElement>;

export const Icon = memo(
  withStyles(styles)(
    forwardRef(({ classes, className, icon, size = 20, color, hoverColor, ...props }: IconProps, ref) => {
      const overrideClasses = makeStyles(({ palette: { type, greyColor } }: Theme) => {
        const getStyles = matchStyles(type);

        return createStyles({
          root: {
            maskSize: size,
            width: size,
            height: size,
            paddingRight: size,
            background: color ?? getStyles(greyColor.dark, greyColor.main),
            '&:hover': {
              background: hoverColor ?? getStyles(greyColor.light, greyColor.dark),
            },
          },
        });
      })();

      return (
        // @ts-ignore
        <Box
          component="i"
          className={clsx('icon', className, overrideClasses.root, classes.root, classes[icon])}
          ref={ref}
          {...props}
        />
      );
    })
  )
);
