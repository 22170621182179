import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      margin: spacing(-2, 4, -2, 0)
    },

    text: {
      marginRight: spacing(2),
      alignSelf: 'center'
    },

    input: {
      marginLeft: spacing(1),
    },

    button: {
      marginLeft: spacing(2),
      alignSelf: 'center'
    }
  });
