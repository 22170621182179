import alertsImageDark from '~assets/images/placeholders/alerts.svg';
import alertsImageLight from '~assets/images/placeholders/alertsLight.svg';
import loopImage from '~assets/images/placeholders/loop.svg';
import noMessagesDark from '~assets/images/placeholders/no-messages.svg';
import noResultsDark from '~assets/images/placeholders/no-results.svg';

import { CardListPlaceholderImage } from './DataPlaceholder';

type ImageMap = { dark: string; light: string };

const images: { [key in CardListPlaceholderImage]: ImageMap } = {
  alerts: {
    dark: alertsImageDark,
    light: alertsImageLight,
  },
  'no-results': {
    dark: noResultsDark,
    light: noResultsDark,
  },
  'no-messages': {
    dark: noMessagesDark,
    light: noMessagesDark,
  },
  loop: {
    dark: loopImage,
    light: loopImage,
  },
};

export const getPlaceholderImageSrc = (image: CardListPlaceholderImage, theme: 'dark' | 'light') => {
  const placeholder = images[image][theme];

  return placeholder ?? alertsImageDark;
};
