import { is } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { appRoutes } from '~constants';
import { boundActions, selectors } from '~store';

export const useAppLoaded = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get('accessToken');
  const pathname = params.get('pathname');
  const instanceNumber = useSelector(selectors.app.getAppInstanceNumber);
  const isLoaded = useSelector(selectors.app.isAppLoaded);

  useEffect(() => {
    if (accessToken) {
      boundActions.auth.setAuthToken({ accessToken, refreshToken: '' });

      history.push(pathname || appRoutes.root);
    }
  }, []);

  useEffect(() => {
    if (is(Number, instanceNumber) && !isLoaded) {
      boundActions.app.appLoad();
    }
  }, [instanceNumber, isLoaded]);
};
