import { Button, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DialogContent, DialogActions } from '~ui-kit/Dialog';

import { boundActions, selectors } from '~store';

const styles = () =>
  createStyles({
    content: {
      padding: '100px 125px !important',
      fontSize: '18px',
      lineHeight: 1.5,
      textAlign: 'center',
    },
  });

type ConnectSpeakerProps = WithStyles<typeof styles>;

export const ConnectSpeaker = withStyles(styles)(({ classes }: ConnectSpeakerProps) => {
  const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);
  const statusText = isSelfMonitoring ? '' : 'UL certified';

  const onCloseDialog = useCallback(
    () => boundActions.app.setAppDialog({ dialog: 'firstLoginStepper', value: false }),
    []
  );

  return (
    <Fragment>
      <DialogContent className={classes.content}>
        Please make sure you have {statusText} speaker connected!
      </DialogContent>

      <DialogActions>
        <Button type="submit" variant="contained" color="primary" onClick={onCloseDialog}>
          Ok
        </Button>
      </DialogActions>
    </Fragment>
  );
});
