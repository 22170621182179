/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
import { isAfter } from 'date-fns';
import { decode, sign, SignOptions } from 'jsonwebtoken';
import { isNil } from 'ramda';

class JWTService {
  isValid(token: string | null): boolean {
    if (isNil(token)) {
      return false;
    }

    try {
      const decoded = this.decode(token);

      if (isNil(decoded)) {
        return false;
      }

      return isAfter(new Date(decoded.exp * 1000), new Date());
    } catch (err) {
      return false;
    }
  }

  decode(token: string): { sub: string; exp: number; iat: number } | null {
    return decode(token) as { sub: string; exp: number; iat: number };
  }

  sign(payload: string | Buffer | object, secret = 'secret', options?: SignOptions) {
    return sign(payload, secret, options);
  }
}

export const jwt = new JWTService();
