import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: 'auto 0',
    },
    count: {
      fontSize: 56,
      lineHeight: 1,
      fontWeight: 500,
      color: theme.palette.success.main,
      margin: theme.spacing(0, 3, 0, 1),
    },
    title: {},
  });
