import { Typography, WithStyles, withStyles } from '@material-ui/core';
import { is } from 'ramda';

import { styles } from './ActiveUsersWidget.styles';

type ActiveUsersWidgetProps = WithStyles<typeof styles> & {
  activeUsersCount?: number;
};

export const ActiveUsersWidget = withStyles(styles)(({ activeUsersCount, classes }: ActiveUsersWidgetProps) => {
  if (!is(Number, activeUsersCount)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.count}>{activeUsersCount}</h2>
      <Typography variant="h3" color="primary" className={classes.title}>
        Active Users
      </Typography>
    </div>
  );
});
