import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    actionButton: {
      minWidth: 'auto',
      padding: '6px 12px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 3, 0),
      '& button:last-child': {
        marginLeft: theme.spacing(1),
      },
    },
    checkbox: {
      minHeight: 'auto',
    },
  });
