import { isNil } from 'ramda';
import { useEffect } from 'react';

import { SubscriberSettingsMode } from '~features/subscribers/subscribersSlice';
import { BusinessUnit } from '~models/businessUnit';
import { Subscriber } from '~models/subscriber';
import { mapBooleanFieldValueToNumber } from '~utils/forms';

import { ModesSettings } from './config';

export const useSettingsModeChange = (
  id: Subscriber['id'],
  businessUnitId: BusinessUnit['id'],
  subscriber: Subscriber,
  values: ModesSettings,
  mode: SubscriberSettingsMode,
  fieldName: keyof Omit<ModesSettings, 'route' | 'manualPath'>,
  onUpdateMode
) => {
  useEffect(() => {
    if (
      isNil(values[fieldName]) ||
      mapBooleanFieldValueToNumber(values[fieldName]) === subscriber.settings?.modes[fieldName]
    ) {
      return;
    }

    onUpdateMode({
      values: { [fieldName]: mapBooleanFieldValueToNumber(values[fieldName]) },
      id,
      businessUnitId,
      mode,
      fieldName,
    });
  }, [values[fieldName]]);
};
