import { MenuItem, withStyles, WithStyles, Button, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { equals, not } from 'ramda';
import { useState, MouseEvent, useEffect, useCallback, useMemo } from 'react';

import { styles } from './Sort.styles';
import { SortOption, SelectedSortOption, SortOrderType } from './types';
import { getSortIconType } from './utils';

import { IconType } from '~icons';
import { IconText, Menu, Icon } from '~ui-kit';

type SortProps = WithStyles<typeof styles> & {
  title?: string;
  selected?: SelectedSortOption | null;
  options: SortOption[];
  onSelect(item: SelectedSortOption | null): void;
};

export const Sort = withStyles(styles)(
  ({ options = [], selected = null, title = 'Sort', classes, onSelect }: SortProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedInner, setSelected] = useState<null | SelectedSortOption>(selected);

    useEffect(() => {
      if (not(equals(selected, selectedInner))) {
        setSelected(selected);
      }
    }, [selected]);

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
      setSelected(selected);
    }, [selected]);

    const onMenuItemSelect = useCallback(
      (item: SortOption) => {
        const isSelected = item.value === selectedInner?.value;
        const opositeOrder: SortOrderType = selectedInner?.order === 'asc' ? 'desc' : 'asc';
        const order = isSelected ? opositeOrder : 'desc';

        setSelected({ ...item, order });
      },
      [selectedInner]
    );

    const onSubmit = useCallback(() => {
      setAnchorEl(null);
      onSelect(selectedInner);
    }, [selectedInner]);

    const onReset = useCallback(() => {
      handleClose();
      onSelect(null);
    }, []);

    const getIconClasses = (isActive: boolean, prop = 'root') => {
      if (isActive) {
        return { [prop]: clsx(classes.menuItemActiveIcon) };
      }

      return {};
    };

    const iconTextProps = useMemo(() => {
      if (selected?.order) {
        return {
          color: theme.palette.primary.main,
          iconColor: theme.palette.primary.main,
        };
      }

      return {};
    }, [selected?.order]);

    return (
      <div>
        <Button variant="text" onClick={handleClick} className={classes.actionButton}>
          <IconText label={title} icon={getSortIconType(selected)} {...iconTextProps} />
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
        >
          {options.map(item => {
            const isActive = selectedInner?.value === item.value;
            const sortIcon: IconType = isActive ? getSortIconType(selectedInner) : 'sort-desc';

            return (
              <MenuItem
                key={item.value}
                className={clsx(classes.menuItem, { active: isActive })}
                onClick={() => onMenuItemSelect(item)}
              >
                <div className="content">
                  <span className="title">{item.label}</span>
                  <Icon icon={sortIcon} classes={getIconClasses(isActive)} />
                </div>
              </MenuItem>
            );
          })}

          <div className={classes.buttons}>
            <Button variant="outlined" color="primary" size="small" onClick={onReset}>
              Reset
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={onSubmit} disabled={not(selectedInner)}>
              Ok
            </Button>
          </div>
        </Menu>
      </div>
    );
  }
);
