import { Button, Grid, styled, Theme, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import { Fragment, useCallback, useEffect, useRef, useMemo, ElementType, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserTimeFormat } from '~features/users/usersUtils';
import { usePageLoaded } from '~hooks/usePageLoaded';

import {
  faultListFiltersCounterOmitFields,
  FaultsListCard,
  FaultsListFiltersCategories,
  getFaultsListFiltersFields,
  mapFaultsFiltersOptions,
  NoLicense,
  PageControls,
  PageDetailsNavigation,
  PageDetailsPanel,
  PaginatedDataList,
  prepareFiltersPayload,
  AssignedDealersDialog,
} from '~components';
import { api, FAULTS_AUTO_REFRESH_INTERVAL } from '~constants';
import { Fault, PageProps, Pagination, BusinessUnit, PageFilters, UnitID } from '~models';
import { boundActions, selectors } from '~store';
import {
  SideFilters,
  AppliedSideFilters,
  getAppliedSideFiltersCategories,
  getAppliedSideFiltersCount,
  Icon,
  TabSections,
  TabSectionItem,
} from '~ui-kit';
import { intToHex } from '~utils';

const RefreshButton = styled(Button)(({ theme }) => ({ marginRight: theme.spacing(2) }));
const RefreshIcon = withStyles((theme: Theme) => ({
  root: {
    background: `${theme.palette.primary.main} !important`,
    marginRight: theme.spacing(1),
  },
}))(Icon);
const FiltersSectionTitle = styled(Typography)(({ theme }) => ({ marginBottom: theme.spacing(1) }));

export type FaultsWithPaginationProps = PageProps & {
  title: string;
  restore?: boolean;
  businessUnitId?: number;
  backTo?: string;
  unit?: boolean;
  timeFormat?: string;
  section?: string;
  sectionItems?: TabSectionItem<string>[];
  setSection?: (section: string) => void;
  getPayloadFaults: (pagination: Pagination, filters?: PageFilters) => { apiUrl: string };
};

export const FaultsWithPagination = ({
  id,
  businessUnitId,
  query,
  routes,
  title,
  backTo,
  restore,
  unit,
  section,
  sectionItems,
  setSection,
  getPayloadFaults,
}: FaultsWithPaginationProps) => {
  const [unitID, setUnitID] = useState<UnitID | null>(null);
  const [unitDealersDialogOpened, setUnitDealersDialogOpened] = useState(false);

  const faults = useSelector(selectors.faults.getFaults, equals);
  const isFaultsLoading = useSelector(selectors.faults.isFaultsLoading);
  const pagination = useSelector(selectors.page.getPagePagination, equals);
  const businessUnit = useSelector(selectors.businessUnits.getBusinessUnit(id), equals) as BusinessUnit;

  const initialSideFiltersValues = useSelector(selectors.page.getSideFiltersInitialValues, equals);
  const sideFiltersValues = useSelector(selectors.page.getSideFiltersValues, equals);
  const isSideFiltersOpened = useSelector(selectors.page.isPageSideFiltersOpened);
  const isSideFiltersLoading = useSelector(selectors.page.isSideFiltersLoading);
  const sideFiltersCategories = useSelector(
    selectors.page.getSideFiltersCategories,
    equals
  ) as FaultsListFiltersCategories;
  const fields = useMemo(() => getFaultsListFiltersFields(sideFiltersCategories), [sideFiltersCategories]);
  const appliedCategories = useMemo(
    () => getAppliedSideFiltersCategories(initialSideFiltersValues, sideFiltersValues, sideFiltersCategories),
    [initialSideFiltersValues, sideFiltersValues, sideFiltersCategories]
  );
  const isLoading = isFaultsLoading || isSideFiltersLoading;

  const getAppliedFiltersCount = useCallback(
    (initial, values) => getAppliedSideFiltersCount(initial, values, faultListFiltersCounterOmitFields),
    []
  );

  const isPageNoLicense = useSelector(selectors.page.isPageNoLicense);
  const autoRefreshTimer = useRef<ReturnType<typeof setInterval>>();

  const account = useSelector(selectors.profile.getAccount, equals);
  const timeFormat = getUserTimeFormat(account);

  const isPageLoaded = usePageLoaded(
    () => {
      if (!businessUnit?.id) {
        return false;
      }

      boundActions.page.fetchSideFiltersOptionsInit({
        url: api.faults.listFilters(businessUnit?.id, section),
        mapFiltersOptions: mapFaultsFiltersOptions,
      });
    },
    () => {
      boundActions.faults.resetFaults();
    }
  );

  const onPaginationChange = useCallback(
    pagination => boundActions.faults.fetchFaultsInit(getPayloadFaults(
      pagination,
      prepareFiltersPayload(sideFiltersValues))),
    [sideFiltersValues]
  );

  const onRefreshFaults = useCallback(() => {
    boundActions.notifications.enqueue({
      message: `${title} faults requested`,
      options: {
        variant: 'success',
      },
    });

    boundActions.faults.fetchFaultsInit({
      ...getPayloadFaults({ ...pagination, number: -1 }),
      notify: true,
      message: `${title} faults updated`,
    });
  }, [section]);

  const onFiltersRefresh = useCallback(() => {
    boundActions.page.fetchSideFiltersOptionsInit({
      url: api.faults.listFilters(businessUnit.id, section),
      mapFiltersOptions: mapFaultsFiltersOptions,
    });
  }, [section]);

  useEffect(() => {
    if (isPageLoaded && Object.keys(sideFiltersValues).length) {
      boundActions.faults.fetchFaultsInit({
        ...getPayloadFaults(
          { ...pagination, number: -1 },
          prepareFiltersPayload(sideFiltersValues)
        ),
      });
    }
  }, [isPageLoaded, sideFiltersValues]);

  useEffect(() => {
    if (!businessUnit?.id) {
      boundActions.faults.fetchFaultsInit({ ...getPayloadFaults({ ...pagination, number: -1 }) });
    }

    if (autoRefreshTimer.current) {
      clearInterval(autoRefreshTimer.current);
    }

    autoRefreshTimer.current = setInterval(() => {
      boundActions.faults.fetchFaultsInit({
        ...getPayloadFaults(
          { ...pagination, number: -1 },
          prepareFiltersPayload(sideFiltersValues)
        ),
      });
    }, FAULTS_AUTO_REFRESH_INTERVAL * 1000);

    return () => {
      boundActions.faults.resetFaults();

      if (autoRefreshTimer.current) {
        clearInterval(autoRefreshTimer.current);
      }
    };
  }, [id, sideFiltersValues, section]);

  const onSideFiltersClose = useCallback(() => boundActions.page.toggleSideFilters(), []);
  const onSideFiltersReset = useCallback(() => boundActions.page.resetSideFiltersToInitialValues(), []);
  const onSideFiltersSubmit = useCallback(values => boundActions.page.setSideFiltersValues(values), []);
  const onSideFiltersClear = useCallback((key: string) => boundActions.page.clearCategoryFilters(key), []);

  const getType = () => {
    if (unitID?.unitType === 'IP_LINK') {
      return 'ipLinks';
    }

    return `${unitID?.unitType.toLowerCase()}s` as 'subscribers' | 'hybrids';
  };

  useEffect(() => {
    if (unitID?.unitType) {
      setUnitDealersDialogOpened(true);
    }
  }, [unitID]);

  if (isPageNoLicense) {
    return <NoLicense />;
  }

  return (
    <Fragment>
      <PageDetailsPanel
        id={id}
        backTo={(query?.backTo as string) || backTo}
        getPanelTitle={({ id }) => (businessUnit ? `${title}` : `${title}  ID ${intToHex(id as number)}`)}
        routes={routes}
      >
        <PageControls />

        <RefreshButton variant="text" color="primary" onClick={onRefreshFaults}>
          <RefreshIcon icon="update" />
          Refresh
        </RefreshButton>
      </PageDetailsPanel>

      {businessUnit ? (
        <Fragment>
          <SideFilters
            opened={isSideFiltersOpened}
            loading={isSideFiltersLoading}
            initialValues={initialSideFiltersValues}
            values={sideFiltersValues}
            getAppliedFiltersCount={getAppliedFiltersCount}
            onClose={onSideFiltersClose}
            onReset={onSideFiltersReset}
            onSubmit={onSideFiltersSubmit}
            onRefresh={onFiltersRefresh}
          >
            <Grid container spacing={2}>
              {fields.map(({ title, Component, ...props }: { Component: ElementType; title: string }, i) => (
                <Grid item xs={12} key={i}>
                  <FiltersSectionTitle variant="h4" color="primary">
                    {title}
                  </FiltersSectionTitle>
                  <Component {...props} />
                </Grid>
              ))}
            </Grid>
          </SideFilters>
          <AppliedSideFilters
            categories={appliedCategories}
            onFilterClear={onSideFiltersClear}
            onReset={onSideFiltersReset}
            onSortingClear={() => void {}}
          />
        </Fragment>
      ) : null}

      <PageDetailsNavigation
        routes={routes}
        query={query}
        tabsProps={businessUnit ? { variant: 'scrollable', scrollButtons: 'auto' } : {}}
        withPageControls={false}
      />

      {businessUnit && <TabSections
        section={section as string}
        items={sectionItems as TabSectionItem<string>[]}
        onChange={data => {
          setSection?.(data);
          boundActions.page.fetchSideFiltersOptionsInit({
            url: api.faults.listFilters(businessUnit?.id, data),
            mapFiltersOptions: mapFaultsFiltersOptions,
          });
        }}
      />}

      <PaginatedDataList<Fault>
        data={faults}
        onPaginationChange={onPaginationChange}
        loading={isLoading}
        renderItem={(fault: Fault, i) => (
          <FaultsListCard
            fault={fault}
            key={i}
            businessUnitId={businessUnitId ?? id}
            timeFormat={timeFormat}
            restore={restore}
            unit={unit}
            onClick={values => setUnitID(values)}
          />
        )}
      />
      {unitID?.unitId && (
        <AssignedDealersDialog
          data={{ id: unitID?.unitId, businessUnitId: businessUnitId ?? id }}
          type={getType()}
          open={unitDealersDialogOpened}
          onConfirm={() => setUnitDealersDialogOpened(false)}
        />
      )}
    </Fragment>
  );
};
