import { useCallback, useEffect, useState } from 'react';

import { debounce } from '~utils';

export const useWindowSize = () => {
  const [size, setSize] = useState<{ width?: number; height?: number }>({});

  const onResize = useCallback(
    debounce(() => setSize({ width: window.innerWidth, height: window.innerHeight })),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return size;
};
