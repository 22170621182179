import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: '0 !important',
    },
    titleRoot: {
      maxWidth: 175,
      width: '100%',
      whiteSpace: 'nowrap',
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    fieldsWrapper: {
      width: '100%',
    },
    field: {
      minWidth: 50,
      marginRight: 5
    },
    address: {
      width: '50%',

      '& > div': {
        minWidth: 100,
        maxWidth: 430,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',

      }
    },
    actions: {
      justifyContent: 'flex-end',
      maxWidth: 250,
      '&.expanded': {
        marginRight: spacing(5),
      },
    },
    buttons: {
      display: 'flex',
      minWidth: 260,
      justifyContent: 'space-between',
    },
    acknowledged: {
      minWidth: 155,

      '& .icon': {
        marginRight: spacing(1),
      },
    },
    cardSummary: {},
    cardDetails: {},
  });
