import { createStyles, Theme } from '@material-ui/core';

import { matchStyles } from '~utils';

export const styles = ({ palette, spacing }: Theme) => {
  const getStyles = matchStyles(palette.type);

  return createStyles({
    root: {
      display: 'flex',
      color: palette.primary.main,
      borderBottom: `1px solid ${getStyles(palette.greyColor.dark, palette.greyColor.main)}`,
      padding: spacing(2, 2, 2, 3),

      '&.error': {
        color: palette.error.main,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    iconButton: {
      margin: spacing(-1, -1, -1, 0),
    },
  });
};
