import { SwitchClassKey, StyleRules, Theme, darken, lighten } from '@material-ui/core';

import { matchStyles } from '~utils/styles';

type SwitchStyles = Partial<StyleRules<SwitchClassKey>>;

export const switchStyles = (theme: Theme): SwitchStyles => {
  const greyColor = matchStyles(theme.palette.type)(theme.palette.greyColor.light, theme.palette.greyColor.dark);
  const switchBaseGreyColor = matchStyles(theme.palette.type)(
    `${darken(greyColor, 0.5)} !important`,
    `${lighten(greyColor, 0.5)} !important`
  );

  return {
    root: {
      width: 56,
      height: 36,
      padding: 12,
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      opacity: 0.7,
      backgroundColor: greyColor,
    },
    switchBase: {
      color: greyColor,
      '&.Mui-disabled': {
        color: switchBaseGreyColor,
        '& + .MuiSwitch-track': {
          opacity: 0.35,
          backgroundColor: `${greyColor} !important`,
        },
      },
    },
  };
};
