import { WithStyles, withStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { styles } from './SideFiltersButtons.styles';

type SideFiltersButtonsProps = PropsWithChildren<WithStyles<typeof styles>>;

export const SideFiltersButtons = withStyles(styles)(({ classes, children }: SideFiltersButtonsProps) => (
  <div className={classes.root}>{children}</div>
));
