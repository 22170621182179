import { Button, FormHelperText, IconButton, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';

import { Icon } from '~ui-kit/Icon';

import { styles } from './FileSelectField.styles';

export type FileSelectFieldProps = WithStyles<typeof styles> &
  Partial<Pick<HTMLInputElement, 'accept' | 'multiple'>> & {
    label?: string;
    helperText?: string;
    disabled?: boolean;
    onChange: (files: File[]) => void;
    onRemove: (file: File, index: number) => void;
    files: File[];
  };

export const FileSelectField = withStyles(styles)(
  ({
    classes,
    label = 'Select file',
    helperText = '',
    disabled,
    onChange,
    onRemove,
    files,
    ...props
  }: FileSelectFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onFilesChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(Array.from(e.target.files as FileList));
      },
      [inputRef.current]
    );

    const onFilesSelect = useCallback(() => {
      inputRef.current?.click();
    }, [inputRef.current]);

    const onFileRemove = useCallback((file: File, index: number) => e => onRemove(file, index), [onRemove]);

    useEffect(() => {
      if (!files.length && inputRef.current?.value) {
        inputRef.current.value = '';
      }
    }, [files, inputRef.current]);

    return (
      <div className={classes.root}>
        <input type="file" ref={inputRef} hidden {...props} onChange={onFilesChange} />

        <ul className={classes.files}>
          {files.map((file, index) => (
            <li key={file.name} className={classes.file}>
              {file.name}
              <IconButton size="small" onClick={onFileRemove(file, index)} disabled={disabled}>
                <Icon icon="close" classes={{ root: classes.icon }} />
              </IconButton>
            </li>
          ))}
        </ul>

        <div className={classes.buttonWrapper}>
          {helperText && !files.length && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={onFilesSelect}
            disabled={disabled}
          >
            {label}
          </Button>
        </div>
      </div>
    );
  }
);
