import { FC, ReactNode } from 'react';

import { PagePanel } from '~components';
import { PageProps } from '~models';

type PageDetailsPanelProps = PageProps & {
  backTo?: string;
  getPanelTitle(props: Partial<PageProps>): ReactNode;
};

export const PageDetailsPanel: FC<PageDetailsPanelProps> = ({
  id,
  title,
  backTo,
  getPanelTitle = ({ id }) => id,
  children,
}) => (
  <PagePanel title={getPanelTitle({ id, title })} backTo={backTo}>
    {children}
  </PagePanel>
);
