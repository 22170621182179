import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing, palette: { greyColor } }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      '& .buttons': {
        marginLeft: spacing(2),
        '& button': {
          whiteSpace: 'nowrap',
          '&:last-child': {
            marginTop: spacing(1),
          },
        },
      },
    },
    placeholder: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      width: '100%',
      '& .image': {
        maxWidth: 50,
        position: 'relative',
        top: -2,
        marginRight: spacing(3),
      },
      '& .title': {
        color: greyColor.main,
        flexGrow: 1,
      },
      '& .buttons': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
      },
    },
  });
