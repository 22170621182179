import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';

import { TimeField, TimeFieldProps } from '../Fields/TimeField/TimeField';

import { styles } from './styles';

export type FormTimeFieldProps = Partial<TimeFieldProps> &
  WithStyles<typeof styles> & {
    name: string;
    cleanable?: boolean;
  };

export const FormTimeField = withStyles(styles)(
  ({ name, classes, cleanable, onClean, ...props }: FormTimeFieldProps) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Field name={name}>
        {({ field, meta: { error, touched } }) => (
          <TimeField
            {...props}
            {...field}
            error={touched && Boolean(error)}
            helperText={(touched && error) || props.helperText}
            classes={{ root: clsx(classes.root) }}
            onClean={(onClean || cleanable) && !props.showing ? () => setFieldValue(name, '') : undefined}
          />
        )}
      </Field>
    );
  }
);
