import * as Yup from 'yup';

import { appRoutes } from '~constants/index';
import { UnitType } from '~models/unit';
import { phoneNumber10DigitsValidator } from '~utils/validators';

const emptyMobileInfo = [{ mobileCarrierId: null, phoneNumber: '' }];

export const getInitialValue = notifications => {
  const newEvents = notifications?.allEvents.map(e => ({
    ...e,
    isEnabled:
      notifications?.events !== null ? notifications?.events.find(ev => ev.event === e.eventName)?.isEnable : false,
  }));

  return {
    events: newEvents,
    emails: notifications.emails || [],
    sendNotification: notifications.sendNotification,
    sendRestoral: notifications.sendRestoral,
    mobileInfo: notifications.mobileInfo || [],
  };
};

export const prepareRequestPayload = values => {
  const events = values.events.map(e => ({ event: e.eventName, isEnable: e.isEnabled }));
  const mobileInfo = values.mobileInfo?.length ? (values.mobileInfo).filter(e => e.phoneNumber) : [];
  const emails = values.emails?.length ? (values.emails).filter(e => e) : [];
  return {
    emails,
    mobileInfo,
    events,
    sendNotification: values.sendNotification,
    sendRestoral: values.sendRestoral
  };
};

export const getAppRoutes = (unitType: UnitType) => {
  switch (unitType) {
    case 'IP_LINK':
      return appRoutes.ipLinks;
    case 'SUBSCRIBER':
      return appRoutes.subscribers;
    case 'HYBRID':
      return appRoutes.hybrids;
  }
};

export const addMobileInfo = mobileInfo => {
  if (mobileInfo) {
    return [...mobileInfo, ...emptyMobileInfo];
  }

  return emptyMobileInfo;
};

export const removeMobileInfo = (mobileInfo, index: number) => {
  if (mobileInfo.length === 1) {
    return emptyMobileInfo;
  }

  return mobileInfo.filter((e, i) => i !== index);
};

export const addEmailField = email => {
  if (email) {
    return [...email, ''];
  }

  return '';
};

export const removeEmailField = (email, index: number) => {
  if (email.length === 1) {
    return [];
  }
  return email.filter((e, i) => i !== index);
};

export const notificationValidationSchema = Yup.object().shape({
  emails: Yup.array()
    .of(Yup.string().email(() => 'Invalid email address')),
  mobileInfo: Yup.array()
    .of(Yup.object().shape({
      phoneNumber: phoneNumber10DigitsValidator().nullable(true),
    }))
});
