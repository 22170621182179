import { format, addDays, differenceInCalendarDays } from 'date-fns';

import { PieData } from './PieChart';

const randomValue = (min: number, max: number) => Math.ceil(Math.random() * (max - min) + min);

export const generateChartData = <T extends unknown>(start, end, fields: string[]): T[] => {
  const data: T[] = [];
  const min = 0;
  const max = 350;
  for (let i: Date = start; differenceInCalendarDays(i, end) < 0; i = addDays(i, 1)) {
    const item = {
      arg: format(new Date(i), 'EEE d'),
    };

    fields.forEach(field => {
      item[field] = randomValue(min, max);
    });
    data.push(item as T);
  }

  return data;
};

export const generatePieChartData = (count: number) => {
  const data: PieData[] = [];
  const min = 0;
  const max = 350;
  let i = count;
  while (i--) {
    data.push({
      device: randomValue(0x1000, 0xffff).toString(16),
      signals: randomValue(min, max),
    });
  }

  return data;
};
