import { Grid, Tooltip } from '@material-ui/core';
import { Fragment } from 'react';

import { FormSwitchField } from '~ui-kit';

const eventItem = (name: string, label: string, index: number) =>
  <Tooltip title={name} placement="left" key={index} arrow>
    <Grid item xs={12} md={4} key={index}>
      <FormSwitchField
        activeText={label}
        text={label}
        name={`events[${index}].isEnabled`}
      />
    </Grid>
  </Tooltip>;

type UnitNotificationSettingsEventsListProps = {
    event: { faultCode: string, eventLabel: string },
    index: number,
}

export const UnitNotificationSettingsEventsList = ({ event, index }: UnitNotificationSettingsEventsListProps) => {
  if (index === 0) {
    return (
      <Fragment>
        <Grid item xs={12} md={4}>
          <FormSwitchField
            activeText={<b>Send Notification</b>}
            text={<b>Send Notification</b>}
            name="sendNotification"
          />
        </Grid>
        {eventItem(event.faultCode, event.eventLabel, index)}
      </Fragment>
    );
  }

  if (index === 2) {
    return (
      <Fragment>
        <Grid item xs={12} md={4}>
          <FormSwitchField
            activeText={<b>Fault Restoral</b>}
            text={<b>Fault Restoral</b>}
            name="sendRestoral"
          />
        </Grid>
        {eventItem(event.faultCode, event.eventLabel, index)}
      </Fragment>
    );
  }

  return eventItem(event.faultCode, event.eventLabel, index);
};
