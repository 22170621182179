import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { NotFound } from './NotFound/NotFound';

import { ProtectedRouteProps } from '~components';
import { appRoutes } from '~constants';
import { PermissionName } from '~models';

const Dashboard = lazy(async () => import('./Dashboard/Dashboard'));
const KioskMode = lazy(async () => import('./KioskMode/KioskMode'));
const BusinessUnits = lazy(async () => import('./BusinessUnits/BusinessUnits'));
const Subscribers = lazy(async () => import('./Subscribers/Subscribers'));
const Settings = lazy(async () => import('./Settings/Settings'));
const IPLinks = lazy(async () => import('./IPLinks/IPLinks'));
const Profile = lazy(async () => import('./Profile/Profile'));
const LiveTraffic = lazy(async () => import('./LiveTraffic/LiveTraffic'));
const Geography = lazy(async () => import('./Geography/Geography'));
const Help = lazy(async () => import('./Help/Help'));
const Users = lazy(async () => import('./Users/Users'));
const Hybrids = lazy(async () => import('./Hybrids/Hybrids'));
const Dealers = lazy(async () => import('./Dealers/Dealers'));
const SearchPage = lazy(async () => import('./Search/Search'));
const RelaysControl = lazy(async () => import('./RelaysControl/RelaysControl'));

export const routes: Omit<ProtectedRouteProps, 'access'>[] = [
  { component: Dashboard, path: appRoutes.dashboard },
  { component: KioskMode, path: appRoutes.kioskMode },
  {
    component: BusinessUnits,
    path: appRoutes.businessUnits,
    permissions: [PermissionName.BU_VIEW] && ([PermissionName.SUBSCRIBERS_VIEW] || [PermissionName.IP_LINK_VIEW]),
  },
  { component: Subscribers, path: appRoutes.subscribers, permissions: [PermissionName.SUBSCRIBERS_VIEW] },
  { component: Settings, path: appRoutes.settings, permissions: [PermissionName.SETTINGS_VIEW] },
  { component: IPLinks, path: appRoutes.ipLinks, permissions: [PermissionName.IP_LINK_VIEW] },
  { component: Profile, path: appRoutes.profile },
  { component: LiveTraffic, path: appRoutes.liveTraffic, permissions: [PermissionName.LIVE_TRAFFIC_VIEW] },
  { component: Geography, path: appRoutes.geography, permissions: [PermissionName.GEO_VIEW] },
  { component: Users, path: appRoutes.users, permissions: [PermissionName.USER_VIEW] },
  { component: Hybrids, path: appRoutes.hybrids, permissions: [PermissionName.SUBSCRIBERS_VIEW] },
  {
    component: () => <Redirect to={appRoutes.dashboard} />,
    path: appRoutes.root,
    exact: true,
  },
  { component: Help, path: appRoutes.help },
  { component: Dealers, path: appRoutes.dealers },
  {
    component: SearchPage,
    path: appRoutes.search,
    permissions: [PermissionName.BU_VIEW] && ([PermissionName.SUBSCRIBERS_VIEW] || [PermissionName.IP_LINK_VIEW]),
  },
  {
    component: RelaysControl,
    path: appRoutes.controlRelays,
    permissions: [PermissionName.CONTROL_RELAY_VIEW],
  },
  {
    component: NotFound,
  },
];
