import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    header: {
      alignItems: 'flex-start',
    },
    title: {},
    subTitle: {},
    chart: {
      padding: 0,
      boxShadow: 'none !important',
      flex: '1 1 auto',
    },
    actionButton: {
      padding: 0,
      paddingLeft: 8,
    },
  });
