import { styled, Typography } from '@material-ui/core';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { PageLoader, PageContent, PagePagination } from '../Page';

import { Pagination } from '~models';
import { selectors } from '~store';
import { DataPlaceholder } from '~ui-kit';

const CountTitleTypography = styled(Typography)(({ theme }) => ({ marginBottom: theme.spacing(2) }));

export interface PaginatedDataListProps<T> {
  data: T[];
  loading?: boolean;
  renderItem: (item: T, i: number) => unknown;
  onPaginationChange: (pagination: Pagination) => void;
  countTitle?: string;
}

export const PaginatedDataList = <T extends unknown>({
  data,
  loading,
  renderItem,
  onPaginationChange,
  countTitle = '',
}: PaginatedDataListProps<T>) => {
  const pagination = useSelector(selectors.page.getPagePagination, equals);

  if (loading) {
    return (
      <PageContent>
        <PageLoader />
      </PageContent>
    );
  }

  return (
    <Fragment>
      {countTitle ? (
        <CountTitleTypography color="primary" variant="h3">
          {countTitle}
        </CountTitleTypography>
      ) : null}

      <PageContent>{data?.length ? data.map(renderItem) : <DataPlaceholder />}</PageContent>

      {pagination.totalPages > 1 ? (
        <PagePagination pagination={pagination} loading={loading} onChange={onPaginationChange} />
      ) : null}
    </Fragment>
  );
};
