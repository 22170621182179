import { Divider as MuiDivider, DividerProps as MuiDividerProps, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';

import { styles } from './Divider.styles';

export type DividerProps = MuiDividerProps &
  WithStyles<typeof styles> & {
    spacing?: 'left' | 'right' | 'top' | 'bottom' | 'both';
  };

export const Divider = memo(
  withStyles(styles)(({ spacing, orientation = 'horizontal', classes, ...props }: DividerProps) => (
    <MuiDivider {...props} className={clsx(classes.root, spacing, orientation)} />
  ))
);
