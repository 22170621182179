import { FormControlLabel, FormControlLabelProps, CheckboxProps } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReactNode } from 'react';

import { Checkbox } from './Checkbox';
import { styles } from './CheckboxLabel.styles';

type CheckboxLabelProps = WithStyles<typeof styles> &
  CheckboxProps & Partial<FormControlLabelProps> & {
    label?: string | ReactNode;
  };

export const CheckboxLabel = withStyles(styles)(({ label = '', classes, ...props }: CheckboxLabelProps) => (
  <FormControlLabel control={props.control || <Checkbox {...props} />} classes={classes} label={label} />
));
