import { WithStyles, CircularProgress, Box, Typography, withStyles } from '@material-ui/core';

import { styles } from './Progress.styles';

export type ProgressProps = WithStyles<typeof styles> & {
  value?: number;
  label?: string;
  loaded?: number;
  total?: number;
  completed?: boolean;
};

const bytesToMB = (bytes: number) => `${Math.round((bytes * 100) / (1 << 20)) / 100}MB`;

export const Progress = withStyles(styles)(({ classes, value, label, loaded, total }: ProgressProps) => {
  const progress = value ?? (total && loaded && (loaded / total) * 100);
  const progressLabel = label ?? (progress && `${Math.round(progress)}%`);
  let sizeProgressLabel = '';

  if (loaded) {
    sizeProgressLabel = `${bytesToMB(loaded)}`;
    if (total) {
      sizeProgressLabel += `/${bytesToMB(total)}`;
    }
  }

  return (
    <Box className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant={(progress && 'determinate') || 'indeterminate'}
          {...((progress && { value: progress }) || {})}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {progressLabel}
          </Typography>
        </Box>
      </Box>
      {sizeProgressLabel && (
        <Box className={classes.sizeProgress}>
          <Typography variant="caption" component="div" color="textSecondary">
            {sizeProgressLabel}
          </Typography>
        </Box>
      )}
    </Box>
  );
});
