import { Theme } from '@material-ui/core';

import { matchStyles } from '~utils';

const showingClassName = 'showing';

export const styles = ({ palette: { type, greyColor, blueColor } }: Theme) => {
  const getStyles = matchStyles(type);

  return {
    root: {
      width: '100%',
      color: greyColor.main,
      [`&.${showingClassName} .MuiInputBase-root`]: {
        color: getStyles(greyColor.light, blueColor.main),
      },
      [`&.${showingClassName} .MuiInput-underline:after`]: {
        display: 'none !important',
      },
      [`&.${showingClassName} .MuiInput-underline:before`]: {
        display: 'none !important',
      },
    },
  };
};
