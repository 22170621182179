import { Button } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { FormEvent, useCallback, useEffect, useState } from 'react';

import { FileSelectField, FileSelectFieldProps } from '~ui-kit/Fields';

import { styles } from './FileUploadForm.styles';

type FileUploadFormProps = WithStyles<typeof styles> &
  Partial<Pick<FileSelectFieldProps, 'accept' | 'multiple' | 'helperText' | 'label'>> & {
    onSubmit(files: File[]): void;
    submitButtonProps?: {
      label?: string;
      disabled?: boolean;
    };
    reset?: boolean;
  };

export const FileUploadForm = withStyles(styles)(
  ({ classes, onSubmit, reset, submitButtonProps, ...props }: FileUploadFormProps) => {
    const [files, setFiles] = useState<File[]>([]);

    const onFileRemove = useCallback(
      (file: File, index: number) => {
        setFiles(files.filter((f, i) => i !== index));
      },
      [files, setFiles]
    );

    const onFormSubmit = useCallback(
      (e: FormEvent) => {
        e.preventDefault();

        onSubmit(files);
      },
      [files]
    );

    useEffect(() => {
      if (reset && files.length) {
        setFiles([]);
      }
    }, [reset]);

    return (
      <form className={classes.root} onSubmit={onFormSubmit}>
        <FileSelectField
          onChange={setFiles}
          onRemove={onFileRemove}
          files={files}
          disabled={submitButtonProps?.disabled}
          {...props}
        />

        {files.length ? (
          <Button variant="contained" color="primary" type="submit" disabled={submitButtonProps?.disabled}>
            {submitButtonProps?.label || 'Submit'}
          </Button>
        ) : null}
      </form>
    );
  }
);
