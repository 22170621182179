import { WithStyles, withStyles, Theme, Button } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { FormTextField, Icon } from '~ui-kit';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: spacing(2, 0),
    },

    input: {
      margin: spacing(-2.5, 2, 0, -3),

      '& input': {
        paddingLeft: spacing(4),

      },

      '& label': {
        paddingLeft: spacing(4),
      }
    },

    button: {
      maxHeight: spacing(3.2),
    }
  });

type SearchInputProps = WithStyles<typeof styles> & {
  inputName: string;
  isValues: boolean;
  disabled?: boolean;
  onFind?: (values) => void;
  onClean?: () => void;
  cleanable?: boolean;
  onKeyPress?: (values) => void;
};

export const UnitsSearch = withStyles(styles)(
  ({ classes, isValues, inputName, onClean, cleanable, onKeyPress, disabled = false }: SearchInputProps) => (
    <div className={classes.root}>
      <Icon icon="search" />

      <FormTextField
        className={classes.input}
        name={inputName}
        label={'Search by Unit ID'}
        cleanable={cleanable}
        onClean={onClean}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />

      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        size="small"
        disabled={!isValues}
      >
        Search
      </Button>
    </div>
  )
);
